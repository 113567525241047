import axios from "axios"
import { store } from "../store/store"
import { EventBus } from "../EventBus"
const axiosScream = axios.create({
  //baseURL: process.env.VUE_APP_BASE_URL
  //baseURL: "http://10.10.10.11:7780/"
  // baseURL: "http://192.168.10.39:7780/"
})
//console.log("axios------------- ", Vue.prototype.$config)

// axiosScream.defaults.headers.common["Content-type"] = "application/json"
// axiosScream.defaults.headers.common["Auth-Token"] = "ABCDEFGHi3klmnop"
axiosScream.defaults.headers.common["Cache-Control"] = "no-cache"
//axiosScream.defaults.headers.common.Authorization = sessionStorage.sessionId

axiosScream.interceptors.response.use(
  function(response) {
    // Do something with response data
    //console.log("axios response ", response.headers.date)
    store.commit("serverDate", response.headers.date)
    //const dat = new Date(response.headers.date)
    //console.log("axios response dat ", dat)
    return response
  },
  function(error) {
    //console.log("error1 ", error)
    //console.log("error1 ", error)
    //console.log("error2 ", error.message)
    //console.log("error3 ", error.response)
    //console.log("error4 ", error.toJSON())
    // for (const er in error) {
    //   console.log("err1 ", er)
    //   console.log("err2 ", error[er])
    // }
    const salert = { message: error.message, timeOut: 8000, color: "error" }
    if (error.message == "Network Error") {
      salert.message = "Problemă de comunicare cu serverul"
    }
    if (error.response) {
      if (error.response.data._error) {
        salert.message = error.response.status + ": " + error.response.data._error.message
      }
      ///console.log("error.response.status ", error.response.status)
      if (error.response.data._issues) {
        //console.log("msg err", geterrorMessages(error.response.data._issues, "", ""))

        salert.message =
          error.response.status +
          ": " +
          error.response.statusText +
          "###" +
          geterrorMessages(error.response.data._issues, "", "")
      }
      if (error.response.status == 401) {
        store.dispatch("logOut")
      }
    } else if (error.request) {
      // salert.message = "Bad request"
    } else {
      // salert.message = error.message
    }

    EventBus.$emit("show-alert", salert)
    return Promise.reject(error)
  }
)
function geterrorMessages(errors, messages, path) {
  for (const err in errors) {
    if (typeof errors[err] == "string") {
      messages += path + "." + err + ": " + errors[err] + "###"
    } else {
      if (path) {
        messages += geterrorMessages(errors[err], "", path + "." + err)
      } else {
        messages += geterrorMessages(errors[err], "", err)
      }
    }
  }
  return messages
}

export default axiosScream

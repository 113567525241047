<template>
  <v-row dense>
    <v-col v-for="(fld, ind) in value" :key="'461' + ind" cols="12" sm="6" md="4" lg="3">
      <span class="d-none">{{ render }}</span>
      <span class="d-none">{{ ren }}</span>
      <v-sheet
        v-if="!fld.link && !details[ind].title"
        class="d-flex justify-center align-center"
        elevation="3"
        width="100%"
        height="100%"
        :min-height="height"
        @click="$emit('search', ind)"
      >
        <v-btn color="cyan" fab x-small>
          <v-icon>
            mdi-plus
          </v-icon>
        </v-btn>
      </v-sheet>
      <v-hover v-else v-slot:default="{ hover }">
        <v-sheet class="py-3 pl-4 pr-2 subtitle-1" style="position: relative" elevation="3" width="100%" height="100%">
          <div v-if="details[ind].title && details[ind]._id" class="mb-0">
            <v-tooltip v-if="collection == 'ebib_marc_mon'" top>
              <template v-slot:activator="{ on }">
                <v-icon class="mr-1" v-on="on">mdi-book</v-icon>
              </template>
              {{ $t("Titlu") }}
            </v-tooltip>
            <v-tooltip v-else-if="collection == 'ebib_marc_ath'" top>
              <template v-slot:activator="{ on }">
                <v-icon class="mr-1" v-on="on">mdi-account</v-icon>
              </template>
              {{ $t("Nume personal") }}
            </v-tooltip>
            <v-tooltip v-else-if="collection == 'ebib_marc_cln'" top>
              <template v-slot:activator="{ on }">
                <v-icon class="mr-1" v-on="on">mdi-account</v-icon>
              </template>
              {{ $t("Cititor") }}
            </v-tooltip>
            {{ details[ind].title }}
          </div>
          <div v-else class="mb-0">
            <v-text-field
              :value="details[ind].title"
              readonly
              label="462t Nivel subset"
              :error-messages="$t('Valoarea nu există!')"
            />
            <v-btn color="info" small @click="addData(ind)">{{ $t("Creează") }}</v-btn>
          </div>
          <div v-if="details[ind].forma_preferata" class="mb-0">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon class="mr-1" v-on="on">mdi-account-check</v-icon>
              </template>
              {{ $t("Forma preferată") }}
            </v-tooltip>
            {{ details[ind].forma_preferata }}
          </div>
          <div v-if="details[ind].barcode" class="mb-0">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon class="mr-1" v-on="on">mdi-barcode</v-icon>
              </template>
              {{ $t("Barcode") }}
            </v-tooltip>
            {{ details[ind].barcode }}
          </div>
          <div v-if="details[ind].forma_nepreferata" class="mb-0">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon class="mr-1" v-on="on">mdi-account-cancel</v-icon>
              </template>
              {{ $t("Forma nepreferată") }}
            </v-tooltip>
            {{ details[ind].forma_nepreferata }}
          </div>
          <div v-if="details[ind].autor" class="mb-0">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon class="mr-1" v-on="on">mdi-account</v-icon>
              </template>
              {{ $t("Autor principal") }}
            </v-tooltip>
            {{ details[ind].autor }}
          </div>
          <div v-if="details[ind].localitate" class="mb-0">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon class="mr-1" v-on="on">mdi-earth</v-icon>
              </template>
              {{ $t("Localitate") }}
            </v-tooltip>
            {{ details[ind].localitate }}
          </div>
          <div v-if="details[ind].editura" class="mb-0">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon class="mr-1" v-on="on">mdi-alpha-e-box</v-icon>
              </template>
              {{ $t("Editura") }}
            </v-tooltip>
            {{ details[ind].editura }}
          </div>
          <div v-if="details[ind].an" class="mb-0">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon class="mr-1" v-on="on">mdi-calendar</v-icon>
              </template>
              {{ $t("An") }}
            </v-tooltip>
            {{ details[ind].an }}
          </div>
          <div v-if="fld['4'] && show7024" class="d-flex">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon class="mr-1 mt-1" v-on="on">mdi-account-question</v-icon>
              </template>
              {{ $t("Funcție") }}
            </v-tooltip>
            <!-- <v-text-field v-model="fld['4'][0].val" hide-details dense /> -->
            <s-autocomplete
              v-model="fld['4'][0].val"
              dense
              hide-details
              :clearable="false"
              view-type="marc"
              placeholder="Funcție"
              :item-text="'fields.702.4.val'"
              :item-value="'fields.702.4.val'"
              collection="ebib_marc_mon"
            />
          </div>
          <div v-if="fld['4'] && show7124" class="d-flex">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon class="mr-1 mt-1" v-on="on">mdi-account-question</v-icon>
              </template>
              {{ $t("Funcție") }}
            </v-tooltip>
            <!-- <v-text-field v-model="fld['4'][0].val" hide-details dense /> -->
            <s-autocomplete
              v-model="fld['4'][0].val"
              dense
              hide-details
              :clearable="false"
              view-type="marc"
              placeholder="Funcție"
              :item-text="'fields.712.4.val'"
              :item-value="'fields.712.4.val'"
              collection="ebib_marc_mon"
            />
          </div>
          <div v-if="fld['4'] && show7224" class="d-flex">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon class="mr-1 mt-1" v-on="on">mdi-account-question</v-icon>
              </template>
              {{ $t("Funcție") }}
            </v-tooltip>
            <!-- <v-text-field v-model="fld['4'][0].val" hide-details dense /> -->
            <s-autocomplete
              v-model="fld['4'][0].val"
              dense
              hide-details
              :clearable="false"
              view-type="marc"
              placeholder="Funcție"
              :item-text="'fields.722.4.val'"
              :item-value="'fields.722.4.val'"
              collection="ebib_marc_mon"
            />
          </div>
          <v-sheet style="position: absolute;top:5px;right:5px;float:right" class="mr-0 mt-0 d-flex">
            <isbd-info :id="fld.link" :prefix="prefix" :show="hover" />
            <v-btn v-show="hover" color="grey darken-1" icon small @click="$emit('del', ind)">
              <v-icon>
                mdi-delete
              </v-icon>
            </v-btn>
          </v-sheet>
        </v-sheet>
      </v-hover>
    </v-col>
    <component :is="currentEditForm" ref="addDataPopUp" @mounted="addDataMounted" @reload="recordAdded" />
  </v-row>
</template>
<script>
import axios from "../../plugins/axios"
import IsbdInfo from "./IsbdInfo"
import SAutocomplete from "../inputs/Autocomplete"
export default {
  name: "LinkFields",
  components: { SAutocomplete, IsbdInfo },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    height: {
      type: String,
      default: "50px"
    },
    render: {
      type: Number,
      default: 1
    },
    width: {
      type: Number,
      default: 100
    },
    collection: {
      type: String,
      default: ""
    },
    show7024: {
      type: Boolean,
      default: false
    },
    show7124: {
      type: Boolean,
      default: false
    },
    show7224: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    details: [],
    ren: 1,
    componentToDisplay: null,
    category: "monography",
    addRec: null,
    addRecInd: null
  }),
  computed: {
    prefix() {
      return this.collection.split("_")[2]
    },
    currentEditForm: function() {
      if (this.componentToDisplay) {
        this.$log("currentEditForm")
        return () => import("../../views/settings/AddDataPopUp.vue")
      }
      return null
    }
  },
  watch: {
    render() {
      if (this.value[this.value.length - 1].link) {
        this.details.push({})
        this.getDetails(this.value[this.value.length - 1].link, this.value.length - 1)
        this.$emit("add", this.value.length)
      }
    },
    value() {
      this.refreshData()
    }
  },
  created() {
    //this.$log("created link ", this.value)
    this.refreshData()
  },
  methods: {
    addData(e) {
      this.addRecInd = e
      //this.addRec = this.details[this.addRecInd].title
      this.launchEditForm()
    },
    launchEditForm() {
      this.$log("launchEditForm")
      this.componentToDisplay = "views/settings/AddData.vue"
      if (this.$refs.addDataPopUp) {
        this.addDataMounted()
      }
    },
    addDataMounted(e) {
      //this.$log("addDataMounted ", e)
      const col = "marc_mon"
      let view
      const tables = this.$store.getters.appSchema.filter(itm => itm.collection == col)
      const schema = this.$store.getters.appViewCollection(tables[0].collection)
      if (schema.findIndex(itm => itm.ctg == this.category) > -1) {
        if (schema.findIndex(itm => itm.ctg == this.category && itm.default) > -1) {
          view = schema.find(itm => itm.ctg == this.category && itm.default)._id
        } else {
          view = schema.find(itm => itm.ctg == this.category)._id
        }
      } else {
        view = schema.find(itm => itm.default)._id
      }
      this.$nextTick(() => {
        const rdef = {
          fields: {
            200: [
              {
                a: [{ val: this.details[this.addRecInd].title }]
              }
            ]
          }
        }

        //this.$log("rdef ", rdef)
        this.$refs.addDataPopUp.addData(tables[0], view, rdef, this.category)
      })
    },
    recordAdded(e) {
      //this.$log("recordAdded", e)
      this.$emit("linking", e._id)
      this.$set(this.value[this.addRecInd], "link", e._id)
      this.getDetails(e._id, this.addRecInd)
      //this.$set(this.details[this.addRecInd], "_id", e._id)
      //this.pasteValue = true
      //this.searchRecords(this.search, true)
      //this.$emit("input", e)
    },
    refreshData() {
      //this.$log("refreshData ", this.value)
      this.value.map((itm, ind) => {
        if (itm.link) {
          this.details.push({ _id: itm.link })
          this.getDetails(itm.link, ind)
        } else if (itm.t) {
          //this.$log("itm.t ", this.collection)
          this.$set(this.details, ind, { title: itm.t[0].val })
          this.searchTitle(itm.t[0].val, ind)
          this.ren = Date.now()
        }
        //this.$log("refreshData ", itm)
      })
      if (this.value[this.value.length - 1].link || this.details[this.value.length - 1].title) {
        this.details.push({})
        this.$emit("add", this.value.length)
      }
      this.details.push({})
    },
    searchTitle(title, ind) {
      if (title) {
        axios
          .get(this.collection + '/?where={"fields.299.a.val":{"$regex":"' + title + '","$options":"i"}}')
          .then(res => {
            //this.$log("searchtitle ", res)
            //this.$log("searchtitlei ", ind)
            if (res.data._items.length > 0) {
              this.$emit("linking", res.data._items[0]._id)
              this.$set(this.value[ind], "link", res.data._items[0]._id)
              this.setDetails(res.data._items[0], ind)
            }
          })
      }
    },
    getDetails(id, ind) {
      axios.get(this.collection + "/" + id).then(res => {
        //this.$log("response ", res)
        this.setDetails(res.data, ind)
      })
    },
    setDetails(res, ind) {
      if (this.collection == "ebib_marc_mon") {
        if (res.fields["299"]) {
          res.title = res.fields["299"][0].a[0].val
          // if (res.fields["200"][0].c) {
          //   res.title += ". " + res.fields["200"][0].c[0].val
          // }
          // if (res.fields["200"][0].d) {
          //   res.title += " = " + res.fields["200"][0].d[0].val
          // }
          // if (res.fields["200"][0].h) {
          //   res.title += " " + res.fields["200"][0].h[0].val
          // }
          // if (res.fields["200"][0].i) {
          //   res.title += " : " + res.fields["200"][0].i[0].val
          // }
        }
        if (res.fields["700"]) {
          if (res.fields["700"][0].a) {
            res.autor = res.fields["700"][0].a[0].val
          }
        }
        if (res.fields["210"]) {
          if (res.fields["210"][0].c) {
            res.editura = res.fields["210"][0].c[0].val
          }
          if (res.fields["210"][0].a) {
            res.localitate = res.fields["210"][0].a[0].val
          }
          if (res.fields["210"][0].d) {
            res.an = res.fields["210"][0].d[0].val
          }
        }
      } else if (this.collection == "ebib_marc_ath") {
        if (res.fields["299"]) {
          res.title = res.fields["299"][0].a[0].val
        }
        // if (res.ctg == "nume-personal") {
        //   res.title = res.fields["200"][0].a[0].val
        //   if (res.fields["200"][0].b) {
        //     res.title += ", " + res.fields["200"][0].b[0].val
        //   }
        //   if (res.fields["920"]) {
        //     res.title += " (" + res.fields["920"][0].a[0].val + ")"
        //   }
        //   if (res.fields["200"][0].f) {
        //     res.title += " (" + res.fields["200"][0].f[0].val + ")"
        //   }
        //   if (res.fields["400"]) {
        //     if (res.fields["400"][0].a) {
        //       res.forma_preferata = res.fields["400"][0].a[0].val
        //     }
        //     if (res.fields["400"][0].b) {
        //       res.forma_preferata += ", " + res.fields["400"][0].b[0].val
        //     }
        //   }
        //   if (res.fields["500"]) {
        //     if (res.fields["500"][0].a) {
        //       res.forma_nepreferata = res.fields["500"][0].a[0].val
        //     }
        //     if (res.fields["500"][0].b) {
        //       res.forma_nepreferata += ", " + res.fields["500"][0].b[0].val
        //     }
        //   }
        // } else {
        //   const flds = [200, 210, 215, 250, 270, 230, 220, 216, 260, 240, 245, 280, 235, 243, 217]
        //   flds.map(field => {
        //     if (res.fields[field]) {
        //       res.title = res.fields[field][0].a[0].val
        //     }
        //   })
        // }
      } else if (this.collection == "ebib_marc_art") {
        if (res.fields["299"]) {
          res.title = res.fields["299"][0].a[0].val
        }
      } else if (this.collection == "ebib_marc_evn") {
        if (res.fields["299"]) {
          res.title = res.fields["299"][0].a[0].val
        }
      } else if (this.collection == "ebib_marc_cln") {
        if (res.fields["200"]) {
          res.title = res.fields["200"][0].a[0].val
        }
        if (res.fields["500"]) {
          if (res.fields["500"][0].a) {
            res.barcode = res.fields["500"][0].a[0].val
          }
        }
        // } else if (this.collection == "ebib_marc_rmf") {
        //   if (res.fields["200"]) {
        //     if (res.fields["200"][0].a) {
        //       res.title = res.fields["200"][0].a[0].val
        //     }
        //     if (res.fields["200"][0].p) {
        //       res.title = res.fields["200"][0].p[0].val + "/" + res.title
        //     }
        //     if (res.fields["200"][0].s) {
        //       res.title += "/" + res.fields["200"][0].s[0].val
        //     }
        //   }
      } else {
        if (res.fields["200"]) {
          res.title = res.fields["200"][0].a[0].val
        }
      }
      this.$set(this.details, ind, res)
      this.ren = Date.now()
    }
  }
}
</script>
<style scoped></style>

<template>
  <base-item-group :item="item" text sub-group :mini="mini" :catalog="catalog" />
</template>

<script>
export default {
  name: "ItemSubGroup",

  props: {
    item: {
      type: Object,
      default: () => ({
        avatar: undefined,
        group: undefined,
        title: undefined,
        children: []
      })
    },
    catalog: {
      type: Boolean,
      default: false
    },
    mini: {
      type: Boolean,
      default: false
    }
  }
}
</script>

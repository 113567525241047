export default {
  getCleanRecord(rec, path, schema) {
    //const base64Matcher = new RegExp("^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{4})$")
    //this.$log("getCleanRecord  path ", path)
    for (const itm in rec) {
      if (itm == "iiid") {
        rec[itm] = undefined
      }
      //this.$log("base64 " + itm + " - " + typeof rec[itm] + " - " + base64Matcher.test(rec[itm]))
      //this.$log("typeof " + itm + " - " + (rec[itm] instanceof File))
      //this.$log("getCleanRecord  itm ", itm)
      //this.$log("getCleanRecord val ", rec[itm])
      if (!(rec[itm] instanceof File)) {
        if (Array.isArray(rec[itm])) {
          if (rec[itm].length == 1 && rec[itm][0] == "") {
            rec[itm] = undefined
          } else {
            rec[itm].map((arr, ind) => {
              if (arr._id) {
                let p = path ? path + "." + itm : itm
                p = p.split(".").join(".schema.")
                const def = this.resolvePath(p, schema)
                rec[itm] = rec[itm][def.data_relation.field]
                rec[itm][ind] = arr._id
              } else if (typeof arr == "object") {
                this.getCleanRecord(rec[itm][ind], path ? path + "." + itm : itm, schema)
              }
            })

            if (rec[itm].length == 1) {
              let bool = true
              for (const prop in rec[itm][0]) {
                if (rec[itm][0][prop]) bool = false
              }
              if (bool) {
                rec[itm] = undefined
              }
            } else if (rec[itm].length == 0) {
              rec[itm] = undefined
            } else {
              //this.$log("arr slice1 ", rec[itm])
              let bool = true
              for (const prop in rec[itm][rec[itm].length - 1]) {
                if (rec[itm][rec[itm].length - 1][prop]) bool = false
              }
              if (bool) {
                rec[itm].splice(-1)
              }
            }
          }
        } else if (typeof rec[itm] == "object") {
          if (rec[itm]) {
            if (rec[itm]._id) {
              let p = path ? path + "." + itm : itm
              p = p.split(".").join(".schema.")
              const def = this.resolvePath(p, schema)
              rec[itm] = rec[itm][def.data_relation.field]
            } else {
              this.getCleanRecord(rec[itm], path ? path + "." + itm : itm, schema)
              let bool = true
              for (const prop in rec[itm]) {
                if (rec[itm][prop]) bool = false
              }
              if (bool) {
                rec[itm] = undefined
              }
            }
          }
        } else if (typeof rec[itm] == "string") {
          //if (base64Matcher.test(rec[itm])) {
          //this.$log("base64 " + itm + " - " + typeof rec[itm] + " - " + base64Matcher.test(rec[itm]))
          //}
        }
        if (rec[itm] == null || rec[itm] === "") {
          rec[itm] = undefined
        }
        // if (rec[itm] === "") {
        //   let p = path ? path + "." + itm : itm
        //   p = p.split(".").join(".schema.")
        //   const def = this.resolvePath(p, schema)
        //   if (def.type != "string") {
        //     rec[itm] = undefined
        //   }
        //   //rec[itm] = undefined
        // }
      }
    }
  },
  resolvePath(path, obj) {
    return path.split(".").reduce(function(prev, curr) {
      return prev ? prev[curr] : null
    }, obj || self)
  },
  generateRecord(rec, recview) {
    for (const itm in rec) {
      if (Array.isArray(rec[itm])) {
        //this.$log("genrec ", itm + " - " + rec[itm].length + " - " + rec[itm][0])
        if (rec[itm].length == 1 && rec[itm][0] == "") {
          rec[itm] = [""]
        } else {
          rec[itm].map((arr, ind) => {
            if (typeof arr == "object") {
              //this.$log("itm ", itm)
              //this.$log("recview[itm] ", recview[itm])
              if (recview[itm] !== undefined) {
                if (typeof recview[itm][0] == "object") {
                  const dic = JSON.parse(JSON.stringify(recview[itm][0]))
                  Object.assign(dic, { ...arr })
                  if (typeof dic == "object") {
                    dic.iiid = this.idGlobal++
                  }
                  rec[itm][ind] = { ...dic }
                  this.generateRecord(rec[itm][ind], { ...recview[itm][0] })
                }
              }
            }
          })
        }
        if (rec[itm].length == 0) {
          rec[itm] = undefined
        }
      } else if (typeof rec[itm] == "object" && rec[itm]) {
        //this.$log("obj itm1 ", itm)
        //this.$log("obj itm2 ", recview[itm])
        this.generateRecord(rec[itm], { ...recview[itm] })
      }
    }
    for (const item in recview) {
      if (rec[item] == null) {
        rec[item] = JSON.parse(JSON.stringify(recview[item]))
      }
    }
  },
  generateList(rec, recv, name) {
    // this.$log("gen ", rec)
    for (const prop in rec) {
      const bool = true
      // if (rec["type"]) {
      //   if (typeof rec["type"] != "object") {
      //     bool = false
      //     if (rec.schema) {
      //       this.generateList(rec.schema, recv)
      //     } else {
      //       recv = ""
      //     }
      //   }
      // }
      let newname = ""
      if (name) {
        newname = name + "." + prop
      } else {
        newname = prop
      }
      //this.$log("name " + newname + " - " + rec[prop].type)
      if (bool) {
        if (rec[prop].type == "boolean") {
          this.$log("def ", rec[prop].default)
          if (rec[prop].default != undefined) {
            recv[prop] = rec[prop].default == "true"
          } else {
            recv[prop] = false
          }
        } else if (rec[prop].type == "list") {
          recv[prop] = []
          if (rec[prop].schema) {
            if (rec[prop].schema.type == "dict") {
              // this.$log("====dict")
              const arr = {}
              this.generateList(rec[prop].schema.schema, arr, newname)
              //this.$log("genrec arr1 ", arr)
              //this.$log("genrec arr2 ", Object.keys(arr).length)
              //if (Object.keys(arr).length > 0) {
              recv[prop].push(arr)
              //}
            } else {
              if (rec[prop].default != undefined) {
                recv[prop].push(rec[prop].default)
              } else {
                recv[prop].push("")
              }
            }
          } else {
            if (rec[prop].default != undefined) {
              recv[prop].push(rec[prop].default)
            } else {
              recv[prop].push("")
            }
          }
        } else if (rec[prop].type == "dict") {
          recv[prop] = {}
          this.generateList(rec[prop].schema, recv[prop], newname)
        } else {
          if (rec[prop].default != undefined) {
            recv[prop] = rec[prop].default
          } else {
            recv[prop] = ""
          }
        }
      }
    }
  },
  cloneObject(child, clone) {
    child.children.map(itm => {
      //this.$log("itm", itm)
      if (itm.key) {
        if (itm.key.indexOf(".") > 0) {
          const itms = itm.key.split(".")
          let newc = {}
          for (let i = 1; i < itms.length - 1; i++) {
            newc = this.cloneKey(itms[i], newc)
          }
          if (itm.children) {
            newc[itms[itms.length - 1]] = []
            // newc[itms[itms.length - 1]].push(this.cloneObject(itm, {}))
            if (Object.entries(this.cloneObject(itm, {})).length == 0) {
              newc[itms[itms.length - 1]].push("")
            } else {
              newc[itms[itms.length - 1]].push(this.cloneObject(itm, {}))
            }
          } else {
            if (itm.default != undefined) {
              newc[itms[itms.length - 1]] = itm.default
            } else {
              newc[itms[itms.length - 1]] = ""
            }
          }
          // this.$log("clone[itms[0]] ", clone[itms[0]])
          // this.$log("newc ", newc)
          if (clone[itms[0]]) {
            Object.assign(clone[itms[0]], newc)
          } else {
            clone[itms[0]] = newc
          }
        } else {
          if (itm.children) {
            if (itm.key) {
              clone[itm.key] = []
              if (Object.entries(this.cloneObject(itm, {})).length == 0) {
                if (itm.default != undefined) {
                  clone[itm.key].push(itm.default)
                } else {
                  clone[itm.key].push("")
                }
              } else {
                clone[itm.key].push(this.cloneObject(itm, {}))
              }
            } else {
              this.cloneObject(itm, clone)
            }
          } else {
            if (itm.key) {
              if (itm.default != undefined) {
                clone[itm.key] = itm.default
              } else {
                clone[itm.key] = ""
              }
            }
          }
        }
      }
    })
    // if (!clone["id"]) clone["id"] = Date.now()
    // this.$log("clone ", clone)
    return clone
  }
}

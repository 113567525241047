<template>
  <v-dialog v-model="show" persistent scrollable>
    <v-card outlined>
      <v-card-title>
        {{ $t("Caută") }}
        <v-spacer />
        <v-icon color="red" class="mt-n2 mr-n2" large @click="show = false">
          mdi-close
        </v-icon>
      </v-card-title>
      <v-card-text class="pa-2">
        <table-management
          ref="table"
          name="tmsearch"
          :table="table"
          :where="where"
          :view-table="viewTable"
          show-select-item
          :edit-inline="false"
          :category="category"
          :record-default="recordDefault"
          @selectItem="selectItem"
          @input="selectedItems = $event"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn outlined @click="show = false">
          {{ $t("Anulează") }}
        </v-btn>
        <v-btn color="primary" @click="clickSelect">
          {{ $t("Selectează") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "../../plugins/axios"
import TableManagement from "../../components/containers/TableManagement"
import { EventBus } from "../../EventBus"
import moment from "moment"
export default {
  components: {
    TableManagement
  },
  props: {
    selectEvent: {
      type: Boolean,
      default: false
    },
    selectMultiple: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    category: "",
    show: false,
    resource: "",
    config: null,
    field: "",
    collection: "",
    table: "",
    where: "",
    embed: "",
    viewTable: "",
    searchId: null,
    selectedItems: []
  }),
  computed: {
    recordDefault() {
      //this.$log("recdef2 ", this.collection)
      //this.$log("recdef2 ", this.category)
      if (this.collection == "ebib_marc_cln" && this.category == "reader") {
        // this.$log("recdef0 ", this.$store.getters.dictionary("all_clnStareCont")[0].value)
        // this.$log("recdef1 ", this.collection)
        // this.$log("recdef2 ", this.path)
        // this.$log("recdef3 ", this.$route.path)
        const d = new Date(this.$store.getters.serverDate)
        const d505b = moment(d).format("YYYY-MM-DD hh:mm:ss")
        const d505z = moment(d)
          .add(this.$store.getters.libraryData.config.library.client_subscription.signup_duration_months, "M")
          .format("YYYY-MM-DD hh:mm:ss")
        return {
          fields: {
            200: [
              {
                d: [{ val: "Român" }]
              }
            ],
            210: [
              {
                h: [{ val: this.$store.getters.libraryData.library.county }],
                l: [{ val: "ROU" }]
              }
            ],
            505: [
              {
                a: [{ val: "General" }],
                b: [{ val: d505b }],
                c: [{ val: "Activ" }],
                d: [{ val: "Înscris" }],
                z: [{ val: d505z }]
              }
            ]
          }
        }
      } else if (this.collection == "ebib_marc_srv") {
        const d = new Date(this.$store.getters.serverDate)
        const d210d = moment(d).format("YYYY-MM-DD hh:mm:ss")
        return {
          fields: {
            210: [
              {
                d: [{ val: d210d }]
              }
            ]
          }
        }
      } else if (this.collection == "ebib_marc_inv") {
        return {
          fields: {
            500: [
              {
                a: [{ val: "1 - General" }]
              }
            ],
            501: [
              {
                a: [{ val: "Bibliotecă" }]
              }
            ],
            505: [
              {
                a: [{ val: "Împrumut" }]
              }
            ]
          }
        }
      } else if (this.collection == "ebib_marc_rmf") {
        const d = new Date(this.$store.getters.serverDate)
        const d220a = moment(d).format("YYYY-MM-DD hh:mm:ss")
        return {
          fields: {
            220: [
              {
                a: [{ val: d220a }]
              }
            ]
          }
        }
      }

      return {}
    },
    appSchema() {
      return this.$store.getters.appSchema
    }
  },
  methods: {
    clickSelect() {
      if (this.selectMultiple) {
        this.selectItem(this.selectedItems)
      } else {
        this.selectItem(this.selectedItems[0])
      }
    },
    generateList(rec, name) {
      for (const prop in rec) {
        if ((prop === "type" || prop === "schema") && rec.type && rec.schema) {
          if (prop === "type") {
            this.generateList(rec.schema, name)
          }
        } else {
          let newname = ""
          if (name) {
            newname = name + "." + prop
          } else {
            newname = prop
          }
          if (rec[prop].type == "objectid") {
            if (rec[prop].data_relation.embeddable) {
              if (this.embed) {
                this.embed += ',"' + newname + '":1'
              } else {
                this.embed += '"' + newname + '":1'
              }
            }
          }
          if (rec[prop].schema) {
            if (rec[prop].type === "list") {
              if (rec[prop].schema.type == "objectid") {
                if (rec[prop].schema.data_relation.embeddable) {
                  if (this.embed) {
                    this.embed += ',"' + newname + '":1'
                  } else {
                    this.embed += '"' + newname + '":1'
                  }
                }
              }
            }
            this.generateList(rec[prop].schema, newname)
          }
        }
      }
    },
    selectItem(item) {
      //this.$log("select ", item)
      //this.$log("this.selectEvent ", this.selectEvent)
      //this.$log("selectfld ", this.resolvePath(this.field, item))
      if (this.field) {
        if (this.selectEvent) {
          this.$emit("select", this.resolvePath(this.field, item))
        } else {
          EventBus.$emit("search-link-selected", { value: this.resolvePath(this.field, item), id: this.searchId })
        }
        if (!this.selectMultiple) {
          this.show = false
        }
      } else {
        //this.$log("schemaaaa ", this.appSchema.filter(itm => itm.collection == this.table)[0].content)
        this.generateList(this.appSchema.filter(itm => itm.collection == this.table)[0].content, "")
        const litem = []
        if (Array.isArray(item)) {
          litem.push(...item)
        } else {
          litem.push(item)
        }
        litem.map(itm => {
          axios
            .get(this.collection + "/" + itm._id + (this.embed ? "?embedded={" + this.embed + "}" : ""))
            .then(response => {
              response.data._created = undefined
              response.data._updated = undefined
              response.data._links = undefined
              response.data._version = undefined
              response.data._latest_version = undefined
              if (this.selectEvent) {
                this.$emit("select", response.data)
              } else {
                EventBus.$emit("search-link-selected", { value: response.data, id: this.searchId })
              }
              if (!this.selectMultiple) {
                this.show = false
              }
            })
        })
      }
    },
    resolvePath(path, obj) {
      return path.split(".").reduce(function(prev, curr) {
        return prev ? prev[curr] : null
      }, obj || self)
    },
    open(resource, field, searchId, config) {
      //this.$log("this.selectEvent ", this.selectEvent)
      let ref = false
      if (this.resource == resource && JSON.stringify(this.config) == JSON.stringify(config)) {
        ref = true
      }
      this.searchId = searchId
      this.resource = resource
      this.field = field
      this.config = config
      if (config) {
        this.category = config.ctg
        this.where = config.where
        this.viewTable = config.viewTable
      } else {
        this.category = ""
        this.where = ""
        this.viewTable = ""
      }
      this.show = true
      this.table = this.resource.substring(this.resource.indexOf("_") + 1)
      this.$log("tb ", this.table)
      this.collection = this.appSchema.filter(itm => itm.collection == this.table)[0].item_url
      this.$log("tb collection ", this.collection)
      if (ref) {
        this.$nextTick(() => {
          if (this.$refs.table) {
            this.$refs.table.getRecords()
          }
        })
      }
    }
  }
}
</script>
<style scoped></style>

<template>
  <base-material-card
    inline
    class="mb-0"
    :icon="icon ? icon : 'mdi-clipboard-text'"
    :title="$t(title)"
    :categories="categories"
    :category="category.lblRo"
    @change-category="changeCategory"
    @icon-click="changeCategory({ category: '', lblRo: '' })"
  >
    <div v-show="!editRecord">
      <v-tabs v-show="!selectedCollection" v-model="table" center-active show-arrows>
        <v-tab v-for="itm in tables" :key="itm._id">
          {{ itm.title }}
        </v-tab>
      </v-tabs>
      <table-management
        ref="table"
        name="tmdatabases"
        :table="collection"
        :where="where"
        :view="view"
        :view-table="viewTable"
        :category="category.category"
        :total-results="category.count ? Number(category.count) : 1000000"
        :opac="opac"
        :record-default="recordDefault"
        @viewTableChanged="viewTableChanged"
        @addData="addItem"
        @editData="editItem"
        @inventoryManagment="openInventoryTM"
        @libraryUsers="openLibraryUsers"
        @refreshCounts="refreshCounts"
      />
    </div>
    <add-data v-show="editRecord" ref="addData" name="addDataTM" @close="editRecord = false" @reload="getRecords" />
    <search-link ref="searchLink" />
    <inventory-managment ref="inventoryManagment" />
    <import-monography ref="importMonography" />
    <library-users ref="libraryUsers" />
    <create-edit ref="createEdit" />
    <v-dialog v-model="showImport" persistent scrollable max-width="1200px">
      <import-ebibliophil ref="importRecord" is-pop-up @import="importRecord4" @close="showImport = false" />
    </v-dialog>
  </base-material-card>
</template>
<script>
import TableManagement from "../../components/containers/TableManagement"
import ImportEbibliophil from "../../views/dashboard/template/ImportEbibliophil4"
import SearchLink from "../../components/containers/SearchLink"
import InventoryManagment from "../../components/containers/InventoryManagment"
import ImportMonography from "../../components/containers/ImportMonography"
import LibraryUsers from "../../components/containers/LibraryUsers"
import CreateEdit from "../../views/settings/AddDictionaryEdit"
import AddData from "../../views/settings/AddData"
import moment from "moment"
import axios from "../../plugins/axios"
import { EventBus } from "../../EventBus"
export default {
  components: {
    TableManagement,
    AddData,
    SearchLink,
    InventoryManagment,
    ImportMonography,
    LibraryUsers,
    CreateEdit,
    ImportEbibliophil
  },
  data: () => ({
    path: "",
    editRecord: false,
    showImport: false,
    selectedTM: 0,
    //totalResults: 1000000,
    tableManagement: [],
    tables: [],
    opac: false,
    icon: "",
    collection: "",
    where: "",
    view: "",
    viewTable: "",
    comp: "",
    title: "",
    selectedCollection: "",
    selectedView: "",
    category: { lblRo: "Toate", category: "" },
    categories: [],
    table: -1,
    headers: [],
    selected: [],
    records: []
  }),
  computed: {
    recordDefault() {
      if (this.collection == "marc_cln" && this.category.category == "reader" && this.$store.getters.libraryData) {
        // this.$log("recdef0 ", this.$store.getters.dictionary("all_clnStareCont")[0].value)
        // this.$log("recdef1 ", this.collection)
        // this.$log("recdef2 ", this.path)
        // this.$log("recdef3 ", this.$route.path)
        const d = new Date(this.$store.getters.serverDate)
        const d505b = moment(d).format("YYYY-MM-DD hh:mm:ss")
        const d505z = moment(d)
          .add(this.$store.getters.libraryData.config.library.client_subscription.signup_duration_months, "M")
          .format("YYYY-MM-DD hh:mm:ss")
        return {
          fields: {
            200: [
              {
                d: [{ val: "Român" }]
              }
            ],
            210: [
              {
                h: [{ val: this.$store.getters.libraryData.library.county }],
                l: [{ val: "ROU" }]
              }
            ],
            505: [
              {
                a: [{ val: "General" }],
                b: [{ val: d505b }],
                c: [{ val: "Activ" }],
                d: [{ val: "Înscris" }],
                z: [{ val: d505z }]
              }
            ]
          }
        }
      } else if (this.collection == "marc_srv") {
        const d = new Date(this.$store.getters.serverDate)
        const d210d = moment(d).format("YYYY-MM-DD hh:mm:ss")
        return {
          fields: {
            210: [
              {
                d: [{ val: d210d }]
              }
            ]
          }
        }
      } else if (this.collection == "marc_inv") {
        return {
          fields: {
            500: [
              {
                a: [{ val: "1 - General" }]
              }
            ],
            501: [
              {
                a: [{ val: "Bibliotecă" }]
              }
            ],
            505: [
              {
                a: [{ val: "Împrumut" }]
              }
            ]
          }
        }
      } else if (this.collection == "marc_rmf") {
        const d = new Date(this.$store.getters.serverDate)
        const d220a = moment(d).format("YYYY-MM-DD hh:mm:ss")
        return {
          fields: {
            220: [
              {
                a: [{ val: d220a }]
              }
            ]
          }
        }
      }

      return {}
    },
    companyId() {
      return this.$store.getters.companyId
    },
    appSchema() {
      return this.$store.getters.appSchema
    },
    categoriesold() {
      //&& itm.category != ""
      const cat = this.$store.getters.confPrefixes.filter(itm => "marc_" + itm.prefix == this.collection)
      cat.sort((a, b) => {
        return a.ordPref - b.ordPref
      })
      this.$log("cat lenght ", cat.length)
      if (this.collection == "marc_ath") {
        this.changeCategory(cat[1])
        return [...cat.slice(1)]
      }
      if (cat.length == 2) {
        return [...cat.slice(1, 1)]
      } else {
        //return [{ lblRo: "Toate", category: "" }, ...cat]
        return [...cat]
      }
    }
  },
  watch: {
    table() {
      //this.$log("table changed ", this.table)
      this.collection = this.tables[this.table].collection
      //this.$log("table changed collection ", this.collection)
      this.refreshCategories()
      //this.$log("table changed collection ", this.categories)
      //this.refreshCounts()
    },
    $route(to) {
      //this.$log("route to ", to)
      if (to.path == this.path) {
        this.getRecords()
        //this.refreshCounts()
      }
      if (to.name.indexOf("Databases") == 0) this.getSchema()
    }
  },
  created() {
    //this.$log("this.$config.", this.$config)
    //this.$log("createddb ", this.$route)
    this.path = this.$route.path
    if (this.path.indexOf("/opac/") > -1) {
      //this.$log("opac ", this.opac)
      this.opac = true
    }
    window.addEventListener("keydown", e => {
      if (this.path == this.$route.path) {
        //this.$log(e)
        //v-dialog__content--active
        //v-dialog--active
        //e.stopPropagation()
        if (!document.querySelector(".v-dialog--active")) {
          if (e.key == "F5") {
            e.preventDefault()
            this.getRecords()
          } else if (e.key == "F7") {
            e.preventDefault()
            this.$refs.table.addItem()
          } else if (e.key == "F8") {
            e.preventDefault()
            this.$refs.table.clearFilters()
            this.$refs.table.resetFiltersTM(false)
          }
          if (e.ctrlKey) {
            if (e.key == "f" || e.key == "F") {
              e.preventDefault()
              this.$refs.table.$refs.filterGeneralCombo.focus()
            } else if (e.key == "PageDown") {
              e.preventDefault()
              this.$refs.table.nextPage()
            } else if (e.key == "PageUp") {
              e.preventDefault()
              this.$refs.table.previousPage()
            } else if (e.key == "Home") {
              e.preventDefault()
              this.$refs.table.firstPage()
            } else if (e.key == "End") {
              e.preventDefault()
              this.$refs.table.lastPage()
            }
          }
        }
      }
    })
    this.selectedCollection = this.$route.meta.collection
    this.icon = this.$route.meta.icon
    if (this.$route.meta.where.substring(0, 1) != '"') {
      this.$route.meta.where = ""
      this.comp = this.$route.meta.where
    }
    this.where = this.$route.meta.where
    this.view = this.$route.meta.view
    this.title = this.$route.meta.title
    this.viewTable = this.$route.meta.table
    //this.$log("this.viewTable ", this.viewTable)
    this.getSchema()
    EventBus.$on("create-and-edit", search => {
      //this.$log("createAndEdit ", search)
      if (this.$refs.createEdit) {
        this.$refs.createEdit.showData(search.dictionary, search.id)
      }
    })
    EventBus.$on("import-mon4", search => {
      this.$log("import-mon4 ", this.collection)
      if (this.collection == "marc_mon") {
        this.showImport = true
      }
    })
    EventBus.$on("search-link", search => {
      //this.$log("search-link ", search)
      if (this.$refs.searchLink && this.path == this.$route.path) {
        this.$refs.searchLink.open(search.resource, search.field, search.id, search.config)
      }
    })
    EventBus.$on("import-monography", search => {
      //this.$log("search-link ", search)
      if (this.$refs.importMonography && this.path == this.$route.path) {
        this.$refs.importMonography.open(search)
      }
    })
    EventBus.$on("inventory-managment", inv => {
      //this.$log("inventory-managment ", inv)
      if (this.$refs.inventoryManagment) {
        this.$refs.inventoryManagment.open(inv)
      }
    })
  },
  methods: {
    importRecord4(rec) {
      this.$log("importRecord4 ", rec)
      this.showImport = false
      EventBus.$emit("import-mon4-selected", rec)
    },
    refreshCounts() {
      //this.$log("refreshCounts db ")
      const prefix = this.collection.split("_")[1]
      if (this.categories && this.collection.split("_")[0] == "marc") {
        axios.get("marc/count_categories/" + prefix).then(res => {
          for (const cat in res.data.data) {
            if (this.categories.find(itm => itm.prefix == prefix && itm.category == cat)) {
              this.$set(
                this.categories.find(itm => itm.prefix == prefix && itm.category == cat),
                "count",
                String(res.data.data[cat])
              )
            }
          }
        })
      }
      // this.categories.map(itm => {
      //   this.getCount(itm.prefix, itm.category)
      // })
    },
    refreshCategories() {
      let cat = []
      if (this.$store.getters.confPrefixes) {
        cat = this.$store.getters.confPrefixes.filter(itm => "marc_" + itm.prefix == this.collection && itm.active == 1)
      }
      cat.sort((a, b) => {
        return a.ordPref - b.ordPref
      })
      this.categories = []
      //this.$log("cat lenght ", cat.length)
      if (this.collection == "marc_athh") {
        this.changeCategory(cat[1])
        this.categories.push(...cat.slice(1))
      } else if (cat.length == 2) {
        this.categories.push(...cat.slice(1, 1))
      } else {
        //return [{ lblRo: "Toate", category: "" }, ...cat]
        //this.categories.push(...cat)
        this.changeCategory(cat[1])
        this.categories.push(...cat.slice(1))
      }
      //this.$log("cat lenght ", this.categories)
    },
    getCount(prefix, cat) {
      axios.get("marc/count/" + prefix + (cat ? "/" + cat : "")).then(res => {
        //this.$log("res ", res.data.data)
        this.$set(
          this.categories.find(itm => itm.prefix == prefix && itm.category == cat),
          "count",
          String(res.data.data)
        )
      })
    },
    openLibraryUsers(cmp) {
      this.$log("openLibraryUsers ", cmp)
      if (this.$refs.libraryUsers) {
        this.$refs.libraryUsers.open(cmp)
      }
    },
    openInventoryTM(inv) {
      this.$log("inventory-managment ", inv)
      if (this.$refs.inventoryManagment) {
        this.$refs.inventoryManagment.open(inv)
      }
    },
    changeCategory(cat) {
      //this.$log("changeCategory(cat)1 ", cat)
      //this.refreshCounts()
      //const tms = this.$store.getters.appViewTableCollection(this.collection)

      if (cat) {
        this.category = cat
        if (cat.category != "") {
          this.where = '"ctg":"' + cat.category + '"'
          // if (tms.find(itm => itm.ctg == cat.category)) {
          //   this.viewTable = tms.find(itm => itm.ctg == cat.category)._id
          // } else {
          //   this.viewTable = tms.find(itm => itm.default)._id
          // }
        } else {
          this.where = ""
          //this.$log("tm cat ", this.tableManagement)
          //this.viewTable = this.tableManagement.find(itm => itm.default)._id
          //this.viewTable = tms.find(itm => itm.default)._id
        }
      }
    },
    viewTableChanged(e) {
      this.$log("viewTableChanged ", e)
      this.selectedView = e
    },
    addItem(e) {
      this.$log("adddata e ", e)
      this.editRecord = true
      this.$refs.addData.addData(e.collection, e.view, e.recordDefault, e.ctg)
    },
    editItem(e) {
      this.editRecord = true
      this.$refs.addData.editData(e.collection, e.record, e.view)
    },
    getRecords() {
      this.editRecord = false
      //this.$log("getRecordsgetRecords")
      this.$refs.table.getRecords()
    },
    getSchema() {
      //this.$log("getschema1 ", this.comp)
      //this.$log("getschema2 ", this.selectedCollection)
      //this.$log("getschema3 ", this.appSchema)
      if (this.comp == "marc") {
        this.tables = this.appSchema.filter(itm => itm.collection.substring(0, 4) == "marc")
      } else if (this.selectedCollection) {
        this.tables = this.appSchema.filter(itm => itm.collection == this.selectedCollection)
      } else {
        this.tables = this.appSchema
      }
      //this.$log("getschema4 ", this.tables)
      if (this.tables.length == 0) {
        this.collection = this.selectedCollection
        //this.selectedCollection = this.title
      }
      //this.$log("getschema5 ", this.tables)
    }
  }
}
</script>

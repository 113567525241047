<template>
  <!-- <base-material-card color="success" inline title="Import" icon="mdi-database-import"> -->
  <v-card outlined>
    <v-card-title ref="cardTitle" class="pb-2 pt-2 pl-2 d-flex">
      <v-sheet color="primary" max-height="90" width="auto" elevation="6" class="mt-0 mr-3 mb-0 pa-1" dark>
        <v-icon size="24">
          mdi-database-import
        </v-icon>
      </v-sheet>
      <!-- :style="'width:' + titleWidth + 'px'" -->
      <span class="text-truncate">
        {{ $t("Import") }}
      </span>

      <v-spacer />
      <v-icon v-if="isPopUp" color="red" class="mt-n2 mr-n2" large @click="$emit('close')">
        mdi-close
      </v-icon>
    </v-card-title>
    <v-card-text class="pb-2 pt-2">
      <v-row dense>
        <v-col cols="12" sm="4" md="3" class="d-flex">
          <v-list dense width="100%" style="overflow-y:auto" height="250px">
            <v-list-item-group v-model="selectedServer" color="primary" @change="serverChanged">
              <v-list-item v-for="item in importServers" :key="item.code">
                <v-list-item-content>
                  {{ item.name }}
                </v-list-item-content>
                <v-list-item-icon>
                  {{ item.count }}
                </v-list-item-icon>
              </v-list-item>
            </v-list-item-group>
          </v-list>
          <v-divider class="ml-2" vertical />
        </v-col>
        <v-col cols="12" sm="8" md="9">
          <!-- <v-divider vertical /> -->
          <v-row dense>
            <v-col class="text-h4 mt-2" cols="12" sm="4">
              {{ $t("Selectați domeniul de căutare:") }}
            </v-col>
            <v-col cols="12" sm="8">
              <v-select v-model="domain" class="font-weight-bold" dense :items="domains" @change="changeDomain">
                <template v-slot:selection="props">
                  <span class="text-h5 ">{{ props.item.text }}</span>
                </template>
                <template v-slot:item="props">
                  <span v-if="props.item.root" class="font-weight-bold">{{ props.item.text }}</span>
                  <span v-else>{{ props.item.text }}</span>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row v-for="flt in filters" :key="flt.id" dense class="text-no-wrap" align="center">
            <v-col cols="12" sm="4">
              <v-autocomplete
                v-model="flt.field"
                outlined
                dense
                hide-details
                :label="$t('Câmp')"
                :items="filtersFields"
                @change="
                  flt.type = filtersTypes[fieldTypes[flt.field]][0].value
                  flt.value1 = ''
                  flt.label = ''
                "
              />
            </v-col>
            <v-col cols="12" sm="3">
              <v-autocomplete
                v-model="flt.type"
                auto-select-first
                outlined
                dense
                hide-details
                item-text="name"
                :label="$t('Tip căutare')"
                :items="filtersTypes[fieldTypes[flt.field]]"
              />
            </v-col>
            <v-col cols="12" sm="5">
              <v-select
                v-if="fieldTypes[flt.field] == 'boolean' || flt.type == 'i'"
                v-model="flt.value1"
                append-icon=""
                clearable
                dense
                hide-details
                :items="booleanValues"
              />
              <v-text-field
                v-else-if="flt.type == 'r=' || flt.type == 'r>' || flt.type == 'r<'"
                v-model="flt.value1"
                clearable
                dense
                hide-details
                :label="$t('Termeni de căutare')"
              />
              <v-text-field
                v-else
                v-model="flt.value1"
                clearable
                dense
                hide-details
                :label="$t('Termeni de căutare')"
              />
              <!-- <s-autocomplete
                v-else
                v-model="flt.value1"
                name="sautoelse"
                :view-type="viewType"
                :label="$t('Termeni de căutare')"
                :item-text="flt.field"
                :item-value="flt.field"
                :collection="'ebib_' + collection"
              /> -->
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="5" offset-sm="7">
              <v-btn color="primary" @click="searchAdvanced">
                {{ $t("Caută") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>

        <!-- <v-col cols="12">
        <v-tabs v-model="selectedServer" color="primary" @change="serverChanged">
          <v-tab v-for="item in importServers" :key="item.code" class="px-3 text-body-2">
            {{ item.name + " (" + item.count + ")" }}
          </v-tab>
        </v-tabs>
      </v-col> -->
        <v-col cols="12">
          <v-divider />
        </v-col>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="serverData"
            :hide-default-footer="true"
            :page.sync="page"
            :server-items-length="totalResults"
            :items-per-page.sync="itemsPerPage"
            :loading="loading"
            dense
            class="elevation-1"
          >
            <template v-slot:item.actions="{ item }">
              <v-btn color="primary" width="100%" small @click="importItem(item)">Import</v-btn>
            </template>
          </v-data-table>
        </v-col>
        <v-col class="mt-2" cols="12">
          <v-select
            v-model="itemsPerPage"
            class="float-left px-2 py-0 my-0 "
            style="width:85px"
            :items="rowsPerPageItems"
            flat
            hide-details
          />
          <span class=" float-left mt-2  body-1">
            {{ (page - 1) * itemsPerPage + 1 }}-{{
              totalResults > page * itemsPerPage ? page * itemsPerPage : totalResults
            }}
            {{ $t("din") }} {{ totalResults }}
          </span>
          <v-pagination
            v-model="page"
            class="d-flex justify-center"
            :length="Math.ceil(totalResults / itemsPerPage)"
            total-visible="7"
          />
        </v-col>
      </v-row>
      <add-data-pop-up ref="addDataPopUp" />
    </v-card-text>
  </v-card>
  <!-- </base-material-card> -->
</template>

<script>
import axios from "../../../plugins/axios"
import axiosdef from "axios"
import AddDataPopUp from "../../../views/settings/AddDataPopUp"
// import axiosscream from "../../../plugins/axios-scream"
// import SAutocomplete from "../../../components/inputs/Autocomplete"
//import axio from "axios"
export default {
  name: "DashboardDashboard",
  components: { AddDataPopUp },
  props: {
    isPopUp: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dense: true,
      hided: false,
      loading: false,
      currentPage: 1,
      page: 1,
      totalResults: 0,
      itemsPerPage: 10,
      rowsPerPageItems: [
        { text: 5, value: 5 },
        { text: 10, value: 10 },
        { text: 15, value: 15 },
        { text: 30, value: 30 }
      ],
      serverData: [],
      selectedServer: 0,
      headers: [
        { text: "Titlu (299a)", value: "v299a" },
        { text: "Autor (700a)", value: "v700a" },
        { text: "Editura (210c)", value: "v210c" },
        { text: "An (210d)", value: "v210d" },
        { text: "Loc publicare (210a)", value: "v210a" },
        { text: "Optiuni", value: "actions" }
      ],
      importServers: [
        {
          code: "MMPD4",
          name: "BJ Maramureș",
          token: "Uq9T78YTn9fZQfJd",
          link: "https://api.bibliotecamm.ebibliophil.ro/api/1.0/mmpd4"
        },
        {
          code: "SVIG4",
          name: "BJ Suceava",
          token: "R2rcjwXaJdfYE57H",
          link: "https://api.igsbiera.ebibliophil.ro/api/1.0/svig4"
        },
        {
          code: "GTKN4",
          name: "BJ Neamț",
          token: "PabzXZ535LJtWShv",
          link: "https://api.bibgtkneamt.ebibliophil.ro/api/1.0/gtkn4"
        },
        {
          code: "ISBJ4",
          name: "BJ Iași",
          token: "isbj4k5qmpNMdLeD",
          link: "https://api.bjiasi.ebibliophil.ro/api/1.0/isbj4"
        },
        {
          code: "BJCS4",
          name: "BJ Caras-Severin",
          token: "bjcs4Fn5pOeQrtYZ",
          link: "https://api.bjcs.ebibliophil.ro/api/1.0/bjcs4"
        },
        {
          code: "MMSIG",
          name: "BM Sighetu Marmatiei",
          token: "mmsigkMzPcAdRKaB",
          link: "https://api071.ebibliophil.ro/api/1.0/mmsig"
        },
        {
          code: "EBIPH",
          name: "*eBibliophil*",
          token: "P9FZHGCAqbv6S3cX",
          link: "https://api.ebibliophil.ro/api/1.0/ebapi"
        }
      ],
      domains: [],
      domain: "mon",
      category: "",
      collection: "",
      viewType: "marc",
      filters: [
        { field: "", type: "c", value1: "", value2: "", value3: {}, label: "", id: 0 },
        { field: "", type: "c", value1: "", value2: "", value3: {}, label: "", id: 1 },
        { field: "", type: "c", value1: "", value2: "", value3: {}, label: "", id: 2 }
      ],
      filtersWhere: "",
      filtersFields: [],
      fieldTypes: {},
      filtersTypes: {},
      booleanValues: [
        {
          text: "Adevărat",
          value: true
        },
        {
          text: "Fals",
          value: false
        }
      ],
      fieldSchema: {},
      schemaTypes: {}
    }
  },

  computed: {
    categories() {
      const cat = this.$store.getters.confPrefixes.filter(
        itm => "marc_" + itm.prefix == this.collection && itm.category != ""
      )
      cat.sort((a, b) => {
        return a.ordPref - b.ordPref
      })
      return [{ lblRo: "Toate", category: "" }, ...cat]
    },
    appSchema() {
      return this.$store.getters.appSchema
    },
    table() {
      return this.appSchema.filter(itm => itm.collection == "marc_mon")[0]
    },
    baseURL() {
      return axios.defaults.baseURL
    },
    companyId() {
      return this.$store.getters.companyId
    }
  },
  watch: {
    page() {
      this.getRecords()
    },
    itemsPerPage() {
      if (this.page == 1) {
        this.getRecords()
      } else {
        this.page = 1
      }
    }
  },
  created() {
    this.domains.push({ text: "Monografii", value: "mon", root: true })
    this.addDomainsCat("mon", "Monografii")
    this.getdictionary("SearchAdvVarchar").then(res => {
      this.filtersTypes.SearchAdvVarchar = [...res]
    })
    this.getdictionary("SearchAdvFulltext").then(res => {
      this.filtersTypes.SearchAdvFulltext = [...res]
    })
    this.getdictionary("SearchAdvNum").then(res => {
      this.filtersTypes.SearchAdvNum = [...res]
    })
    this.getCounts()
    this.generateListFields()
    this.getSchema()
  },
  methods: {
    getCounts() {
      this.importServers.map((srv, ind) => {
        this.getCount(srv, ind)
      })
    },
    getCount(srv, ind) {
      const cat = this.category ? "&category=" + this.category : ""
      srv.count = -1
      this.$set(this.importServers, ind, srv)
      axiosdef
        .get(
          srv.link +
            "/marc/search/compact/mon?page=1&viewColumns=200a" +
            this.filtersWhere +
            cat +
            "&perPage=1&options=count",
          {
            headers: {
              "Content-type": "application/json",
              "Auth-Token": srv.token
            }
          }
        )
        .then(res => {
          //this.$log("servers ", res)
          if (res.status == 200) {
            srv.count = res.data.total
            this.$set(this.importServers, ind, srv)
            if (this.selectedServer == ind) {
              this.serverChanged()
            }
          }
        })
    },
    getRecords() {
      if (this.selectedServer == null) {
        return
      }
      this.loading = true
      const cat = this.category ? "&category=" + this.category : ""
      const srv = this.importServers[this.selectedServer]
      axiosdef
        .get(
          srv.link +
            "/marc/search/compact/mon?viewColumns=299a,700a,210a,210c,210d" +
            this.filtersWhere +
            cat +
            "&perPage=" +
            this.itemsPerPage +
            "&page=" +
            this.page +
            "&options=count",
          {
            headers: {
              "Content-type": "application/json",
              "Auth-Token": srv.token
            }
          }
        )
        .then(res => {
          this.loading = false
          this.$log("servers res ", res)
          if (res.status == 200) {
            this.serverData = [...res.data.data]
            this.totalResults = res.data.total
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
    serverChanged() {
      if (this.page == 1) {
        this.getRecords()
      } else {
        this.page = 1
      }
    },
    importItem(itm) {
      this.$log("importItem ", itm)
      const rec = {}
      //const id = itm.id
      //rec.id = id
      rec.company = this.companyId
      rec.fields = {}
      const srv = this.importServers[this.selectedServer]
      axiosdef
        .get(srv.link + "/marc/object/id/mon/" + itm.id, {
          headers: {
            "Content-type": "application/json",
            "Auth-Token": srv.token
          }
        })
        .then(response => {
          this.$log("importItem res ", response)
          if (response.data.ctg) {
            rec.ctg = response.data.ctg
          }
          for (const fld in response.data.data) {
            if (
              String(fld).substr(0, 2) != "00" &&
              String(fld).substr(0, 1) != "9" &&
              (String(fld).substr(0, 1) != "4" || this.isPopUp)
            ) {
              for (const ord in response.data.data[fld]) {
                const r = {}
                //r[fld] = { subfields: [] }
                r[fld] = {}
                for (let i = 0; i < response.data.data[fld][ord].sub.length; i++) {
                  // this.$log(
                  //   "aid ",
                  //   fld + response.data.data[fld][ord].sub[i].sub,
                  //   " a ",
                  //   response.data.data[fld][ord].sub[i].aid
                  // )
                  //if (!response.data.data[fld][ord].sub[i].aid) {
                  const sub = {}
                  if (fld + response.data.data[fld][ord].sub[i].sub == "856ddd") {
                  }
                  if (response.data.data[fld][ord].sub[i].sub == ".") {
                    r[fld].ind1 = response.data.data[fld][ord].sub[i].value
                  } else if (response.data.data[fld][ord].sub[i].sub == "-") {
                    r[fld].ind2 = response.data.data[fld][ord].sub[i].value
                  } else {
                    if (!r[fld][response.data.data[fld][ord].sub[i].sub]) {
                      r[fld][response.data.data[fld][ord].sub[i].sub] = []
                    }
                    if (
                      this.schemaTypes[fld + response.data.data[fld][ord].sub[i].sub] == "datetime" &&
                      response.data.data[fld][ord].sub[i].value.length < 12
                    ) {
                      sub[response.data.data[fld][ord].sub[i].sub] =
                        response.data.data[fld][ord].sub[i].value + " 00:00:00"
                      r[fld][response.data.data[fld][ord].sub[i].sub].push({
                        ord: 0,
                        val: response.data.data[fld][ord].sub[i].value + " 00:00:00"
                      })
                    } else if (this.schemaTypes[fld + response.data.data[fld][ord].sub[i].sub] == "objectid") {
                      // if (response.data.data[fld][ord].sub[i].aid) {
                      //   sub[response.data.data[fld][ord].sub[i].sub] = response.data.data[fld][ord].sub[i].aid
                      // }
                    } else {
                      sub[response.data.data[fld][ord].sub[i].sub] = response.data.data[fld][ord].sub[i].value
                      r[fld][response.data.data[fld][ord].sub[i].sub].push({
                        ord: 0,
                        val: response.data.data[fld][ord].sub[i].value
                      })
                    }
                    //r[fld].subfields.push(sub)
                  }
                  //}
                }

                if (this.schemaTypes[fld]) {
                  if (JSON.stringify(r[fld]).length > 2) {
                    if (rec.fields[fld] == undefined) {
                      rec.fields[fld] = []
                    }
                    rec.fields[fld].push(r[fld])
                  }
                }
              }
            }
          }
          //this.currentRecord++
          //this.getPageRecords()
          this.$log("rec import ", rec)
          let view = null
          if (!view && rec.ctg != "") {
            const schema = this.$store.getters.appViewCollection("marc_mon")
            //this.$log("schema ", schema)
            if (schema.findIndex(itm => itm.ctg == rec.ctg) > -1) {
              if (schema.findIndex(itm => itm.ctg == rec.ctg && itm.default) > -1) {
                view = schema.find(itm => itm.ctg == rec.ctg && itm.default)._id
              } else {
                view = schema.find(itm => itm.ctg == rec.ctg)._id
              }
            } else {
              view = schema.find(itm => itm.default)._id
            }
          }
          if (this.isPopUp) {
            this.$emit("import", rec)
          } else {
            this.$refs.addDataPopUp.addData(this.table, view, rec, rec.ctg, true)
          }
          // axios
          //   .post("ebib_marc_" + this.prefix, rec)
          //   .then(() => {
          //     this.currentRecord++
          //     this.getPageRecords()
          //     //this.$log("add response ", response)
          //   })
          //   .catch(() => {
          //     this.currentRecord++
          //     this.getPageRecords()
          //   })
        })
    },
    getdictionary(dic) {
      return new Promise((resolve, reject) => {
        if (this.$store.getters.dictionary("all_" + dic)) {
          resolve(this.$store.getters.dictionary("all_" + dic))
        } else {
          axios
            .get('app_dictionary?where={"value":"' + dic + '"}')
            .then(response => {
              if (response.data._items[0]) {
                this.$store.commit("dictionary", { name: "all_" + dic, values: response.data._items[0].content })
                resolve(response.data._items[0].content)
              }
            })
            .catch(err => {
              this.$log("errrrrrr ", err)
              reject(new Error("Dictionary not found"))
            })
        }
      })
    },
    changeDomain(e) {
      this.$log("change dom ", e)
      const chg = this.collection != "marc_" + e.split(".")[0]
      this.collection = "marc_" + e.split(".")[0]
      this.category = e.split(".")[1]
      //this.filtersFields = []
      this.domain = e
      if (chg) {
        //this.$log("chage do1 ", this.filtersFields)
        this.$set(this.filters[0], "field", "299a")
        this.$set(this.filters[1], "field", "700a")
        this.$set(this.filters[2], "field", "010a")
        this.$set(this.filters[0], "type", this.filtersTypes[this.fieldTypes[this.filters[0].field]][0].value)
        this.$set(this.filters[1], "type", this.filtersTypes[this.fieldTypes[this.filters[1].field]][0].value)
        this.$set(this.filters[2], "type", "e")
        this.$set(this.filters[0], "value1", "")
        this.$set(this.filters[1], "value1", "")
        this.$set(this.filters[2], "value1", "")
      }
    },
    addDomainsCat(prefix, lbl) {
      const cat = this.$store.getters.confPrefixes.filter(itm => itm.prefix == prefix && itm.category != "")
      cat.sort((a, b) => {
        return a.ordPref - b.ordPref
      })
      cat.map(itm => {
        this.domains.push({ text: lbl + ": " + itm.lblRo, value: itm.prefix + "." + itm.category })
      })
    },
    generateListFields() {
      axios
        .get(
          'ebib_conf_definition/?where={"prefix":"mon","act":1,"flexAdvSearch":{"$ne":0},"virtuality":{"$ne":1},"chst":-1}&sort=[("flexAdvSearch",1)]&max_results=10000&page=1'
        )
        .then(response => {
          this.$log("ebib_conf_definition res ", response)
          response.data._items.map(itm => {
            let srct = "SearchAdvVarchar"
            if (itm.dty == "text") {
              srct = "SearchAdvFulltext"
            } else if (itm.dty == "int") {
              srct = "SearchAdvNum"
            }
            this.filtersFields.push({
              text: itm.fld + itm.sub + " - " + itm.lblRo,
              value: itm.fld + itm.sub,
              type: srct
            })
            this.fieldTypes[itm.fld + itm.sub] = srct
            this.fieldSchema[itm.fld + itm.sub] = itm
            this.fieldSchema[itm.fld + itm.sub].lbl = itm.fld + itm.sub + " - " + itm.lblRo
          })
          //this.$log("this.filtersFields ", this.filtersFields)
          this.changeDomain("mon")
        })
    },

    searchAdvanced() {
      this.$log("searchAdvanced")
      let i = 0
      this.filtersWhere = ""
      this.filters.map(itm => {
        //this.$log("searchAdvanced map ", itm)
        if (itm.value1 !== "" && itm.value1 != undefined) {
          this.filtersWhere +=
            "&f[" +
            i +
            "][field]=" +
            itm.field +
            "&f[" +
            i +
            "][mode]=" +
            itm.type +
            "&f[" +
            i +
            "][value]=" +
            encodeURIComponent(itm.value1)
          i++
        }
      })
      this.$log("filters f ", this.filtersWhere)
      this.getCounts()
    },
    getSchema() {
      //const fields = {}
      const subfields = {}
      const schema = {
        company: {
          type: "objectid",
          data_relation: {
            resource: "app_company",
            field: "_id"
          }
        },
        id: {
          type: "integer",
          unique: true
        },
        ctg: {
          type: "string"
        },
        fields: {
          allow_unknown: true,
          type: "dict",
          schema: {}
        }
      }
      //const fields = {} //schema.fields.schema.schema
      const marcPrefix = {}
      axios.get("ebib_conf_prefixes/?max_results=10000&page=1").then(responseMP => {
        responseMP.data._items.map(itm => {
          //marcPrefix[itm.id] = itm.category
          marcPrefix[itm.id] = itm
        })
        axios
          .get(
            'ebib_conf_definition/?where={"prefix":"mon","act":1,"virtuality":{"$ne":1},"chst":-1}&sort=[("fld",1),("sub",1)]&max_results=10000&page=1'
          )
          .then(response => {
            this.$log("res ", response)
            response.data._items.map(itm => {
              if (itm.fld != "299aa") {
                if (itm.sub == "") {
                  subfields[itm.fld] = {}
                  schema.fields.schema[itm.fld] = {
                    type: "list",
                    nullable: true,
                    meta: { label: itm.lblRo, labelEn: itm.lblEn, repeatable: itm.rpt },
                    schema: {
                      allow_unknown: true,
                      type: "dict",
                      schema: {
                        ord: { type: "integer" }
                      }
                    }
                  }
                  this.schemaTypes[itm.fld] = "dict"
                } else {
                  let type = "string"
                  if (itm.dtf == "boolean01" || itm.dtf == "true_false") {
                    type = "boolean"
                    // } else if (itm.dts == "auth") {
                    //   type = "objectid"
                  } else {
                    switch (itm.dty) {
                      case "date":
                      case "datetime":
                        type = "datetime"
                        break
                      case "decimal":
                      case "float":
                        type = "float"
                        break
                      case "bigint":
                      case "integer":
                      case "tinyint":
                        type = "integer"
                        break
                      case "char":
                      case "longtext":
                      case "text":
                      case "varchar":
                        type = "string"
                        break
                    }
                  }
                  this.schemaTypes[itm.fld + itm.sub] = type
                  // if (type == "objectid") {
                  //   type = "integer"
                  // }
                  const sch = {
                    type: type,
                    meta: {
                      label: itm.lblRo,
                      labelEn: itm.lblEn,
                      mandatory: itm.mnd,
                      repeatable: itm.rpt,
                      length: itm.dtyl,
                      component: itm.flexObjectType,
                      editable: itm.flexEditable,
                      regex_info: itm.regexInfo
                    }
                  }
                  //this.$log("type " + itm.fld + itm.sub + " - " + type)
                  if (itm.dts == "auth") {
                    sch.meta.component = "Link"
                    // sch.data_relation = {
                    //   resource: "ebib_marc_" + itm.authToPrefix,
                    //   field: "id",
                    //   embeddable: true
                    // }
                    // schema.fields.schema[itm.fld].schema.schema.src_id = {
                    //   type: "string"
                    // }
                    // sch.src_id = {
                    //   type: "string"
                    // }
                    let re = "ebib_marc_" + itm.authToPrefix
                    if (itm.authToPrefix == "med") {
                      //re = "ebib_files"
                      re = "ebib_media"
                    }
                    schema.fields.schema[itm.fld].schema.schema.link = {
                      type: "objectid",
                      data_relation: {
                        resource: re,
                        field: "_id"
                      }
                    }
                  }
                  if (itm.mnd == "1") {
                    //sch.required = true
                  }
                  if (itm.regex != "") {
                    sch.regex = itm.regex
                  }
                  if (itm.dtf != "") {
                    sch.meta.dictionary = itm.dtf
                  }
                  if (itm.authToField != "") {
                    sch.meta.to_prefix = itm.authToPrefix
                    let tof = itm.authToField
                    if (itm.authToField == "299") {
                      if (marcPrefix[itm.authCid].entryElement.substring(0, 3) != "299") {
                        tof = marcPrefix[itm.authCid].entryElement.substring(0, 3)
                      } else {
                        tof = "200"
                      }
                    }
                    sch.meta.to_field = tof
                    // itm.authToField == "299" ? marcPrefix[itm.authCid].entryElement.substring(0, 3) : itm.authToField
                    sch.meta.to_subfield = itm.authToSubfield
                    sch.meta.to_category = marcPrefix[itm.authCid] ? marcPrefix[itm.authCid].category : ""
                  }
                  if (itm.sub == ".") {
                    schema.fields.schema[itm.fld].schema.schema.ind1 = sch
                  } else if (itm.sub == "-") {
                    schema.fields.schema[itm.fld].schema.schema.ind2 = sch
                  } else {
                    //subfields[itm.fld][itm.sub] = sch
                    schema.fields.schema[itm.fld].schema.schema[itm.sub] = {
                      type: "list",
                      schema: {
                        type: "dict",
                        schema: {
                          ord: {
                            type: "number"
                          },
                          src_id: itm.dts == "auth" ? { type: "string" } : undefined,
                          val: sch
                        }
                      }
                    }
                  }
                  //this.$log("sch " + itm.fld)
                  //this.$log("sch " + schema[itm.fld])
                  //this.$log("sch " + schema[itm.fld].schema)
                }
              }
            })
            schema.unknown_fields = {
              meta: {
                label: "Unknown fields"
              },
              type: "list",
              schema: {
                type: "dict",
                allow_unknown: true
              }
            }
          })
      })
    },
    resolvePath(path, obj) {
      return path.split(".").reduce(function(prev, curr) {
        return prev ? prev[curr] : null
      }, obj || self)
    }
  }
}
</script>

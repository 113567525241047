<template>
  <v-dialog v-model="show" persistent scrollable>
    <v-card outlined>
      <v-card-title>
        {{ $t("Caută") }}
        <v-icon @click="show = false">mdi-close</v-icon>
      </v-card-title>
      <v-card-text class="pa-2">
        <table-management
          ref="table"
          name="tmsearch"
          :table="table"
          :where="where"
          :view-table="viewTable"
          show-select-item
          :category="category"
          @selectItem="selectItem"
          @input="selectedItems = $event"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text outlined @click="show = false">
          {{ $t("Anulează") }}
        </v-btn>
        <v-btn color="primary" @click="selectItem(selectedItems[0])">
          {{ $t("Selectează") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "../../plugins/axios"
import TableManagement from "../../components/containers/TableManagement"
import { EventBus } from "../../EventBus"
export default {
  components: {
    TableManagement
  },
  props: {
    selectEvent: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    category: "",
    show: false,
    resource: "",
    config: null,
    field: "",
    collection: "",
    table: "",
    where: "",
    embed: "",
    viewTable: "",
    searchId: null,
    selectedItems: []
  }),
  computed: {
    appSchema() {
      return this.$store.getters.appSchema
    }
  },
  methods: {
    generateList(rec, name) {
      for (const prop in rec) {
        if ((prop === "type" || prop === "schema") && rec.type && rec.schema) {
          if (prop === "type") {
            this.generateList(rec.schema, name)
          }
        } else {
          let newname = ""
          if (name) {
            newname = name + "." + prop
          } else {
            newname = prop
          }
          if (rec[prop].type == "objectid") {
            if (rec[prop].data_relation.embeddable) {
              if (this.embed) {
                this.embed += ',"' + newname + '":1'
              } else {
                this.embed += '"' + newname + '":1'
              }
            }
          }
          if (rec[prop].schema) {
            if (rec[prop].type === "list") {
              if (rec[prop].schema.type == "objectid") {
                if (rec[prop].schema.data_relation.embeddable) {
                  if (this.embed) {
                    this.embed += ',"' + newname + '":1'
                  } else {
                    this.embed += '"' + newname + '":1'
                  }
                }
              }
            }
            this.generateList(rec[prop].schema, newname)
          }
        }
      }
    },
    selectItem(item) {
      //this.$log("select ", item)
      //this.$log("this.selectEvent ", this.selectEvent)
      //this.$log("selectfld ", item[this.field])

      //this.$log("schemaaaa ", this.appSchema.filter(itm => itm.collection == this.table)[0].content)
      this.generateList(this.appSchema.filter(itm => itm.collection == this.table)[0].content, "")
      axios
        .get(this.collection + "/" + item._id + (this.embed ? "?embedded={" + this.embed + "}" : ""))
        .then(response => {
          response.data._created = undefined
          response.data._updated = undefined
          response.data._links = undefined
          response.data._version = undefined
          response.data._latest_version = undefined
          response.data._etag = undefined
          response.data._id = undefined
          response.data.company = undefined
          EventBus.$emit("import-monography-selected", { value: response.data, id: this.searchId })
          this.show = false
        })
    },
    open(config) {
      //this.$log("this.selectEvent ", this.selectEvent)
      this.searchId = config.id
      this.config = config
      if (config.ctg) {
        this.category = config.ctg
      } else {
        this.category = ""
      }
      this.show = true
      this.table = "marc_mon"
      this.$log("tb ", this.table)
      this.collection = this.appSchema.filter(itm => itm.collection == this.table)[0].item_url
      this.$nextTick(() => {
        this.$refs.table.getRecords()
      })
    }
  }
}
</script>
<style scoped></style>

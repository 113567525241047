<template>
  <v-dialog v-model="show" persistent scrollable max-width="1200px">
    <v-card outlined>
      <v-card-title>
        {{ "Administrează valori" }}
        <v-spacer />
        <v-icon aria-label="Close" @click="show = false">
          mdi-close
        </v-icon>
      </v-card-title>
      <v-card-text>
        <v-form ref="dataForm">
          <data-view ref="dataView" name="dataviedictionary" />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="show = false">
          {{ $t("Anulează") }}
        </v-btn>
        <v-btn text color="primary" @click="saveData(null)">
          {{ $t("Salvează") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "../../plugins/axios"
import { EventBus } from "../../EventBus"
import DataView from "../settings/DataView"
export default {
  components: {
    DataView
  },
  data: () => ({
    show: false,
    listContent: {},
    list: [],
    record: {},
    dictionary: "",
    selectId: null
  }),
  computed: {
    appSchema() {
      return this.$store.getters.appSchema
    }
  },
  created() {
    this.listContent = this.appSchema.filter(itm => itm.collection == "dictionary")[0]
    EventBus.$on("table-form-select", sel => {
      this.saveData(sel)
    })
  },
  methods: {
    reload() {
      this.show = false
      this.$emit("reload")
    },
    showData(e, id) {
      this.$log("showData ", e)
      this.dictionary = e
      this.show = true
      this.list = null
      this.record = null
      this.selectId = id
      //axios.get('app_view?where={"name": "CreateAndEdit", "collection": "dictionary", "type": "form"}').then(schema => {
      this.list = this.$store.getters.appViewName("CreateAndEdit")
      this.editData()
      //})
      axios.get('app_dictionary?where={"value": "' + e + '"}').then(response => {
        this.record = response.data._items[0]
        this.editData()
      })
      //this.$refs.dataView.showData(this.record, this.list, this.listContent)
    },

    editData() {
      if (this.list && this.record) {
        this.$refs.dataView.showData(this.record, this.list, this.listContent)
      }
    },
    saveData(select) {
      this.record = this.$refs.dataView.getRecord()
      const tag = this.record._etag
      this.record._created = undefined
      this.record._etag = undefined
      this.record._links = undefined
      this.record._version = undefined
      this.record._latest_version = undefined
      this.record._updated = undefined
      this.$log("save ", this.record)
      axios
        .put("app_dictionary/" + this.record._id, this.record, {
          headers: {
            "If-Match": tag
          }
        })
        .then(response => {
          if (response.status == 200) {
            //this.$emit("reload")
            //this.$log("okkk ", response)
            this.show = false
            EventBus.$emit("dictionary-reload", { dictionary: this.dictionary, id: this.selectId, select: select })
          }
        })
        .catch(error => {
          this.$log("error ", error)
        })
    }
  }
}
</script>
<style scoped></style>

<template>
  <v-list>
    <v-list-item-group v-model="selected" multiple @change="selectedChanged">
      <template v-for="(rec, index) in records">
        <v-hover :key="rec._id" v-slot:default="{ hover }">
          <v-list-item class="pl-2 pr-2">
            <div style="height:85px" />
            <!-- <v-list-item-avatar tile height="70" width="0"> </v-list-item-avatar> -->
            <v-list-item-content>
              <v-list-item-title>
                <v-icon v-if="rec.ctg == 'intrare'" color="primary">mdi-table-arrow-left</v-icon>
                <v-icon v-if="rec.ctg == 'iesire'" color="primary">mdi-table-arrow-right</v-icon>
                {{ getRMFno(rec) }}
                {{ rec["fields.330.a.val"] ? " - " + rec["fields.330.a.val"] : "" }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ rec["fields.410.t.val"] ? rec["fields.410.t.val"] + ": " : "" }}
                {{ getDocno(rec) }}
                {{ rec["fields.410.a.val"] ? ", " + rec["fields.410.a.val"] : "" }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>{{ rec["fields.300.a.val"] }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-list-item-action-text class="d-flex">
                <span v-show="!hover">{{ rec["fields.220.a.val"] | dataRo }}</span>
                <div v-show="hover" class="d-flex">
                  <span v-show="hover" class="mt-2 mr-2">{{ rec["fields.220.a.val"] | dataRo }}</span>
                  <v-btn v-if="showSelectItem && hover" color="info" icon small @click.stop="$emit('select-item', rec)">
                    <v-icon>
                      mdi-check
                    </v-icon>
                  </v-btn>
                  <v-btn
                    v-if="actions.indexOf('edit') >= 0 && hover"
                    v-show="!showSelectItem"
                    color="success"
                    icon
                    small
                    @click.stop="$emit('edit', rec)"
                  >
                    <v-icon>
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                  <v-btn
                    v-if="actions.indexOf('edit') >= 0 && hover"
                    v-show="showSelectItem"
                    color="success"
                    icon
                    small
                    @click.stop="$emit('edit', rec)"
                  >
                    <v-icon>
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                  <v-btn v-if="debug && hover" color="blue-grey" icon small @click.stop="$emit('sheet-json', rec)">
                    <v-icon>
                      mdi-code-json
                    </v-icon>
                  </v-btn>
                  <v-btn
                    v-if="hover"
                    v-show="!showSelectItem"
                    color="warning"
                    icon
                    small
                    @click.stop="$emit('sheet-item', rec)"
                  >
                    <v-icon>mdi-format-list-bulleted</v-icon>
                  </v-btn>
                  <v-edit-dialog
                    large
                    :save-text="$t('Șterge')"
                    :cancel-text="$t('Anulează')"
                    @save="$emit('delete', rec)"
                  >
                    <v-btn v-if="actions.indexOf('delete') >= 0 && hover" color="error" icon small>
                      <v-icon>
                        mdi-delete
                      </v-icon>
                    </v-btn>
                    <template v-slot:input>
                      <v-sheet>
                        <v-card-title>
                          {{ $t("Sigur doriți să ștergeți?") }}
                        </v-card-title>
                      </v-sheet>
                    </template>
                  </v-edit-dialog>
                </div>
              </v-list-item-action-text>
              <v-list-item-action-text>
                <span v-if="rec['fields.410.e.val']">
                  {{ rec["fields.410.e.val"] | dataRo }}
                </span>
              </v-list-item-action-text>
              <v-list-item-action-text>
                {{ rec["fields.410.k.val"] ? rec["fields.410.k.val"] : "" }}
                {{ rec["fields.410.l.val"] }}
              </v-list-item-action-text>
            </v-list-item-action>
            <!-- <v-list-item-action class="ml-4">
              <v-list-item-action-text>
                <v-btn v-if="showSelectItem && hover" color="info" icon x-small @click.stop="$emit('select-item', rec)">
                  <v-icon>
                    mdi-check
                  </v-icon>
                </v-btn>
                <v-btn
                  v-if="actions.indexOf('edit') >= 0 && hover"
                  v-show="!showSelectItem"
                  color="success"
                  icon
                  x-small
                  @click.stop="$emit('edit', rec)"
                >
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </v-list-item-action-text>
              <v-list-item-action-text>
                <v-btn
                  v-if="actions.indexOf('edit') >= 0 && hover"
                  v-show="showSelectItem"
                  color="success"
                  icon
                  x-small
                  @click.stop="$emit('edit', rec)"
                >
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>
                <v-btn v-if="debug && hover" color="blue-grey" icon x-small @click.stop="$emit('sheet-json', rec)">
                  <v-icon>
                    mdi-code-json
                  </v-icon>
                </v-btn>
                <v-btn
                  v-if="hover"
                  v-show="!showSelectItem"
                  color="warning"
                  icon
                  x-small
                  @click.stop="$emit('sheet-item', rec)"
                >
                  <v-icon>mdi-format-list-bulleted</v-icon>
                </v-btn>
              </v-list-item-action-text>
              <v-list-item-action-text>
                <v-edit-dialog
                  large
                  :save-text="$t('Șterge')"
                  :cancel-text="$t('Anulează')"
                  @save="$emit('delete', rec)"
                >
                  <v-btn v-if="actions.indexOf('delete') >= 0 && hover" color="error" icon x-small>
                    <v-icon>
                      mdi-delete
                    </v-icon>
                  </v-btn>
                  <template v-slot:input>
                    <v-sheet>
                      <v-card-title>
                        {{ $t("Sigur doriți să ștergeți?") }}
                      </v-card-title>
                    </v-sheet>
                  </template>
                </v-edit-dialog>
              </v-list-item-action-text>
            </v-list-item-action> -->
          </v-list-item>
        </v-hover>
        <v-divider v-if="index < records.length - 1" :key="index"> </v-divider>
      </template>
    </v-list-item-group>
  </v-list>
</template>
<script>
export default {
  filters: {
    limitData(value) {
      if (!value) return ""
      value = value.toString()
      if (value.length > 10) {
        return value.substring(0, 10)
      }
      return value
    },
    dataRo(value) {
      if (value == undefined) return ""
      value = value.toString()
      if (value.length > 10) {
        return value.substr(8, 2) + "." + value.substr(5, 2) + "." + value.substr(0, 4)
      }
      return value
    }
  },
  props: {
    value: {
      default: () => [],
      type: Array
    },
    baseurl: {
      default: "",
      type: String
    },
    showSelectItem: {
      default: false,
      type: Boolean
    },
    records: {
      default: () => [],
      type: Array
    },
    actions: {
      default: () => [],
      type: Array
    },
    debug: {
      default: false,
      type: Boolean
    }
  },
  data: () => ({
    clicks: 0,
    selected: [],
    lastSelected: null,
    fields: [
      "fields.200.a.val",
      "fields.330.a.val",
      "fields.410.a.val",
      "fields.410.t.val",
      "fields.220.a.val",
      "fields.410.e.val",
      "fields.410.k.val",
      "fields.410.l.val",
      "fields.300.a.val"
    ]
  }),
  watch: {
    selected(newv, oldv) {
      if (newv.length != oldv.length) {
        this.$emit("select", this.selected)
      }
    },
    value(newv, oldv) {
      if (oldv != newv) {
        this.selected = []
        this.value.map(itm => this.selected.push(this.records.indexOf(itm)))
      }
    }
  },
  mounted() {
    this.$emit("mounted")
  },
  methods: {
    selectedChanged(e) {
      //this.$log("selectedChanged ", e)
      if (this.clicks == 0) {
        this.clicks++
        setTimeout(
          e => {
            if (this.clicks > 1) {
              if (e.length > this.selected.length) {
                this.$emit("double-click", e[e.length - 1])
              } else {
                this.$emit("double-click", this.lastSelected[this.lastSelected.length - 1])
              }
            }
            this.clicks = 0
          },
          250,
          e
        )
      } else {
        this.clicks++
      }
      if (e.length > 0) {
        this.lastSelected = e
      }
    },
    getRMFno(rec) {
      let str = ""
      if (rec["fields.200.p.val"]) {
        str = rec["fields.200.p.val"] + "/"
      }
      if (rec["fields.200.a.val"]) {
        str += rec["fields.200.a.val"]
      }
      if (rec["fields.200.s.val"]) {
        str += "/" + rec["fields.200.s.val"]
      }
      return str
    },
    getDocno(rec) {
      let str = ""
      if (rec["fields.410.b.val"]) {
        str = rec["fields.410.b.val"] + "/"
      }
      if (rec["fields.410.c.val"]) {
        str += rec["fields.410.c.val"]
      }
      if (rec["fields.410.d.val"]) {
        str += "/" + rec["fields.410.d.val"]
      }
      return str
    }
  }
}
</script>
<style scoped></style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',{ref:"cardTitle",staticClass:"pa-2 d-flex flex-nowrap"},[(_vm.isMarc)?_c('v-sheet',{staticClass:"mt-0 mr-3 mb-0 pa-1",attrs:{"color":"primary","max-height":"90","width":"auto","elevation":"6","dark":""}},[_c('v-icon',{attrs:{"size":"24"}},[_vm._v(" "+_vm._s(_vm.$route.meta.icon)+" ")])],1):_c('v-sheet',{staticClass:"mt-0 mr-3 mb-0 pa-1",attrs:{"color":"primary","max-height":"90","width":"auto","elevation":"6","dark":""}},[(_vm.recordId)?_c('v-icon',{attrs:{"size":"24"}},[_vm._v(" mdi-pencil ")]):_c('v-icon',{attrs:{"size":"24"}},[_vm._v(" mdi-plus ")])],1),_c('span',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('v-spacer'),_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":"","max-height":_vm.menuMaxHeight,"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onMenu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"min-width":"0","text":"","fab":"","small":""}},Object.assign({}, onMenu, on)),[_c('v-icon',[_vm._v("mdi-help-circle-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("Ajutor!")))])])]}}])},[_c('v-card',{staticClass:"mt-0 mb-0",attrs:{"width":"360"}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("Ajutor!"))+" ")]),_c('v-card-text',{staticClass:"text-h6"},[_c('v-divider'),_c('v-subheader',[_vm._v(_vm._s(_vm.$t("Butoane")))]),_c('div',[_c('v-btn',{attrs:{"color":"error","fab":"","text":"","small":""}},[_c('v-icon',[_vm._v("mdi-close")])],1),_vm._v(" "+_vm._s(_vm.$t("Închide ferestra fără salvarea înregistrări."))+" ")],1),_c('v-subheader',[_vm._v(_vm._s(_vm.$t("Scurtături")))]),_c('div',[_vm._v(_vm._s(_vm.$t("F8: Resetează modificările făcute.")))]),_c('div',[_vm._v(_vm._s(_vm.$t("CTRL+S: Salvează/Adaugă înregistrarea.")))])],1)],1)],1),_c('div',{staticStyle:{"width":"55px"}},[_c('v-select',{attrs:{"dense":"","hide-details":"","items":_vm.versions},on:{"change":_vm.versionChanged},model:{value:(_vm.selectedVersion),callback:function ($$v) {_vm.selectedVersion=$$v},expression:"selectedVersion"}})],1),_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2 ml-2",attrs:{"text":"","fab":"","small":""}},on),[_c('v-icon',{attrs:{"size":"24"}},[_vm._v(" mdi-dots-vertical ")])],1)]}}])},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.collectionForms),function(tab,ind){return _c('v-list-item',{key:tab._id,on:{"click":function($event){return _vm.changeForm(ind)}}},[_vm._v(" "+_vm._s(tab.name)+" ")])}),1)],1),_c('v-icon',{attrs:{"color":"red","large":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" mdi-close ")])],1),_c('v-divider'),_c('v-card-text',[_c('v-form',{ref:"dataForm"},[(_vm.viewType == 'data')?_c('data-view',{ref:"dataView",attrs:{"name":"datavieform"},on:{"sheet":function($event){return _vm.$emit('sheet', $event)}}}):_vm._e(),(_vm.viewType == 'html')?_c('html-view',{ref:"htmlView",attrs:{"name":"htmlvieform"},on:{"sheet":function($event){return _vm.$emit('sheet', $event)}}}):_vm._e(),(_vm.viewType == 'marc')?_c('marc-view',{ref:"marcView",attrs:{"name":"marcvieform"},on:{"sheet":function($event){return _vm.$emit('sheet', $event)}}}):_vm._e()],1)],1),_c('v-divider'),_c('v-card-actions',[(_vm.record._id && _vm.collection == 'graduate_syllabi')?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onMenu = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"#316394","outlined":""}},onMenu),[_vm._v(" "+_vm._s(_vm.$t("Export"))+" ")])]}}],null,false,3535970942)},[_c('v-sheet',[_c('div',{staticClass:"pt-2 px-4"},[_c('v-btn',{attrs:{"color":"#316394","block":""},on:{"click":function($event){return _vm.graduateReport('docx')}}},[_vm._v("DOCX")])],1),_c('div',{staticClass:"mt-2 pb-2 px-4"},[_c('v-btn',{attrs:{"color":"#316394","block":""},on:{"click":function($event){return _vm.graduateReport('pdf')}}},[_vm._v("PDF")])],1)])],1):_vm._e(),(_vm.collection == 'marc_cln')?_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.openCard}},[_vm._v(" "+_vm._s(_vm.$t("Permis"))+" ")]):(_vm.collection == 'marc_mon')?_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.importMon}},[_vm._v(" "+_vm._s(_vm.$t("Import"))+" ")]):_vm._e(),(_vm.isMarc && _vm.recordId)?_c('v-btn',{attrs:{"color":"warning","outlined":""},on:{"click":function($event){return _vm.$emit('sheet', { _id: _vm.recordId })}}},[_vm._v(" "+_vm._s(_vm.$t("Fișă"))+" ")]):_vm._e(),(_vm.recordId && (_vm.collection == 'marc_mon' || _vm.collection == 'marc_rmf'))?_c('v-btn',{attrs:{"color":"info","outlined":""},on:{"click":function($event){_vm.$emit('inventories', {
          _id: _vm.recordId,
          'fields.200.a.val': _vm.resolvePath('fields.200.0.a.0.val', _vm.record),
          ctg: _vm.record.ctg
        })}}},[_vm._v(" "+_vm._s(_vm.$t("Inventare"))+" ")]):_vm._e(),_c('v-spacer'),(_vm.collection == 'marc_inv')?_c('div',{staticStyle:{"width":"65px"}},[_c('v-text-field',{staticClass:"mt-2",attrs:{"dense":"","hide-details":"","label":_vm.$t('Nr. inventare'),"min":"1","max":"10","step":"1","type":"number"},model:{value:(_vm.noInventories),callback:function ($$v) {_vm.noInventories=$$v},expression:"noInventories"}})],1):_vm._e(),(_vm.collection == 'marc_inv')?_c('v-btn',{staticClass:"ml-2",attrs:{"outlined":"","color":"info"},on:{"click":_vm.generateInventories}},[_vm._v(" "+_vm._s(_vm.$t("Generează"))+" ")]):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","outlined":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" "+_vm._s(_vm.$t("Anulează"))+" ")]),(_vm.recordId && _vm.isMarc)?_c('v-btn',{attrs:{"outlined":"","color":"indigo"},on:{"click":_vm.copyData}},[_vm._v(" "+_vm._s(_vm.$t("Copiază"))+" ")]):_vm._e(),(_vm.isMarc)?_c('v-btn',{attrs:{"outlined":"","color":"info"},on:{"click":function($event){return _vm.saveData(false)}}},[_vm._v(" "+_vm._s(_vm.$t("Aplică"))+" ")]):_vm._e(),(_vm.recordId)?_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){return _vm.saveData(true)}}},[_vm._v(" "+_vm._s(_vm.$t("Salvează"))+" ")]):_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){return _vm.saveData(true)}}},[_vm._v(" "+_vm._s(_vm.importing ? _vm.$t("Importă") : _vm.$t("Adaugă"))+" ")])],1),_c('v-overlay',{attrs:{"absolute":""},model:{value:(_vm.overlay),callback:function ($$v) {_vm.overlay=$$v},expression:"overlay"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-tooltip top>
    <template v-slot:activator="{ on }">
      <v-icon v-show="show" color="info" v-on="on">mdi-help-circle-outline</v-icon>
    </template>
    <div>
      <div>{{ isbd.autor }}</div>
      <div class="ml-4">{{ isbd.title }}</div>
      <div class="ml-4">{{ isbd.title_info }}</div>
      <div class="ml-4">{{ isbd.edition }}</div>
      <div class="ml-4">{{ isbd.notes }}</div>
      <div class="ml-4">{{ isbd.volumes }}</div>
    </div>
  </v-tooltip>
</template>
<script>
import axios from "../../plugins/axios"
export default {
  components: {},
  props: {
    id: { type: String, default: "" },
    prefix: { type: String, default: "" },
    show: { type: Boolean, default: false }
  },
  data: () => ({
    isbd: ""
  }),
  watch: {
    show() {
      if (!this.isbd) {
        this.getInfo()
      }
    }
  },
  methods: {
    getInfo() {
      if (this.id) {
        axios.get("dataview/record/isbd/" + this.prefix + "/" + this.id).then(response => {
          //this.$log("isbd ", response)
          this.isbd = response.data.isbd
        })
      }
    }
  }
}
</script>
<style scoped></style>

import Vue from "vue"
import Vuex from "vuex"
import account from "./module/account"
import efirma from "./module/efirma"

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
    barColor: "rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)",
    barImage: "https://demos.creative-tim.com/material-dashboard-pro/assets/img/sidebar-1.jpg",
    drawer: null,
    primaryColor: "#00CAE3",
    primaryColorDark: "#00CAE3",
    darkMode: false
  },
  getters: {
    primaryColor: state => {
      if (localStorage.getItem("primaryColor") !== null) {
        state.primaryColor = localStorage.getItem("primaryColor")
      }
      return state.primaryColor
    },
    primaryColorDark: state => {
      if (localStorage.getItem("primaryColorDark") !== null) {
        state.primaryColorDark = localStorage.getItem("primaryColorDark")
      }
      return state.primaryColorDark
    },
    darkMode: state => {
      if (localStorage.getItem("darkMode") !== null) {
        state.darkMode = localStorage.getItem("darkMode")
      }
      return state.darkMode == "true"
    },
    barColor: state => {
      if (localStorage.getItem("barColor") !== null) {
        state.barColor = localStorage.getItem("barColor")
      }
      return state.barColor
    }
  },
  mutations: {
    primaryColor(state, payload) {
      //console.log("store primaryColor ", payload)
      state.primaryColor = payload
      localStorage.setItem("primaryColor", payload)
    },
    primaryColorDark(state, payload) {
      state.primaryColorDark = payload
      localStorage.setItem("primaryColorDark", payload)
    },
    darkMode(state, payload) {
      state.darkMode = payload
      localStorage.setItem("darkMode", payload)
    },
    SET_BAR_IMAGE(state, payload) {
      state.barImage = payload
    },
    SET_DRAWER(state, payload) {
      state.drawer = payload
    },
    SET_SCRIM(state, payload) {
      state.barColor = payload
      localStorage.setItem("barColor", payload)
    }
  },
  actions: {},
  modules: {
    account,
    efirma
  }
})

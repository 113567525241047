import axios from "../../plugins/axios"

const efirma = {
  state: {
    appSchema: null,
    appView: null, // in functie de apptype
    confPrefixes: null,
    dictionary: {},
    dictionaryUpdated: null,
    serverDate: null
  },
  getters: {
    serverDate: state => {
      return state.serverDate
    },
    appSchema: state => {
      if (!state.appSchema) {
        state.appSchema = JSON.parse(localStorage.getItem("appSchema"))
      }
      return state.appSchema
    },
    appView: (state, getters) => {
      if (!state.appView) {
        state.appView = JSON.parse(localStorage.getItem("appView_" + getters.appType))
      }
      return state.appView
    },
    confPrefixes: state => {
      if (!state.confPrefixes) {
        state.confPrefixes = JSON.parse(localStorage.getItem("confPrefixes"))
      }
      return state.confPrefixes
    },
    appViewCollection: (state, getters) => col => {
      if (!state.appView) {
        state.appView = JSON.parse(localStorage.getItem("appView_" + getters.appType))
      }
      return state.appView.filter(itm => itm.collection == col && itm.type == "form")
    },
    appViewName: (state, getters) => name => {
      if (!state.appView) {
        state.appView = JSON.parse(localStorage.getItem("appView_" + getters.appType))
      }
      return state.appView.filter(itm => itm.name == name && itm.type == "form")
    },
    appViewTableCollection: (state, getters) => col => {
      if (!state.appView) {
        state.appView = JSON.parse(localStorage.getItem("appView_" + getters.appType))
      }
      return state.appView.filter(itm => itm.collection == col && itm.type == "table")
    },
    appViewTableCollectionCategory: (state, getters) => (col, ctg) => {
      //console.log("appViewTableCollectionCategory1 ", state.appView)
      //console.log("appViewTableCollectionCategory2 ", col)
      //console.log("appViewTableCollectionCategory3 ", ctg)
      if (!state.appView) {
        state.appView = JSON.parse(localStorage.getItem("appView_" + getters.appType))
      }
      //this.$log("state.appView " + ctg + " - " + col)
      if (ctg) {
        return state.appView.filter(itm => itm.collection == col && itm.ctg == ctg && itm.type == "table")
      }
      //state.appView.map(vvv => console.log("vv ", vvv.collection))
      return state.appView.filter(itm => itm.collection == col && itm.type == "table")
    },
    appViewId: (state, getters) => id => {
      if (!state.appView) {
        state.appView = JSON.parse(localStorage.getItem("appView_" + getters.appType))
      }
      return state.appView.filter(itm => itm._id == id)
    },
    appViews: (state, getters) => {
      if (!state.appView) {
        state.appView = JSON.parse(localStorage.getItem("appView_" + getters.appType))
      }
      return state.appView
    },
    dictionary: state => dic => {
      if (!state.dictionary[dic]) {
        state.dictionary[dic] = JSON.parse(localStorage.getItem("dictionary_" + dic))
      }
      return state.dictionary[dic]
    },
    dictionaryUpdated: state => {
      if (!state.dictionaryUpdated) {
        state.dictionaryUpdated = JSON.parse(localStorage.getItem("dictionaryUpdated"))
      }
      return state.dictionaryUpdated
    }
  },
  mutations: {
    serverDate(state, serverDate) {
      state.serverDate = new Date(serverDate)
    },
    appSchema(state, appSchema) {
      // this.$log(" mutations appSchema  ")
      state.appSchema = appSchema
      localStorage.setItem("appSchema", JSON.stringify(appSchema))
    },
    appView(state, appView) {
      //this.$log("setappvie ", appView.appType)
      state.appView = appView.value
      localStorage.setItem("appView_" + appView.appType, JSON.stringify(appView.value))
    },
    confPrefixes(state, confPrefixes) {
      state.confPrefixes = confPrefixes
      localStorage.setItem("confPrefixes", JSON.stringify(confPrefixes))
    },
    dictionary(state, dic) {
      //this.$log(" mutations appSchema  ")
      state.dictionary[dic.name] = dic.values
      localStorage.setItem("dictionary_" + dic.name, JSON.stringify(dic.values))
    },
    dictionaryUpdated(state, dic) {
      // this.$log(" mutations appSchema  ")
      state.dictionaryUpdated = dic
      localStorage.setItem("dictionaryUpdated", JSON.stringify(dic))
    }
  },
  actions: {
    getappSchema({ commit, getters }) {
      // this.$log(" getappSchema appSchema  ", getters.accountLevel)
      if (getters.appType) {
        let url = 'app_schema?where={"owner.app_type":"' + getters.appType + '"}'

        //if (getters.accountLevel == "admin") {
        url =
          'app_schema?where={"$or":[{"owner.app_type":"system"},{"owner.app_type":"' +
          getters.appType +
          '"}]}&max_results=1000'
        //}
        if (getters.appType == "system") url = "app_schema?max_results=1000"
        axios
          .get(url)
          .then(response => {
            //this.$log("appSchema response222 ", response)
            commit("appSchema", response.data._items)
          })
          .catch(() => {})
      }
      // }
    },
    getappView({ commit, getters, dispatch }) {
      //this.$log("getappView ", getters.appType)
      if (getters.appType) {
        let url =
          'app_view?where={"$or":[{"collection":"user"},{"collection":"log_messages"},{"owner.app_type":"' +
          getters.appType +
          '"}]}&max_results=1000'
        if (getters.appType == "system") url = "app_view?max_results=1000"
        axios
          .get(url)
          .then(response => {
            //this.$log("getappView commit", getters.appType)
            commit("appView", { appType: getters.appType, value: response.data._items })
          })
          .catch(() => {})
      }
    },
    getconfPrefixes({ commit, getters, dispatch }) {
      if (getters.appType) {
        axios
          .get('ebib_conf_prefixes?where={"active":1}&sort=[("prefix",1),("ordPref",1)]&max_results=1000')
          .then(response => {
            //console.log("prefixes ", response.data._items)
            let pre = ""
            let con = []
            response.data._items.map(itm => {
              if (itm.category == "") {
                if (pre) {
                  commit("dictionary", { name: "all_categorii_" + pre, values: con })
                }
                con = []
              } else {
                con.push({ name: itm.lblRo, value: itm.category })
              }
              pre = itm.prefix
            })
            commit("dictionary", { name: "all_categorii_" + pre, values: con })
            commit("confPrefixes", response.data._items)
          })
          .catch(() => {})
      }
    },
    getMyDictionaries({ commit, getters, dispatch }) {
      if (getters.companyId) {
        axios
          .get(
            'app_dictionary?where={"owner.company":"' +
              getters.companyId +
              '","active":true}&projection={"_updated":1}&max_results=1000'
          )
          .then(response => {
            const dic = { ...getters.dictionaryUpdated }
            const upd = []
            if (dic) {
              response.data._items.map(itm => {
                if (itm._updated != dic[itm._id]) {
                  upd.push(itm)
                }
              })
              dispatch("updateDictionaries", { dic: upd, i: 0, upd: dic })
            } else {
              dispatch("updateDictionaries", { dic: response.data._items, i: 0, upd: dic })
            }
          })
          .catch(() => {})
      }
    },
    getdictionaries({ commit, getters, dispatch }) {
      if (getters.appType) {
        let url =
          'app_dictionary?where={"owner.app_type":"' +
          getters.appType +
          '","active":true}&projection={"_updated":1}&max_results=1000'
        if (getters.appType == "system") {
          url =
            'app_dictionary?where={"active":true,"owner.company.0":{"$exists":false}}&projection={"_updated":1}&max_results=1000'
        }
        axios
          .get(url)
          .then(response => {
            const dic = { ...getters.dictionaryUpdated }
            const upd = []
            if (dic) {
              response.data._items.map(itm => {
                if (itm._updated != dic[itm._id]) {
                  upd.push(itm)
                }
              })
              dispatch("updateDictionaries", { dic: upd, i: 0, upd: dic })
            } else {
              dispatch("updateDictionaries", { dic: response.data._items, i: 0, upd: dic })
            }
          })
          .catch(() => {})
      }
    },
    updateDictionaries({ dispatch, commit }, itm) {
      if (itm.i < itm.dic.length && itm.dic.length > 0) {
        axios
          .get("app_dictionary/" + itm.dic[itm.i]._id)
          .then(response => {
            itm.upd[response.data._id] = response.data._updated
            commit("dictionary", { name: "all_" + response.data.value, values: response.data.content })
            dispatch("updateDictionaries", { dic: itm.dic, i: ++itm.i, upd: itm.upd })
          })
          .catch(() => {
            dispatch("updateDictionaries", { dic: itm.dic, i: ++itm.i, upd: itm.upd })
          })
      } else {
        commit("dictionaryUpdated", itm.upd)
      }
    },
    getAccountData() {}
  }
}

export default efirma

<template>
  <div style="position: relative;min-height:120px">
    <v-img v-if="src" :height="height" :width="width" contain :src="src" />
    <!-- <v-btn v-else icon @click="$refs.file.click()">
      <v-icon size="120">
        mdi-camera
      </v-icon>
    </v-btn> -->
    <v-menu absolute offset-y style="max-width: 600px">
      <template v-slot:activator="{ on }">
        <v-btn
          v-if="src && isMobile"
          style="bottom:10px;right:0px;"
          color="primary"
          absolute
          right
          bottom
          class="mr-0 px-2 py-0"
          elevation="2"
          fab
          x-small
          @click="$refs.file.click()"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn
          v-else-if="src && !isMobile"
          style="bottom:10px;right:0px;"
          color="primary"
          absolute
          right
          bottom
          class="mr-0 px-2 py-0"
          elevation="2"
          fab
          x-small
          v-on="on"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn v-else-if="isMobile" class="mt-12 ml-0" icon @click="$refs.file.click()">
          <v-icon size="120">
            mdi-image
          </v-icon>
        </v-btn>
        <v-btn v-else class="mt-12 ml-0" icon v-on="on">
          <v-icon size="120">
            mdi-image
          </v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item @click="$refs.file.click()">
          <v-list-item-icon>
            <v-icon>mdi-upload</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t("Încarcă") }}</v-list-item-title>
        </v-list-item>
        <v-list-item @click="$refs.camCapture.open()">
          <v-list-item-icon>
            <v-icon>mdi-camera</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t("Capturează") }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <div v-show="false">
      <input ref="file" type="file" accept="image/*" @change="imageChanged" />
    </div>
    <cam-capture ref="camCapture" @captured="imgCaptured" />
  </div>
</template>
<script>
import axios from "../../plugins/axios"
import CamCapture from "./CamCapture"
import isMobile from "ismobilejs"
export default {
  name: "AddImage",
  components: { CamCapture },
  props: {
    value: {
      type: String,
      default: ""
    },
    height: {
      type: Number,
      default: 50
    },
    width: {
      type: Number,
      default: 100
    }
  },
  data: () => ({ src: "" }),
  computed: {
    companyId() {
      return this.$store.getters.companyId
    },
    isMobile() {
      return isMobile(navigator.userAgent).any
    }
  },
  watch: {
    value() {
      this.idChanged(this.value)
    }
  },
  created() {
    this.$log("ismobile ", this.isMobile)
    this.idChanged(this.value)
  },
  methods: {
    imageChanged(e) {
      this.$log("imageChanged ", e)
      //    record.image = $event.target.files[0]
      //   fileName = $event.target.files[0].name
      //   createURL('urlImg', $event)
      const formData = new FormData()
      formData.append("company", this.companyId)
      formData.append("content_type", "image/jpeg")
      formData.append("ctg", "imagine")
      formData.append("name", e.target.files[0].name)
      formData.append("file_name", e.target.files[0].name)
      formData.append("file", e.target.files[0])
      axios
        .post("ebib_media/", formData, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          }
        })
        .then(res => {
          this.$log("response media ", res)
          if (res.status == 201) {
            this.$emit("input", res.data._id)
            this.idChanged(res.data._id)
          }
        })
        .catch(error => {
          this.$log("error ", error)
        })
    },
    imgCaptured(e) {
      this.$emit("input", e._id)
      //this.idChanged(id)
      this.src = "data:" + e.content_type + ";base64," + e.file
    },
    idChanged(id) {
      this.$log("idChanged() ", id)
      if (id) {
        axios.get("ebib_media/" + id).then(res => {
          //this.$log("response ", res)
          this.src = "data:" + res.data.content_type + ";base64," + res.data.file
        })
      } else {
        this.src = ""
      }
    }
  }
}
</script>
<style scoped></style>

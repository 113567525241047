<template>
  <v-dialog v-model="dialog" persistent scrollable>
    <v-card outlined>
      <v-card-title class="pr-2">
        <span class="headline">{{ $t("Coloane") }}</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6" md="3">
            <v-text-field v-model="tm.name" dense :label="$t('Nume')" />
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <v-select
              v-model="tm.ctg"
              dense
              clearable
              item-text="name"
              item-value="value"
              :label="$t('Categorie')"
              :items="collectionCategories()"
            />
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <v-checkbox
              :value="tm.access == 'public'"
              class="mt-0"
              dense
              :label="$t('Public')"
              @change="tm.access = $event ? 'public' : 'private'"
            />
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <v-checkbox v-model="tm.default" class="mt-0" dense :label="$t('Predefinit')" />
          </v-col>
          <v-col cols="12" md="8">
            <div style="min-height:10px;">
              <v-progress-linear
                v-show="loading"
                style="margin:0px"
                :indeterminate="loading"
                color="primary"
              ></v-progress-linear>
            </div>
            <v-data-table
              ref="table"
              v-model="selectId"
              color="primary"
              dense
              single-select
              hide-default-header
              fixed-header
              :headers="headers"
              :items="records"
              :server-items-length="totalResults"
              hide-default-footer
              @click:row="rowClick"
            >
              <template v-slot:header="{ props }">
                <thead>
                  <tr>
                    <th
                      v-for="header in props.headers"
                      :key="header.value"
                      :width="header.width ? header.width : '50px'"
                    >
                      {{ $t(header.text) }}
                    </th>
                    <th style="width:1px" width="1px"></th>
                  </tr>
                </thead>
              </template>
              <template v-slot:body="{ items }">
                <draggable v-model="records" tag="tbody">
                  <tr
                    v-for="item in items"
                    :key="item.value"
                    :class="{ 'v-data-table__selected': selectId[0] == item }"
                    @click="selectItem(item)"
                  >
                    <td v-for="header in headers" :key="header.value">{{ item[header.value] }}</td>
                    <td>
                      <v-icon @click="deleteItem(item)">mdi-delete</v-icon>
                    </td>
                  </tr>
                </draggable>
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="12" md="4">
            <v-tabs v-model="tabCol">
              <v-tab>{{ $t("Tabel") }}</v-tab>
              <v-tab>{{ $t("Coloană") }}</v-tab>
              <v-tabs-items v-model="tabCol">
                <v-tab-item>
                  <v-text-field v-model="table.filter" dense :label="$t('Filtru')" />
                  <v-autocomplete
                    v-model="table.view"
                    clearable
                    dense
                    :label="$t('Vizualizator')"
                    :items="views"
                    item-text="name"
                    item-value="_id"
                  />
                  <v-select
                    v-model="table.viewTypes"
                    class="custom-placeholer-color"
                    dense
                    color="primary"
                    :label="$t('Tipuri vizualizare')"
                    :items="['list', 'details', 'module']"
                    chips
                    multiple
                  />
                  <v-checkbox v-model="table.showHeader" dense class="mt-0" :label="$t('Afișare cap tabel')" />
                  <v-checkbox v-model="table.dense" dense class="mt-0" :label="$t('Dens')" />
                  <v-checkbox v-model="table.filtrable" dense class="mt-0" :label="$t('Filtrabil')" />
                  <v-checkbox v-model="table.sortable" dense class="mt-0" :label="$t('Sortabil')" />
                  <v-checkbox v-model="table.showAdd" dense class="mt-0" :label="$t('Afișare adăugare')" />
                  <v-checkbox v-model="table.addPopUp" dense class="mt-0" :label="$t('Afișare Pop-up')" />
                  <v-checkbox v-model="table.showSelect" dense class="mt-0" :label="$t('Afișare selectare')" />
                  <v-checkbox v-model="table.showAction" dense class="mt-0" :label="$t('Afișare acțiuni')" />
                  <v-select
                    v-model="table.actions"
                    class="custom-placeholer-color"
                    dense
                    color="primary"
                    :label="$t('Acțiuni')"
                    :items="['edit', 'delete', 'select', 'inventare']"
                    chips
                    multiple
                  />
                  <v-checkbox v-model="table.editPopUp" dense class="mt-0" :label="$t('Editare Pop-up')" />
                  <v-checkbox v-model="table.showPagination" dense class="mt-0" :label="$t('Afișare paginare')" />
                  <v-text-field v-model="table.height" dense clearable :label="$t('Înălțime')" />
                </v-tab-item>
                <v-tab-item>
                  <v-combobox
                    v-model="selectedItem.key"
                    class="custom-placeholer-color"
                    dense
                    color="primary"
                    :label="$t('Câmp')"
                    item-text="text"
                    item-value="value"
                    :placeholder="selectedItem.key"
                    :items="keysList"
                    :return-object="false"
                    @change="changeKey"
                  >
                    <template v-slot:item="{ item }">
                      <span v-if="item.text">
                        {{ item.text }}
                      </span>
                      <span v-else-if="item.value">
                        {{ item.value }}
                      </span>
                      <span v-else></span>
                    </template>
                  </v-combobox>
                  <v-text-field v-model="selectedItem.name" dense :label="$t('Etichetă')" />
                  <v-checkbox v-model="selectedItem.embed" dense class="mt-0" :label="$t('Încorporabil')" />
                  <v-checkbox v-model="selectedItem.filtrable" dense class="mt-0" :label="$t('Filtrabil')" />
                  <v-checkbox v-model="selectedItem.sortable" dense class="mt-0" :label="$t('Sortabil')" />
                </v-tab-item>
              </v-tabs-items>
            </v-tabs>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn text outlined @click="addRecord">
          <v-icon>mdi-plus</v-icon>
          {{ $t("Coloană") }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary darken-1" text outlined @click="dialog = false">{{ $t("Închide") }}</v-btn>
        <v-btn color="primary darken-1" @click="save">{{ $t("Salvează") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "../../plugins/axios"
import draggable from "vuedraggable"
export default {
  components: {
    // EditColumn,

    draggable
  },
  props: {
    prefix: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      records: [],
      name: "",
      category: "",
      totalResults: 2000,
      tabCol: 0,
      dialog: false,
      loading: false,
      isUpdated: false,
      selectedItem: {},
      keysList: [],
      selectId: [],
      views: [],
      table: {},
      tm: {},
      headers: [
        { text: "Nume", value: "name", align: "left", type: "String", width: "150" },
        { text: "Camp", value: "key", align: "left", type: "String", width: "50" }
        // { text: "Aliniere", value: "align", align: "left", type: "String", width: "100" },
        // { text: "Tip", value: "type", align: "left", type: "String", width: "100" },
        // { text: "Obiect", value: "object", align: "left", type: "String", width: "100" },
        // { text: "Dimensiune", value: "width", align: "left", type: "String", width: "100" }
        // { text: "", value: "action", align: "left", type: "String", sortable: false, width: "50" }
      ]
    }
  },
  methods: {
    collectionCategories() {
      const cats = [{ name: "Toate", value: "" }]
      //this.$log("cc this.tm.collection ", this.tm.collection)
      if (this.tm.collection) {
        const dic = "all_categorii_" + this.tm.collection.substring(5)
        if (this.$store.getters.dictionary(dic)) {
          cats.push(...this.$store.getters.dictionary(dic))
          return cats
        } else {
          axios
            .get('app_dictionary?where={"value":"' + "categorii_" + this.tm.substring(5) + '"}')
            .then(response => {
              this.$log("cc res ", response)
              if (response.data._items[0]) {
                this.$store.commit("dictionary", { name: dic, values: response.data._items[0].content })
                cats.push(...response.data._items[0].content)
                return cats
              }
            })
            .catch(err => {
              this.$log("errrrrrr ", err)
              return cats
            })
        }
      }
    },
    changeKey(e) {
      this.$log("changeKey ", e)
    },
    rowClick(e, row) {
      //this.selectedId = e._id
      this.$log("rowclick ", e)
      row.select(true)
    },
    addRecord() {
      this.$log("addrecord")
      this.$refs.column.addRecord()
    },
    addColumn(e) {
      this.$log("addColumn ", e)
      //this.$refs.column.addRecord()
      this.records.push(e)
    },
    deleteItem(e) {
      this.$log("delete ", this.selectId)
      this.records.splice(this.records.indexOf(e), 1)
    },
    selectItem(e) {
      this.selectId = [e]
      this.selectedItem = e
    },
    saveColumns() {
      this.$log("savecolumns")
      this.$store.commit("setConfigColumns", { prefix: this.prefix, records: this.records })
    },
    save() {
      this.$log("save")
      this.tm.content[0] = this.table
      this.tm.content[0].children = this.records
      this.$emit("save", this.tm)
      this.dialog = false
    },
    apply() {
      this.$log("apply")
      this.tm.content[0] = this.table
      this.tm.content[0].children = this.records
      this.$emit("apply", this.tm)
      this.dialog = false
    },
    getRecord(id) {
      return axios.get("marc/object/id/" + this.prefix + "/" + id)
    },
    openConfig(header, tm, keysList) {
      this.$log("conf tm ", tm)
      this.dialog = true
      this.keysList = keysList
      this.tm = tm

      this.records = []
      if (tm.content) {
        this.tabCol = 0
        this.views = this.$store.getters.appView.filter(itm => itm.collection == tm.collection && itm.type == "form")
        this.table = tm.content[0]
        this.records = tm.content[0].children
      } else {
        this.records.push(...header)
      }

      this.$log("conf ", header)
    }
  }
}
</script>
<style>
/* .v-data-table tr:nth-of-type(2n) {
  background-color: rgba(0, 0, 0, 0.05);
} */
</style>

<template>
  <v-data-table
    :headers="headers"
    :items="records"
    :items-per-page="1000"
    dense
    fixed-header
    hide-default-footer
    height="400px"
  >
    <template v-slot:item.action="{ item }">
      <v-btn color="warning" icon small @click="sheetItem(item)">
        <v-icon>mdi-format-list-bulleted</v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>
<script>
import axios from "../../plugins/axios"
import moment from "moment"
export default {
  props: {
    id: {
      type: String,
      default: ""
    },
    prefix: {
      type: String,
      default: ""
    }
  },
  data: () => ({
    headers: [
      { text: "Data", value: "dt", align: "left", type: "String" },
      { text: "Bibliotecar", value: "usr", align: "left", type: "String" },
      { text: "Versiune", value: "ver", align: "left", type: "String" },
      { text: "Opțiuni", value: "action", sortable: false, align: "right" }
    ],
    records: []
  }),
  computed: {
    collection() {
      return "ebib_marc_prefix"
    }
  },
  mounted() {
    axios.get("marc/record_timeline/" + this.prefix + "/" + this.id).then(response => {
      this.records = response.data.data
      this.records.map(itm => {
        const d = new Date(itm.dt)
        itm.dt = moment(d).format("DD.MM.YYYY hh:mm:ss")
      })
    })
  },
  methods: {
    sheetItem(itm) {
      //this.$log(" sheetItem2 ", itm)
      this.$emit("sheet", { _id: this.id, ver: itm.ver })
    }
  }
}
</script>

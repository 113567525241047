<template>
  <v-row no-gutters>
    <v-col v-show="dateCols" :cols="dateCols">
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on: { click } }">
          <!-- v-mask="dateMask" -->
          <v-text-field
            :value="datam"
            :label="label"
            :dense="dense"
            :rules="rules"
            :error-messages="errorMessages"
            placeholder="ZZ.LL.AAAA"
            persistent-hint
            :clearable="clearable"
            :autofocus="autofocus"
            @input="datamchange"
            @click:append="click"
            @click="click"
            @focus="$emit('focus')"
            @blur="$emit('blur')"
          >
            <template v-if="appendIcon" v-slot:append>
              <v-icon class="pt-1" tabindex="-1" @click="click">mdi-calendar</v-icon>
            </template>
          </v-text-field>
        </template>
        <v-date-picker :value="date" scrollable :locale="$t('locale')" @input="dateSelected($event)" />
      </v-menu>
    </v-col>
    <v-col v-show="timeCols" :cols="timeCols">
      <v-menu
        ref="menutime"
        v-model="menu2"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on: { click } }">
          <!-- v-mask="timeMask" -->
          <v-text-field
            v-model="timem"
            class="pl-2"
            :hide-details="hideDetails"
            :dense="dense"
            placeholder="HH:MM"
            persistent-hint
            :clearable="clearable"
            @input="timemchange"
            @click="click"
            @focus="$emit('focus')"
            @blur="$emit('blur')"
          >
            <template v-slot:append>
              <v-icon class="pt-1" tabindex="-1" @click="click">mdi-clock-outline</v-icon>
            </template>
          </v-text-field>
        </template>
        <v-time-picker v-if="menu2" format="24hr" :value="time" full-width @change="timeSelected($event)" />
      </v-menu>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    label: { type: String, default: "" },
    value: { type: String, default: "" },
    type: { type: String, default: "datetime" }, //date, datetime, time, datefield
    clearable: { type: Boolean, default: false },
    dense: { type: Boolean, default: false },
    autofocus: { type: Boolean, default: false },
    hideDetails: { type: String, default: "auto" },
    errorMessages: { type: String, default: "" },
    appendIcon: { type: String, default: "mdi-calendar" },
    rules: { type: Array, default: () => [] }
  },
  data() {
    return {
      menu: false,
      menu2: false,
      valueDate: "",
      datam: "",
      timem: ""
    }
  },

  computed: {
    dateMask() {
      const year = [/[1-2]/, /[0-9]/, /[0-9]/, /[0-9]/]
      const month = [/[0-1]/, this.datam.charAt(3) === "1" ? /[0-2]/ : /[0-9]/]
      const day = [/[0-3]/, this.datam.charAt(0) === "3" ? /[0-1]/ : /[0-9]/]
      return [...day, ".", ...month, ".", ...year]
    },
    timeMask() {
      //this.$log("this.timemmmm ",this.timem)
      const hours = [/[0-2]/, this.timem.charAt(0) === "2" ? /[0-3]/ : /[0-9]/]
      const minutes = [/[0-5]/, /[0-9]/]
      return this.timem.length > 2 ? [...hours, ":", ...minutes] : hours
    },
    dateCols() {
      switch (this.type) {
        case "date":
          return 12
        case "datetime":
          return 7
        case "time":
          return 0
        case "datefield":
          return 12
      }
      return 12
    },
    timeCols() {
      switch (this.type) {
        case "date":
          return 0
        case "datetime":
          return 5
        case "time":
          return 12
        case "datefield":
          return 0
      }
      return 5
    },
    date() {
      //this.$log("datetimed ", this.valueDate)
      if (this.valueDate.length > 9) {
        return this.valueDate.substring(0, 10)
      }
      return ""
    },
    time() {
      //this.$log("datetimet ", this.valueDate)
      if (this.valueDate.length > 15) return this.valueDate.substring(11, 16)
      return ""
    }
  },
  watch: {
    value() {
      this.valueDate = this.value
      this.valueChanged(this.value)
    }
  },
  created() {
    this.valueDate = this.value
    this.valueChanged(this.value)
  },
  methods: {
    valueChanged(val) {
      //this.$log("valueChanged ", this.value)
      if (val.length > 9) {
        //this.datam = val.substring(0, 10)
        this.datam = val.substr(8, 2) + "." + val.substr(5, 2) + "." + val.substr(0, 4)
      } else {
        this.datam = ""
      }
      if (val.length > 15) {
        this.timem = val.substring(11, 16)
      } else {
        this.timem = ""
      }
      //this.$log("valueChanged t", this.timem)
    },
    datamchange(e) {
      this.menu = false
      if (e) {
        if (e.length == 10) {
          e = e.substr(6, 4) + "-" + e.substr(3, 2) + "-" + e.substr(0, 2)
          this.dateSelected(e)
        }
      }
    },
    timemchange(e) {
      this.menu2 = false
      if (e.length == 5) {
        this.timeSelected(e)
      }
    },
    dateSelected(e) {
      //this.$log("da1 ", e)
      //this.$log("da2 ", this.type)
      //this.datam = e
      this.datam = e.substr(8, 2) + "." + e.substr(5, 2) + "." + e.substr(0, 4)
      switch (this.type) {
        case "date":
          this.valueDate = e + " 00:00:00"
          this.$emit("input", e + " 00:00:00")
          break
        case "datetime":
          this.timem = (this.time == "" ? "00:00" : this.time) + ":00"
          this.valueDate = e + " " + (this.time == "" ? "00:00" : this.time) + ":00"
          this.$emit("input", e + " " + (this.time == "" ? "00:00" : this.time) + ":00")

          break
        case "datefield":
          this.valueDate = e
          this.$emit("input", e)
      }
      this.menu = false
    },
    timeSelected(e) {
      //this.$log("datetimet timeSelected1 ", e)
      //this.$log("datetimet timeSelected2 ", this.date)
      this.timem = e + ":00"
      switch (this.type) {
        case "datetime":
          this.valueDate = this.date + " " + e + ":00"
          this.$emit("input", this.date + " " + e + ":00")
          break
        case "time":
          this.valueDate = e + ":00"
          this.$emit("input", e + ":00")
          break
      }
      this.menu2 = false
    }
  }
}
</script>
<style scopped>
.v-date-picker-title__date {
  font-size: 20px;
}
</style>
<!--

-->

<template>
  <v-row dense>
    <v-col v-for="el in list" :key="el.id" cols="12">
      <scream-view ref="screamViewData" :children="el" :record="record" :schema="schemaContent" />
    </v-col>
  </v-row>
</template>
<script>
import ScreamView from "../../components/containers/ScreamView"
import manageRecord from "../../util/manage-record"
// import { EventBus } from "../../EventBus"
export default {
  components: {
    ScreamView
  },
  data: () => ({
    idGlobal: 50,
    collectionName: "",
    collection: "",
    collectionForms: [],
    recordId: "",
    etag: "",
    show: false,
    listContent: {},
    list: [],
    recordView: {},
    record: {},
    schemaContent: {},
    insertPosition: 0
  }),
  computed: {
    companyId() {
      return this.$store.getters.companyId
    }
  },
  methods: {
    ...manageRecord,
    allChildren(parent) {
      parent.$children.map(child => {
        if (child.isScreamMarcView) {
          //this.$log("child1 ", child.fields)
          //this.$log("child1 ", this.record)
          //child.generateRecord()
          const filds = child.generateRecord()
          //this.record.fields.splice(this.insertPosition, 0, ...flds)
          filds.map(flds => {
            for (const field in flds) {
              this.record.fields[field] = []
            }
          })
          filds.map(flds => {
            for (const field in flds) {
              //this.$log("fields1 ", field)
              //this.$log("fields2 ", flds[field])
              if (Object.entries(flds[field]).length == 0) {
                this.record.fields[field] = null
              } else {
                if (!this.record.fields[field]) {
                  this.record.fields[field] = []
                }
                flds[field].ord = this.insertPosition++
                this.record.fields[field].push(flds[field])
              }
            }
          })
          //this.insertPosition += flds.length
        }

        //this.$log("child2 ", child.$el)
        this.allChildren(child)
      })
    },
    getRecord() {
      //this.$log("g ", this.$refs.screamViewData)
      this.insertPosition = 0
      if (!this.record.fields) {
        this.record.fields = {}
      }
      this.$refs.screamViewData.map(itm => {
        this.allChildren(itm)
      })
      this.$log("rec1 ", this.record)
      //this.record.fields.splice(this.insertPosition, 0)
      //this.$refs.screamView.
      const rec = { ...this.record }
      //this.$log("gen list1 ", this.list)
      //this.$log("gen list2 ", this.listContent)
      this.getCleanRecord(rec, "", this.listContent)
      this.$log("rec2 ", rec)
      return rec
    },

    cloneComponent(prop) {
      const newprop = { ...prop }
      newprop.iiid = Date.now() // idGlobal++
      if (newprop.children) newprop.children = []
      return newprop
    },
    generateJson(list, arr) {
      // this.$log("generateJson1 ", list)
      // this.$log("generateJson2 ", arr)
      list.map(itm => {
        let newarr = arr
        if (itm.key) {
          if (itm.key.indexOf(arr + ".") === 0) {
            itm.key = itm.key.substring(arr.length + 1, itm.key.length)
          }
          if (itm.key.indexOf("[]") > 0) {
            itm.key = itm.key.substr(0, itm.key.length - 2)

            if (arr) {
              newarr = arr + "." + itm.key
            } else {
              newarr = itm.key
            }
          }
          let path = itm.key
          if (arr) {
            path = arr + "." + itm.key
          }
          path = path.split(".").join(".schema.")
          const dic = { ...this.resolvePath(path, this.listContent) }
          dic.schema = undefined
          Object.assign(itm, dic)
        }
        if (itm.children) {
          // if (itm.children.type) {
          //   this.$log("type ", typeof itm.children.type)
          // }
          this.generateJson(itm.children, newarr)
        }
      })
    },
    resolvePath(path, obj) {
      return path.split(".").reduce(function(prev, curr) {
        return prev ? prev[curr] : null
      }, obj || self)
    },

    cloneKey(keys, clone) {
      return (clone[keys] = {})
    },
    generateContent(content, cont) {
      for (const itm in cont) {
        // this.$log("itm cont ", itm)
        content[itm] = { ...cont[itm] }

        if (cont[itm].schema) {
          let bool = true
          if (cont[itm].schema.type) {
            if (cont[itm].schema.type === "dict") {
              bool = false
              this.generateContent(content[itm].schema, cont[itm].schema.schema)
              // this.$log("itm cont typeeeeeeeeeeeeeeeee ", cont[itm].schema.type)
            }
          }
          if (bool) {
            this.generateContent(content[itm].schema, cont[itm].schema)
          }
        }
      }
    },
    copyData(fields) {
      this.$set(this.record, "_id", undefined)
      fields.map(fld => {
        this.$set(this.record.fields, fld, JSON.parse(JSON.stringify(this.recordView.fields[fld])))
      })
    },
    showData(recordData, schema, listContent) {
      //this.$log("show data ", listContent)
      this.list = schema
      this.schemaContent = JSON.parse(JSON.stringify(listContent))
      this.listContent = JSON.parse(JSON.stringify(listContent))
      const newcontent = {}
      this.generateContent(newcontent, this.listContent)
      this.listContent = { ...newcontent }
      this.record = recordData
      // this.collectionName = this.collectionForms[ind].name
      // this.$log("list1", JSON.stringify(listContent))
      this.generateJson(this.list, "")
      //this.$log("list2", JSON.stringify(this.list))
      this.recordView = this.cloneObject({ children: [...this.list] }, {})
      const dic = { ...this.recordView }
      Object.assign(dic, this.record)
      this.record = { ...dic }
      //this.$log("this.recordview", JSON.stringify(this.recordView))
      //this.$log("list1", JSON.stringify(this.record))
      this.generateRecord(this.record, { ...this.recordView })

      //this.$log("this.record", this.record)
    }
  }
}
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"dense":""}},[_vm._l((_vm.value),function(fld,ind){return _c('v-col',{key:'461' + ind,attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('span',{staticClass:"d-none"},[_vm._v(_vm._s(_vm.render))]),_c('span',{staticClass:"d-none"},[_vm._v(_vm._s(_vm.ren))]),(!fld.link && !_vm.details[ind].title)?_c('v-sheet',{staticClass:"d-flex justify-center align-center",attrs:{"elevation":"3","width":"100%","height":"100%","min-height":_vm.height},on:{"click":function($event){return _vm.$emit('search', ind)}}},[_c('v-btn',{attrs:{"color":"cyan","fab":"","x-small":""}},[_c('v-icon',[_vm._v(" mdi-plus ")])],1)],1):_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-sheet',{staticClass:"py-3 pl-4 pr-2 subtitle-1",staticStyle:{"position":"relative"},attrs:{"elevation":"3","width":"100%","height":"100%"}},[(_vm.details[ind].title && _vm.details[ind]._id)?_c('div',{staticClass:"mb-0"},[(_vm.collection == 'ebib_marc_mon')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-1"},on),[_vm._v("mdi-book")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t("Titlu"))+" ")]):(_vm.collection == 'ebib_marc_ath')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-1"},on),[_vm._v("mdi-account")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t("Nume personal"))+" ")]):(_vm.collection == 'ebib_marc_cln')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-1"},on),[_vm._v("mdi-account")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t("Cititor"))+" ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.details[ind].title)+" ")],1):_c('div',{staticClass:"mb-0"},[_c('v-text-field',{attrs:{"value":_vm.details[ind].title,"readonly":"","label":"462t Nivel subset","error-messages":_vm.$t('Valoarea nu există!')}}),_c('v-btn',{attrs:{"color":"info","small":""},on:{"click":function($event){return _vm.addData(ind)}}},[_vm._v(_vm._s(_vm.$t("Creează")))])],1),(_vm.details[ind].forma_preferata)?_c('div',{staticClass:"mb-0"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-1"},on),[_vm._v("mdi-account-check")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t("Forma preferată"))+" ")]),_vm._v(" "+_vm._s(_vm.details[ind].forma_preferata)+" ")],1):_vm._e(),(_vm.details[ind].barcode)?_c('div',{staticClass:"mb-0"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-1"},on),[_vm._v("mdi-barcode")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t("Barcode"))+" ")]),_vm._v(" "+_vm._s(_vm.details[ind].barcode)+" ")],1):_vm._e(),(_vm.details[ind].forma_nepreferata)?_c('div',{staticClass:"mb-0"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-1"},on),[_vm._v("mdi-account-cancel")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t("Forma nepreferată"))+" ")]),_vm._v(" "+_vm._s(_vm.details[ind].forma_nepreferata)+" ")],1):_vm._e(),(_vm.details[ind].autor)?_c('div',{staticClass:"mb-0"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-1"},on),[_vm._v("mdi-account")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t("Autor principal"))+" ")]),_vm._v(" "+_vm._s(_vm.details[ind].autor)+" ")],1):_vm._e(),(_vm.details[ind].localitate)?_c('div',{staticClass:"mb-0"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-1"},on),[_vm._v("mdi-earth")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t("Localitate"))+" ")]),_vm._v(" "+_vm._s(_vm.details[ind].localitate)+" ")],1):_vm._e(),(_vm.details[ind].editura)?_c('div',{staticClass:"mb-0"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-1"},on),[_vm._v("mdi-alpha-e-box")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t("Editura"))+" ")]),_vm._v(" "+_vm._s(_vm.details[ind].editura)+" ")],1):_vm._e(),(_vm.details[ind].an)?_c('div',{staticClass:"mb-0"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-1"},on),[_vm._v("mdi-calendar")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t("An"))+" ")]),_vm._v(" "+_vm._s(_vm.details[ind].an)+" ")],1):_vm._e(),(fld['4'] && _vm.show7024)?_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-1 mt-1"},on),[_vm._v("mdi-account-question")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t("Funcție"))+" ")]),_c('s-autocomplete',{attrs:{"dense":"","hide-details":"","clearable":false,"view-type":"marc","placeholder":"Funcție","item-text":'fields.702.4.val',"item-value":'fields.702.4.val',"collection":"ebib_marc_mon"},model:{value:(fld['4'][0].val),callback:function ($$v) {_vm.$set(fld['4'][0], "val", $$v)},expression:"fld['4'][0].val"}})],1):_vm._e(),(fld['4'] && _vm.show7124)?_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-1 mt-1"},on),[_vm._v("mdi-account-question")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t("Funcție"))+" ")]),_c('s-autocomplete',{attrs:{"dense":"","hide-details":"","clearable":false,"view-type":"marc","placeholder":"Funcție","item-text":'fields.712.4.val',"item-value":'fields.712.4.val',"collection":"ebib_marc_mon"},model:{value:(fld['4'][0].val),callback:function ($$v) {_vm.$set(fld['4'][0], "val", $$v)},expression:"fld['4'][0].val"}})],1):_vm._e(),(fld['4'] && _vm.show7224)?_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-1 mt-1"},on),[_vm._v("mdi-account-question")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t("Funcție"))+" ")]),_c('s-autocomplete',{attrs:{"dense":"","hide-details":"","clearable":false,"view-type":"marc","placeholder":"Funcție","item-text":'fields.722.4.val',"item-value":'fields.722.4.val',"collection":"ebib_marc_mon"},model:{value:(fld['4'][0].val),callback:function ($$v) {_vm.$set(fld['4'][0], "val", $$v)},expression:"fld['4'][0].val"}})],1):_vm._e(),_c('v-sheet',{staticClass:"mr-0 mt-0 d-flex",staticStyle:{"position":"absolute","top":"5px","right":"5px","float":"right"}},[_c('isbd-info',{attrs:{"id":fld.link,"prefix":_vm.prefix,"show":hover}}),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(hover),expression:"hover"}],attrs:{"color":"grey darken-1","icon":"","small":""},on:{"click":function($event){return _vm.$emit('del', ind)}}},[_c('v-icon',[_vm._v(" mdi-delete ")])],1)],1)],1)]}}],null,true)})],1)}),_c(_vm.currentEditForm,{ref:"addDataPopUp",tag:"component",on:{"mounted":_vm.addDataMounted,"reload":_vm.recordAdded}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-row dense>
    <v-col cols="12" class="d-flex">
      <span class="d-none">{{ render }}</span>
      <v-spacer />
      <v-btn-toggle v-model="selectedView" class="mb-2" dense mandatory>
        <v-btn icon>
          <v-icon>mdi-view-module</v-icon>
        </v-btn>
        <v-btn icon>
          <v-icon>mdi-view-list</v-icon>
        </v-btn>
      </v-btn-toggle>
    </v-col>
    <v-col cols="12">
      <draggable class="" tag="v-row" :list="records" draggable=".itemMR" @change="dragChange">
        <template v-for="(item, i) in records">
          <v-hover :key="i" v-slot:default="{ hover }">
            <!--  -->
            <v-col
              cols="12"
              :sm="selectedView ? 12 : 6"
              :md="selectedView ? 12 : 4"
              :lg="selectedView ? 12 : 3"
              :xl="selectedView ? 12 : 2"
              :class="{ itemMR: type == 'admin' && item.link }"
            >
              <v-card class="mt-0 mb-0" width="100%" :height="height + 60 + 'px'" :elevation="hover ? 7 : 3" tile>
                <v-card-title
                  :style="
                    'height:40px;overflow:hidden;' + (type == 'admin' && i != records.length - 1 ? 'cursor:move' : '')
                  "
                  class="grey lighten-4 d-flex pt-0 pl-3"
                >
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <div class="text-h5 text-truncate mr-4" v-on="on">{{ item.name }}</div>
                    </template>
                    <span>{{ item.name }}</span>
                  </v-tooltip>
                  <v-btn
                    v-show="type == 'admin' && hover && i != records.length - 1"
                    style="position: absolute;top:5px;right:5px;"
                    color="error"
                    icon
                    small
                    @click="deleteResource(i)"
                  >
                    <v-icon>
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text class="d-flex justify-center align-center mt-2">
                  <template v-if="item.link">
                    <!-- <v-icon v-if="item.field == 950" size="100">mdi-video</v-icon> -->
                    <video v-if="item.field == 950" :height="height + 'px'" controls>
                      <source :src="baseURL + 'file_stream/?f=' + item.link" />
                    </video>
                    <!-- <v-icon v-else-if="item.field == 953" size="100">mdi-volume-medium</v-icon> -->
                    <audio v-else-if="item.field == 953" class="mt-8" controls>
                      <source :src="baseURL + 'file_stream/?f=' + item.link" />
                    </audio>
                    <!-- <v-icon v-else-if="item.field == 955" size="100">mdi-image</v-icon> -->
                    <v-img
                      v-else-if="item.field == 955"
                      style="cursor:pointer"
                      :src="baseURL + 'file_stream/?f=' + item.link"
                      :height="height"
                      contain
                      @click="
                        preview = true
                        previewTitle = item.name
                        previewLink = item.link
                        previewType = 'image'
                      "
                    />
                    <v-icon
                      v-else-if="item.type == 'application/pdf'"
                      size="120"
                      @click="
                        preview = true
                        previewTitle = item.name
                        previewLink = item.link
                        previewType = 'pdf'
                      "
                    >
                      mdi-file-pdf-box
                    </v-icon>
                    <v-icon v-else-if="item.type == 'text/plain'" size="120" @click="openTXT(item)">
                      mdi-format-text
                    </v-icon>
                    <v-icon v-else size="120">mdi-file</v-icon>
                  </template>
                  <v-menu v-else absolute offset-y style="max-width: 600px">
                    <template v-slot:activator="{ on }">
                      <v-btn v-if="isMobile" color="cyan" class="mt-10" fab x-small @click="$refs.file.click()">
                        <v-icon>
                          mdi-plus
                        </v-icon>
                      </v-btn>
                      <v-btn v-else color="cyan" class="mt-10" fab x-small v-on="on">
                        <v-icon>
                          mdi-plus
                        </v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="$refs.file.click()">
                        <v-list-item-icon>
                          <v-icon>mdi-upload</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{ $t("Încarcă") }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="$refs.camCapture.open()">
                        <v-list-item-icon>
                          <v-icon>mdi-camera</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{ $t("Capturează") }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="openSelect">
                        <v-list-item-icon>
                          <v-icon>mdi-check</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{ $t("Selectează") }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-card-text>
              </v-card>
            </v-col>
          </v-hover>
        </template>
      </draggable>
    </v-col>
    <div v-show="false">
      <input
        ref="file"
        type="file"
        accept="image/*,video/*,audio/*,text/*,.doc,.docx,.odt,.pdf,.xls,.xlsx,.ods,.ppt,.pptx,.zip,.rar,.7z"
        @change="imageChanged"
      />
    </div>
    <cam-capture ref="camCapture" file-name="resursa_" @captured="imgCaptured" />
    <v-dialog v-model="preview" persistent scrollable @keydown.esc="preview = false">
      <v-card>
        <v-card-title class="pt-3 pb-1">
          {{ previewTitle }}
          <v-spacer />
          <v-icon color="red" class="mt-n2 mr-n2" large @click="preview = false">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider color="primary" />
        <v-card-text class="pt-3">
          <v-img
            v-if="previewType == 'image'"
            :src="baseURL + 'file_stream/?f=' + previewLink"
            :height="dialogHeight"
            contain
          />
          <scream-pdf
            v-else-if="previewType == 'pdf'"
            :src="baseURL + 'file_stream/?f=' + previewLink"
            :height="dialogHeight - 35 + 'px'"
          />
          <div v-else-if="previewType == 'txt'">
            <pre>
            {{ previewText }}
            </pre>
            <!-- <iframe src="https://docs.google.com/gview?url=http://ieee802.org/secmail/docIZSEwEqHFr.doc&embedded=true"
             frameborder="0">
            </iframe>
            <iframe src='https://view.officeapps.live.com/op/embed.aspx?src=http%3A%2F%2Fieee802%2Eorg%3A80%2Fsecmail%2FdocIZSEwEqHFr%2Edoc'
            width='100%' height='100%' frameborder='0'>
            </iframe> -->
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import axios from "../../plugins/axios"
import CamCapture from "./CamCapture"
import ScreamPdf from "./ScreamPdf"
import draggable from "vuedraggable"
import isMobile from "ismobilejs"
import { EventBus } from "../../EventBus"
export default {
  name: "AddImage",
  components: { CamCapture, draggable, ScreamPdf },
  props: {
    record: {
      type: Object,
      default: () => {}
    },
    type: {
      type: String,
      default: "admin"
    },
    render: {
      type: Number,
      default: 1
    },
    height: {
      type: Number,
      default: 50
    },
    width: {
      type: Number,
      default: 100
    }
  },
  data: () => ({
    src: "",
    uid: 1,
    ord: 900,
    selectedView: 0,
    preview: false,
    previewTitle: "",
    previewLink: "",
    previewType: "",
    previewText: "",
    fields: {
      image: 955,
      audio: 953,
      video: 950
    }
  }),
  computed: {
    dialogHeight() {
      return window.innerHeight - 175
    },
    records() {
      this.$log("recordssssss ", this.record)
      const arr = []
      const fields = ["950", "953", "955", "958", "959"]
      if (this.record.fields) {
        fields.map(field => {
          if (this.record.fields[field]) {
            this.record.fields[field].map(itm => {
              if (itm.link) {
                arr.push({
                  uid: this.uid++,
                  ord: itm.ord,
                  type: itm.c[0].val,
                  field,
                  link: itm.link,
                  name: itm.a[0].val
                })
                if (itm.ord > this.ord) {
                  this.ord = itm.ord + 1
                }
              }
            })
          }
        })
        if (this.type == "admin") {
          arr.push({ ord: this.ord })
        }
        arr.sort((a, b) => {
          return a.ord - b.ord
        })
      }
      return arr
    },
    baseURL() {
      return axios.defaults.baseURL
    },
    companyId() {
      return this.$store.getters.companyId
    },
    isMobile() {
      return isMobile(navigator.userAgent).any
    }
  },
  created() {
    EventBus.$on("search-link-selected", search => {
      if (search.id == "ManageResource") {
        const ctype = search.value.content_type.split("/")
        let field = 958
        if (this.fields[ctype[0]]) {
          field = this.fields[ctype[0]]
        }
        this.$emit("add", {
          field,
          ord: ++this.ord,
          link: search.value._id,
          type: search.value.content_type,
          name: search.value.name
        })
      }
    })
  },
  methods: {
    openTXT(item) {
      this.previewTitle = item.name
      this.previewLink = item.link
      this.previewType = "txt"
      axios.get(this.baseURL + "file_stream/?f=" + item.link).then(res => {
        this.previewText = res.data
        this.preview = true
      })
    },
    openSelect() {
      EventBus.$emit("search-link", { resource: "ebib_media", id: "ManageResource" })
    },
    deleteResource(i) {
      this.$emit("del", this.records[i])
    },
    dragChange(e) {
      if (e.moved.oldIndex != this.records.length - 1) {
        let oldp = this.records[e.moved.newIndex].ord
        let oldo = this.records[e.moved.newIndex].ord
        if (e.moved.oldIndex > e.moved.newIndex) {
          for (let i = e.moved.oldIndex; i >= e.moved.newIndex; i--) {
            oldp = oldo
            oldo = this.records[i].ord
            this.records[i].ord = oldp
          }
          this.$emit("chg", this.records.slice(e.moved.newIndex, e.moved.oldIndex + 1))
        } else {
          for (let i = e.moved.oldIndex; i <= e.moved.newIndex; i++) {
            oldp = oldo
            oldo = this.records[i].ord
            this.records[i].ord = oldp
          }
          this.$emit("chg", this.records.slice(e.moved.oldIndex, e.moved.newIndex + 1))
        }
      }
    },
    imageChanged(e) {
      this.$log("imageChanged ", e)
      const formData = new FormData()
      formData.append("company", this.companyId)
      formData.append("content_type", e.target.files[0].type)
      formData.append("name", e.target.files[0].name)
      formData.append("file_name", e.target.files[0].name)
      formData.append("file", e.target.files[0])
      axios
        .post("ebib_media/", formData, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          }
        })
        .then(res => {
          this.$log("response media ", res)
          if (res.status == 201) {
            const ctype = res.data.content_type.split("/")
            let field = 958
            if (this.fields[ctype[0]]) {
              field = this.fields[ctype[0]]
            }
            this.$emit("add", {
              field,
              ord: ++this.ord,
              link: res.data._id,
              type: res.data.content_type,
              name: res.data.name
            })
          }
        })
        .catch(error => {
          this.$log("error ", error)
        })
    },
    imgCaptured(e) {
      this.$emit("add", { field: 955, ord: ++this.ord, link: e._id, type: e.content_type, name: e.name })
    }
  }
}
</script>
<style scoped></style>

<template>
  <v-dialog v-model="show" v-resize="resize" persistent scrollable>
    <v-card outlined>
      <v-card-title>Card</v-card-title>
      <v-card-text id="cardtext">
        <v-row v-scroll:#cardtext="onScroll">
          <v-col class="order-1 order-md-0" cols="12" md="3">
            <v-row>
              <v-col v-for="img in records" :key="img.link" cols="12">
                <v-img
                  v-if="img.link"
                  :src="baseURL + 'file_stream/?f=' + img.link"
                  contain
                  :height="150"
                  :width="250"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col class="order-0 order-md-1" cols="12" md="9">
            <v-form ref="dataForm">
              <v-row dense>
                <v-col cols="12">
                  <v-select
                    v-model="camera"
                    :label="$t('Camera')"
                    item-text="label"
                    :items="listCamera"
                    return-object
                    @change="changeCamera"
                  />
                </v-col>
                <v-col cols="12">
                  <div class="video_container">
                    <div v-show="!isScroll" :style="{ left: leftFloat }" class="video_overlay"></div>

                    <web-cam
                      ref="cam"
                      :device-id="deviceId"
                      :resolution="resolution"
                      height="300"
                      @cameras="listCameras"
                    />
                  </div>
                </v-col>
                <v-col class="d-flex justify-center" cols="12">
                  <v-btn small @click="startCam">
                    start
                  </v-btn>
                  <v-btn small @click="$refs.cam.stop()">
                    stop
                  </v-btn>
                  <v-btn small @click="captureCam">
                    capture
                  </v-btn>
                </v-col>
                <v-col class="d-flex justify-center" cols="12">
                  <img :src="img" contain height="300" />
                  <canvas ref="canvas" style="display:none;"></canvas>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="py-0">
        <v-spacer />
        <v-btn
          text
          small
          @click="
            $refs.cam.stop()
            show = false
          "
        >
          {{ $t("Închide") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "../../plugins/axios"
import { WebCam } from "vue-web-cam"
export default {
  name: "UserCard",
  components: { WebCam },
  data: () => ({
    show: false,
    records: [],
    camera: null,
    img: null,
    deviceId: null,
    listCamera: [],
    mstream: null,
    response: null,
    leftFloat: "0px",
    isScroll: false,
    resolution: { width: 1920, height: 1080 }
  }),
  computed: {
    baseURL() {
      return axios.defaults.baseURL
    },
    inputRequired() {
      const rule = []
      rule.push(v => !!v || this.$t("required"))
      return rule
    },
    companyId() {
      return this.$store.getters.companyId
    },
    userId() {
      return this.$store.getters.accountId
    }
    // leftFloat() {
    //   if (this.$refs.cam) {
    //     return this.$refs.cam.$el.offsetLeft + (this.$refs.cam.$el.offsetWidth - 240) / 2 + "px"
    //   }
    //   return "0px"
    // }
  },
  methods: {
    onScroll(e) {
      if (e.target.scrollTop > 0) {
        this.isScroll = true
      } else {
        this.isScroll = false
      }
    },
    resize() {
      if (this.$refs.cam != undefined) {
        this.leftFloat = this.$refs.cam.$el.offsetLeft + (this.$refs.cam.$el.offsetWidth - 240) / 2 + "px"
      } else {
        this.leftFloat = "0px"
      }
    },
    changeCamera(e) {
      this.$log("changeCamera ", e)
      this.camera = e
      this.deviceId = e.deviceId
    },
    startCam() {
      this.$log("startCam ", this.$refs.cam)
      if (this.$refs.cam) {
        this.$refs.cam.start()
      }
    },
    captureCam() {
      const video = this.$refs.cam.$el
      this.$log("vvv ", this.$refs.cam)
      this.$refs.canvas.width = (video.videoHeight / 5) * 4
      this.$refs.canvas.height = video.videoHeight
      this.$refs.canvas
        .getContext("2d")
        .drawImage(
          video,
          (video.videoWidth - (video.videoHeight / 5) * 4) / 2,
          0,
          (video.videoHeight / 5) * 4,
          video.videoHeight,
          0,
          0,
          (video.videoHeight / 5) * 4,
          video.videoHeight
        )
      this.img = this.$refs.canvas.toDataURL("image/webp")
      const formData = new FormData()
      formData.append("company", this.companyId)
      formData.append("content_type", "image/png")
      formData.append("file_name", "permis_" + Date.now())
      formData.append("name", "permis_" + Date.now())
      this.$refs.canvas.toBlob(blob => {
        formData.append("file", blob)
        axios
          .post("ebib_media/", formData, {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded"
            }
          })
          .then(response => {
            if (response.status == 201) {
              if (this.response.data.fields["856"]) {
                this.response.data.fields["856"].push({
                  link: response.data._id,
                  f: [{ ord: 0, val: response.data.name }],
                  ord: this.response.data.fields["856"][this.response.data.fields["856"].length - 1].ord
                    ? this.response.data.fields["856"][this.response.data.fields["856"].length - 1].ord + 1
                    : 100
                })
              } else {
                this.response.data.fields["856"] = []
                this.response.data.fields["856"].push({
                  link: response.data._id,
                  f: [{ ord: 0, val: response.data.name }],
                  ord: 100
                })
              }
              this.response.data._links = undefined
              this.response.data._version = undefined
              this.response.data._latest_version = undefined
              this.response.data._updated = undefined
              this.response.data._created = undefined
              this.response.data._etag = undefined
              axios
                .patch("ebib_marc_cln/" + this.response.data._id, this.response.data, {
                  headers: {
                    "If-Match": this.etag
                  }
                })
                .then(res => {
                  if (res.status == 200) {
                    this.open({ id: res.data._id })
                  }
                })
            }
          })
      })
    },
    listCameras(e) {
      this.listCamera = e
      if (this.listCamera.length > 0) {
        this.camera = this.listCamera[0]
        this.deviceId = this.camera.deviceId
      }
    },
    open(e) {
      this.show = true
      this.records = []
      axios.get("ebib_marc_cln/" + e.id + '?projection={"fields.856":1}').then(response => {
        this.etag = response.data._etag
        this.response = response
        this.resize()
        if (response.data.fields) {
          this.records = response.data.fields["856"]
        }
      })
      this.startCam()
      //this.$nextTick(() => this.resize())
    }
  }
}
</script>
<style scoped>
.video_containeer {
  position: relative;
  width: 420px;
}
.video_overlay {
  position: absolute;
  height: 300px;
  border-style: solid;
  border-color: red;
  border-width: thin;
  width: 240px;
}
</style>

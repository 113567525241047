<template>
  <v-row dense>
    <v-col cols="12" class="d-flex grey lighten-1 justify-center">
      <v-btn color="primary" class="mr-0 mt-1 mb-0" icon small @click="scale -= scale > 0.15 ? 0.1 : 0">
        <v-icon>mdi-minus</v-icon>
      </v-btn>
      <span class="mt-1 ml-0 white px-1"> {{ zoom }} </span>
      <!-- <div style="width:60px" class="textPage">
          <v-text-field
            :value="zoom"
            style="text-align:right!important;"
            class="white mt-0"
            reverse
            dense
            hide-details
            outlined
          />
        </div> -->
      <v-btn color="primary" class="mr-1 mt-1 mb-0" icon small @click="scale += scale < 4 ? 0.1 : 0">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-spacer />

      <v-btn
        color="primary"
        class="mr-1 mt-1 mb-0"
        :disabled="inputPage == 1"
        icon
        small
        @click="page = Number(inputPage) - 1"
      >
        <v-icon>mdi-arrow-up-thick</v-icon>
      </v-btn>
      <v-btn
        color="primary"
        class="mr-2 mt-1 mb-0"
        :disabled="inputPage == totalPages"
        icon
        small
        @click="page = Number(inputPage) + 1"
      >
        <v-icon>mdi-arrow-down-thick</v-icon>
      </v-btn>
      <div style="width:55px" class="textPage">
        <v-text-field
          v-model="inputPage"
          v-mask="pageMask"
          style="text-align:right!important;"
          class="white mt-0"
          reverse
          dense
          hide-details
          outlined
          min="1"
          :max="totalPages"
          @keydown.enter="changePage"
          @blur="changePage"
        />
      </div>
      <span class="mt-1 ml-2"> din {{ totalPages }} </span>
      <v-btn
        color="primary"
        class="mr-1 mt-1 mb-0"
        icon
        small
        @click="
          rotate += 90
          render++
        "
      >
        <v-icon>mdi-rotate-right</v-icon>
      </v-btn>
      <v-btn
        color="primary"
        class="mr-2 mt-1 mb-0"
        icon
        small
        @click="
          rotate -= 90
          render++
        "
      >
        <v-icon>mdi-rotate-left</v-icon>
      </v-btn>
      <v-spacer />
      <v-checkbox v-model="allPage" class="mt-0 mr-4" dense hide-details :label="$t('Toate paginile')" />
    </v-col>
    <v-col v-show="loading" class="pl-0 pr-0 pt-0 pb-0 grey lighten-2" cols="12">
      <v-progress-linear v-show="loading" style="margin:0px" :indeterminate="loading" color="primary" />
    </v-col>
    <v-col class="pl-0 pr-0 pt-0 pb-0 grey lighten-2" cols="12">
      <!-- <keep-alive> -->
      <v-sheet
        ref="pdfSheet"
        v-scroll.self="pdfScroll"
        style="overflow:auto"
        class="grey lighten-2 d-flex flex-wrap justify-center"
        min-height="600px"
        :height="height"
      >
        <pdf
          v-if="!allPage && !loading"
          :key="render"
          class="ma-2"
          :src="srcPdf"
          :page="page"
          :scale.sync="scale"
          :resize="resize"
          :annotation="annotation"
          :text="text"
          :rotate="rotate"
        ></pdf>
        <!-- <v-virtual-scroll v-else :bench="0" :items="pdfItems" height="600" item-height="800">
          <template v-slot:default="{ item }">
            <pdf
              :id="'pdfpage' + item"
              :key="item"
              class="ma-2"
              :src="srcPdf"
              :page="item"
              :scale="scale"
              :resize="resize"
              :annotation="annotation"
              :text="text"
              :rotate="rotate"
              @loading="loadedData"
              @numpages="loadedData"
            ></pdf>
          </template>
        </v-virtual-scroll> -->
        <!-- <div > -->

        <pdf
          v-else
          :id="'pdfpage' + i"
          v-for="i in pdfPages"
          :key="i"
          class="ma-2"
          :src="srcPdf"
          :page="i"
          :scale="scale"
          :resize="resize"
          :annotation="annotation"
          :text="text"
          :rotate="rotate"
          @loading="loadedData"
          @numpages="loadedData"
        ></pdf>

        <!-- </div> -->
        <!-- <pdf
          v-else
          v-for="i in totalPages"
          :id="'pdfpage' + i"
          :key="i"
          class="ma-2"
          :src="srcPdf"
          :page="i"
          :scale="scale"
          :resize="resize"
          :annotation="annotation"
          :text="text"
          :rotate="rotate"
          @loading="loadedData"
          @numpages="loadedData"
        ></pdf> -->
      </v-sheet>
      <!-- </keep-alive> -->
    </v-col>
    <!-- <v-col v-else :key="render" class="pa-0" cols="12">
      <v-sheet style="overflow:auto" class="grey lighten-2 d-flex flex-wrap justify-center" height="100%">
        <pdf
          v-for="i in totalPages"
          :key="i"
          class="ma-2"
          :src="srcPdf"
          :page="i"
          :scale="scale"
          :resize="resize"
          :annotation="annotation"
          :text="text"
          :rotate="rotate"
        ></pdf>
      </v-sheet>
    </v-col> -->
  </v-row>
</template>
<script>
import pdf from "pdfvuer"

//import pdf from "vue-pdf"
export default {
  name: "ScreamPDF",
  components: { pdf },
  props: {
    src: {
      type: String,
      default: ""
    },
    height: {
      type: String,
      default: "100%"
    }
  },
  //http://ip:7780/file_stream/?f=601ba0ca1dd2d1825301c840
  data: () => ({
    loading: true,
    loadPage: false,
    allPage: false,
    render: 1,
    page: 1,
    inputPage: 1,
    pdfItems: {},
    pdfPages: {},
    rotate: 0,
    srcPdf: null,
    loadPdf: null,
    scale: 1,
    resize: false,
    annotation: false,
    text: false,
    totalPages: 0
  }),
  computed: {
    zoom() {
      return String(Number.parseInt(this.scale * 100)) + "%"
    },
    pageMask() {
      const pg = []
      for (let i = 0; i < String(this.totalPages).length; i++) {
        if (i == 0) {
          pg.push(/[1-9]/)
        } else {
          pg.push(/[0-9]/)
        }
      }
      return [...pg]
    }
  },
  watch: {
    page() {
      this.loadPage = true
      this.addPages(this.page)
      this.inputPage = this.page
      //this.$set(this.pdfItems[this.page], "link", this.srcPdf)
      //this.$refs.pdfSheet =
      //this.$log("page ", this.page)
      if (this.allPage) {
        this.$nextTick(() => {
          document.getElementById("pdfpage" + this.page).scrollIntoView()
        })
      }
    },
    allPage() {
      if (!this.allPage) {
        this.page = this.inputPage
        this.$refs.pdfSheet.$el.scrollTo(0, 0)
      } else {
        this.loadPage = true
        this.pdfItems = {}
        this.addPages(this.page)
      }
    },
    src() {
      this.$log("src changed")
      this.srcPdf = pdf.createLoadingTask(this.src)
      this.srcPdf.then(itm => {
        //this.$log("pdf loaded ", itm)
        this.totalPages = itm.numPages
      })
    }
  },
  created() {
    this.$log("created " + this.src)
  },
  mounted() {
    this.$log("mounted " + this.src)
    //this.srcPdf = this.src
    this.loading = true
    this.srcPdf = pdf.createLoadingTask(this.src)
    this.srcPdf
      .then(itm => {
        this.$log("pdf loaded ", itm)
        this.loading = false
        this.render++
        this.totalPages = itm.numPages
        this.addPages(this.page)
        this.genpdfPages()
      })
      .catch(err => {
        this.$log("error pdf ", err)
      })
  },
  destroyed() {
    this.$log("destroy " + this.src)
    this.srcPdf = null
  },
  methods: {
    addPages(page) {
      this.pdfItems["a" + (page - 5)] = page - 5
      this.pdfItems["a" + (page - 4)] = page - 4
      this.pdfItems["a" + (page - 3)] = page - 3
      this.pdfItems["a" + (page - 2)] = page - 2
      this.pdfItems["a" + (page - 1)] = page - 1
      this.pdfItems["a" + page] = page
      this.pdfItems["a" + (page + 1)] = page + 1
      this.pdfItems["a" + (page + 2)] = page + 2
      this.pdfItems["a" + (page + 3)] = page + 3
      this.pdfItems["a" + (page + 4)] = page + 4
      this.pdfItems["a" + (page + 5)] = page + 5
      this.genpdfPages()
    },
    genpdfPages() {
      this.pdfPages = {}
      for (let i = 1; i <= this.totalPages; i++) {
        if (this.pdfItems["a" + i]) {
          this.pdfPages["a" + i] = i
        }
      }
      //this.$log("pdfpages ", this.pdfPages)
    },
    intersected(i) {
      this.$log("intersected ", i)
    },
    loadedData(e) {
      if (this.allPage && this.loadPage) {
        //this.$log("loadedData ", e)
        document.getElementById("pdfpage" + this.inputPage).scrollIntoView()
      }
    },
    pdfScroll() {
      if (this.allPage) {
        this.loadPage = false
        let i = 1
        let curp = 1
        do {
          try {
            if (
              this.$refs.pdfSheet.$el.scrollTop + 200 >=
              document.getElementById("pdfpage" + i).offsetTop - this.$refs.pdfSheet.$el.offsetTop
            ) {
              curp = i
            }
          } catch (err) {}
          i++
        } while (i <= this.totalPages)
        if (this.inputPage != curp) {
          //this.$log("pdfScroll ", curp)
          this.addPages(curp)
        }
        this.inputPage = curp
      }
    },
    scaleAdd() {
      if (this.scale == "page-width") {
        this.scale = 1.1
      } else {
        this.scale += 0.1
      }
      //this.$log("scale ", this.scale)
    },
    scaleDel() {
      if (this.scale == "page-width") {
        this.scale = 0.9
      } else {
        this.scale -= 0.1
      }
      //this.$log("scale ", this.scale)
    },
    changePage() {
      if (this.inputPage < 1) {
        this.inputPage = 1
      } else if (this.inputPage > this.totalPages) {
        this.inputPage = this.totalPages
      }
      this.page = Number(this.inputPage)
    }
  }
}
</script>
<style>
.textPage .v-text-field .v-input__control .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
}
</style>

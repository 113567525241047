<template>
  <div>
    <!-- <v-btn v-if="showAdd" class="mb-5" color="success" absolute fab @click="addItem" bottom right>
      <v-icon>mdi-plus</v-icon>
    </v-btn> -->
    <div class="d-flex mt-2">
      <v-tooltip top>
        <template #activator="{ on }">
          <v-btn color="teal" fab text small @click="getRecords" v-on="on">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("Reîncarcă datele") }}</span>
      </v-tooltip>
      <v-tooltip
        v-if="showAdd && ((collection == 'ebib_marc_mon' && category != '') || collection != 'ebib_marc_mon')"
        top
      >
        <template #activator="{ on }">
          <v-btn color="cyan" fab text small @click="addItem" v-on="on">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("Adaugă înregistrare") }}</span>
      </v-tooltip>
      <v-tooltip v-if="showSelectInv" top>
        <template #activator="{ on }">
          <v-btn color="cyan" fab text small @click="addRMFInv" v-on="on">
            <v-icon>mdi-check</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("Selectează inventare") }}</span>
      </v-tooltip>
      <!-- <v-btn
        v-if="collection == 'ebib_marc_mon'"
        class="mt-1"
        color="success"
        elevation="3"
        fab
        x-small
        @click="addItem"
      >
        <v-icon>mdi-database-import</v-icon>
      </v-btn> -->
      <!-- <v-tooltip v-if="actions.indexOf('edit') >= 0 && selectedId.length == 1" top>
        <template v-slot:activator="{ on }">
          <v-btn class="mt-1" elevation="3" color="success" fab x-small @click="editItem(selectedId[0])" v-on="on">
            <v-icon>
              mdi-pencil
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t("Modifică înregistrare") }}</span>
      </v-tooltip> -->
      <!-- <v-tooltip v-if="showSheet && selectedId.length == 1" top>
        <template v-slot:activator="{ on }">
          <v-btn class="mt-1" color="warning" elevation="3" fab x-small @click="sheetItem('marc')" v-on="on">
            <v-icon>mdi-format-list-bulleted</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("Fișă marc") }}</span>
      </v-tooltip>
      <v-tooltip v-if="selectedId.length == 1 && debug" top>
        <template v-slot:activator="{ on }">
          <v-btn class="mt-1" color="blue-grey" elevation="3" fab x-small @click="sheetItem('json')" v-on="on">
            <v-icon>mdi-code-json</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("Înregistrare JSON") }}</span>
      </v-tooltip> -->
      <!-- <v-tooltip v-if="selectedId.length == 1 && actions.indexOf('inventare') >= 0" top>
        <template v-slot:activator="{ on }">
          <v-btn class="mt-1" color="info" elevation="3" fab x-small @click="inventareItem(selectedId[0])" v-on="on">
            <v-icon>
              mdi-bookshelf
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t("Inventare") }}</span>
      </v-tooltip> -->
      <v-tooltip v-if="selectedId.length > 0 && collection == 'infuni_admin_events'" top>
        <template #activator="{ on }">
          <v-btn color="success" fab text small v-on="on">
            <v-icon>
              mdi-play
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t("Run events") }}</span>
      </v-tooltip>
      <v-menu
        v-if="selectedId.length > 0 && (collection == 'infuni_publons' || collection == 'infuni_academics')"
        offset-y
      >
        <template #activator="{ on: onMenu }">
          <v-tooltip top>
            <template #activator="{ on }">
              <v-btn color="#316394" fab text small v-on="{ ...onMenu, ...on }">
                <v-icon>
                  mdi-alpha-p-box-outline
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t("Update publons data") }}</span>
          </v-tooltip>
        </template>
        <v-sheet>
          <div class="pt-2 px-4">
            <v-btn color="#316394" block @click="getpublons('all')">{{ $t("Update publons all") }}</v-btn>
          </div>
          <div class="mt-2 px-4">
            <v-btn color="#316394" block @click="getpublons('details')">{{ $t("Update publons details") }}</v-btn>
          </div>
          <div class="mt-2 px-4">
            <v-btn color="#316394" block @click="getpublons('publications')">
              {{ $t("Update publons publications") }}
            </v-btn>
          </div>
          <div class="mt-2 px-4">
            <v-btn color="#316394" block @click="getpublons('editorial_boards')">
              {{ $t("Update publons editorials") }}
            </v-btn>
          </div>
          <div class="mt-2 pb-2 px-4">
            <v-btn color="#316394" block @click="getpublons('reviews')">{{ $t("Update publons reviews") }}</v-btn>
          </div>
        </v-sheet>
      </v-menu>

      <v-menu
        v-if="selectedId.length > 0 && (collection == 'infuni_academics' || collection == 'infuni_scopus')"
        offset-y
      >
        <template #activator="{ on: onMenu }">
          <v-tooltip top>
            <template #activator="{ on }">
              <v-btn color="#ec6a1f" fab text small v-on="{ ...onMenu, ...on }">
                <v-icon>
                  mdi-alpha-s-box-outline
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t("Update scopus data") }}</span>
          </v-tooltip>
        </template>
        <v-sheet>
          <div class="pt-2 px-4">
            <v-btn color="#ec6a1f" block @click="getscopus('all')">{{ $t("Update scopus all") }}</v-btn>
          </div>
          <div class="mt-2 px-4">
            <v-btn color="#ec6a1f" block @click="getscopus('details')">{{ $t("Update scopus details") }}</v-btn>
          </div>
          <div class="mt-2 px-4">
            <v-btn color="#ec6a1f" block @click="getscopus('publications')">
              {{ $t("Update scopus publications") }}
            </v-btn>
          </div>
          <div class="mt-2 pb-2 px-4">
            <v-btn color="#ec6a1f" block @click="getscopus('citations')">{{ $t("Update scopus citations") }}</v-btn>
          </div>
        </v-sheet>
      </v-menu>

      <v-menu v-if="selectedId.length > 0 && viewType == 'marc' && !opac" offset-y>
        <template #activator="{ on: onMenu }">
          <v-tooltip top>
            <template #activator="{ on }">
              <v-btn color="brown" fab text small v-on="{ ...onMenu, ...on }">
                <v-icon>
                  mdi-file-move-outline
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t("Schimbă categorie") }}</span>
          </v-tooltip>
        </template>
        <v-list>
          <v-list-item v-for="(item, index) in categories" :key="index" link @click="changeCategory(item)">
            <v-list-item-title>{{ item.lblRo }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-edit-dialog large :save-text="$t('Șterge')" :cancel-text="$t('Anulează')" @save="deleteSelected">
        <v-tooltip v-if="actions.indexOf('delete') >= 0 && selectedId.length > 0" top>
          <template #activator="{ on }">
            <v-btn color="error" fab text small v-on="on">
              <v-icon>
                mdi-delete
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t("Șterge") }}</span>
        </v-tooltip>
        <template #input>
          <v-sheet>
            <v-card-title>
              {{ $t("Sigur doriți să ștergeți?") }}
            </v-card-title>
          </v-sheet>
        </template>
      </v-edit-dialog>

      <div style="width:100%">
        <!-- viewType == 'marc' &&  -->
        <div v-if="!customTable" style="width:450px" class="pl-3 d-flex">
          <span class="mt-3 mr-3">{{ $t("Caută") }}</span>
          <v-combobox
            ref="filterGeneralCombo"
            v-model="filterGeneral"
            class="mt-2"
            clearable
            dense
            hide-details
            append-icon=""
            :return-object="false"
            item-value="text"
            :items="userSearchs"
            @change="searchAdvanced"
            @keyup.enter="searchAdvanced"
          />
          <v-tooltip top>
            <template #activator="{ on }">
              <v-btn color="primary" fab text small @click="searchAdvanced" v-on="on">
                <v-icon>
                  mdi-magnify
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t("Caută") }}</span>
          </v-tooltip>
          <v-tooltip top>
            <template #activator="{ on }">
              <v-btn color="info" fab text small @click="showFilters = !showFilters" v-on="on">
                <v-icon>
                  mdi-view-list
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t("Cautăre avansată") }}</span>
          </v-tooltip>
        </div>
      </div>
      <v-spacer />
      <!-- <v-tooltip top>
        <template #activator="{ on }">
          <v-btn v-show="filtrable" color="primary" fab text small @click="showFilters = !showFilters" v-on="on">
            <v-badge
              color="primary"
              :content="advancedSearchFilters.length"
              :value="advancedSearchFilters.length"
              overlap
            >
            <v-icon>mdi-filter-variant</v-icon>
            </v-badge>
          </v-btn>
        </template>
        <span>{{ $t("Căutare avansată") }}</span>
      </v-tooltip> -->
      <v-menu v-if="!customTable" bottom left offset-y :close-on-content-click="false">
        <template #activator="{ on: onMenu }">
          <v-tooltip top>
            <template #activator="{ on }">
              <v-btn v-show="filtrable" color="primary" text fab small v-on="{ ...onMenu, ...on }">
                <v-icon>mdi-filter-variant</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("Căutări salvate") }}</span>
          </v-tooltip>
        </template>
        <v-list dense>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="text-h4">
                {{ $t("Salvează căutare") }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <v-form ref="filterForm">
                  <v-combobox
                    v-model="filterName"
                    :search-input.sync="filterText"
                    :rules="inputValidate"
                    :label="$t('Nume căutare')"
                    :items="listFilters"
                    @change="filterChanged"
                  />
                </v-form>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <v-btn color="error" outlined @click="deleteFilter">{{ $t("Șterge") }}</v-btn>
                <v-btn color="primary" class="ml-2" @click="saveFilter">{{ $t("Salvează") }}</v-btn>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <v-btn class="float-right" color="error" outlined @click="deleteFilter">{{ $t("Șterge") }}</v-btn>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->
        </v-list>
      </v-menu>
      <!-- <v-icon>mdi-view-headline</v-icon> -->
      <!-- <v-icon>mdi-view-list</v-icon> -->
      <!--  -->
      <v-btn-toggle v-if="!customTable" v-model="selectedView" mandatory class="mt-2">
        <v-btn v-for="itm in viewTypes" :key="itm" icon small width="35px">
          <!-- {{ itm }} -->
          <v-icon v-if="itm == 'list'">mdi-view-headline</v-icon>
          <v-icon v-if="itm == 'table'">mdi-table-large</v-icon>
          <v-icon v-if="itm == 'details'">mdi-view-agenda</v-icon>
          <v-icon v-if="itm == 'module'">mdi-view-comfy</v-icon>
        </v-btn>
      </v-btn-toggle>
      <v-menu v-if="!customTable" bottom left offset-y :close-on-content-click="false">
        <template #activator="{ on: onMenu }">
          <v-tooltip top>
            <template #activator="{ on }">
              <v-btn class="ml-1" color="primary" text fab small v-on="{ ...onMenu, ...on }">
                <v-icon>mdi-table-column</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("Modifică coloane") }}</span>
          </v-tooltip>
        </template>
        <v-list dense>
          <draggable class="dragArea list-group " :list="headers">
            <template v-for="(hd, ind) in headers">
              <v-list-item v-if="fieldSchema[hd.value]" :key="hd.value" dense style="cursor:move">
                <v-list-item-icon>
                  <v-icon>mdi-cursor-move</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ fieldSchema[hd.value].lbl }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon @click="headers.splice(ind, 1)">mdi-delete</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </template>
          </draggable>
          <!-- <v-divider /> -->
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <v-autocomplete
                  :v-model="selectedColumn"
                  :label="$t('Adaugă coloană')"
                  :items="tableColumns"
                  return-object
                  @change="addColumn"
                />
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider />
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="text-h4">
                {{ $t("Salvează coloane") }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <v-form ref="columnsForm">
                  <v-combobox
                    v-model="columnsName"
                    :search-input.sync="columnsText"
                    :rules="inputValidate"
                    :label="$t('Nume')"
                    :items="listColumns"
                    @change="columnsChanged"
                  />
                </v-form>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <v-btn color="error" outlined @click="deleteColumns">{{ $t("Șterge") }}</v-btn>
                <v-btn class="ml-2" color="primary" @click="saveColumn">{{ $t("Salvează") }}</v-btn>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <v-btn class="float-right" color="error" outlined @click="deleteFilter">{{ $t("Șterge") }}</v-btn>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->
          <!-- <template v-for="(fld, itm) in fieldSchema">
            <v-list-item v-if="fld.meta" :key="itm">
              <v-list-item-icon class="mr-2 mt-2 mb-0">
                <v-checkbox dense hide-details class="mt-0 pt-0" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ itm + " " + fld.meta.label }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template> -->
        </v-list>
      </v-menu>
      <v-tooltip v-if="!customTable" top>
        <template #activator="{ on }">
          <v-btn color="primary" fab text small @click="showCSV = true" v-on="on">
            <v-icon>mdi-export-variant</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("Export CSV") }}</span>
      </v-tooltip>
      <v-menu v-if="!customTable" bottom left offset-y>
        <template #activator="{ on }">
          <v-btn color="primary" text fab small v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <!-- <v-list-item v-for="(tab, ind) in tableManagement" :key="tab._id" @click="changeHeader(ind)">
            {{ tab.name }}
          </v-list-item> -->
          <v-list-group @click.stop>
            <template #activator>
              <v-list-item-content>
                <v-list-item-title>{{ $t("Profil") }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item v-for="(tab, ind) in tableManagement" :key="tab._id" @click="changeHeader(ind)">
              <!-- <v-list-item-icon>
                <v-icon v-text="tab.icon" />
              </v-list-item-icon> -->
              <v-list-item-title class="pl-4" v-text="tab.name" />
              <!-- {{ tab.name }} -->
            </v-list-item>
          </v-list-group>
          <v-list-item @click="configColumns">
            <v-list-item-title>
              {{ $t("Setări") }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item v-if="filtrable" @click="showTMFilters = !showTMFilters">
            <v-list-item-title>
              {{ $t("Filtre") }}
            </v-list-item-title>
            <v-icon v-show="showTMFilters" class="ml-10">mdi-check</v-icon>
          </v-list-item>
          <!-- <v-list-item @click="showCSV = true">
            <v-list-item-title>
              {{ $t("Export CSV") }}
            </v-list-item-title>
          </v-list-item> -->
        </v-list>
      </v-menu>
    </div>
    <v-sheet v-show="showFilters" class="mx-0 mb-2 pa-3" elevation="5">
      <v-sheet
        ref="advSearchBox"
        style="border-style:solid;border-width:1px!important;border-color:#BBDEFB;margin-top:6px!important;overflow-x:auto;overflow-y:hidden"
        class="d-inline-flex mb-3 pr-2"
        width="100%"
        min-height="24px"
        elevation="0"
      >
        <div v-for="(filt, index) in advancedFilters" :key="filt.uid" class="d-inline-flex text-h3">
          <v-chip color="ml-2 mt-1 grey lighten-4" label small>
            <span class="text-caption">
              {{ filt.label }}
            </span>
          </v-chip>
          <v-chip v-if="filt.type" class="ml-1 mt-1" color="grey lighten-3 " label small>
            <span class="text-caption">
              {{ filt.type }}
            </span>
          </v-chip>
          <v-chip
            v-if="filt.value"
            class="ml-1 mt-1"
            color="grey lighten-3"
            label
            close
            small
            close-icon="mdi-close-thick"
            @click:close="deleteAdvFilter(index)"
          >
            <span class="text-subtitle-2">
              {{ filt.value }}
            </span>
          </v-chip>
        </div>
        <v-combobox
          ref="advFilter"
          v-model="filterValue"
          style="min-width:150px;min-height:26px;height:26px!important"
          class="pt-0 customadv ml-2 mt-0"
          item-text="label"
          item-value="value"
          :items="filtersValue"
          hide-details
          dense
          append-icon=""
          return-object
          open-on-clear
          :menu-props="menuProps"
          @change="filterChange"
          @update:search-input="filterValueInput"
          @keyup.delete="filterDeleteKey"
        >
          <template #append-outer>
            <v-icon tabindex="-1" class="ml-n2" @click="clearAdvFilter">
              mdi-close
            </v-icon>
          </template>
        </v-combobox>
      </v-sheet>
      <v-row v-for="(flt, ind) in filters" :key="flt.id" dense class="text-no-wrap" align="center">
        <v-col cols="12" sm="4" lg="4">
          <v-autocomplete
            v-model="flt.field"
            outlined
            dense
            hide-details
            :label="$t('Câmp')"
            :items="filtersFields"
            @change="
              flt.type = filtersTypes[fieldTypes[flt.field]][0].value
              flt.value1 = ''
              flt.label = ''
            "
          />
        </v-col>
        <v-col cols="12" sm="3" lg="3">
          <v-autocomplete
            v-model="flt.type"
            auto-select-first
            outlined
            dense
            hide-details
            :label="$t('Tip căutare')"
            :items="filtersTypes[fieldTypes[flt.field]]"
          >
            <template #selection="{ item }">
              {{ $t(item.text) }}
            </template>
            <template #item="{ item }">
              {{ $t(item.text) }}
            </template>
          </v-autocomplete>
          <!-- {{ fieldTypes[flt.field] }} -->
        </v-col>
        <v-col cols="12" sm="5" lg="5">
          <v-select
            v-if="fieldTypes[flt.field] == 'boolean' || flt.type == 'i'"
            v-model="flt.value1"
            append-icon=""
            clearable
            dense
            hide-details
            :items="booleanValues"
          />
          <!-- <s-autocomplete
            v-else-if="fieldTypes[flt.field] == 'objectid'"
            name="sautoobjectid"
            :value="flt.value1"
            type="v-autocomplete"
            :return-object="true"
            :label="$t('Search type')"
            :item-text="tables[0]['content'][flt.field].meta.field"
            :item-value="tables[0]['content'][flt.field].data_relation.field"
            :collection="tables[0]['content'][flt.field].data_relation.resource"
            @input="
              flt.value1 = $event[tables[0]['content'][flt.field].data_relation.field]
              flt.label = $event[tables[0]['content'][flt.field].meta.field]
            "
          /> -->
          <v-text-field
            v-else-if="flt.type == 'r=' || flt.type == 'r>' || flt.type == 'r<'"
            v-model="flt.value1"
            clearable
            dense
            hide-details
            :label="$t('Termeni de căutare')"
          />
          <template v-else-if="fieldSchema[flt.field]">
            <s-date-time
              v-if="fieldSchema[flt.field].meta.component == 'DateField'"
              v-model="flt.value1"
              append-icon=""
              clearable
              hide-details
              dense
              :locale="$t('locale')"
              type="datefield"
            />
            <v-autocomplete
              v-else-if="fieldSchema[flt.field].meta.component == 'ComboBox'"
              v-model="flt.value1"
              :label="$t('Termeni de căutare')"
              clearable
              hide-details
              dense
              append-icon=""
              item-text="name"
              item-value="value"
              :items="getdictionary(fieldSchema[flt.field].meta.dictionary)"
            />
            <v-combobox
              v-else-if="
                fieldSchema[flt.field].meta.component == 'AutoComplete' && fieldSchema[flt.field].meta.dictionary
              "
              v-model="flt.value1"
              :label="$t('Termeni de căutare')"
              clearable
              hide-details
              dense
              append-icon=""
              item-text="name"
              item-value="value"
              :return-object="false"
              :items="getdictionary(fieldSchema[flt.field].meta.dictionary)"
            />
            <!-- <s-autocomplete
              v-else
              v-model="flt.value1"
              name="sautoelse"
              :view-type="viewType"
              :label="$t('Termeni de căutare')"
              :item-text="flt.field"
              :item-value="flt.field"
              :collection="collection"
            /> -->
            <v-text-field
              v-else
              :value="flt.value1"
              clearable
              dense
              hide-details
              :label="$t('Termeni de căutare')"
              @change="$set(filters[ind], 'value1', $event)"
            />
          </template>
          <!-- <s-autocomplete
            v-else
            v-model="flt.value1"
            name="sautoelse"
            :view-type="viewType"
            :label="$t('Termeni de căutare')"
            :item-text="flt.field"
            :item-value="flt.field"
            :collection="collection"
          /> -->
          <v-text-field
            v-else
            :value="flt.value1"
            clearable
            dense
            hide-details
            :label="$t('Termeni de căutare')"
            @change="$set(filters[ind], 'value1', $event)"
          />
        </v-col>
      </v-row>
      <v-row dense>
        <!-- <v-col cols="12" sm="7" lg="5">
        </v-col> -->

        <v-col cols="12" class="d-flex">
          <v-spacer />
          <v-btn color="error" outlined @click="clearFilters">{{ $t("Resetează") }}</v-btn>
          <v-btn class="ml-4" color="primary" @click="searchAdvanced">{{ $t("Caută") }}</v-btn>
        </v-col>
      </v-row>
    </v-sheet>
    <v-sheet>
      <v-chip
        v-for="(flt, ind) in advancedSearchFilters"
        :key="flt.id"
        class="mb-1 mx-1 d-none d-md-inline-block"
        close
        @click="showFilters = !showFilters"
        @click:close="cancelFiltersChip(flt.id, ind)"
      >
        {{ flt.str }}
      </v-chip>
    </v-sheet>
    <div ref="loader" style="min-height:4px;">
      <v-progress-linear v-show="loading" style="margin:0px" :indeterminate="loading" color="primary" />
    </div>
    <v-sheet id="thumb" ref="thumb" style="overflow-y:auto;overflow-x:hidden" class="pl-2 pr-2" :class="scrollbarTheme">
      <v-row v-show="viewTypes[selectedView] == 'module'" align="stretch" dense>
        <template v-for="rec in records">
          <v-hover :key="rec._id" v-slot="{ hover }">
            <v-col class="xs" sm="6" md="4" xl="3" cols="12">
              <v-sheet style="position: relative" elevation="3" width="100%" height="100%">
                <v-row align="start" dense justify="space-around">
                  <v-col v-if="rec['fields.856.link']" class="align-center justify-start" cols="3">
                    <!-- :src="baseURL + 'coperta/?pre=' + prefix + '&rid=' + rec._id" -->
                    <v-img
                      alt="user"
                      class="ml-2"
                      :src="baseURL + 'file_stream/?f=' + rec['fields.856.link']"
                      :lazy-src="defaultImg"
                      contain
                      width="90px"
                      height="150px"
                      max-height="150px"
                    ></v-img>
                  </v-col>
                  <v-col v-else class="align-center justify-start" cols="3">
                    <v-img
                      alt="user"
                      class="ml-2"
                      :src="defaultImg"
                      contain
                      width="90px"
                      height="150px"
                      max-height="150px"
                    ></v-img>
                  </v-col>
                  <v-col :cols="rec['fields.856.link'] ? 9 : 9">
                    <v-list dense width="100%">
                      <template v-for="header in headers.slice(0, 3)">
                        <v-list-item :key="header.value">
                          <v-list-item-content>
                            <!-- <v-list-item-subtitle>{{ $t(header.text) }}</v-list-item-subtitle> -->
                            <v-list-item-subtitle>{{ header.text }}</v-list-item-subtitle>
                            <v-list-item-title>{{ rec[header.value] }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-list>
                  </v-col>
                </v-row>
                <v-sheet style="position: absolute;top:5px;right:5px;float:right" class="mr-0 mt-0 d-flex">
                  <slot name="actions" :props="{ item: rec }">
                    <v-btn
                      v-if="showSelectItem"
                      v-show="hover"
                      color="info"
                      text
                      fab
                      x-small
                      @click="$emit('selectItem', rec)"
                    >
                      <v-icon>
                        mdi-check
                      </v-icon>
                    </v-btn>
                    <v-tooltip v-if="actions.indexOf('edit') >= 0" top>
                      <template #activator="{ on }">
                        <v-btn v-show="hover" color="success" text fab x-small @click="editItem(rec)" v-on="on">
                          <v-icon>
                            mdi-pencil
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t("Editează înregistrarea") }}</span>
                    </v-tooltip>
                    <v-btn
                      v-if="debug"
                      v-show="hover"
                      color="blue-grey"
                      text
                      fab
                      x-small
                      @click="sheetItem('json', rec)"
                    >
                      <v-icon>
                        mdi-code-json
                      </v-icon>
                    </v-btn>
                    <v-tooltip v-if="showSheet" top>
                      <template #activator="{ on }">
                        <v-btn
                          v-show="hover"
                          color="warning"
                          text
                          fab
                          x-small
                          @click="sheetItem('marc', rec)"
                          v-on="on"
                        >
                          <v-icon>mdi-format-list-bulleted</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t("Fișă") }}</span>
                    </v-tooltip>
                    <v-tooltip v-if="actions.indexOf('inventare') >= 0" top>
                      <template #activator="{ on }">
                        <v-btn v-show="hover" color="info" text fab x-small @click="inventareItem(rec)" v-on="on">
                          <v-icon>
                            mdi-bookshelf
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t("Inventare") }}</span>
                    </v-tooltip>
                    <v-edit-dialog
                      large
                      :save-text="$t('Șterge')"
                      :cancel-text="$t('Anulează')"
                      @save="deleteItem(rec)"
                    >
                      <v-tooltip v-if="actions.indexOf('delete') >= 0" top>
                        <template #activator="{ on }">
                          <v-btn v-show="hover" color="error" text fab x-small v-on="on">
                            <v-icon>
                              mdi-delete
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t("Șterge înregistrarea") }}</span>
                      </v-tooltip>
                      <template #input>
                        <v-sheet>
                          <v-card-title>
                            {{ $t("Sigur doriți să ștergeți?") }}
                          </v-card-title>
                        </v-sheet>
                      </template>
                    </v-edit-dialog>
                    <!-- <v-btn
                  v-if="actions.indexOf('delete') >= 0"
                  color="error"
                  class="ml-1"
                  icon
                  x-small
                  @click="deleteItem(rec)"
                >
                  <v-icon small>
                    mdi-close
                  </v-icon>
                </v-btn> -->
                  </slot>
                </v-sheet>
              </v-sheet>
            </v-col>
          </v-hover>
        </template>
      </v-row>
      <div v-if="collection == 'ebib_marc_cln'" v-show="viewTypes[selectedView] == 'list'">
        <view-list-cln
          ref="list_ebib_marc_cln"
          :value="selectedId"
          :records="records"
          :actions="actions"
          :debug="debug"
          :show-select-item="showSelectItem"
          :baseurl="baseURL"
          @select="selectedChanged"
          @select-item="$emit('selectItem', $event)"
          @sheet-json="sheetItem('json', $event)"
          @sheet-item="sheetItem('marc', $event)"
          @inventare="inventareItem"
          @edit="editItem"
          @delete="deleteItem"
          @double-click="rowDoubleClick(records[$event])"
          @mounted="mountedViewList"
        />
      </div>
      <div v-if="collection == 'ebib_marc_mon'" v-show="viewTypes[selectedView] == 'list'">
        <view-list-mon
          ref="list_ebib_marc_mon"
          :records="records"
          :actions="actions"
          :debug="debug"
          :show-select-item="showSelectItem"
          :baseurl="baseURL"
          @select="selectedChanged"
          @select-item="$emit('selectItem', $event)"
          @sheet-json="sheetItem('json', $event)"
          @inventare="inventareItem"
          @edit="editItem"
          @delete="deleteItem"
          @double-click="rowDoubleClick(records[$event])"
          @mounted="mountedViewList"
        />
      </div>
      <div v-if="collection == 'ebib_marc_inv'" v-show="viewTypes[selectedView] == 'list'">
        <view-list-inv
          ref="list_ebib_marc_inv"
          :records="records"
          :actions="actions"
          :debug="debug"
          :show-select-item="showSelectItem"
          :baseurl="baseURL"
          @select="selectedChanged"
          @select-item="$emit('selectItem', $event)"
          @sheet-json="sheetItem('json', $event)"
          @sheet-item="sheetItem('marc', $event)"
          @inventare="inventareItem"
          @edit="editItem"
          @delete="deleteItem"
          @double-click="rowDoubleClick(records[$event])"
          @mounted="mountedViewList"
        />
      </div>
      <div v-if="collection == 'ebib_marc_rmf'" v-show="viewTypes[selectedView] == 'list'">
        <view-list-rmf
          ref="list_ebib_marc_rmf"
          :records="records"
          :actions="actions"
          :debug="debug"
          :show-select-item="showSelectItem"
          :baseurl="baseURL"
          @select="selectedChanged"
          @select-item="$emit('selectItem', $event)"
          @sheet-json="sheetItem('json', $event)"
          @sheet-item="sheetItem('marc', $event)"
          @inventare="inventareItem"
          @edit="editItem"
          @delete="deleteItem"
          @double-click="rowDoubleClick(records[$event])"
          @mounted="mountedViewList"
        />
      </div>
      <div v-if="collection == 'ebib_marc_prv'" v-show="viewTypes[selectedView] == 'list'">
        <view-list-prv
          ref="list_ebib_marc_prv"
          :records="records"
          :actions="actions"
          :debug="debug"
          :show-select-item="showSelectItem"
          :baseurl="baseURL"
          @select="selectedChanged"
          @select-item="$emit('selectItem', $event)"
          @sheet-json="sheetItem('json', $event)"
          @sheet-item="sheetItem('marc', $event)"
          @inventare="inventareItem"
          @edit="editItem"
          @delete="deleteItem"
          @double-click="rowDoubleClick(records[$event])"
          @mounted="mountedViewList"
        />
      </div>
      <div v-if="collection == 'ebib_tm_lib_loan'" v-show="viewTypes[selectedView] == 'list'">
        <view-list-loan
          ref="list_ebib_tm_lib_loan"
          :records="records"
          :debug="debug"
          :show-select-item="showSelectItem"
          :baseurl="baseURL"
          @mounted="mountedViewList"
        />
      </div>
    </v-sheet>
    <!-- <v-sheet id="thumb" ref="thumb" style="overflow-y:auto;overflow-x:hidden" class="pl-2 pr-2">
    </v-sheet> -->
    <v-overlay v-model="loading" opacity="0.2" absolute>
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
    <v-data-table
      v-show="viewTypes[selectedView] != 'module' && viewTypes[selectedView] != 'list'"
      ref="table"
      v-model="selectedId"
      style="border-bottom: thin solid rgba(0, 0, 0, 0.12);"
      fixed-header
      :mobile-breakpoint="mobilebrp"
      :headers="headers"
      :items="records"
      :hide-default-footer="true"
      :hide-default-header="!showHeader"
      :show-select="showSelect"
      :page.sync="page"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :no-data-text="loading ? $t('Încărcare') : $t('Nu există date disponibile')"
      item-key="_id"
      :single-select="!multipleSelect"
      multi-sort
      :locale="$t('locale')"
      :show-expand="rowExpand"
      :server-items-length="totalResults"
      :items-per-page.sync="itemsPerPage"
      :disable-sort="!sortable"
      class="s-tm elevation-0"
      @input="$emit('input', $event)"
      @click:row="rowClick"
    >
      <template v-for="head in headers">
        <v-tooltip :slot="'header.' + head.value" :key="head.value" top>
          <template #activator="{ on }">
            <span v-on="on">{{ $t(head.text) }}</span>
          </template>
          <span v-if="fieldSchema[head.value]">{{ fieldSchema[head.value].lbl }}</span>
          <span v-else>{{ $t(head.text) }}</span>
        </v-tooltip>
        <!-- <span :slot="'header.' + head.value" :key="head.value">{{ head.text }}</span> -->
        <!-- <slot :header[head.value]="{ header }" :key="head.value">{{ header.text.toUpperCase() }}</slot> -->
      </template>
      <!-- <span slot="header.fields.856.link">fffff</span> -->
      <template #expanded-item="exp">
        <slot name="expanded-item" v-bind="exp" />
        <!-- <tr>
          <td class="px-0 py-2" :colspan="exp.headers.length">
            <v-chip v-for="re in allrec[exp.item._id]" :key="re.uid" class="mt-1 mx-1 ">
              <span class="font-weight-bold body-1">{{ fieldSchema[re.label].lbl + ": " }}</span>
              <span class=" ml-1 body-1">{{ re.value }}</span>
            </v-chip>
          </td>
        </tr> -->
        <!-- <v-icon @click="editInline(allrec[exp.item._id], exp.item)">mdi-close</v-icon> -->
        <!-- <v-chip v-for="header in headers" :key="header.value" class="mt-1 mx-1 ">
            <span class="font-weight-bold body-1">{{ header.text + ": " }}</span>

            <span class=" ml-1 body-1">{{ exp.item[header.value] ? " " + exp.item[header.value] : "" }}</span>
          </v-chip> -->
        <!-- <v-simple-table dense>
            <template v-slot:default>
              <tbody>
                <tr v-for="header in headers" :key="header.value">
                  <td class="font-weight-bold">{{ header.text }}</td>
                  <td>{{ exp.item[header.value] ? exp.item[header.value] : "" }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table> -->
        <!-- <div v-for="header in headers" :key="header.value">
            {{ header.text + ": " + (item[header.value] ? item[header.value] : "") }}
          </div> -->
      </template>
      <template #item="props">
        <!-- :class="props.item.edited ? 'yellow lighten-1' : ''" -->
        <!-- 'yellow lighten-1': props.item.edited, -->
        <tr
          :class="{
            'v-data-table__mobile-table-row': props.isMobile,
            'v-data-table__selected': props.isSelected,
            tdselected: props.isSelected
          }"
          @click="rowClick(props.item, props)"
        >
          <!-- style="border-right: thin solid rgba(0, 0, 0, 0.12);" -->
          <td
            v-for="header in props.headers"
            :key="header.value"
            :class="{
              'v-data-table__mobile-row': props.isMobile,
              darkb: $vuetify.theme.dark,
              whiteb: !$vuetify.theme.dark,
              fixed: header.value == 'action',
              'text-right': header.align == 'right',
              'text-center': header.align == 'center',
              'text-left': header.align == 'left',
              'text-truncate': noScroll
            }"
          >
            <div v-if="props.isMobile" class="v-data-table__mobile-row__header">
              {{ header.text }}
            </div>
            <div :class="{ 'v-data-table__mobile-row__cell': props.isMobile }">
              <v-simple-checkbox
                v-if="header.value == 'data-table-select'"
                :value="props.isSelected"
                @input="props.select($event)"
              />
              <v-btn
                v-else-if="header.value == 'data-table-expand'"
                class="v-data-table__expand-icon"
                icon
                @click="props.expand(!props.isExpanded)"
              >
                <v-icon v-if="props.isExpanded">mdi-chevron-up</v-icon>
                <v-icon v-else>mdi-chevron-down</v-icon>
              </v-btn>
              <div v-else-if="header.value == 'action'" style="min-width:65px" class="d-flex flex-wrap justify-end">
                <v-tooltip v-if="showSelectItem" top>
                  <template #activator="{ on }">
                    <v-btn color="info" text fab x-small @click="$emit('selectItem', props.item)" v-on="on">
                      <v-icon>
                        mdi-check
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("Selectează") }}</span>
                </v-tooltip>
                <v-tooltip top>
                  <template #activator="{ on }">
                    <v-btn
                      v-if="actions.indexOf('edit') >= 0"
                      color="success"
                      text
                      fab
                      x-small
                      @click="editItem(props.item)"
                      v-on="on"
                    >
                      <v-icon>
                        mdi-pencil
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("Editează înregistrarea") }}</span>
                </v-tooltip>
                <v-tooltip top>
                  <template #activator="{ on }">
                    <v-btn v-if="collection == 'infuni_admin_events'" color="success" text fab x-small v-on="on">
                      <v-icon>
                        mdi-play
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("Run event") }}</span>
                </v-tooltip>
                <v-tooltip top>
                  <template #activator="{ on }">
                    <v-btn
                      v-if="actions.indexOf('preview') >= 0"
                      color="indigo"
                      text
                      fab
                      x-small
                      v-on="on"
                      @click="previewItem(props.item)"
                    >
                      <v-icon>
                        mdi-eye
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("Preview") }}</span>
                </v-tooltip>
                <v-btn v-if="debug" color="blue-grey" text fab x-small @click="sheetItem('json', props.item)">
                  <v-icon>
                    mdi-code-json
                  </v-icon>
                </v-btn>
                <v-tooltip v-if="showSheet" top>
                  <template #activator="{ on }">
                    <v-btn color="warning" text fab x-small @click="sheetItem('marc', props.item)" v-on="on">
                      <v-icon>mdi-format-list-bulleted</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("Fișă") }}</span>
                </v-tooltip>
                <v-tooltip v-if="actions.indexOf('inventare') >= 0" top>
                  <template #activator="{ on }">
                    <v-btn color="info" text fab x-small @click="inventareItem(props.item)" v-on="on">
                      <v-icon>
                        mdi-bookshelf
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("Inventare") }}</span>
                </v-tooltip>
                <v-tooltip v-if="collection == 'ebib_marc_doc'" top>
                  <template #activator="{ on }">
                    <v-btn color="info" text fab x-small @click="downloadReport(props.item)" v-on="on">
                      <!-- <a class="v-breadcrumbs__item mx-1" :href="baseURL + 'download_doc/' + props.item._id" v-on="on"> -->
                      <v-icon color="info">
                        mdi-download
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("Descarcă") }}</span>
                </v-tooltip>
                <v-tooltip v-if="collection == 'nvd_media'" top>
                  <template #activator="{ on }">
                    <v-btn color="info" text fab x-small @click="downloadMedia(props.item)" v-on="on">
                      <!-- <a class="v-breadcrumbs__item mx-1" :href="baseURL + 'download_doc/' + props.item._id" v-on="on"> -->
                      <v-icon color="info">
                        mdi-download
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("Download") }}</span>
                </v-tooltip>
                <v-btn
                  v-if="collection == 'app_company'"
                  color="info"
                  text
                  fab
                  x-small
                  @click="showAccounts(props.item)"
                >
                  <v-icon>
                    mdi-account-multiple
                  </v-icon>
                </v-btn>
                <!-- <v-tooltip v-if="collection == 'infuni_academics'" top>
                  <template #activator="{ on }">
                    <v-btn color="info" text fab x-small v-on="on" @click="findPublonsAccount(props.item)">
                      <v-icon>
                        mdi-transit-connection-variant
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("Find publons account") }}</span>
                </v-tooltip> -->
                <v-edit-dialog
                  large
                  :save-text="$t('Șterge')"
                  :cancel-text="$t('Anulează')"
                  @save="deleteItem(props.item)"
                >
                  <v-tooltip top>
                    <template #activator="{ on }">
                      <v-btn v-if="actions.indexOf('delete') >= 0" color="error" text fab x-small v-on="on">
                        <v-icon>
                          mdi-delete
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("Șterge înregistrarea") }}</span>
                  </v-tooltip>
                  <template #input>
                    <v-sheet>
                      <v-card-title>
                        {{ $t("Sigur doriți să ștergeți?") }}
                      </v-card-title>
                    </v-sheet>
                  </template>
                </v-edit-dialog>
                <!-- <v-btn v-if="actions.indexOf('delete') >= 0" color="error" icon small @click="deleteItem(props.item)">
                  <v-icon>
                    mdi-close
                  </v-icon>
                </v-btn> -->
              </div>
              <span v-else-if="header.value == 'ctg'">
                {{ $t(props.item[header.value]) }}
              </span>
              <div v-else-if="header.value == 'fields.856.link'">
                <!--  :src="baseURL + 'coperta/?pre=' + prefix + '&rid=' + props.item._id" -->
                <v-img
                  v-if="props.item['fields.856.link']"
                  :src="baseURL + 'file_stream/?f=' + props.item['fields.856.link']"
                  :lazy-src="defaultImg"
                  contain
                  :height="50"
                  :width="45"
                />
                <v-img v-else :src="defaultImg" contain :height="50" :width="45" />
              </div>

              <span v-else-if="viewType != 'marc' && header.typeObject == 'Date'">
                {{ props.item[header.value] | dataRo }}
              </span>
              <!-- &hyphen; -->
              <span v-else-if="viewType != 'marc'" style="hyphens: none;-webkit-hyphens: none;-moz-hyphens: none">
                {{ resolvePath(header.value, props.item) }}
              </span>
              <template v-else-if="!(editInline && editTMInline && actions.indexOf('edit') >= 0 && !opac)">
                <template v-if="noScroll"> {{ props.item[header.value] | limitLength }} </template>
                <template v-else> {{ props.item[header.value] }} </template>
              </template>
              <!-- v-else-if="fieldSchema[header.value].meta.repeatable == 1" -->
              <v-edit-dialog
                v-else-if="fieldSchema[header.value].meta.component != 'Link'"
                :return-value.sync="props.item[header.value]"
                large
                :save-text="$t('Salvează')"
                :cancel-text="$t('Anulează')"
                @save="lineEdited(props.item, header.value)"
              >
                <!-- @save="$set(props.item, 'edited', true)" -->
                <span v-if="fieldSchema[header.value].meta.component == 'DateField' || header.typeObject == 'Date'">
                  {{ props.item[header.value] | dataRo }}
                </span>
                <div v-else-if="header.width" :style="'width:' + header.width" class="text-truncate">
                  {{ props.item[header.value] }}
                </div>
                <template v-else-if="noScroll"> {{ props.item[header.value] | limitLength }} </template>
                <template v-else> {{ props.item[header.value] }} </template>
                <template #input>
                  <v-text-field
                    v-if="fieldSchema[header.value].meta.component == 'TextInput'"
                    v-model="props.item[header.value]"
                    style="width:250px"
                    class="mt-3"
                    :label="header.text"
                    hide-details
                    autofocus
                  ></v-text-field>
                  <v-textarea
                    v-else-if="fieldSchema[header.value].meta.component == 'TextArea'"
                    v-model="props.item[header.value]"
                    style="width:250px"
                    class="mt-3"
                    :label="header.text"
                    hide-details
                    autofocus
                    rows="3"
                  ></v-textarea>
                  <v-text-field
                    v-else-if="fieldSchema[header.value].meta.component == 'NumericStepper'"
                    v-model="props.item[header.value]"
                    style="width:250px"
                    class="mt-3"
                    :label="header.text"
                    hide-details
                    autofocus
                    type="number"
                  ></v-text-field>
                  <v-date-picker
                    v-else-if="fieldSchema[header.value].meta.component == 'DateField'"
                    v-model="props.item[header.value]"
                    show-current
                    scrollable
                    :locale="$t('locale')"
                    @input="$emit('save')"
                  />
                  <s-date-time
                    v-else-if="fieldSchema[header.value].meta.component == 'DateTime'"
                    v-model="props.item[header.value]"
                    :locale="$t('locale')"
                    type="datetime"
                  />
                  <v-autocomplete
                    v-else-if="fieldSchema[header.value].meta.component == 'ComboBox'"
                    v-model="props.item[header.value]"
                    style="width:250px"
                    class="mt-3"
                    :label="header.text"
                    hide-details
                    item-text="name"
                    item-value="value"
                    :items="getdictionary(fieldSchema[header.value].meta.dictionary)"
                    autofocus
                  />
                  <v-combobox
                    v-else-if="
                      fieldSchema[header.value].meta.component == 'AutoComplete' &&
                        fieldSchema[header.value].meta.dictionary
                    "
                    v-model="props.item[header.value]"
                    style="width:250px"
                    class="mt-3"
                    :label="header.text"
                    hide-details
                    item-text="name"
                    item-value="value"
                    :return-object="false"
                    :items="getdictionary(fieldSchema[header.value].meta.dictionary)"
                  />
                  <s-autocomplete
                    v-else-if="fieldSchema[header.value].meta.component == 'AutoComplete'"
                    v-model="props.item[header.value]"
                    style="width:250px"
                    class="mt-3"
                    :label="header.text"
                    hide-details
                    view-type="marc"
                    :item-text="header.value"
                    :item-value="header.value"
                    :collection="collection"
                    autofocus
                  />
                  <!-- <s-autocomplete
                    v-else-if="fieldSchema[header.value].meta.component == 'Link'"
                    v-model="props.item[header.value]"
                    style="width:250px"
                    hide-details
                    :clearable="false"
                    view-type="marc"
                    :where="
                      fieldSchema[header.value].meta.to_category
                        ? `&quot;ctg&quot;:&quot;` + fieldSchema[header.value].meta.to_category + `&quot;,`
                        : ''
                    "
                    :item-text="
                      'fields.' +
                        fieldSchema[header.value].meta.to_field +
                        '.' +
                        fieldSchema[header.value].meta.to_subfield +
                        '.val'
                    "
                    :item-value="
                      'fields.' +
                        fieldSchema[header.value].meta.to_field +
                        '.' +
                        fieldSchema[header.value].meta.to_subfield +
                        '.val'
                    "
                    :collection="'ebib_marc_' + fieldSchema[header.value].meta.to_prefix"
                    autofocus
                    type="v-autocomplete"
                  >
                  </s-autocomplete> -->
                </template>
              </v-edit-dialog>
              <template v-else-if="noScroll">
                {{ props.item[header.value] | limitLength }}
              </template>
              <template v-else> {{ props.item[header.value] }} </template>
              <!-- <template v-else>
                {{ props.item[header.value] }}
              </template> -->
            </div>
          </td>
        </tr>
      </template>
      <!-- <template v-slot:item.fields.200.a.val="props">
        <v-edit-dialog :return-value.sync="props.item['fields.200.a.val']">
          {{ props.item["fields.200.a.val"] }}
          <template v-slot:input>
            <v-text-field v-model="props.item['fields.200.a.val']" label="Edit" single-line></v-text-field>
          </template>
        </v-edit-dialog>
      </template> -->
      <template #item.fields.856.link="{ item }">
        <!-- <marc-image :id="item['fields.856.link']" :height="50" :width="45" /> :src="baseURL + 'coperta/?pre=' + prefix + '&rid=' + item._id" -->
        <v-img
          v-if="item['fields.856.link']"
          :src="baseURL + 'file_stream/?f=' + item['fields.856.link']"
          :lazy-src="defaultImg"
          contain
          :height="50"
          :width="45"
        />
        <v-img v-else :src="defaultImg" contain :height="50" :width="45" />
        <!-- {{ item["fields.856.link"] }} -->
      </template>
      <template #item.ctg="{ item }">
        {{ $t(item.ctg) }}
      </template>
      <template v-if="showTMFilters" #body.prepend="{ headers }">
        <tr
          v-if="viewTypes[selectedView] == 'table' && !$vuetify.breakpoint.xs"
          style="border-bottom: thin solid rgba(0, 0, 0, 0.12);"
        >
          <td
            v-for="header in headers"
            :key="header.value"
            class="py-0 pl-0 pr-1"
            :style="'position:sticky;top:' + theadheight + 'px;z-index:2;'"
            :class="{ darkb: $vuetify.theme.dark, whiteb: !$vuetify.theme.dark, fixed: header.value == 'action' }"
          >
            <div v-if="header.filterable" style="width:100%" class="d-inline-flex">
              <span v-show="false">{{ render }}</span>
              <v-menu
                v-if="fieldTypes[header.value] != 'boolean' && fieldTypes[header.value] != 'objectid'"
                bottom
                right
                offset-y
              >
                <template #activator="{ on }">
                  <v-btn class="mr-0 mt-1" icon small v-on="on">
                    <v-icon>mdi-menu-down</v-icon>
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item-group
                    :value="filtersTMType[header.value]"
                    color="primary"
                    @change="changeFilterType($event, header.value)"
                  >
                    <v-list-item
                      v-for="tab in filtersTypes[fieldTypes[header.value]]"
                      :key="tab.value"
                      :value="tab.value"
                      selectable
                    >
                      {{ $t(tab.text) }}
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
              <v-select
                v-if="fieldTypes[header.value] == 'boolean' || filtersTMType[header.value] == 'i'"
                v-model="filtersTM[header.value]"
                style="width:100%"
                append-icon=""
                clearable
                dense
                hide-details
                :items="booleanValues"
                @change="
                  page = 1
                  getRecords()
                "
              />
              <v-text-field
                v-else-if="
                  filtersTMType[header.value] == 'r=' ||
                    filtersTMType[header.value] == 'r>' ||
                    filtersTMType[header.value] == 'r<'
                "
                v-model="filtersTM[header.value]"
                style="width:100%"
                clearable
                dense
                hide-details
                @keydown.enter="
                  page = 1
                  getRecords()
                "
                @click:clear="
                  filtersTM[header.value] = ''
                  page = 1
                  getRecords()
                "
              />
              <s-autocomplete
                v-else-if="fieldTypes[fieldObjectid[header.value]] == 'objectid'"
                :value="filtersTM[header.value]"
                style="width:100%"
                name="sautotmobjectid"
                type="v-autocomplete"
                :return-object="true"
                :label="$t('Search type')"
                :item-text="tables[0]['content'][fieldObjectid[header.value]].meta.field"
                :item-value="tables[0]['content'][fieldObjectid[header.value]].data_relation.field"
                :collection="tables[0]['content'][fieldObjectid[header.value]].data_relation.resource"
                @input="
                  filtersTM[header.value] =
                    $event[tables[0]['content'][fieldObjectid[header.value]].data_relation.field]
                  page = 1
                  getRecords()
                "
                @change="getRecords"
              />
              <template v-else-if="fieldSchema[header.value]">
                <s-date-time
                  v-if="fieldSchema[header.value].meta.component == 'DateField'"
                  v-model="filtersTM[header.value]"
                  style="width:100%"
                  append-icon=""
                  clearable
                  hide-details
                  dense
                  :locale="$t('locale')"
                  type="datefield"
                  @input="
                    page = 1
                    getRecords()
                  "
                />
                <v-autocomplete
                  v-else-if="fieldSchema[header.value].meta.component == 'ComboBox'"
                  v-model="filtersTM[header.value]"
                  style="width:100%"
                  clearable
                  hide-details
                  dense
                  append-icon=""
                  item-text="name"
                  item-value="value"
                  :items="getdictionary(fieldSchema[header.value].meta.dictionary)"
                  @input="
                    page = 1
                    getRecords()
                  "
                />
                <v-combobox
                  v-else-if="
                    fieldSchema[header.value].meta.component == 'AutoComplete' &&
                      fieldSchema[header.value].meta.dictionary
                  "
                  v-model="filtersTM[header.value]"
                  style="width:100%"
                  clearable
                  hide-details
                  dense
                  append-icon=""
                  item-text="name"
                  item-value="value"
                  :return-object="false"
                  :items="getdictionary(fieldSchema[header.value].meta.dictionary)"
                  @input="
                    page = 1
                    getRecords()
                  "
                />
                <s-autocomplete
                  v-else
                  v-model="filtersTM[header.value]"
                  style="width:100%"
                  dense
                  hide-details
                  :view-type="viewType"
                  append-icon="mdi-close"
                  :item-text="header.value"
                  :item-value="header.value"
                  :collection="collection"
                  @input="
                    page = 1
                    getRecords()
                  "
                >
                  <!-- <template v-slot:append>
                    <v-icon>mdi-magnify</v-icon>
                  </template> -->
                </s-autocomplete>
              </template>
              <s-autocomplete
                v-else
                v-model="filtersTM[header.value]"
                style="width:100%"
                dense
                hide-details
                :view-type="viewType"
                append-icon="mdi-close"
                :item-text="header.value"
                :item-value="header.value"
                :collection="collection"
                @input="
                  page = 1
                  getRecords()
                "
              />
              <v-btn
                class="mr-0 mt-1"
                icon
                small
                @click="
                  page = 1
                  getRecords()
                "
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </div>
            <div v-else-if="header.value == 'action'" class="d-flex float-right">
              <!-- <v-btn
                class="ml-3 mt-1"
                color="primary"
                icon
                small
                @click="
                  page = 1
                  getRecords()
                "
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn> -->
              <!-- <v-spacer /> -->
              <v-btn class="ml-3 mt-1" color="teal" icon small @click="resetFiltersTM">
                <v-icon>mdi-undo</v-icon>
              </v-btn>
            </div>
          </td>
        </tr>
      </template>
      <!-- <template v-slot:header.name="{ header }">
        {{ header.text.toUpperCase() }}
        <v-text-field />
      </template> -->
      <!-- <template v-slot:header="{ props: { headers } }">
        <thead>
          <tr>
            <th v-for="header in headers" :key="header.value">
              {{ header.text }}
            </th>
          </tr>
        </thead>
      </template> -->
      <template #item.action="{ item }">
        <div class="d-flex">
          <v-btn v-if="showSelectItem" color="info" text fab x-small @click="$emit('selectItem', item)">
            <v-icon>
              mdi-check
            </v-icon>
          </v-btn>
          <v-btn v-if="actions.indexOf('edit') >= 0" color="success" text fab x-small @click="editItem(item)">
            <v-icon>
              mdi-pencil
            </v-icon>
          </v-btn>
          <v-btn v-if="showSheet" color="warning" text fab x-small @click="sheetItem('marc', item)">
            <v-icon>mdi-format-list-bulleted</v-icon>
          </v-btn>
          <v-btn v-if="debug" color="blue-grey" text fab x-small @click="sheetItem('json', item)">
            <v-icon>
              mdi-code-json
            </v-icon>
          </v-btn>
          <v-btn v-if="actions.indexOf('inventare') >= 0" color="info" text fab x-small @click="inventareItem(item)">
            <v-icon>
              mdi-bookshelf
            </v-icon>
          </v-btn>
          <v-edit-dialog large :save-text="$t('Șterge')" :cancel-text="$t('Anulează')" @save="deleteSelected">
            <v-btn v-if="actions.indexOf('delete') >= 0" color="error" text fab x-small>
              <v-icon>
                mdi-delete
              </v-icon>
            </v-btn>
            <template #input>
              <v-sheet>
                <v-card-title>
                  {{ $t("Sigur doriți să ștergeți?") }}
                </v-card-title>
              </v-sheet>
            </template>
          </v-edit-dialog>
          <!-- <v-btn v-if="actions.indexOf('delete') >= 0" color="error" class="ml-1" icon x-small @click="deleteItem(item)">
          <v-icon small>
            mdi-close
          </v-icon>
        </v-btn> -->
        </div>
      </template>
    </v-data-table>
    <v-row class="mt-2" dense>
      <v-col class="mt-2 flex-wrap" cols="12">
        <div style="width:200px" class="float-sm-left d-flex mb-2">
          <v-combobox
            v-model="itemsPerPage"
            style="width:85px"
            class="px-2 py-0 my-0 "
            :items="rowsPerPageItems"
            :return-object="false"
            flat
            hide-details
          />
          <span class="mt-2  text-body-1">
            {{ (page - 1) * itemsPerPage + 1 }}-{{
              totalResults > page * itemsPerPage ? page * itemsPerPage : totalResults
            }}
            {{ $t("din") }} {{ totalResults }}
          </span>
        </div>
        <v-pagination
          v-model="page"
          class="d-flex"
          :length="Math.ceil(totalResults / itemsPerPage)"
          total-visible="7"
        />
      </v-col>
      <!-- <v-col class="mt-2" cols="12" sm="4" md="3">
        <v-select
          v-model="itemsPerPage"
          class="float-left px-2 py-0 my-0 "
          style="width:75px"
          :items="rowsPerPageItems"
          flat
          hide-details
        />
        <span class=" float-left mt-2  body-1">
          {{ (page - 1) * itemsPerPage + 1 }}-{{
            totalResults > page * itemsPerPage ? page * itemsPerPage : totalResults
          }}
          {{ $t("of") }} {{ totalResults }}
        </span>
      </v-col>
      <v-col cols="12" sm="8" md="9">
        <v-pagination v-model="page" :length="Math.ceil(totalResults / itemsPerPage)" total-visible="7" />
        <v-btn
          v-if="showAdd"
          class="float-right ml-3 mt-n9 mr-1"
          color="success"
          elevation="3"
          fab
          x-small
          @click="addItem"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col> -->
    </v-row>
    <add-data-pop-up
      ref="addDataPopUp"
      @reload="getRecords"
      @sheet="sheetItem('marc', $event)"
      @inventories="inventareItem"
    />
    <preview-data ref="previewData" :collection="collection" />
    <marc-sheet ref="marcSheet" @reload="getRecords" />
    <user-card v-if="collection == 'ebib_marc_cln'" ref="userCard" />
    <config-columns ref="configColumns" @apply="applyColumns" @save="saveColumns" />
    <v-dialog v-model="showCSV" persistent max-width="400px">
      <v-card outlined>
        <v-card-title class="pt-2 pr-2 pl-4">
          {{ $t("Export CSV") }}
          <v-spacer />
          <v-icon color="red" large @click="showCSV = false">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text>
          <v-form ref="csvForm">
            <v-text-field v-model="nameCSV" dense :label="$t('Nume fișier')" :rules="inputValidate" />
            <v-checkbox v-model="headerCSV" dense class="mt-2" :label="$t('Cap de tabel')" />
            <v-select v-model="separatorCSV" dense :label="$t('Separator câmp')" :items="['tab', ',', ';', '#']" />
            <v-select v-model="encloserCSV" dense :label="$t('Delimitator câmp')" :items="encloserValues" />
            <v-select
              v-model="endlineCSV"
              dense
              :label="$t('Separator înregistrare')"
              :items="[
                { text: '\\r\\n', value: 'rn' },
                { text: '\\n', value: 'n' },
                { text: '\\r', value: 'r' },
                { text: '#', value: '#' }
              ]"
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text outlined @click="showCSV = false">
            {{ $t("Anulează") }}
          </v-btn>
          <v-btn color="primary" text outlined @click="exportCSV">
            {{ $t("Exportă") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showDelete" persistent max-width="400px">
      <v-card outlined>
        <v-card-title class="pb-2 pt-2 pl-2">
          {{ $t("Șterge titlu și inventare") }}
          <v-spacer />
          <v-icon color="red" class="mt-n2 mr-n2" large @click="showDelete = false">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text>
          Monografia are asociate {{ bookInventories.length }} inventare, sigur doriți să ștergeți monografia și
          inventarele asociate?
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text outlined @click="showDelete = false">
            {{ $t("Anulează") }}
          </v-btn>
          <v-btn color="primary" text outlined @click="deleteBookInventory">
            {{ $t("Șterge") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "../../plugins/axios"
import { EventBus } from "../../EventBus"
import AddDataPopUp from "../../views/settings/AddDataPopUp"
import SAutocomplete from "../inputs/Autocomplete"
import SDateTime from "../../components/inputs/DateTime"
import ConfigColumns from "../../views/settings/ConfigColumns"
import MarcSheet from "./MarcSheet"
import UserCard from "./UserCard"
import ViewListCln from "./ViewListCln"
import ViewListMon from "./ViewListMon"
import ViewListInv from "./ViewListInv"
import ViewListRmf from "./ViewListRMF"
import ViewListPrv from "./ViewListPrv"
import ViewListLoan from "./ViewListLoan"
import { debounce } from "../../util/helpers"
import draggable from "vuedraggable"
import PreviewData from "../../views/settings/PreviewData.vue"
// import MarcImage from "./MarcImage"

export default {
  components: {
    AddDataPopUp,
    SAutocomplete,
    MarcSheet,
    UserCard,
    ConfigColumns,
    ViewListCln,
    ViewListMon,
    ViewListInv,
    ViewListRmf,
    ViewListPrv,
    ViewListLoan,
    SDateTime,
    draggable,
    PreviewData
    // MarcImage
  },
  filters: {
    // limitLength(value, len) {
    //   if (!value) return ""
    //   value = value.toString()
    //   if (value.length > len) {
    //     return value.substring(0, len) + "..."
    //   }
    //   return value
    // },
    limitLength(value) {
      if (!value) return ""
      value = value.toString()
      if (value.length > 30) {
        return value.substring(0, 30) + "..."
      }
      return value
    },
    limitData(value) {
      if (value == undefined) return ""
      value = value.toString()
      if (value.length > 10) {
        return value.substring(0, 10)
      }
      return value
    },
    dataRo(value) {
      if (value == undefined) return ""
      value = value.toString()
      if (value.length > 10) {
        return value.substr(8, 2) + "." + value.substr(5, 2) + "." + value.substr(0, 4)
      }
      return value
    }
  },
  props: {
    showSelectItem: {
      type: Boolean,
      default: false
    },
    showSelectInv: {
      type: Boolean,
      default: false
    },
    customTable: {
      type: Boolean,
      default: false
    },
    table: {
      required: true,
      type: String
    },
    opac: {
      type: Boolean,
      default: false
    },
    editInline: {
      type: Boolean,
      default: true
    },
    editType: {
      default: "auto",
      type: String
    },
    appView: {
      default: "",
      type: String
    },
    recordDefault: {
      default: () => {},
      type: Object
    },
    where: {
      default: "",
      type: String
    },
    view: {
      default: "",
      type: String
    },
    viewTable: {
      default: "",
      type: String
    },
    // totalResults: {
    //   default: 100000,
    //   type: Number
    // },
    category: {
      default: "",
      type: String
    }
  },
  data: () => ({
    dict: {},
    academicsId: "",
    filterName: "",
    filterText: "",
    columnsName: "",
    columnsText: "",
    menuProps: {},
    loading: true,
    selectedId: [],
    bookInventories: [],
    deleteBook: null,
    tableChangeinterval: null,
    embed: "",
    mediaFields: "",
    noScroll: false,
    itemsPerPage: 10,
    selectedColumn: null,
    // rowsPerPageItems: [6, 12, 15, 24, 36],
    rowsPerPageItems: [
      { text: "Predefinit", value: 1 },
      { text: 10, value: 10 },
      { text: 25, value: 25 },
      { text: 50, value: 50 },
      { text: 100, value: 100 },
      { text: 250, value: 250 },
      { text: 1000, value: 1000 }
    ],
    advancedSearchFilters: [],
    advancedFilters: [],
    advancedFilters2: [
      { field: "fields.299.a.val", label: "Titlu", value: "Amintiri", type: "c" },
      { field: "fields.700.a.val", label: "Autor", value: "Ion Creanga", type: "c" }
    ],
    filterDeleted: false,
    filterField: "",
    filterValue: "",
    filterSearchValue: "",
    filtersValue: [],
    tableHeight: "250px",
    theadheight: 0,
    isPopUp: false,
    offsetTop: 0,
    mobilebrp: 600,
    selectedView: 0,
    filtrable: true,
    sortable: true,
    addPopUp: true,
    editPopUp: true,
    editTMInline: false,
    showAdd: true,
    showPagination: true,
    showHeader: true,
    showSheet: true,
    multipleSelect: true,
    rowExpand: false,
    showSelect: false,
    render: "",
    viewForm: "",
    height: "",
    actions: ["edit", "delete"],
    viewType: "data",
    viewTypesList: ["list", "table", "details", "module"],
    viewTypes: [],
    selectedTM: 0,
    tableManagement: [],
    profiles: [],
    indTable: 0,
    tables: [],
    collection: "",
    headers: [],
    selected: [],
    records: [],
    allrec: {},
    resrec: [],
    uid: 0,
    totalResults: 0,
    page: 1,
    sortBy: [],
    sortDesc: [],
    tmClicks: 0,
    showCSV: false,
    showDelete: false,
    nameCSV: "export",
    headerCSV: true,
    separatorCSV: "tab",
    encloserCSV: "",
    encloserValues: ["", '"', "'"],
    endlineCSV: "rn",
    booleanValues: [
      {
        text: "Da",
        value: true
      },
      {
        text: "Nu",
        value: false
      }
    ],
    tmColumns: [],
    filter: "",
    showFilters: false,
    showTMFilters: true,
    filterGeneral: "",
    filtersTM: {},
    filtersTMType: {},
    fieldTypes: {},
    fieldSchema: {},
    fieldObjectid: {},
    filtersWhere: "",
    filters: [
      { field: "299a", type: "c", value1: "", value2: "", value3: {}, label: "", id: 0 },
      { field: "299a", type: "c", value1: "", value2: "", value3: {}, label: "", id: 1 },
      { field: "299a", type: "c", value1: "", value2: "", value3: {}, label: "", id: 2 }
    ],
    filtersFields: [],
    filtersTypes: {
      string: [
        {
          text: "Conține",
          value: "c"
        },
        {
          text: "Începe cu",
          value: "b"
        },
        {
          text: "Se termină cu",
          value: "e"
        },
        {
          text: "Egal",
          value: "="
        },
        {
          text: "Există",
          value: "i"
        },
        {
          text: "Repetări =",
          value: "r="
        },
        {
          text: "Repetări <",
          value: "r<"
        },
        {
          text: "Repetări >",
          value: "r>"
        }
      ],
      integer: [
        {
          text: "Egal",
          value: "="
        },
        {
          text: "Mai mare decât",
          value: ">"
        },
        {
          text: "Mai mic decât",
          value: "<"
        },
        {
          text: "Există",
          value: "i"
        },
        {
          text: "Repetări =",
          value: "r="
        },
        {
          text: "Repetări <",
          value: "r<"
        },
        {
          text: "Repetări >",
          value: "r>"
        }
      ],
      float: [
        {
          text: "Egal",
          value: "="
        },
        {
          text: "Mai mare decât",
          value: ">"
        },
        {
          text: "Mai mic decât",
          value: "<"
        },
        {
          text: "Există",
          value: "i"
        },
        {
          text: "Repetări =",
          value: "r="
        },
        {
          text: "Repetări <",
          value: "r<"
        },
        {
          text: "Repetări >",
          value: "r>"
        }
      ],
      number: [
        {
          text: "Egal",
          value: "="
        },
        {
          text: "Mai mare decât",
          value: ">"
        },
        {
          text: "Mai mic decât",
          value: "<"
        },
        {
          text: "Există",
          value: "i"
        },
        {
          text: "Repetări =",
          value: "r="
        },
        {
          text: "Repetări <",
          value: "r<"
        },
        {
          text: "Repetări >",
          value: "r>"
        }
      ],
      datetime: [
        {
          text: "Egal",
          value: "="
        },
        {
          text: "Mai mare decât",
          value: ">"
        },
        {
          text: "Mai mic decât",
          value: "<"
        },
        {
          text: "Există",
          value: "i"
        },
        {
          text: "Repetări =",
          value: "r="
        },
        {
          text: "Repetări <",
          value: "r<"
        },
        {
          text: "Repetări >",
          value: "r>"
        }
      ],
      boolean: [
        {
          text: "Egal",
          value: "="
        },
        {
          text: "Există",
          value: "i"
        },
        {
          text: "Repetări =",
          value: "r="
        },
        {
          text: "Repetări <",
          value: "r<"
        },
        {
          text: "Repetări >",
          value: "r>"
        }
      ],
      list: [
        {
          text: "Conține",
          value: "c"
        },
        {
          text: "Începe cu",
          value: "b"
        },
        {
          text: "Se termină cu",
          value: "e"
        },
        {
          text: "Există",
          value: "i"
        },
        {
          text: "Repetări =",
          value: "r="
        },
        {
          text: "Repetări <",
          value: "r<"
        },
        {
          text: "Repetări >",
          value: "r>"
        }
      ],
      objectid: [
        {
          text: "Egal",
          value: "="
        },
        {
          text: "Există",
          value: "i"
        }
      ]
    },
    searchType: [
      {
        text: "Contain",
        value: "c"
      },
      {
        text: "Begin",
        value: "b"
      },
      {
        text: "End",
        value: "e"
      },
      {
        text: "Equal",
        value: "="
      },
      {
        text: "Bigger",
        value: ">"
      },
      {
        text: "Smaller",
        value: "<"
      },
      {
        text: "Exists",
        value: "i"
      },
      {
        text: "Repeats =",
        value: "r="
      },
      {
        text: "Repeats <",
        value: "r<"
      },
      {
        text: "Repeats >",
        value: "r>"
      }
    ]
  }),
  computed: {
    tableColumns() {
      //this.$log("tableColumns")
      const arr = []
      this.tmColumns.map(itm => {
        if (this.headers.find(hd => hd.value == itm.value) == undefined) {
          arr.push(itm)
        }
      })
      return arr
    },
    inputValidate() {
      const rule = []
      rule.push(v => !!v || this.$t("required"))
      return rule
    },
    categories() {
      //&& itm.category != ""
      //this.$log("categories ", this.collection)
      if (this.$store.getters.confPrefixes) {
        const cat = this.$store.getters.confPrefixes.filter(itm => "ebib_marc_" + itm.prefix == this.collection)
        cat.sort((a, b) => {
          return a.ordPref - b.ordPref
        })
        return cat.slice(1)
      }
      return []
    },
    defaultImg() {
      if (this.viewType == "marc") {
        return "/image/default/" + this.prefix + "/default.png"
      }
      return "/image/default/default.png"
    },
    scrollbarTheme() {
      return this.$vuetify.theme.dark ? "dark" : "light"
    },
    baseURL() {
      return axios.defaults.baseURL
    },
    debug() {
      return this.$store.getters.debug
    },
    companyId() {
      return this.$store.getters.companyId
    },
    userId() {
      return this.$store.getters.accountId
    },
    appType() {
      return this.$store.getters.appType
    },
    userType() {
      return this.$store.getters.userType
    },
    appSchema() {
      return this.$store.getters.appSchema
    },
    userFilters() {
      if (this.$store.getters.userFilters) {
        return this.$store.getters.userFilters
      }
      return null
    },
    userColumns() {
      if (this.$store.getters.userColumns) {
        return this.$store.getters.userColumns
      }
      return null
    },
    userSearchs() {
      //this.$log("userSearchs0")
      //this.$log("userSearchs1 ", this.$store.getters.userSearchs)
      const arr = []
      if (this.$store.getters.userSearchs) {
        if (this.$store.getters.userSearchs[this.collection]) {
          //this.$log("userSearchs2 ", this.$store.getters.userSearchs[this.collection])
          arr.push(...this.$store.getters.userSearchs[this.collection])
        }
      }
      return arr
    },
    listFilters() {
      //this.$log("get listFilters ", this.$store.getters.userFilters)
      const arr = []
      if (this.$store.getters.userFilters) {
        if (this.$store.getters.userFilters[this.collection]) {
          for (const s in this.$store.getters.userFilters[this.collection]) {
            if (this.$store.getters.userFilters[this.collection][s]) {
              arr.push(s)
            }
          }
        }
      }
      //this.$log("arr ", arr)
      return arr
    },
    listColumns() {
      //this.$log("get listFilters ", this.$store.getters.userColumns)
      const arr = []
      if (this.$store.getters.userColumns) {
        if (this.$store.getters.userColumns[this.collection]) {
          for (const s in this.$store.getters.userColumns[this.collection]) {
            if (this.$store.getters.userColumns[this.collection][s]) {
              arr.push(s)
            }
          }
        }
      }
      //this.$log("arr ", arr)
      return arr
    },
    prefix() {
      const arr = this.collection.split("_")
      if (arr.length == 3) {
        return arr[2]
      }
      return ""
    }
  },
  watch: {
    headers() {
      //this.$log("headers changed")
      this.getRecords()
    },
    table() {
      //this.$log("tablechange1 ", this.category)
      //this.$log("tablechange2 ", this.viewType)
      //this.$log("tablechange3 ", this.table)
      if (!this.tableChangeinterval) {
        this.tableChangeinterval = setInterval(() => {
          this.tableChanged()
        }, 100)
      }
    },
    viewTable() {
      this.$log("viewTablechange ", this.tableChangeinterval)
      if (!this.tableChangeinterval) {
        this.tableChangeinterval = setInterval(() => {
          this.tableChanged()
        }, 100)
      }
    },
    where() {
      //this.$log("wherechange ", this.tableChangeinterval)
      if (!this.tableChangeinterval) {
        this.tableChangeinterval = setInterval(() => {
          this.tableChanged()
        }, 100)
      }
    },
    page() {
      //this.$log("page ", this.page)
      //this.$log("page changed")
      this.getRecords()
    },
    // sortBy() {
    //   this.$log("sortBy ", this.sortBy)
    // },
    sortDesc() {
      //this.$log("sortDesc ", this.sortDesc)
      //this.$log("sort changed")
      this.getRecords()
    },
    itemsPerPage() {
      //this.$log("itemsPerPage ", this.itemsPerPage)
      //this.$log("rowsPerPageItems ", this.rowsPerPageItems[0].value)
      this.noScroll = this.rowsPerPageItems[0].value == this.itemsPerPage
      if (this.page == 1) {
        //if (!this.noScroll) {
        //this.$log("itemsperpage changed")
        this.getRecords()
        //}
      } else {
        this.page = 1
      }
    },
    selectedView() {
      //this.$log("selectedView ", this.selectedView)
      if (this.viewTypes[this.selectedView] == "table") {
        this.mobilebrp = 600
      } else if (this.viewTypes[this.selectedView] == "details") {
        this.mobilebrp = 10000
      } else if (this.viewTypes[this.selectedView] == "list") {
        //this.$log("selectview changed")
        this.getRecords()
      }
    },
    selectedId() {
      //this.$log("selectedid ", this.selectedId)
      this.$emit("select", this.selectedId)
    }
  },
  mounted() {
    //this.$log("table mount", this.customTable)
    // this.getSchema()
    EventBus.$on("search-link-selected", search => {
      //this.$log("search-link-selected1 ", search)
      //this.$log("search-link-selected2 ", this.searchId)
      if (search.id == "findPublonsAccount") {
        //this.accountId
        this.$log("findPublonsAccount ", search.value)
        if (search.value.ids.rid) {
          axios.get(this.collection + "/" + this.academicsId).then(response => {
            this.$log("academics ", response)
            const etag = response.data._etag
            response.data._created = undefined
            response.data._etag = undefined
            response.data._latest_version = undefined
            response.data._links = undefined
            response.data._updated = undefined
            response.data._version = undefined
            if (!response.data.researchers_ids) {
              response.data.researchers_ids = {}
            }
            response.data.researchers_ids.publons_ids = [search.value.ids.rid]
            axios
              .put(this.collection + "/" + this.academicsId, response.data, {
                headers: {
                  "If-Match": etag
                }
              })
              .then(resp => {
                this.$log("put ", resp)
                this.getRecords()
              })
          })
        }
      }
    })
    EventBus.$on("user-card", id => {
      this.$log("open user card ", id)
      this.$log("open user this.collection ", this.collection)
      if (this.collection == "ebib_marc_cln") {
        this.openUserCard(id)
      }
    })
    this.tableChanged()
    let par = this.$refs.table.$el
    this.offsetTop = par.getBoundingClientRect().y
    while (par != document) {
      if (par.classList.contains("v-dialog")) {
        this.$refs.table.$el.style.maxHeight = "1250px"
        this.offsetTop -= par.getBoundingClientRect().y
        this.isPopUp = true
        break
      }
      par = par.parentNode
    }
    // this.$log("offtop ", this.offsetTop)
    this.resize()
  },
  updated() {
    this.$nextTick(() => this.resize())
    this.resize()
    setTimeout(this.resize, 100)
  },
  methods: {
    addColumn(e) {
      this.$log("addColumn ", e)
      this.selectedColumn = null
      this.headers.push(e)
    },
    previousPage() {
      if (this.page > 1) {
        this.page--
        this.getRecords()
      }
    },
    nextPage() {
      if (this.page < Math.ceil(this.totalResults / this.itemsPerPage)) {
        this.page++
        this.getRecords()
      }
    },
    firstPage() {
      this.page = 1
      this.getRecords()
    },
    lastPage() {
      this.page = Math.ceil(this.totalResults / this.itemsPerPage)
      this.getRecords()
    },
    mountedViewList() {
      if (this.viewTypes[this.selectedView] == "list") {
        this.getRecords()
      }
    },
    deleteColumns() {
      if (this.$refs.columnsForm.validate() || this.columnsText) {
        let columns = {}
        if (this.userColumns) {
          columns = JSON.parse(JSON.stringify(this.userColumns))
        }
        if (columns[this.collection]) {
          columns[this.collection][this.columnsText] = null
          this.$store.commit("userColumns", columns)
          this.$store.dispatch("setUserColumns")
          this.columnsText = ""
          this.$refs.columnsForm.resetValidation()
        }
      }
    },
    saveColumn() {
      this.$nextTick(() => {
        if (this.$refs.columnsForm.validate() || this.columnsText) {
          let columns = {}
          if (this.userColumns) {
            columns = JSON.parse(JSON.stringify(this.userColumns))
          }
          if (!columns[this.collection]) {
            columns[this.collection] = {}
          }
          columns[this.collection][this.columnsText] = this.headers
          this.$store.commit("userColumns", columns)
          this.$store.dispatch("setUserColumns")
        }
      })
    },
    columnsChanged() {
      if (this.columnsName && this.userColumns) {
        const columns = this.userColumns
        if (columns[this.collection]) {
          if (columns[this.collection][this.columnsName]) {
            this.headers = JSON.parse(JSON.stringify(columns[this.collection][this.columnsName]))
            this.getRecords()
          }
        }
      }
    },
    deleteFilter() {
      if (this.$refs.filterForm.validate() || this.filterText) {
        let filter = {}
        if (this.userFilters) {
          filter = JSON.parse(JSON.stringify(this.userFilters))
        }
        if (filter[this.collection]) {
          filter[this.collection][this.filterText] = null
          this.$store.commit("userFilters", filter)
          this.$store.dispatch("setUserFilters")
          this.filterText = ""
          this.$refs.filterForm.resetValidation()
        }
      }
    },
    saveFilter() {
      this.$log("saveFilter1")
      this.$nextTick(() => {
        if (this.$refs.filterForm.validate() || this.filterText) {
          this.$log("saveFilter2")
          let filter = {}
          if (this.userFilters) {
            filter = JSON.parse(JSON.stringify(this.userFilters))
          }
          if (!filter[this.collection]) {
            filter[this.collection] = {}
          }
          filter[this.collection][this.filterText] = {
            general: this.filterGeneral,
            filters: this.filters,
            advancedFilters: this.advancedFilters
          }
          this.$store.commit("userFilters", filter)
          this.$store.dispatch("setUserFilters")
        }
      })
    },
    filterChanged() {
      this.$log("filterChanged ", this.filterName)
      if (this.filterName && this.userFilters) {
        const filter = this.userFilters
        if (filter[this.collection]) {
          if (filter[this.collection][this.filterName]) {
            this.filterGeneral = filter[this.collection][this.filterName].general
            this.filters = JSON.parse(JSON.stringify(filter[this.collection][this.filterName].filters))
            this.advancedFilters = JSON.parse(JSON.stringify(filter[this.collection][this.filterName].advancedFilters))
            this.searchAdvanced()
          }
        }
      }
    },
    cancelFilters(fltid, ind) {
      this.$refs.searchAdvanced.clearFiltersItem(fltid)
      this.advancedSearchFilters.splice(ind, 1)
      this.reloadTable()
    },
    filtersChanged(e) {
      //this.showFilters = false
      this.advancedSearchFilters = e
      this.reloadTable()
    },
    deleteAdvFilter(e) {
      this.advancedFilters.splice(e, 1)
      // this.searchAdvancedInput()
      this.searchAdvanced()
    },
    filterValueInput(e) {
      this.filterSearchValue = e
      if (e) {
        this.filterDeleted = false
      }
    },
    filterDeleteKey() {
      //this.$log("filterDeleteKey1 ", this.filterSearchValue)
      //this.$log("filterDeleteKey2 ", this.filterDeleted)
      //this.$log("filterDeleteKey3 ", this.filterField)
      if (this.filterDeleted) {
        if (this.filterField == "addvalue") {
          this.$log("filterDeleteKey addtype ")
          this.filterField = "del"
          this.filtersValue = []
          this.filtersTypes[this.fieldTypes[this.advancedFilters[this.advancedFilters.length - 1].field]].map(itm => {
            this.filtersValue.push({ label: this.$t(itm.text), value: itm.value })
          })
          this.filterSearchValue = this.advancedFilters[this.advancedFilters.length - 1].type
          this.filterValue = this.advancedFilters[this.advancedFilters.length - 1].type
          this.filterValue = {
            label: this.advancedFilters[this.advancedFilters.length - 1].typeLabel,
            value: this.advancedFilters[this.advancedFilters.length - 1].type
          }
          this.$set(this.filterValue, "label", this.advancedFilters[this.advancedFilters.length - 1].typeLabel)
          this.$set(this.filterValue, "value", this.advancedFilters[this.advancedFilters.length - 1].type)
          this.$set(this.advancedFilters[this.advancedFilters.length - 1], "type", null)
          this.filterField = "addtype"
        } else if (this.filterField) {
          this.$log("filterDeleteKey del ")

          //this.filtersValue.push(...this.filtersFields)
          this.filtersValue = []
          this.filtersFields.map(itm => {
            this.filtersValue.push({ label: itm.text, value: itm.value })
          })
          if (this.advancedFilters.length > 0) {
            this.filterValue = {
              label: this.advancedFilters[this.advancedFilters.length - 1].label,
              value: this.advancedFilters[this.advancedFilters.length - 1].field
            }
            this.$set(this.filterValue, "label", this.advancedFilters[this.advancedFilters.length - 1].label)
            this.$set(this.filterValue, "value", this.advancedFilters[this.advancedFilters.length - 1].field)
            this.advancedFilters.pop()
          }
          this.filterField = ""
        } else if (this.advancedFilters.length > 0) {
          this.$log("filterDeleteKey  ")
          this.filtersValue = []
          this.filterSearchValue = this.advancedFilters[this.advancedFilters.length - 1].value
          this.filterValue = this.advancedFilters[this.advancedFilters.length - 1].value
          this.$set(this.advancedFilters[this.advancedFilters.length - 1], "value", null)
          this.filterField = "addvalue"
          // this.searchAdvancedInput()
          this.searchAdvanced()
        }
      }
      if (!this.filterSearchValue) {
        this.filterDeleted = true
      } else {
        this.filterDeleted = false
      }
    },
    filterChange(e) {
      this.$log("filterChange ", e, this.filterField)
      if (e) {
        if (this.filterField == "addtype") {
          this.$log("addttype ", this.filterValue)
          this.menuProps = {}
          this.advancedFilters[this.advancedFilters.length - 1].type = this.filterValue.value
          this.advancedFilters[this.advancedFilters.length - 1].typeLabel = this.filterValue.label
          this.$nextTick(() => (this.$refs.advSearchBox.$el.scrollLeft = this.$refs.advSearchBox.$el.scrollWidth))
          this.filterValue = { label: "", value: "" }
          this.$set(this.filterValue, "label", "")
          this.$set(this.filterValue, "value", "")
          this.filterField = "addvalue"
          this.filtersValue = []
        } else if (this.filterField) {
          this.$log("add2")
          this.advancedFilters[this.advancedFilters.length - 1].value = this.filterValue
          this.$nextTick(() => (this.$refs.advSearchBox.$el.scrollLeft = this.$refs.advSearchBox.$el.scrollWidth))
          this.filterValue = ""
          this.filterField = ""
          this.filtersValue = []
          this.filtersFields.map(itm => {
            this.filtersValue.push({ label: itm.text, value: itm.value })
          })
          // this.searchAdvancedInput()
          this.searchAdvanced()
        } else if (typeof e === "object") {
          this.$log("add1 ")
          this.advancedFilters.push({ label: e.label, field: e.value, uid: Date.now() })
          this.$nextTick(() => {
            this.$refs.advSearchBox.$el.scrollLeft = this.$refs.advSearchBox.$el.scrollWidth
            this.menuProps.value = true
          })
          this.filterField = "addtype"
          this.filterValue = { label: "", value: "" }
          this.$set(this.filterValue, "label", "")
          this.$set(this.filterValue, "value", "")
          this.filtersValue = []
          this.filtersValue = []
          this.filtersTypes[this.fieldTypes[e.value]].map(itm => {
            this.filtersValue.push({ label: this.$t(itm.text), value: itm.value })
          })
        } else {
          //this.searchAdvancedInput()
          this.searchAdvanced()
        }
      } else {
        //if (this.advancedFilters.length == 0) {
        this.searchAdvancedInput()
        //}
        //this.searchAdvanced()
      }
      this.filterSearchValue = ""
      this.filterDeleted = true
    },
    searchAdvancedInput() {
      this.$nextTick(() => {
        this.$log("searchAdvanced")
        this.filtersWhere = ""
        this.advancedSearchFilters = []
        //let i = 5
        this.advancedFilters.map((itm, ind) => {
          if (itm.value) {
            if (this.collection == "ebib_marc_mon" && itm.field == "fields.709.a.val") {
              if (this.filtersWhere != "") {
                this.filtersWhere += ","
              }
              let val = itm.value
              val = val.replace(/[-[/{}()*+?.^$|\]\\]/g, "\\\\$&")
              val = encodeURIComponent(val)
              this.filtersWhere +=
                '"$or":[{"fields.700.a.val":{"$regex":"' +
                val +
                '","$options":"i"}},{"fields.701.a.val":{"$regex":"' +
                val +
                '","$options":"i"}},{"fields.702.a.val":{"$regex":"' +
                val +
                '","$options":"i"}}]'
              this.advancedSearchFilters.push({ str: "709a - Autor = " + itm.value, id: 10 + ind })
            } else {
              const dic = {
                field: itm.field,
                type: itm.type,
                value1: itm.value,
                value2: "",
                value3: {},
                label: itm.label,
                id: 10 + ind
              }
              const res = this.getFilter(dic)
              dic.str = res.str
              if (this.filtersWhere != "") {
                this.filtersWhere += "," + res.filter
              } else {
                this.filtersWhere = res.filter
              }
              if (typeof dic.value1 == "string") {
                dic.value1 = dic.value1.replace(/[-[/{}()*+?.^$|\]\\]/g, "\\\\$&")
              }
              dic.value1 = encodeURIComponent(dic.value1)
              this.advancedSearchFilters.push(dic)
            }
          }
        })
        if (this.filterGeneral) {
          this.advancedSearchFilters.push({ str: "General = " + this.filterGeneral, id: 4 })
        }
        //this.showFilters = false
        this.page = 1
        //this.$log("sadvanced changed")
        this.getRecords()
      })
    },

    addRMFInv() {
      this.$log("addRMFInv")
      this.$emit("selectRMFInventories")
    },
    exportCSV() {
      this.$log("this.endlineCSV ", this.endlineCSV)
      if (this.$refs.csvForm.validate()) {
        let i = 0
        const d = this.$store.getters.serverDate
        const dat =
          ("0" + d.getDate()).slice(-2) +
          "-" +
          ("0" + (d.getMonth() + 1)).slice(-2) +
          "-" +
          d.getFullYear() +
          "_" +
          ("0" + d.getHours()).slice(-2) +
          "-" +
          ("0" + d.getMinutes()).slice(-2)
        this.$log("day ", dat)
        let csvContent = "data:text/csv;charset=utf-8,"
        if (this.headerCSV) {
          this.headers.map(hed => {
            if (hed.value != "action" && hed.value != "data-table-expand" && hed.value != "data-table-select") {
              if (i > 0) {
                if (this.separatorCSV == "tab") {
                  csvContent += "\t"
                } else {
                  csvContent += this.separatorCSV
                }
              }
              csvContent += this.encloserCSV
              csvContent += hed.text
              csvContent += this.encloserCSV
              i++
            }
          })
        }
        if (this.endlineCSV == "rn") {
          csvContent += "\r\n"
        } else if (this.endlineCSV == "n") {
          csvContent += "\n"
        } else if (this.endlineCSV == "r") {
          csvContent += "\r"
        } else {
          csvContent += this.endlineCSV
        }
        this.records.map(rec => {
          i = 0
          this.headers.map(hed => {
            if (hed.value != "action" && hed.value != "data-table-expand" && hed.value != "data-table-select") {
              if (i > 0) {
                if (this.separatorCSV == "tab") {
                  csvContent += "\t"
                } else {
                  csvContent += this.separatorCSV
                }
              }
              csvContent += this.encloserCSV
              if (this.viewType == "marc") {
                csvContent += rec[hed.value] ? rec[hed.value] : ""
              } else {
                csvContent += this.resolvePath(hed.value, rec)
              }
              csvContent += this.encloserCSV
              i++
            }
          })
          if (this.endlineCSV == "rn") {
            csvContent += "\r\n"
          } else if (this.endlineCSV == "n") {
            csvContent += "\n"
          } else if (this.endlineCSV == "r") {
            csvContent += "\r"
          } else {
            csvContent += this.endlineCSV
          }
        })
        const encodedUri = encodeURI(csvContent)
        //window.open(encodedUri)
        const link = document.createElement("a")
        link.setAttribute("href", encodedUri)
        link.setAttribute("download", this.nameCSV + "_" + dat + ".csv")
        document.body.appendChild(link)
        link.click()
      }
    },
    findPublonsAccount(itm) {
      this.$log("findPublonsAccount ", itm)
      this.academicsId = itm._id
      EventBus.$emit("search-link", { resource: "infuni_publons", field: null, id: "findPublonsAccount", config: null })
      //open("ebib_marc_cln", null, "flow", { where: '"ctg":"reader"' })
    },
    getscopus(type) {
      this.$log("getscopus ", type)
      this.$log("getscopus ", this.selectedId)
      //this.dict.indexScopus = 0
      //this.dict.subindexScopus = 0
      //this.updateNextScopus(type)
      let path = ""
      if (this.collection == "infuni_scopus") {
        path = "ids.scopus_id"
      } else if (this.collection == "infuni_academics") {
        path = "researchers_ids.scopus_ids"
      }
      let str = ""
      this.selectedId.map(itm => {
        const values = this.resolvePath(path, itm)
        this.$log("values ", values)
        if (values) {
          if (str) {
            str += "," + values.split("\n")
          } else {
            str = values.split("\n")
          }
        }
      })
      if (str) {
        axios.post("infuni/events/academics/scopus/" + str + "/" + type).then(res => {
          this.$log("events ", res)
        })
      }
    },
    updateNextScopus(type) {
      if (this.dict.indexScopus < this.selectedId.length) {
        if (this.collection == "infuni_academics") {
          if (this.selectedId[this.dict.indexScopus].researchers_ids) {
            if (this.selectedId[this.dict.indexScopus].researchers_ids.scopus_ids.split("\n").length > 0) {
              if (
                this.dict.subindexScopus <
                this.selectedId[this.dict.indexScopus].researchers_ids.scopus_ids.split("\n").length
              ) {
                this.updatescopus(
                  this.selectedId[this.dict.indexScopus].researchers_ids.scopus_ids.split("\n")[
                    this.dict.subindexScopus++
                  ],
                  type
                )
              } else {
                this.dict.subindexScopus = 0
                this.dict.indexScopus++
                this.updateNextScopus(type)
              }
            } else {
              this.dict.indexScopus++
              this.updateNextScopus(type)
            }
          } else {
            this.dict.indexScopus++
            this.updateNextScopus(type)
          }
        }
      } else {
        EventBus.$emit("show-alert", {
          message: "Updated finished.",
          timeout: 7000
        })
      }
    },
    updatescopus(id, type) {
      this.$log("updatescopus ", id)
      this.$log("updatescopus ", type)
      axios
        .get("infuni/scopus/" + type + "/" + id)
        .then(response => {
          this.$log("updatescopus ", response)
          this.updateNextScopus(type)
          EventBus.$emit("show-alert", {
            message: "Scopus data updated.",
            timeout: 7000
          })
        })
        .catch(() => {
          this.updateNextScopus(type)
        })
    },
    getpublons(type) {
      this.$log("getpublons1 ", type)
      this.$log("getpublons2 ", this.selectedId)
      // this.dict.indexPublons = 0
      // this.dict.subindexPublons = 0
      // this.updateNextPubloans(type)
      let path = ""
      if (this.collection == "infuni_publons") {
        path = "ids.rid"
      } else if (this.collection == "infuni_academics") {
        path = "researchers_ids.publons_ids"
      }
      let str = ""
      this.selectedId.map(itm => {
        const values = this.resolvePath(path, itm)
        this.$log("values ", values)
        if (values) {
          if (str) {
            str += "," + values.split("\n")
          } else {
            str = values.split("\n")
          }
        }
      })
      if (str) {
        axios.post("infuni/events/academics/publons/" + str + "/" + type).then(res => {
          this.$log("events ", res)
        })
      }
    },
    updateNextPubloans(type) {
      if (this.dict.indexPublons < this.selectedId.length) {
        if (this.collection == "infuni_publons") {
          this.updatepublons(this.selectedId[this.dict.indexPublons++]._id, type)
        } else if (this.collection == "infuni_academics") {
          if (this.selectedId[this.dict.indexPublons].researchers_ids) {
            if (this.selectedId[this.dict.indexPublons].researchers_ids.publons_ids.split("\n").length > 0) {
              if (
                this.dict.subindexPublons <
                this.selectedId[this.dict.indexPublons].researchers_ids.publons_ids.split("\n").length
              ) {
                axios
                  .get(
                    'infuni_publons?where={"ids.rid":"' +
                      encodeURIComponent(
                        this.selectedId[this.dict.indexPublons].researchers_ids.publons_ids.split("\n")[
                          this.dict.subindexPublons++
                        ]
                      ) +
                      '"}'
                  )
                  .then(response => {
                    this.$log("response pub ", response)
                    this.updatepublons(response.data._items[0]._id, type)
                  })
              } else {
                this.dict.subindexPublons = 0
                this.dict.indexPublons++
                this.updateNextPubloans(type)
              }
            } else {
              this.dict.indexPublons++
              this.updateNextPubloans(type)
            }
          } else {
            this.dict.indexPublons++
            this.updateNextPubloans(type)
          }
        }
      } else {
        EventBus.$emit("show-alert", {
          message: "Updated finished.",
          timeout: 7000
        })
      }
    },
    updatepublons(id, type) {
      this.$log("updatepublons1 ", id)
      this.$log("updatepublons2 ", type)
      axios.get("infuni_publons/" + id).then(response => {
        this.$log("get rid ", response)
        const etag = response.data._etag
        response.data._created = undefined
        response.data._etag = undefined
        response.data._latest_version = undefined
        response.data._links = undefined
        response.data._updated = undefined
        response.data._version = undefined
        //all reviews publications editorial_boards
        this.getacademic(response.data.ids.rid, type).then(resp => {
          this.$log("getaca ", resp)
          if (type == "all") {
            response.data = resp.data
          } else if (type == "") {
            resp.data.reviews.review_list = response.data.reviews.review_list
            resp.data.publications.publication_list = response.data.publications.publication_list
            resp.data.editorial_boards.editorial_board_list = response.data.editorial_boards.editorial_board_list
            response.data = resp.data
          } else if (type == "publications") {
            response.data.publications.publication_list = resp.data
          } else if (type == "editorial_boards") {
            response.data.editorial_boards.editorial_board_list = resp.data
          } else if (type == "reviews") {
            response.data.reviews.review_list = resp.data
          }
          axios
            .put("infuni_publons/" + id, response.data, {
              headers: {
                "If-Match": etag
              }
            })
            .then(res => {
              EventBus.$emit("show-alert", {
                message: response.data.publishing_name + " " + type + " updated.",
                timeout: 7000
              })
              this.updateNextPubloans(type)
              this.$log("change ", res)
            })
        })
      })
    },
    getacademic(id, type) {
      return new Promise((resolve, reject) => {
        axios
          .get("/infuni/publons/academic/" + id + (type ? "/" + type : ""))
          .then(resp => {
            resolve(resp)
          })
          .catch(() => {
            reject(new Error("API Error"))
          })
      })
    },
    getpublonsDetails() {
      this.$log("getpublonsDetails ", this.$config)
      this.$log("getpublonsDetails ", this.selectedId)
      this.selectedId.map(itm => {
        axios.get(this.collection + "/" + itm._id).then(response => {
          this.$log("get rid ", response)
          axios.get("/infuni/publons/academic/" + response.data.ids.rid).then(resp => {
            this.$log("getpublonsDetails ", resp)
            const etag = response.data._etag
            resp.data.reviews.review_list = response.data.reviews.review_list
            resp.data.publications.publication_list = response.data.publications.publication_list
            resp.data.editorial_boards.editorial_board_list = response.data.editorial_boards.editorial_board_list
            this.$log("getpublonsDetails ", resp)
            axios
              .put(this.collection + "/" + itm._id, resp.data, {
                headers: {
                  "If-Match": etag
                }
              })
              .then(res => {
                EventBus.$emit("show-alert", {
                  message: response.data.publishing_name + " data updated.",
                  timeout: 7000
                })
                this.$log("change ", res)
              })
          })
        })
      })
    },
    getpublonsAll() {
      this.$log("getpublonsAll ", this.$config)
      this.$log("getpublonsAll ", this.selectedId)
      this.selectedId.map(itm => {
        axios.get(this.collection + "/" + itm._id).then(response => {
          this.$log("get rid ", response)
          axios.get("/infuni/publons/academic/" + response.data.ids.rid + "/all").then(resp => {
            this.$log("getpublonsAll ", resp)
            const etag = response.data._etag
            response.data = resp.data
            response.data._created = undefined
            response.data._etag = undefined
            response.data._latest_version = undefined
            response.data._links = undefined
            response.data._updated = undefined
            response.data._version = undefined
            this.$log("getpublonsAll ", resp)
            axios
              .put(this.collection + "/" + itm._id, response.data, {
                headers: {
                  "If-Match": etag
                }
              })
              .then(res => {
                EventBus.$emit("show-alert", {
                  message: response.data.publishing_name + " all data updated.",
                  timeout: 7000
                })
                this.$log("change ", res)
              })
          })
        })
      })
    },
    getpublonsReviews() {
      this.$log("getpublonsReviews ", this.$config)
      this.$log("getpublonsReviews ", this.selectedId)
      this.selectedId.map(itm => {
        axios.get(this.collection + "/" + itm._id).then(response => {
          this.$log("get rid ", response)
          axios.get("/infuni/publons/academic/" + response.data.ids.rid + "/reviews").then(resp => {
            this.$log("getpublonsReviews ", resp)
            response.data.reviews.review_list = resp.data
            const etag = response.data._etag
            response.data._created = undefined
            response.data._etag = undefined
            response.data._latest_version = undefined
            response.data._links = undefined
            response.data._updated = undefined
            response.data._version = undefined
            axios
              .put(this.collection + "/" + itm._id, response.data, {
                headers: {
                  "If-Match": etag
                }
              })
              .then(res => {
                EventBus.$emit("show-alert", {
                  message: response.data.publishing_name + " reviews updated.",
                  timeout: 7000
                })
                this.$log("change ", res)
              })
          })
        })
      })
    },
    getpublonsPublications() {
      this.$log("getpublonsPublications ", this.$config)
      this.$log("getpublonsPublications ", this.selectedId)
      this.selectedId.map(itm => {
        axios.get(this.collection + "/" + itm._id).then(response => {
          this.$log("get rid ", response)
          axios.get("/infuni/publons/academic/" + response.data.ids.rid + "/publications").then(resp => {
            this.$log("getpublonsPublications ", resp)
            response.data.publications.publication_list = resp.data
            const etag = response.data._etag
            response.data._created = undefined
            response.data._etag = undefined
            response.data._latest_version = undefined
            response.data._links = undefined
            response.data._updated = undefined
            response.data._version = undefined
            axios
              .put(this.collection + "/" + itm._id, response.data, {
                headers: {
                  "If-Match": etag
                }
              })
              .then(res => {
                EventBus.$emit("show-alert", {
                  message: response.data.publishing_name + " publications updated.",
                  timeout: 7000
                })
                this.$log("change ", res)
              })
          })
        })
      })
    },
    getpublonsEditorials() {
      this.$log("getpublonsEditorials ", this.$config)
      this.$log("getpublonsEditorials ", this.selectedId)
      this.selectedId.map(itm => {
        axios.get(this.collection + "/" + itm._id).then(response => {
          this.$log("get rid ", response)
          axios.get("/infuni/publons/academic/" + response.data.ids.rid + "/editorial_boards").then(resp => {
            this.$log("getpublonsEditorials ", resp)
            response.data.editorial_boards.editorial_board_list = resp.data
            const etag = response.data._etag
            response.data._created = undefined
            response.data._etag = undefined
            response.data._latest_version = undefined
            response.data._links = undefined
            response.data._updated = undefined
            response.data._version = undefined
            axios
              .put(this.collection + "/" + itm._id, response.data, {
                headers: {
                  "If-Match": etag
                }
              })
              .then(res => {
                EventBus.$emit("show-alert", {
                  message: response.data.publishing_name + " editorials updated.",
                  timeout: 7000
                })
                this.$log("change ", res)
              })
          })
        })
      })
    },
    changeCategory(cat) {
      this.$log("changeCategory22 ", cat)
      this.$log("sel ", this.selectedId)
      this.selectedId.map(itm => {
        const rec = { ctg: cat.category }
        axios
          .patch(this.collection + "/" + itm._id, rec, {
            headers: {
              "If-Match": itm._etag
            }
          })
          .then(response => {
            this.$log("categoychanged ", response)
          })
      })
      //this.$log("changecategory changed")
      setTimeout(this.getRecords, 300)
      //this.$log("refreshCounts setTimeout tm ")
      //setTimeout(this.refreshCounts, 300)
    },
    refreshCounts() {
      //this.$log("refreshCounts tm ")
      this.$emit("refreshCounts")
    },
    resolvePath(path, obj) {
      return path.split(".").reduce(function(prev, curr) {
        return prev ? prev[curr] : null
      }, obj || self)
    },
    getdictionary(dic) {
      //this.$log("getdictionary ", dic)
      if (this.$store.getters.dictionary("all_" + dic)) {
        return this.$store.getters.dictionary("all_" + dic)
      } else {
        axios.get('app_dictionary?where={"value":"' + dic + '"}').then(response => {
          if (response.data._items[0]) {
            this.$store.commit("dictionary", { name: "all_" + dic, values: response.data._items[0].content })
          }
        })
      }
      return []
    },
    selectedChanged(e) {
      this.$log("selectedChanged ", e)
      this.selectedId = []
      e.map(ind => this.selectedId.push(this.records[ind]))
    },
    configColumns() {
      this.$log("configColumns")
      this.$refs.configColumns.openConfig(this.headers, this.tableManagement[this.indTable], this.filtersFields)
    },
    applyColumns(col) {
      this.tableManagement[this.indTable] = col
      this.changeHeader(this.indTable)
      this.$log("applyColumns ", col)
    },
    saveColumns(col) {
      // if (col.owner.user) {
      //   if (col.owner.user.indexOf(this.userId) > -1) {
      //     this.$log("names " + col.name + " - " + this.tableManagement[this.indTable].name)
      //     if (col.name != this.tableManagement[this.indTable].name) {
      //       col._id = undefined
      //     }
      //   } else {
      //     col.owner.user = [this.userId]
      //     col._id = undefined
      //   }
      // } else if (col.owner.company) {
      //   col.owner.user = [this.userId]
      //     col._id = undefined
      // } else {
      // }
      this.tableManagement[this.indTable] = col
      this.changeHeader(this.indTable)
      this.$log("saveColumns ", col)
    },
    openUserCard(id) {
      if (this.$refs.userCard) {
        this.$refs.userCard.open(id)
      }
    },
    cancelFiltersChip(fltid, ind) {
      this.$log("cancelFiltersChip1 ", fltid)
      this.$log("cancelFiltersChip2 ", ind)
      if (fltid == 4) {
        this.filterGeneral = ""
        this.searchAdvanced()
      } else if (fltid < 4) {
        this.filters[fltid].value1 = ""
        this.filters[fltid].value2 = ""
        this.searchAdvanced()
      } else {
        this.advancedFilters.splice(10 - fltid, 1)
        //this.searchAdvancedInput()
        this.searchAdvanced()
      }

      //this.$refs.searchAdvanced.clearFiltersItem(fltid)
      //this.advancedSearchFilters.splice(ind, 1)
      //this.reloadTable()
    },
    clearFilters() {
      this.$log("clearFilters")
      this.filtersWhere = ""
      this.filterGeneral = ""
      this.advancedFilters = []
      this.filterDeleted = true
      this.filterField = "addtype"
      this.filterDeleteKey()
      this.filters.map(itm => {
        itm.value1 = ""
        itm.value2 = ""
      })
      this.advancedSearchFilters = []
      //this.$log("clearfilter changed")
      this.getRecords()
    },
    clearAdvFilter() {
      this.advancedFilters = []
      this.filterDeleted = true
      this.filterField = "addtype"
      this.filterDeleteKey()
      //this.$log("clearfilter changed")
      //this.getRecords()
      this.searchAdvanced()
    },
    searchAdvanced() {
      this.$log("searchAdvanced ", this.collection)
      this.filtersWhere = ""
      this.advancedSearchFilters = []
      if (this.filterGeneral) {
        //this.advancedSearchFilters.push({ str: "General = " + this.filterGeneral, id: 4 })
        const arr = []
        this.$log("this.userSearchs ", this.userSearchs)
        if (this.userSearchs.length > 0) {
          arr.push(...this.userSearchs)
          if (arr.find(itm => itm.text == this.filterGeneral)) {
            arr.find(itm => itm.text == this.filterGeneral).count++
          } else {
            arr.push({ text: this.filterGeneral, count: 1 })
          }
        } else {
          arr.push({ text: this.filterGeneral, count: 1 })
        }
        arr.sort((a, b) => {
          return b.count - a.count
        })
        let usearch = {}
        if (this.$store.getters.userSearchs) {
          usearch = JSON.parse(JSON.stringify(this.$store.getters.userSearchs))
        }
        usearch[this.collection] = arr
        this.$store.commit("userSearchs", usearch)
        this.$nextTick(() => {
          this.$refs.filterGeneralCombo.isMenuActive = false
        })
      }
      this.advancedFilters.map((itm, ind) => {
        if (itm.value) {
          if (this.collection == "ebib_marc_mon" && itm.field == "fields.709.a.val") {
            if (this.filtersWhere != "") {
              this.filtersWhere += ","
            }
            let val = itm.value
            val = val.replace(/[-[/{}()*+?.^$|\]\\]/g, "\\\\$&")
            val = encodeURIComponent(val)
            this.filtersWhere +=
              '"$or":[{"fields.700.a.val":{"$regex":"' +
              val +
              '","$options":"i"}},{"fields.701.a.val":{"$regex":"' +
              val +
              '","$options":"i"}},{"fields.702.a.val":{"$regex":"' +
              val +
              '","$options":"i"}}]'
            this.advancedSearchFilters.push({ str: "709a - Autor2 = " + itm.value, id: 10 + ind })
          } else {
            const dic = {
              field: itm.field,
              type: itm.type,
              value1: itm.value,
              value2: "",
              value3: {},
              id: 10 + ind
            }
            const res = this.getFilter(dic)
            dic.str = res.str
            if (this.filtersWhere != "") {
              this.filtersWhere += "," + res.filter
            } else {
              this.filtersWhere = res.filter
            }
            if (typeof dic.value1 == "string") {
              dic.value1 = dic.value1.replace(/[-[/{}()*+?.^$|\]\\]/g, "\\\\$&")
            }
            dic.value1 = encodeURIComponent(dic.value1)
            this.$log("diccccc ", dic)
            this.advancedSearchFilters.push(dic)
          }
        }
      })
      this.filters.map((itm, index) => {
        // this.$log("searchAdvanced map ", itm)
        if (itm.value1 !== "" && itm.value1 != undefined) {
          if (this.collection == "ebib_marc_mon" && itm.field == "fields.709.a.val") {
            if (this.filtersWhere != "") {
              this.filtersWhere += ","
            }
            let val = itm.value1
            val = val.replace(/[-[/{}()*+?.^$|\]\\]/g, "\\\\$&")
            val = encodeURIComponent(val)
            this.filtersWhere +=
              '"$or":[{"fields.700.a.val":{"$regex":"' +
              val +
              '","$options":"i"}},{"fields.701.a.val":{"$regex":"' +
              val +
              '","$options":"i"}},{"fields.702.a.val":{"$regex":"' +
              val +
              '","$options":"i"}}]'
            this.advancedSearchFilters.push({ str: "709a - Autor = " + itm.value1, id: index })
          } else {
            const res = this.getFilter(itm)
            const dic = { ...itm }
            dic.str = res.str
            if (this.filtersWhere != "") {
              this.filtersWhere += "," + res.filter
            } else {
              this.filtersWhere = res.filter
            }
            if (typeof dic.value1 == "string") {
              dic.value1 = dic.value1.replace(/[-[/{}()*+?.^$|\]\\]/g, "\\\\$&")
            }
            //                      replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
            //                      replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&")
            //dic.value1 = dic.value1.split("[").join("\\[")
            //this.$log("dic.value1 ", dic.value1)
            dic.value1 = encodeURIComponent(dic.value1)
            this.advancedSearchFilters.push(dic)
          }
        }
      })
      //this.showFilters = false
      this.page = 1
      //this.$log("this.page ", this.page)
      //this.$log("searchadvanced changed")
      this.getRecords()
    },
    getFilter(itm) {
      let str = ""
      let filter = ""
      let label = itm.value1
      if (typeof itm.value1 == "string") {
        itm.value1 = itm.value1.replace(/[-[/{}()*+?.^$|\]\\]/g, "\\\\$&")
      }
      itm.value1 = encodeURIComponent(itm.value1)
      if (itm.label != "" && itm.label != undefined) {
        label = itm.label
      }
      //this.$log("searchAdvanced  ", itm)
      const arr = itm.field.split(".")
      switch (itm.type) {
        case "c":
          // if (this.fieldTypes[itm.field] == "list") {
          //   this.filtersWhere = '"' + itm.field + '":"' + itm.value1 + '"'
          // } else {
          filter = '"' + itm.field + '":{"$regex":"' + itm.value1 + '","$options":"i"}'
          //this.$log("this.filtersFields  ", this.filtersFields)
          if (this.filtersFields.length > 0) {
            str += this.filtersFields.find(flt => flt.value == itm.field).text + " = ..." + label + "..."
          } else {
            str += this.filtersFields.find(flt => flt.value == itm.field).text + " = ..." + label + "..."
          }
          //}
          break
        case "b":
          filter = '"' + itm.field + '":{"$regex":"^' + itm.value1 + '","$options":"i"}'
          str += this.filtersFields.find(flt => flt.value == itm.field).text + " = " + label + "..."
          break

        case "e":
          filter = '"' + itm.field + '":{"$regex":"' + itm.value1 + '$","$options":"i"}'
          str += this.filtersFields.find(flt => flt.value == itm.field).text + " = ..." + label
          break
        case "i":
          filter = '"' + itm.field + '":{"$exists":' + itm.value1 + "}"
          if (itm.value1 == "true") {
            itm.value1 = true
            str += this.filtersFields.find(flt => flt.value == itm.field).text + ": există"
          } else {
            itm.value1 = false
            str += this.filtersFields.find(flt => flt.value == itm.field).text + ": nu există"
          }
          break
        case "r=":
          if (arr[0] == "fields" && arr[3] == "val") {
            filter =
              '"' +
              itm.field.substring(0, itm.field.length - 4) +
              "." +
              (Number(itm.value1) - 1) +
              '":{"$exists":true}' +
              ',"' +
              itm.field.substring(0, itm.field.length - 4) +
              "." +
              itm.value1 +
              '":{"$exists":false}'
            str += this.filtersFields.find(flt => flt.value == itm.field).text + " []= " + label
          } else {
            filter =
              '"' +
              itm.field +
              "." +
              (Number(itm.value1) - 1) +
              '":{"$exists":true}' +
              ',"' +
              itm.field +
              "." +
              itm.value1 +
              '":{"$exists":false}'
            str += this.filtersFields.find(flt => flt.value == itm.field).text + " []= " + label
          }
          break
        case "r<":
          if (arr[0] == "fields" && arr[3] == "val") {
            filter =
              '"' +
              itm.field.substring(0, itm.field.length - 4) +
              "." +
              (Number(itm.value1) - 1) +
              '":{"$exists":false}'
            str += this.filtersFields.find(flt => flt.value == itm.field).text + " []< " + label
          } else {
            filter = '"' + itm.field + "." + (Number(itm.value1) - 1) + '":{"$exists":false}'
            str += this.filtersFields.find(flt => flt.value == itm.field).text + " []< " + label
          }
          break
        case "r>":
          if (arr[0] == "fields" && arr[3] == "val") {
            filter = '"' + itm.field.substring(0, itm.field.length - 4) + "." + itm.value1 + '":{"$exists":true}'
            str += this.filtersFields.find(flt => flt.value == itm.field).text + " []> " + label
          } else {
            filter = '"' + itm.field + "." + itm.value1 + '":{"$exists":true}'
            str += this.filtersFields.find(flt => flt.value == itm.field).text + " []> " + label
          }
          break
        case "=":
          this.$log("fieldTypes[header.value] ", itm.field)
          this.$log("fieldTypes[header.value] ", this.fieldTypes[itm.field])
          this.$log("fieldTypes[header.value] ", this.fieldTypes)
          if (
            this.fieldTypes[itm.field] == "integer" ||
            this.fieldTypes[itm.field] == "float" ||
            this.fieldTypes[itm.field] == "number" ||
            this.fieldTypes[itm.field] == "boolean"
          ) {
            this.$log("fieldTypes[header.value] number")
            filter = '"' + itm.field + '":' + itm.value1
          } else if (this.fieldTypes[itm.field] == "datetime" || this.fieldTypes[itm.field] == "objectid") {
            filter = '"' + itm.field + '":"' + itm.value1 + '"'
          } else {
            filter = '"' + itm.field + '":{"$regex":"^' + itm.value1 + '$","$options":"i"}'
          }
          this.$log("flt1 ", this.filtersFields)
          this.$log("flt2 ", itm.field)
          this.$log("flt3 ", this.filtersFields.find(flt => flt.value == itm.field).text)
          str += this.filtersFields.find(flt => flt.value == itm.field).text + " = " + label
          break
        case ">":
          if (
            this.fieldTypes[itm.field] == "integer" ||
            this.fieldTypes[itm.field] == "float" ||
            this.fieldTypes[itm.field] == "number"
          ) {
            filter = '"' + itm.field + '":{"$gt":' + itm.value1 + "}"
          } else {
            filter = '"' + itm.field + '":{"$gt":"' + itm.value1 + '"}'
          }
          str += this.filtersFields.find(flt => flt.value == itm.field).text + " > " + label
          break
        case "<":
          if (
            this.fieldTypes[itm.field] == "integer" ||
            this.fieldTypes[itm.field] == "float" ||
            this.fieldTypes[itm.field] == "number"
          ) {
            filter = '"' + itm.field + '":{"$lt":' + itm.value1 + "}"
          } else {
            filter = '"' + itm.field + '":{"$lt":"' + itm.value1 + '"}'
          }
          str += this.filtersFields.find(flt => flt.value == itm.field).text + " < " + label
          break
      }
      itm.value1 = decodeURIComponent(itm.value1)
      return { str, filter }
    },
    lineEdited(line, col) {
      this.$log("lineEdited line ", line)
      this.$log("lineEdited col ", col)
      const arr = col.split(".")
      axios.get(this.collection + "/" + line._id + '?projection={"' + arr[0] + "." + arr[1] + '":1}').then(res => {
        let save = true
        if (this.fieldSchema[col].meta.component == "DateField") {
          this.$set(line, col, line[col] + " 00:00:00")
        }
        if (res.data.fields[arr[1]]) {
          if (res.data.fields[arr[1]].length > 1) {
            save = false
            EventBus.$emit("show-alert", { message: "Acest camp nu se poate edita pentru ca repetabil", timeout: 7000 })
          } else {
            if (res.data.fields[arr[1]][0]) {
              if (res.data.fields[arr[1]][0][arr[2]]) {
                if (res.data.fields[arr[1]][0][arr[2]].length > 1) {
                  save = false
                  EventBus.$emit("show-alert", {
                    message: "Acest camp nu se poate edita pentru ca repetabil",
                    timeout: 7000
                  })
                } else {
                  if (line[col]) {
                    res.data.fields[arr[1]][0][arr[2]][0].val = line[col]
                  } else {
                    if (Object.keys(res.data.fields[arr[1]][0]).length < 2) {
                      //res.data.fields[arr[1]] = undefined
                      res.data.fields[arr[1]] = []
                      //res.data.fields[arr[1]] = null
                    } else {
                      res.data.fields[arr[1]][0][arr[2]] = undefined
                    }
                  }
                }
              } else {
                if (!line[col]) {
                  save = false
                }
                res.data.fields[arr[1]][0][arr[2]] = [{ val: line[col] }]
              }
            } else {
              if (!line[col]) {
                save = false
              }
              const rec = {}
              rec[arr[2]] = [{ val: line[col] }]
              res.data.fields[arr[1]] = [rec]
            }
          }
        } else {
          if (!line[col]) {
            save = false
          }
          const rec = {}
          rec[arr[2]] = [{ val: line[col] }]
          res.data.fields[arr[1]] = [rec]
          //res.data.fields[arr[1]][0][arr[2]][0].val = line[col]
        }
        if (save) {
          const etag = res.data._etag
          res.data._created = undefined
          res.data._links = undefined
          res.data._version = undefined
          res.data._latest_version = undefined
          res.data._updated = undefined
          res.data._etag = undefined
          axios
            .patch(this.collection + "/" + line._id, res.data, {
              headers: {
                "If-Match": etag
              }
            })
            .then(response => {
              const itm = this.recToTM(response.data)
              this.$set(
                this.records,
                this.records.findIndex(it => it._id == itm._id),
                itm
              )
            })
        }
      })
    },
    rowClick(e, row) {
      //this.selectedId = e._id
      //this.$log("rowclick ", document.activeElement)
      //this.$log("rowclick ", this.selectedId.indexOf(e))
      //this.$log("rowclick ", document.activeElement)
      if (!this.showSelect) {
        if (this.selectedId.indexOf(e) > -1) {
          row.select(false)
        } else {
          row.select(true)
        }
      }
      if (this.tmClicks == 0) {
        this.tmClicks++
        setTimeout(() => {
          if (this.tmClicks > 1) {
            //this.$log("doubleclick ", e)
            this.rowDoubleClick(e)
          }
          this.tmClicks = 0
        }, 250)
      } else {
        this.tmClicks++
      }
    },
    rowDoubleClick(e) {
      if (this.showSelectItem) {
        this.$emit("selectItem", e)
      } else if (this.actions.indexOf("edit") >= 0) {
        this.editItem(e)
      }
    },
    resize() {
      //this.$log("resize ", this.$refs.table.$el.querySelector(".v-data-table-header").clientHeight)
      if (!this.isPopUp) {
        this.$refs.table.$el.querySelector(".v-data-table__wrapper").style.maxHeight =
          window.innerHeight - this.$refs.table.$el.getBoundingClientRect().y - 85 + "px"
        if (this.$refs.thumb.$el.getBoundingClientRect().y > 0) {
          this.$refs.thumb.$el.style.maxHeight =
            window.innerHeight - this.$refs.thumb.$el.getBoundingClientRect().y - 85 + "px"
        } else {
          this.$refs.thumb.$el.style.maxHeight = window.innerHeight - 355 + "px"
        }
      } else {
        let par = this.$refs.loader
        this.offsetTop = par.getBoundingClientRect().y
        while (par != document) {
          if (par.classList.contains("v-dialog")) {
            this.offsetTop -= par.getBoundingClientRect().y
            break
          }
          par = par.parentNode
        }
        if (this.$refs.table.$el.getBoundingClientRect().y > 0) {
          this.$refs.table.$el.querySelector(".v-data-table__wrapper").style.maxHeight =
            window.innerHeight - this.offsetTop - 240 + "px"
        } else {
          this.$refs.table.$el.querySelector(".v-data-table__wrapper").style.maxHeight = window.innerHeight - 350 + "px"
        }
        //this.$log("y1 ", this.$el)
        //this.$log("y2 ", this.$refs.thumb.$el.getBoundingClientRect().y)
        if (this.$refs.thumb.$el.getBoundingClientRect().y > 0) {
          this.$refs.thumb.$el.style.maxHeight = window.innerHeight - this.offsetTop - 240 + "px"
        } else {
          this.$refs.thumb.$el.style.maxHeight = window.innerHeight - 650 + "px"
        }
      }
      this.theadheight = this.$refs.table.$el.querySelector(".v-data-table-header").clientHeight
    },
    appViewTableCollection(col) {
      return this.$store.getters.appViewTableCollection(col)
    },
    tableChanged() {
      //this.$log("tableChangedtableChanged ", this.table)

      if (this.tableChangeinterval) {
        clearInterval(this.tableChangeinterval)
        //this.$log("clear ", this.tableChangeinterval)
        this.tableChangeinterval = null
      }
      if (this.customTable) {
        this.viewType = "marc"
        this.showTMFilters = true
        this.multipleSelect = false
        this.actions = []
        this.viewTypes = ["table"]
        this.showAdd = false
        this.tables = this.appSchema.filter(itm => itm.collection == this.table)
        this.$log("tableChangedtableChangedsss ", this.tables)
        this.page = 1
        this.totalResults = 0
        this.filtersFields = []
        if (this.tables.length > 0) {
          this.tmColumns = []
          this.generateListFields(this.tables[0].content, "")
          this.collection = this.tables[0].item_url
          this.filtersValue = []
          this.filtersFields.map(itm => {
            this.filtersValue.push({ label: itm.text, value: itm.value })
          })
          this.$emit("fieldsChanged", this.filtersFields)
          this.loading = false
        }
        return
      }
      if (this.table) {
        //this.$log("tableChangedtableChanged this.appSchema ", this.appSchema)
        // this.appSchema.map(itm => {
        //   this.$log("col ", itm.collection)
        // })
        this.tables = this.appSchema.filter(itm => itm.collection == this.table)
        //this.$log("tableChangedtableChangedsss ", this.tables)
        this.page = 1
        this.filtersFields = []
        if (this.tables.length > 0) {
          this.tmColumns = []
          this.generateListFields(this.tables[0].content, "")
          this.$emit("fieldsChanged", this.filtersFields)
          if (this.tables[0].collection == "marc_mon") {
            this.filtersFields.push({ text: "709a - Autor", value: "fields.709.a.val", type: "string" })
            this.fieldTypes["fields.709.a.val"] = "string"
            this.fieldSchema["fields.709.a.val"] = { meta: {} }
            this.fieldSchema["fields.709.a.val"].lbl = "Autor"
          }
          this.collection = this.tables[0].item_url
          this.filtersValue = []
          this.filtersFields.map(itm => {
            this.filtersValue.push({ label: itm.text, value: itm.value })
          })
        } else {
          //this.filtersFields.push({ text: lbl, value: newname, type: rec[prop].type })
          this.collection = this.table
        }
        //this.$log("gggg1 ", this.fieldTypes)
        //this.$log("gggg2 ", this.filtersFields)
        //this.$log("tablechanged ", this.tables[0])

        // axios
        //   .get('app_view/?where={"collection": "' + this.table + '", "type":"table"}')
        //   .then(response => {
        const cat = this.category ? this.category : undefined
        //this.$log("cat123 ", cat)
        //this.$log("cat124 ", this.viewType)
        let response = this.$store.getters.appViewTableCollectionCategory(this.table, cat)
        //this.$log("app_view reload1 ", response)
        if (response.length == 0) {
          response = this.$store.getters.appViewTableCollectionCategory(this.table)
        }
        //this.$log("app_view reload ", response)
        if (response.length > 0) {
          this.tableManagement = response
          //this.$log("this.viewTable ", this.viewTable)
          if (this.viewTable) {
            this.changeHeader(this.tableManagement.findIndex(itm => itm._id == this.viewTable))
          } else {
            const def = {}
            this.tableManagement.map((itm, ind) => {
              //this.$log("map ", itm)
              if (itm.default) {
                def.default = ind
                if (itm.owner.user) {
                  if (itm.owner.user.indexOf(this.userId) > -1) {
                    def.user = ind
                  }
                }
                if (itm.owner.company) {
                  if (itm.owner.company.indexOf(this.companyId) > -1) {
                    def.company = ind
                  }
                }
              }
            })
            //this.$log("def ", def)
            if (def.user) {
              this.changeHeader(def.user)
            } else if (def.company) {
              this.changeHeader(def.company)
            } else {
              this.changeHeader(def.default)
            }
            //this.changeHeader(this.tableManagement.findIndex(itm => itm.default))
          }
        } else {
          this.generateHeader()
        }

        // })
        // .catch(() => this.generateHeader())
      }
    },
    generateListFields(rec, name) {
      //this.$log("view type ", this.viewType)
      for (const prop in rec) {
        if ((prop === "type" || prop === "schema") && rec.type && rec.schema) {
          if (prop === "type") {
            this.generateListFields(rec.schema, name)
          }
        } else {
          let newname = ""
          if (name) {
            newname = name + "." + prop
          } else {
            newname = prop
          }
          if (rec[prop].schema) {
            //this.$log("prop ", prop)
            //this.$log("prop ", prop)
            let lbl = newname
            if (rec[prop].meta) {
              lbl = rec[prop].meta.label
            }
            if (rec[prop].type === "list" && rec[prop].schema.type != "dict") {
              this.filtersFields.push({ text: lbl, value: newname, type: rec[prop].type })
              this.fieldTypes[newname] = rec[prop].schema.type
              this.fieldSchema[newname] = rec[prop].schema
            } else if (rec[prop].type === "list") {
              const flds = newname.split(".")
              if (this.viewType == "marc" && flds[0] == "fields" && flds.length == 2) {
                //this.filtersFields.push({ text: flds[1] + " - " + lbl, value: newname, type: rec[prop].type })
                //this.fieldTypes[newname] = rec[prop].type
                //this.fieldSchema[newname] = rec[prop]
              } else if (this.viewType != "marc") {
                //this.filtersFields.push({ text: lbl, value: newname, type: rec[prop].type })
                this.fieldTypes[newname] = rec[prop].type
                this.fieldSchema[newname] = rec[prop]
              }
            }
            this.generateListFields(rec[prop].schema, newname)
          } else {
            if (rec[prop].type) {
              if (
                (this.viewType == "marc" &&
                  prop != "ctg" &&
                  prop != "id" &&
                  prop != "company" &&
                  prop != "ord" &&
                  prop != "link" &&
                  prop != "src_id") ||
                this.viewType != "marc"
              ) {
                let lbl = newname
                if (rec[prop].meta) {
                  lbl = rec[prop].meta.label
                }
                if (this.viewType == "marc") {
                  //this.$log("viretype marc flds ", newname)
                  const flds = newname.split(".")
                  if (flds[2] && flds[0] == "fields" && flds[3] == "val") {
                    lbl = flds[1] + flds[2] + " - " + lbl
                  } else if (flds[0] == "fields" && (flds[2] == "ind1" || flds[2] == "ind2")) {
                    lbl = flds[1] + " - " + lbl
                  }
                } else {
                  //lbl += " (" + newname + ")"
                }
                //this.$log("filtersfields ", newname + " - " + rec[prop].type)
                this.filtersFields.push({ text: lbl, value: newname, type: rec[prop].type })
                this.fieldTypes[newname] = rec[prop].type
                this.fieldSchema[newname] = rec[prop]
                this.fieldSchema[newname].lbl = lbl
                if (rec[prop].meta) {
                  if (rec[prop].meta.showInTable) {
                    this.tmColumns.push({ text: lbl, value: newname, props: rec[prop] })
                  }
                }
                //this.$log("fieldschema1 ", newname)
                //this.$log("fieldschema2 ", lbl)
                //this.$log("fieldschema3 ", rec[prop])
              }
            }
          }
        }
      }
    },
    generateHeader() {
      //this.$log("generateHeader reload")
      this.headers = []
      if (this.tables.length > 0) {
        let i = 0
        for (const itm in this.tables[0].content) {
          if (this.tables[0].content[itm].type == "string" && this.tables[0].content[itm].required) {
            //this.$log("this.fieldTypes[itm] " + itm + " - " + this.fieldTypes[itm])
            if (i < 3 && this.fieldTypes[itm]) {
              this.filters[i].field = itm
              this.filters[i].type = this.filtersTypes[this.fieldTypes[itm]][0].value
              i++
            }
            this.filtersTMType[itm] = this.filtersTypes[this.fieldTypes[itm]][0].value
            this.filtersTM[itm] = ""
            if (itm.meta) {
              this.headers.push({
                text: itm.meta.label,
                value: itm
              })
            } else {
              this.headers.push({
                text: itm,
                value: itm
              })
            }
          }
        }
        if (this.tables[0].showAction) {
          this.headers.push({
            text: this.$t("Opțiuni"),
            value: "action",
            sortable: false,
            // align: "center",
            class: "fixed"
          })
        }
      }
    },
    resetFiltersTM(refresh = true) {
      this.headers.map(head => {
        this.$set(this.filtersTM, head.value, "")
      })
      this.render = Date.now()
      //this.$log("resetFilter changed")
      if (refresh) {
        this.getRecords()
      }
    },
    changeFilterType(e, val) {
      this.render = Date.now()
      this.$set(this.filtersTMType, val, e)
      if (this.filtersTM[val] !== "") {
        //this.$log("changeFilterType changed")
        this.getRecords()
      }
    },
    addItem() {
      this.$log("addItem2 ", this.showAdd)
      if (this.showAdd) {
        this.$log("afddddddddddddd2 ", this.category)
        if (this.editType == "auto") {
          let view = this.viewForm ? this.viewForm : this.view
          if (!view && this.category != "") {
            const schema = this.$store.getters.appViewCollection(this.tables[0].collection)
            if (schema.findIndex(itm => itm.ctg == this.category) > -1) {
              if (schema.findIndex(itm => itm.ctg == this.category && itm.default) > -1) {
                view = schema.find(itm => itm.ctg == this.category && itm.default)._id
              } else {
                view = schema.find(itm => itm.ctg == this.category)._id
              }
            } else {
              view = schema.find(itm => itm.default)._id
            }
          }
          if (this.addPopUp) {
            //this.$log("adddatata table[0] ", this.tables[0])
            this.$refs.addDataPopUp.addData(this.tables[0], view, this.recordDefault, this.category)
          } else {
            // this.editRecord = true
            // this.$refs.addData.addData(this.tables[0])
            this.$emit("addData", {
              collection: this.tables[0],
              view: view,
              recordDefault: this.recordDefault,
              ctg: this.category
            })
          }
        } else {
          this.$emit("addData")
        }
      }
    },
    previewItem(e) {
      this.$log("previewItem ", e)
      let title = ""
      if (this.collection == "nvd_cwe") {
        title = "CWE-" + e.id
      }
      if (this.collection == "nvd_cve") {
        title = e.cve.CVE_data_meta.ID
      }
      this.$refs.previewData.previewData(e._id, title)
    },
    sheetItem(e, itm) {
      this.$log(" sheetItem1 ", e)
      this.$log(" sheetItem2 ", itm)
      this.$log(" sheetItem3 ", this.tables.length)
      let id = ""
      if (itm) {
        id = itm._id
      } else {
        id = this.selectedId[0]._id
      }
      const ver = itm.ver ? "?version=" + itm.ver : ""
      let cat = ""
      if (this.categories.find(itm => itm.value == itm.ctg)) {
        cat = this.categories.find(itm => itm.value == itm.ctg).lblRo
      }
      if (this.tables.length > 0) {
        axios.get(this.collection + "/" + id + ver).then(response => {
          //this.$log("this.categories ", this.categories)
          //this.$log("this.categories ", response)
          if (this.viewType == "marc") {
            this.$refs.marcSheet.open(response.data, e, this.collection, cat, this.fieldSchema)
          } else {
            this.$refs.marcSheet.open(response.data, e, this.collection, null, this.fieldSchema)
          }
        })
      } else {
        let col = this.collection
        col = col.replace("_tm", "")
        axios.get(col + "/" + id + ver).then(response => {
          this.$refs.marcSheet.open(response.data, e, this.collection, cat, this.fieldSchema)
        })
      }
    },
    generateList(rec, name) {
      for (const prop in rec) {
        if ((prop === "type" || prop === "schema") && rec.type && rec.schema) {
          if (prop === "type") {
            this.generateList(rec.schema, name)
          }
          // this.$log("objectid0 ", rec[prop])
        } else {
          let newname = ""
          if (name) {
            newname = name + "." + prop
          } else {
            newname = prop
          }
          if (rec[prop].type == "media") {
            if (this.mediaFields) {
              this.mediaFields += ',"' + newname + '":0'
            } else {
              this.mediaFields += '"' + newname + '":0'
            }
          }
          //this.$log("objectid1 " + prop + " - " + rec[prop].type)
          //if (rec[prop].type == "objectid") {
          if (rec[prop].data_relation) {
            //this.$log("objectid1 ", newname)
            //this.$log("objectid2 ", prop)
            //this.$log("objectid3 ", name)
            //this.$log("objectid2 ", rec[prop].data_relation.embeddable)
            if (rec[prop].data_relation.embeddable) {
              if (this.embed) {
                this.embed += ',"' + newname + '":1'
              } else {
                this.embed += '"' + newname + '":1'
              }
            }
          }
          if (rec[prop].schema) {
            if (rec[prop].type === "list") {
              if (rec[prop].schema.type == "objectid") {
                if (rec[prop].schema.data_relation.embeddable) {
                  if (this.embed) {
                    this.embed += ',"' + newname + '":1'
                  } else {
                    this.embed += '"' + newname + '":1'
                  }
                }
              }
            }
            this.generateList(rec[prop].schema, newname)
          }
        }
      }
    },
    showAccounts(item) {
      this.$log("showAccounts ", item)
      this.$emit("libraryUsers", { _id: item._id, title: item["fields.200.a.val"] })
      //EventBus.$emit("inventory-managment", { _id: item._id, title: item["fields.200.a.val"] })
    },
    downloadReport(item) {
      this.$log("downloadReport ", item)
      axios
        .get("download_doc/" + item._id, {
          responseType: "blob"
        })
        .then(rap => {
          //this.$log("resdes ", rap)
          if (rap.status == 200) {
            var a = document.createElement("a")
            document.body.appendChild(a)
            a.style = "display: none"
            //var binaryData = []
            //binaryData.push(rap.data)
            var url = window.URL.createObjectURL(rap.data)
            //var url = window.URL.createObjectURL(new Blob([rap.data], { type: rap.headers["content-type"] }))
            //this.$log("resurl ", url)
            a.href = url
            a.download = item["fields.200.a.val"]
            a.click()
            document.body.removeChild(a)
          }
        })
    },
    downloadMedia(item) {
      this.$log("down ", item)
      axios.get("nvd_media/" + item._id).then(res => {
        this.$log("res ", res)
        if (res.data.file) {
          var a = document.createElement("a")
          document.body.appendChild(a)
          a.style = "display: none"
          var url = "data:" + res.data.content_type + ";base64," + res.data.file
          a.href = url
          a.download = res.data.name
          a.click()
          document.body.removeChild(a)
        } else {
          EventBus.$emit("show-alert", { message: this.$t("File not found."), timeout: 5000 })
        }
      })
    },
    inventareItem(item) {
      this.$log("inventareItem ", item)
      let title = item["fields.299.a.val"]
      if (!title) {
        title = item["fields.200.a.val"]
      }
      this.$emit("inventoryManagment", {
        _id: item._id,
        title: title,
        collection: this.collection,
        ctg: item.ctg
      })
      //EventBus.$emit("inventory-managment", { _id: item._id, title: item["fields.200.a.val"] })
    },
    editItem(item) {
      //this.$log("afdddddddddddddeee ", item)
      this.embed = ""
      this.mediaFields = ""
      this.$log("this.tables[0] ", this.tables)
      if (this.tables[0]) {
        this.generateList(this.tables[0].content, "")
      }
      //this.embed = ""
      //this.$log("this.embed ", this.embed)
      axios
        .get(
          this.collection + "/" + item._id + "?" + "embedded={" + this.embed + "}&projection={" + this.mediaFields + "}"
        )
        .then(response => {
          //this.$log("editItem records", response)
          response.data._created = undefined
          response.data._updated = undefined
          response.data._links = undefined
          //response.data._version = undefined
          //response.data._latest_version = undefined
          this.$log("editItem editType", this.editType)
          this.$log("editItem editPopUp", this.editPopUp)
          //this.$log("editItem records", response)
          if (this.editType == "auto") {
            if (this.editPopUp) {
              this.$refs.addDataPopUp.editData(this.tables[0], response.data, this.viewForm ? this.viewForm : this.view)
            } else {
              // this.editRecord = true
              this.$emit("editData", {
                collection: this.tables[0],
                record: response.data,
                view: this.viewForm ? this.viewForm : this.view
              })
              // this.$refs.addData.editData(this.tables[0], response.data)
            }
          } else {
            this.$emit("editData", response.data)
          }
        })
    },
    deleteBookInventory() {
      //this.$log("deleteBookInventory")
      this.bookInventories.map(inv => {
        this.deleteInventory(inv)
      })
      this.deleteItem(this.deleteBook, true)
      this.showDelete = false
    },
    deleteInventory(inv) {
      //this.$log("delinv ", inv)
      axios.delete("ebib_marc_inv/" + inv._id, {
        headers: {
          "If-Match": inv._etag
        }
      })
    },

    deleteItem(item, checked) {
      if (this.collection == "ebib_tm_lib_reserve") {
        axios.post("cancel_reserve/" + item.cln_barcode + "/" + item.inv_barcode).then(res => {
          this.$log("response ", res)
          if (res.data.msg) {
            EventBus.$emit("show-alert", { message: res.data.msg, more: res.data.extra, timeout: 10000 })
          }
          //this.$log("deleteitem changed")
          this.getRecords()
        })
      } else {
        if (this.collection == "ebib_marc_mon" && !checked) {
          this.$log("ebib_marc_mon delete")
          //ebib_marc_inv?projection={"_id":1}&where={"fields.400.link":"602d14c52d70022dc9cb37af"}&max_results=1000&page=1
          axios
            .get(
              'ebib_marc_inv?projection={"_id":1}&where={"fields.400.link":"' + item._id + '"}&max_results=1000&page=1'
            )
            .then(res => {
              //this.$log("inv ", res)
              this.bookInventories = res.data._items
              if (this.bookInventories.length > 0) {
                this.deleteBook = item
                this.showDelete = true
              } else {
                this.deleteItem(item, true)
              }
            })
        } else {
          axios.get(this.collection + "/" + item._id).then(res => {
            axios
              .delete(this.collection + "/" + item._id, {
                headers: {
                  "If-Match": res.data._etag
                }
              })
              .then(response => {
                if (response.status == 204) {
                  // this.records.splice(
                  //   this.records.findIndex(itm => itm._id == item._id),
                  //   1
                  // )
                  //this.$log("deleteitem2 changed")
                  this.loading = true
                  this.refreshRecords()
                }
              })
          })
        }
      }
    },
    refreshRecords: debounce(function() {
      this.getRecords()
    }, 500),
    deleteSelected() {
      //this.$log("deleteSelected ", this.selectedId)
      const dels = [...this.selectedId]
      dels.map(itm => {
        this.deleteItem(itm)
      })
      this.$log("deleteSelected refreshCounts ")
      //setTimeout(this.refreshCounts, 500)
    },
    changeHeader(ind) {
      //this.$log("changeHeader ", ind)
      //this.$log("changeHeader opac ", this.opac)
      if (ind < 0) ind = 0
      this.indTable = ind
      this.headers = []
      //this.$log("TM ", this.tableManagement[ind])
      //this.$log("TM ", this.tableManagement[ind].content[0])

      //this.$emit("viewTableChanged", this.tableManagement[ind].name)
      if (this.viewType != this.tableManagement[ind].view_type) {
        this.viewType = this.tableManagement[ind].view_type
        this.filtersFields = []
        //this.$log("this.this.this. ", this.tables[0])
        this.tmColumns = []
        this.generateListFields(this.tables[0].content, "")
        this.$emit("fieldsChanged", this.filtersFields)
        if (this.tables[0].collection == "marc_mon") {
          this.filtersFields.push({ text: "709a - Autor", value: "fields.709.a.val", type: "string" })
          this.fieldTypes["fields.709.a.val"] = "string"
          this.fieldSchema["fields.709.a.val"] = { meta: {} }
          this.fieldSchema["fields.709.a.val"].lbl = "Autor"
        }
        this.filtersValue = []
        this.filtersFields.map(itm => {
          this.filtersValue.push({ label: itm.text, value: itm.value })
        })
        //this.$log("fieldSchema ", this.fieldSchema)
        // const adic = []
        // for (const itm in this.fieldSchema) {
        //   if (this.fieldSchema[itm].lbl) {
        //     if (this.fieldSchema[itm].lbl.substr(4, 1) == " ") {
        //       const dic = {}
        //       dic[this.fieldSchema[itm].lbl.substring(7)] = this.fieldSchema[itm].lbl.substring(7)
        //       if (!adic.find(it => it[this.fieldSchema[itm].lbl.substring(7)])) {
        //         adic.push(dic)
        //       }
        //     }
        //   }
        // }
        // this.$log(JSON.stringify(adic))
      }
      // this.editPopUp = this.tableManagement[ind].content[0].editPopUp
      // this.addPopUp = this.tableManagement[ind].content[0].addPopUp
      //this.$log("catch1 ", this.category)
      //this.$log("catch2 ", this.viewType)
      //this.$log("catch3 ", this.$route)
      if (this.viewType == "marc") {
        this.showSheet = true
      } else {
        this.showSheet = false
      }
      if (this.category) {
        this.nameCSV =
          this.$route.meta.title.toLowerCase() +
          "_" +
          this.categories.filter(itm => itm.category == this.category)[0].lblRo.toLowerCase()
      } else {
        this.nameCSV = this.$route.meta.title.toLowerCase()
      }

      if (this.tableManagement[ind].content[0].showSheet != undefined) {
        this.showSheet = this.tableManagement[ind].content[0].showSheet
      }
      if (this.tableManagement[ind].content[0].multipleSelect != undefined) {
        this.multipleSelect = this.tableManagement[ind].content[0].multipleSelect
      } else {
        this.multipleSelect = true
      }
      //this.$log("multipleSelect ", this.tableManagement[ind])
      this.showAdd = this.tableManagement[ind].content[0].showAdd && !this.opac
      if (this.opac) {
        this.actions = this.tableManagement[ind].content[0].actions.filter(itm => itm != "edit" && itm != "delete")
      } else {
        this.actions = this.tableManagement[ind].content[0].actions
      }
      if (this.tableManagement[ind].content[0].viewTypes != undefined) {
        this.viewTypes = this.tableManagement[ind].content[0].viewTypes
        this.$nextTick(() => {
          this.selectedView = 0
        })
        this.selectedView = 0
      } else {
        this.viewTypes = [...this.viewTypesList]
      }
      this.filter = this.tableManagement[ind].content[0].filter
      this.viewForm = this.tableManagement[ind].content[0].view
      this.showHeader = this.tableManagement[ind].content[0].showHeader
      this.rowExpand = this.tableManagement[ind].content[0].rowExpand
      this.showSelect = this.tableManagement[ind].content[0].showSelect && !this.opac
      this.showPagination = this.tableManagement[ind].content[0].showPagination
      this.filtrable = this.tableManagement[ind].content[0].filtrable
      this.editTMInline = this.tableManagement[ind].content[0].editInline
      this.sortable = this.tableManagement[ind].content[0].sortable
      this.sortableField = this.tableManagement[ind].content[0].sortableField
      this.height = this.tableManagement[ind].content[0].height
      let i = 0
      if (!this.tables[0]) {
        this.tableManagement[ind].content[0].children.map((col, ind) => {
          if (col.typeObject == "Numeric") {
            this.fieldTypes[col.key] = "number"
          } else {
            this.fieldTypes[col.key] = "string"
          }

          this.filtersFields.push({ text: col.name, value: col.key, type: "string" })
        })
      }

      this.tableManagement[ind].content[0].children.map((col, ind) => {
        //this.$log("this.fieldTypes[col.key] ", col.key + " - " + this.fieldTypes[col.key])
        if (i < 3 && this.filtersFields.find(itm => itm.value == col.key)) {
          if (this.fieldTypes[col.key]) {
            this.filters[i].field = col.key
            this.filters[i].type = this.filtersTypes[this.fieldTypes[col.key]][0].value
          } else {
            this.filters[i].type = "="
            this.filtersFields.map(f => {
              if (col.key.indexOf(f.value + ".") == 0) {
                this.filters[i].field = f.value
                this.fieldTypes[col.key] = "objectid"
                this.filtersTMType[col.key] = "="
                this.fieldObjectid[col.key] = f.value
              }
            })
            //this.filters[i].field = col.key
          }
          i++
        }
        if (this.fieldTypes[col.key]) {
          this.filtersTMType[col.key] = this.filtersTypes[this.fieldTypes[col.key]][0].value
        } else {
          this.filtersTMType[col.key] = "="
          this.fieldTypes[col.key] = "objectid"
          this.filtersFields.map(f => {
            if (col.key.indexOf(f.value + ".") == 0) {
              //this.filters[i].field = f.value
              //this.fieldTypes[col.key] = "objectid"
              this.fieldObjectid[col.key] = f.value
            }
          })
        }
        this.filtersTM[col.key] = ""
        //this.$log("push ", col.key)
        let fld, sub
        if (this.viewType == "marc") {
          const arr = col.key.split(".")
          fld = arr[1]
          sub = arr[2]
        }

        this.headers.push({
          text: col.name,
          value: col.key,
          typeObject: col.typeObject,
          sortable: col.sortable ? col.sortable : undefined,
          filterable: col.filtrable ? col.filtrable : undefined,
          align: col.align ? col.align : undefined,
          embed: col.embed ? col.embed : undefined,
          width: col.width ? col.width : undefined,
          fld,
          sub
        })
      })

      if (this.tableManagement[ind].content[0].showAction) {
        this.headers.push({
          text: this.$t("Opțiuni"),
          value: "action",
          sortable: false,
          // align: "center",
          class: "fixed"
        })
      }
      //this.$log("this.headers ", this.headers)
    },
    getRecords() {
      //this.$log("vuetify")
      //this.$log("h1 ", this.$refs.table.$el.querySelector(".v-data-table__wrapper").clientHeight)
      //this.$log("h1.1 ", this.$refs.table.$el.querySelector(".v-data-table__wrapper").style.maxHeight)
      //this.$log("h2 ", this.theadheight)
      //this.$log("h3 ", this.$refs.table.$el.querySelector(".v-data-table-header").clientHeight)
      //this.$log("h2 ", this.noScroll)
      // axios.get("publons_publications").then(pub => {
      //   this.$log("publocations ", pub)
      // })
      if (this.noScroll) {
        let ht = this.$refs.table.$el.querySelector(".v-data-table__wrapper").style.maxHeight
        ht = ht.replace("px", "")
        const th = this.theadheight ? this.theadheight : 50
        this.$set(this.rowsPerPageItems[0], "value", Math.floor((ht - th) / 50) - 1)
        this.itemsPerPage = Math.floor((ht - th) / 50) - 1
        //this.$log("h4 ", this.itemsPerPage)
      }

      this.loading = true
      this.selectedId = []
      const col = {}
      let embed = "&embedded={"
      let where = ""
      let filters = this.filtersWhere
      let sort = "["
      //sort += '("_created",-1),'
      this.sortBy.map((s, i) => {
        //sort += (this.sortDesc[i] ? "-" : "") + s + ","
        sort += '("' + s + '",' + (this.sortDesc[i] ? "-1" : "1") + "),"
      })

      //sort=[("fields.500.subfields.a",%20-1)]
      if (sort.length > 1) {
        sort = "&sort=" + sort.substr(0, sort.length - 1)
        sort += "]"
      } else {
        if (this.sortableField) {
          sort = '&sort=[("' + this.sortableField + '",1)]'
        } else {
          sort = '&sort=[("_id",-1)]'
        }

        //sort = ""
      }
      if (this.filterGeneral) {
        if (filters != "") {
          filters += ',"$text": {"$search": "' + this.filterGeneral + '"}'
        } else {
          filters = '"$text": {"$search": "' + this.filterGeneral + '"}'
        }
      }
      this.headers.map(itm => {
        if (itm.embed) {
          let itmv = itm.value.split(".")
          itmv.pop()
          itmv = itmv.join(".")
          col[itmv] = 1
          embed += '"' + itmv + '":1,'
        } else {
          col[itm.value] = 1
        }
        if (this.filtersTM[itm.value] !== "" && this.filtersTM[itm.value] != null) {
          // this.$log(
          //   "typeeeeeeeee ",
          //   itm.value + " - " + this.filtersTypes[itm.value] + " - " + this.fieldObjectid[itm.value]
          // )
          let fd = this.fieldObjectid[itm.value]
          if (this.filtersTypes[itm.value] != undefined) {
            fd = itm.value
          }
          if (fd === undefined) {
            fd = itm.value
          }
          const flt = this.getFilter({
            value1: this.filtersTM[itm.value],
            //field: this.filtersTypes[itm.value] != undefined ? itm.value : this.fieldObjectid[itm.value],
            field: fd,
            type: this.filtersTMType[itm.value]
          })
          //this.$log("fltttt ", flt)
          if (filters != "") {
            filters += "," + flt.filter
          } else {
            filters = flt.filter
          }
        }
      })
      //this.$log("flt1 ", filters)
      //this.$log("flt2 ", this.filter)

      if (this.viewType == "marc") {
        col.ctg = 1
      }
      //this.$log("get rec sel view1 ", this.selectedView)
      //this.$log("get rec sel view2 ", this.viewTypes)
      //this.$log("get rec sel view3 ", this.viewTypes[this.selectedView])
      if (this.viewTypes[this.selectedView] == "list") {
        //this.$log("get rec list ", this.$refs["list_" + this.collection])
        if (this.$refs["list_" + this.collection]) {
          this.$refs["list_" + this.collection].fields.map(itm => {
            col[itm] = 1
          })
        } else {
          return
        }
      }
      //viewTypes[selectedView] == 'list'
      if (this.filter) {
        if (filters != "") {
          filters += "," + this.filter
        } else {
          filters = this.filter
        }
      }
      if (embed.substr(-1, 1) == ",") {
        embed = embed.substr(0, embed.length - 1)
        embed += "}"
      } else {
        embed = ""
      }
      let defWhere = ""
      if (this.where) {
        defWhere = this.where
        if (defWhere.indexOf("$datetoreturn") > -1) {
          //this.$log("indexof")
          const d = this.$store.getters.serverDate
          const dat =
            d.getFullYear() +
            "-" +
            ("0" + (d.getMonth() + 1)).slice(-2) +
            "-" +
            ("0" + d.getDate()).slice(-2) +
            " " +
            ("0" + d.getHours()).slice(-2) +
            ":" +
            ("0" + d.getMinutes()).slice(-2)
          defWhere = defWhere.replace("$datetoreturn", dat)
        }
      }
      //this.$log("this.appView ", this.appView)
      //this.$log("this.where ", defWhere)
      //this.$log("this.where date ", this.$store.getters.serverDate.getFullYear())
      //"date_to_return":{ "$lt":"$datetoreturn"}
      //this.$log("filters ", filters)
      //if (this.appView) where = '&where={"type":"' + this.appView + '"}'

      //this.$log("this.tables this.tables ", this.tables)
      let aggWhere = "&where={"
      if (!this.tables[0]) {
        aggWhere = "{"
      }
      // if (this.appType != "system" && this.tables[0]) {
      //   if (this.tables[0].owner.app_type == "system" && this.tables[0].item_url != "app_user") {
      //     where =
      //       '&where={"owner.company":"' +
      //       this.companyId +
      //       '"' +
      //       (defWhere != "" ? "," + defWhere : "") +
      //       (filters != "" ? "," + filters : "") +
      //       (this.appView != "" ? "," + '"type":"' + this.appView + '"' : "") +
      //       "}"
      //   } else if (this.tables[0].owner.company == undefined) {
      //     where =
      //       '&where={"company":"' +
      //       this.companyId +
      //       '"' +
      //       (defWhere != "" ? "," + defWhere : "") +
      //       (filters != "" ? "," + filters : "") +
      //       (this.appView != "" ? "," + '"type":"' + this.appView + '"' : "") +
      //       "}"
      //   }
      // }

      if (where == "" && defWhere != "") {
        where =
          aggWhere +
          defWhere +
          (filters != "" ? "," + filters : "") +
          (this.appView != "" ? "," + '"type":"' + this.appView + '"' : "") +
          "}"
      } else if (where == "" && filters != "") {
        where = aggWhere + filters + (this.appView != "" ? "," + '"type":"' + this.appView + '"' : "") + "}"
      } else if (where == "" && this.appView) {
        where = aggWhere + '"type":"' + this.appView + '"}'
      }
      if (!this.tables[0] && where) {
        const arr = JSON.parse(where)
        where = "&agg_where=["
        //this.$log("get records where arr ", arr)
        for (const itm in arr) {
          //this.$log("for ", typeof arr[itm])
          this.$log("for ", itm)
          this.$log("for ", arr[itm])
          if (typeof arr[itm] == "object") {
            where += '{"' + itm + '":' + JSON.stringify(arr[itm]) + "},"
          } else if (typeof arr[itm] == "number") {
            where += '{"' + itm + '":' + arr[itm] + "},"
          } else {
            where += '{"' + itm + '":"' + arr[itm] + '"},'
          }
        }
        where = where.substr(0, where.length - 1)
        where += "]"
      }
      //this.$log("get records where ", where)
      axios
        .get(
          this.collection +
            "?projection=" +
            JSON.stringify(col) +
            where +
            sort +
            "&max_results=" +
            this.itemsPerPage +
            "&page=" +
            this.page +
            embed
        )
        .then(response => {
          //this.$log("marc rec response", response)
          if (this.viewType == "marc") {
            this.records = []
            this.allrec = {}
            response.data._items.map(itm => {
              //this.$log("marc rec ", rec)
              this.records.push(this.recToTM(itm))
            })
            //this.$log("marc records ", this.records)
          } else {
            //this.records = response.data._items
            this.records = []
            response.data._items.map(itm => {
              const rec = {}
              for (const s in itm) {
                this.getpathValues(s, itm, rec)
              }
              this.records.push(rec)
            })
            //this.$log("marc records ", this.records)
          }
          //this.resrec = response.data._items
          this.totalResults = response.data._meta.total
          this.loading = false
          this.$nextTick(() => {
            this.theadheight = this.$refs.table.$el.querySelector(".v-data-table-header").clientHeight
            this.refreshCounts()
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    getpathValues(path, rec, values) {
      if (Array.isArray(rec[path])) {
        for (let i = 0; i < rec[path].length; i++) {
          if (typeof rec[path][i] == "object") {
            if (!values[path]) {
              values[path] = {}
            }
            for (const s in rec[path][i]) {
              this.getpathValues(s, rec[path][i], values[path])
            }
          } else {
            if (values[path]) {
              values[path] += "\n" + rec[path][i]
            } else {
              values[path] = rec[path][i]
            }
          }
        }
      } else if (typeof rec[path] == "object") {
        values[path] = {}
        for (const s in rec[path]) {
          this.getpathValues(s, rec[path], values[path])
        }
      } else {
        if (values[path]) {
          values[path] += "\n" + rec[path]
        } else {
          values[path] = rec[path]
        }
      }
    },
    recToTM(itm) {
      const rec = {}
      this.allrec[itm._id] = []
      rec._id = itm._id
      for (const itms in itm) {
        if (itms != "fields") {
          rec[itms] = itm[itms]
        }
      }
      for (const flds in itm.fields) {
        for (const fld in itm.fields[flds]) {
          for (const sub in itm.fields[flds][fld]) {
            if (sub.length == 1) {
              for (const subs in itm.fields[flds][fld][sub]) {
                if (rec["fields." + flds + "." + sub + ".val"]) {
                  rec["fields." + flds + "." + sub + ".val"] += " / " + itm.fields[flds][fld][sub][subs].val
                } else {
                  rec["fields." + flds + "." + sub + ".val"] = itm.fields[flds][fld][sub][subs].val
                }
                this.allrec[itm._id].push({
                  label: "fields." + flds + "." + sub + ".val",
                  value: itm.fields[flds][fld][sub][subs].val,
                  uid: this.uid++
                })
              }
            } else {
              rec["fields." + flds + "." + sub] = itm.fields[flds][fld][sub]
            }
          }
        }
      }
      return rec
    }
  }
}
</script>
<!--style>
 .v-data-table-header th {
  white-space: nowrap;
 }
 </style-->
<style>
/* th {
  vertical-align: top;
} */
.s-tm > .v-data-table__wrapper > table > thead > tr > th {
  vertical-align: top !important;
}

.theme--light.v-data-table.v-data-table--fixed-header thead th:first-child {
  border-left: thin solid rgba(0, 0, 0, 0.12) !important;
}

.theme--light.v-data-table.v-data-table--fixed-header thead th {
  border-right: thin solid rgba(0, 0, 0, 0.12) !important;
  border-top: thin solid rgba(0, 0, 0, 0.12) !important;
  padding-left: 8px;
  padding-right: 8px;
}
.customadv.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none;
  padding-top: 0px;
  padding-bottom: 0px;
}
.customadv.v-text-field > .v-input__control > .v-input__slot:after {
  border-style: none;
  padding-top: 0px;
  padding-bottom: 0px;
}
.customadv.v-text-field > .v-input__control > .v-input__slot:after input {
  border-style: none;
  padding-top: 0px;
  padding-bottom: 0px;
}
.customadv.v-text-field > .v-input__control > .v-input__slot:before input {
  border-style: none;
  padding-top: 0px;
  padding-bottom: 0px;
}

table > tbody > tr > td.fixed:nth-last-child(1),
table > thead > tr > th.fixed:nth-last-child(1) {
  position: sticky !important;
  position: -webkit-sticky !important;
  right: 0 !important;
  z-index: 1;
  /* background: #1e1e1e; */
  border-left: thin solid rgba(0, 0, 0, 0.12) !important;
  /* -webkit-box-shadow: -1px 0px 3px -1px rgba(0, 0, 0, 0.19) !important;
  -moz-box-shadow: -1px 0px 3px -1px rgba(0, 0, 0, 0.19) !important;
  box-shadow: -1px 0px 3px -1px rgba(0, 0, 0, 0.19) !important; */
}

table > thead > tr > th.fixed:nth-last-child(1) {
  z-index: 3;
}
</style>
<style scoped>
.darkb {
  background: #1e1e1e !important;
}
.whiteb {
  background: #ffffff !important;
}
/* .theme--light.v-data-table tbody tr.v-data-table__selected {
  background: #cad2ff;
} */

.tdselected td:first-child {
  border-left-style: groove;
}
td:first-child {
  border-left: thin solid rgba(0, 0, 0, 0.12);
}
td {
  border-right: thin solid rgba(0, 0, 0, 0.12) !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
  white-space: pre-line;
}

/* table {
  table-layout: fixed;
}

td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
} */

/* .dark::-webkit-scrollbar-thumb:hover {
  background: white;
} */
/* tr.v-data-table__selected {
   background: #e4e4e4 !important;
   background: #505050 !important;
} */
/* .v-data-table tr:nth-of-type(2n) {
  background-color: rgba(0, 0, 0, 0.05);
} */
</style>

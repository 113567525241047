<template>
  <v-dialog v-model="show" persistent scrollable>
    <v-card outlined>
      <v-card-title>
        {{ $t("Administrare utilizatori") }}
        <v-spacer />
        <v-icon color="red" class="mt-n2 mr-n2" large @click="show = false">mdi-close</v-icon>
      </v-card-title>
      <v-card-text class="pa-2">
        <table-management ref="table" name="tmsearch" :table="table" :where="where" :record-default="recordDefault" />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="success" @click="show = false">
          {{ $t("Închide") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import TableManagement from "../../components/containers/TableManagement"
export default {
  components: {
    TableManagement
  },
  data: () => ({
    show: false,
    recordDefault: {},
    table: "",
    where: ""
  }),
  methods: {
    open(cmp) {
      this.$log("library users ", cmp)
      if (cmp) {
        this.where = '"company":"' + cmp._id + '"'
        this.recordDefault = {
          company: cmp._id
        }
        this.table = "user"
        this.show = true
      }
    }
  }
}
</script>
<style scoped></style>

import axios from "../../plugins/axios"
import md5 from "js-md5"

const account = {
  state: {
    language: "en",
    accountId: "",
    companyId: "",
    userName: "",
    userEmail: "",
    userWebId: "",
    userId: "",
    userFavoritesMon: null,
    userFavoritesAth: null,
    userFavoritesArt: null,
    userBarcode: "",
    accountName: "",
    accountUser: "",
    accountPass: "",
    accountEmail: "",
    accountLevel: "",
    accountMenu: "",
    accountMenuList: "",
    accountMenuDefault: "",
    accountToken: "",
    sessionId: "",
    appType: "",
    debug: true,
    appTypes: null,
    accountRemember: true,
    libraryData: null,
    userFilters: null,
    userColumns: null,
    userSearchs: null
  },
  getters: {
    language: state => {
      if (localStorage.getItem("language")) {
        state.language = localStorage.getItem("language")
      }
      return state.language
    },
    accountRemember: state => {
      if (localStorage.getItem("accountRemember") !== undefined) {
        state.accountRemember = localStorage.getItem("accountRemember") === "true"
      }
      return state.accountRemember
    },
    accountUser: (state, getters) => {
      if (!state.accountUser) {
        if (getters.accountRemember) {
          state.accountUser = localStorage.getItem("accountUser")
        } else {
          state.accountUser = sessionStorage.getItem("accountUser")
        }
      }
      return state.accountUser
    },
    accountPass: (state, getters) => {
      if (!state.accountPass) {
        if (getters.accountRemember) {
          state.accountPass = localStorage.getItem("accountPass")
        } else {
          state.accountPass = sessionStorage.getItem("accountPass")
        }
      }
      return state.accountPass
    },
    userName: (state, getters) => {
      if (!state.userName) {
        if (getters.accountRemember) {
          state.userName = localStorage.getItem("userName")
        } else {
          state.userName = sessionStorage.getItem("userName")
        }
      }
      return state.userName
    },
    userEmail: (state, getters) => {
      if (!state.userEmail) {
        if (getters.accountRemember) {
          state.userEmail = localStorage.getItem("userEmail")
        } else {
          state.userEmail = sessionStorage.getItem("userEmail")
        }
      }
      return state.userEmail
    },
    userWebId: (state, getters) => {
      if (!state.userWebId) {
        if (getters.accountRemember) {
          state.userWebId = localStorage.getItem("userWebId")
        } else {
          state.userWebId = sessionStorage.getItem("userWebId")
        }
      }
      return state.userWebId
    },
    userId: (state, getters) => {
      if (!state.userId) {
        if (getters.accountRemember) {
          state.userId = localStorage.getItem("userId")
        } else {
          state.userId = sessionStorage.getItem("userId")
        }
      }
      return state.userId
    },
    userBarcode: (state, getters) => {
      if (!state.userBarcode) {
        if (getters.accountRemember) {
          state.userBarcode = localStorage.getItem("userBarcode")
        } else {
          state.userBarcode = sessionStorage.getItem("userBarcode")
        }
      }
      return state.userBarcode
    },
    userFavoritesMon: (state, getters) => {
      if (!state.userFavoritesMon) {
        if (getters.accountRemember) {
          state.userFavoritesMon = JSON.parse(localStorage.getItem("userFavoritesMon"))
        } else {
          state.userFavoritesMon = JSON.parse(sessionStorage.getItem("userFavoritesMon"))
        }
      }
      return state.userFavoritesMon
    },
    userFavoritesAth: (state, getters) => {
      if (!state.userFavoritesAth) {
        if (getters.accountRemember) {
          state.userFavoritesAth = JSON.parse(localStorage.getItem("userFavoritesAth"))
        } else {
          state.userFavoritesAth = JSON.parse(sessionStorage.getItem("userFavoritesAth"))
        }
      }
      return state.userFavoritesAth
    },
    userFavoritesArt: (state, getters) => {
      if (!state.userFavoritesArt) {
        if (getters.accountRemember) {
          state.userFavoritesArt = JSON.parse(localStorage.getItem("userFavoritesArt"))
        } else {
          state.userFavoritesArt = JSON.parse(sessionStorage.getItem("userFavoritesArt"))
        }
      }
      return state.userFavoritesArt
    },

    accountName: (state, getters) => {
      if (!state.accountName) {
        if (getters.accountRemember) {
          state.accountName = localStorage.getItem("accountName")
        } else {
          state.accountName = sessionStorage.getItem("accountName")
        }
      }
      return state.accountName
    },
    accountLevel: (state, getters) => {
      if (!state.accountLevel) {
        if (getters.accountRemember) {
          state.accountLevel = localStorage.getItem("accountLevel")
        } else {
          state.accountLevel = sessionStorage.getItem("accountLevel")
        }
      }
      return state.accountLevel
    },
    accountMenu: (state, getters) => {
      if (!state.accountMenu) {
        if (getters.accountRemember) {
          state.accountMenu = localStorage.getItem("accountMenu")
        } else {
          state.accountMenu = sessionStorage.getItem("accountMenu")
        }
      }
      return state.accountMenu
    },
    accountMenuList: (state, getters) => {
      if (!state.accountMenuList) {
        if (getters.accountRemember) {
          state.accountMenuList = JSON.parse(localStorage.getItem("accountMenuList"))
        } else {
          state.accountMenuList = JSON.parse(sessionStorage.getItem("accountMenuList"))
        }
      }
      return state.accountMenuList
    },
    accountMenuDefault: (state, getters) => {
      if (!state.accountMenuDefault) {
        if (getters.accountRemember) {
          state.accountMenuDefault = JSON.parse(localStorage.getItem("accountMenuDefault"))
        } else {
          state.accountMenuDefault = JSON.parse(sessionStorage.getItem("accountMenuDefault"))
        }
      }
      return state.accountMenuDefault
    },
    debug: (state, getters) => {
      if (!state.debug) {
        if (getters.accountRemember) {
          state.debug = localStorage.getItem("debug") == "true"
        } else {
          state.debug = sessionStorage.getItem("debug") == "true"
        }
      }
      return state.debug
    },
    accountToken: (state, getters) => {
      if (!state.accountToken) {
        if (getters.accountRemember) {
          state.accountToken = localStorage.getItem("accountToken")
        } else {
          state.accountToken = sessionStorage.getItem("accountToken")
        }
      }
      return state.accountToken
    },
    sessionId: (state, getters) => {
      if (!state.sessionId) {
        state.sessionId = localStorage.getItem("sessionId")
      }
      return state.sessionId
    },
    accountEmail: (state, getters) => {
      if (!state.accountEmail) {
        if (getters.accountRemember) {
          state.accountEmail = localStorage.getItem("accountEmail")
        } else {
          state.accountEmail = sessionStorage.getItem("accountEmail")
        }
      }
      return state.accountEmail
    },
    accountId: (state, getters) => {
      if (!state.accountId) {
        if (getters.accountRemember) {
          state.accountId = localStorage.getItem("accountId")
        } else {
          state.accountId = sessionStorage.getItem("accountId")
        }
      }
      return state.accountId
    },
    companyId: (state, getters) => {
      if (!state.companyId) {
        if (getters.accountRemember) {
          state.companyId = localStorage.getItem("companyId")
        } else {
          state.companyId = sessionStorage.getItem("companyId")
        }
      }
      return state.companyId
    },
    appType: (state, getters) => {
      if (state.appType == "" || state.appType == null) {
        if (getters.accountRemember) {
          state.appType = localStorage.getItem("appType")
        } else {
          state.appType = sessionStorage.getItem("appType")
        }
      }
      return state.appType
    },
    appTypes: (state, getters) => {
      if (!state.appTypes) {
        if (getters.accountRemember) {
          state.appTypes = JSON.parse(localStorage.getItem("appTypes"))
        } else {
          state.appTypes = JSON.parse(sessionStorage.getItem("appTypes"))
        }
      }
      return state.appTypes
    },
    libraryData: (state, getters) => {
      if (!state.libraryData) {
        if (getters.accountRemember) {
          state.libraryData = JSON.parse(localStorage.getItem("libraryData"))
        } else {
          state.libraryData = JSON.parse(sessionStorage.getItem("libraryData"))
        }
      }
      return state.libraryData
    },
    userFilters: (state, getters) => {
      if (!state.userFilters) {
        if (getters.accountRemember) {
          state.userFilters = JSON.parse(localStorage.getItem("userFilters"))
        } else {
          state.userFilters = JSON.parse(sessionStorage.getItem("userFilters"))
        }
      }
      return state.userFilters
    },
    userColumns: (state, getters) => {
      if (!state.userColumns) {
        if (getters.accountRemember) {
          state.userColumns = JSON.parse(localStorage.getItem("userColumns"))
        } else {
          state.userColumns = JSON.parse(sessionStorage.getItem("userColumns"))
        }
      }
      return state.userColumns
    },
    userSearchs: state => {
      if (!state.userSearchs) {
        state.userSearchs = JSON.parse(localStorage.getItem("userSearchs"))
      }
      return state.userSearchs
    }
  },
  mutations: {
    language(state, language) {
      state.language = language
      localStorage.setItem("language", language)
    },
    accountRemember(state, accountRemember) {
      state.accountRemember = accountRemember
      localStorage.setItem("accountRemember", accountRemember)
    },
    accountId(state, accountId) {
      state.accountId = accountId
      if (state.accountRemember) {
        localStorage.setItem("accountId", accountId)
      } else {
        sessionStorage.setItem("accountId", accountId)
      }
    },
    companyId(state, companyId) {
      state.companyId = companyId
      if (state.accountRemember) {
        localStorage.setItem("companyId", companyId)
      } else {
        sessionStorage.setItem("companyId", companyId)
      }
    },
    userName(state, userName) {
      state.userName = userName
      if (state.accountRemember) {
        localStorage.setItem("userName", userName)
      } else {
        sessionStorage.setItem("userName", userName)
      }
    },
    userEmail(state, userEmail) {
      state.userEmail = userEmail
      if (state.accountRemember) {
        localStorage.setItem("userEmail", userEmail)
      } else {
        sessionStorage.setItem("userEmail", userEmail)
      }
    },
    userWebId(state, userWebId) {
      state.userWebId = userWebId
      if (state.accountRemember) {
        localStorage.setItem("userWebId", userWebId)
      } else {
        sessionStorage.setItem("userWebId", userWebId)
      }
    },
    userId(state, userId) {
      state.userId = userId
      if (state.accountRemember) {
        localStorage.setItem("userId", userId)
      } else {
        sessionStorage.setItem("userId", userId)
      }
    },
    userBarcode(state, userBarcode) {
      state.userBarcode = userBarcode
      if (state.accountRemember) {
        localStorage.setItem("userBarcode", userBarcode)
      } else {
        sessionStorage.setItem("userBarcode", userBarcode)
      }
    },
    userFavoritesMon(state, userFavoritesMon) {
      if (!userFavoritesMon) {
        userFavoritesMon = []
      }
      state.userFavoritesMon = userFavoritesMon
      if (state.accountRemember) {
        localStorage.setItem("userFavoritesMon", JSON.stringify(userFavoritesMon))
      } else {
        sessionStorage.setItem("userFavoritesMon", JSON.stringify(userFavoritesMon))
      }
    },
    userFavoritesAth(state, userFavoritesAth) {
      if (!userFavoritesAth) {
        userFavoritesAth = []
      }
      state.userFavoritesAth = userFavoritesAth
      if (state.accountRemember) {
        localStorage.setItem("userFavoritesAth", JSON.stringify(userFavoritesAth))
      } else {
        sessionStorage.setItem("userFavoritesAth", JSON.stringify(userFavoritesAth))
      }
    },
    userFavoritesArt(state, userFavoritesArt) {
      if (!userFavoritesArt) {
        userFavoritesArt = []
      }
      state.userFavoritesArt = userFavoritesArt
      if (state.accountRemember) {
        localStorage.setItem("userFavoritesArt", JSON.stringify(userFavoritesArt))
      } else {
        sessionStorage.setItem("userFavoritesArt", JSON.stringify(userFavoritesArt))
      }
    },
    accountName(state, accountName) {
      state.accountName = accountName
      if (state.accountRemember) {
        localStorage.setItem("accountName", accountName)
      } else {
        sessionStorage.setItem("accountName", accountName)
      }
    },
    accountUser(state, accountUser) {
      state.accountUser = accountUser
      if (state.accountRemember) {
        localStorage.setItem("accountUser", accountUser)
      } else {
        sessionStorage.setItem("accountUser", accountUser)
      }
    },
    accountPass(state, accountPass) {
      state.accountPass = accountPass
      if (state.accountRemember) {
        localStorage.setItem("accountPass", accountPass)
      } else {
        sessionStorage.setItem("accountPass", accountPass)
      }
    },
    accountEmail(state, accountEmail) {
      state.accountEmail = accountEmail
      if (state.accountRemember) {
        localStorage.setItem("accountEmail", accountEmail)
      } else {
        sessionStorage.setItem("accountEmail", accountEmail)
      }
    },
    accountLevel(state, accountLevel) {
      state.accountLevel = accountLevel
      if (state.accountRemember) {
        localStorage.setItem("accountLevel", accountLevel)
      } else {
        sessionStorage.setItem("accountLevel", accountLevel)
      }
    },
    accountMenu(state, accountMenu) {
      state.accountMenu = accountMenu
      if (state.accountRemember) {
        localStorage.setItem("accountMenu", accountMenu)
      } else {
        sessionStorage.setItem("accountMenu", accountMenu)
      }
    },
    accountMenuList(state, accountMenuList) {
      state.accountMenuList = accountMenuList
      if (state.accountRemember) {
        localStorage.setItem("accountMenuList", JSON.stringify(accountMenuList))
      } else {
        sessionStorage.setItem("accountMenuList", JSON.stringify(accountMenuList))
      }
    },
    accountMenuDefault(state, accountMenuDefault) {
      state.accountMenuDefault = accountMenuDefault
      if (state.accountRemember) {
        localStorage.setItem("accountMenuDefault", JSON.stringify(accountMenuDefault))
      } else {
        sessionStorage.setItem("accountMenuDefault", JSON.stringify(accountMenuDefault))
      }
    },
    debug(state, debug) {
      state.debug = debug
      if (state.accountRemember) {
        localStorage.setItem("debug", debug)
      } else {
        sessionStorage.setItem("debug", debug)
      }
    },
    accountToken(state, accountToken) {
      state.accountToken = accountToken
      if (state.accountRemember) {
        localStorage.setItem("accountToken", accountToken)
      } else {
        sessionStorage.setItem("accountToken", accountToken)
      }
    },
    sessionId(state, sessionId) {
      state.sessionId = sessionId
      localStorage.setItem("sessionId", sessionId)
    },
    appType(state, appType) {
      //this.$log("settapptype ", appType)
      state.appType = appType
      if (state.accountRemember) {
        localStorage.setItem("appType", appType)
      } else {
        sessionStorage.setItem("appType", appType)
      }
    },
    appTypes(state, appTypes) {
      state.appTypes = appTypes
      if (state.accountRemember) {
        localStorage.setItem("appTypes", JSON.stringify(appTypes))
      } else {
        sessionStorage.setItem("appTypes", JSON.stringify(appTypes))
      }
    },
    libraryData(state, libraryData) {
      state.libraryData = libraryData
      if (state.accountRemember) {
        localStorage.setItem("libraryData", JSON.stringify(libraryData))
      } else {
        sessionStorage.setItem("libraryData", JSON.stringify(libraryData))
      }
    },
    userFilters(state, userFilters) {
      state.userFilters = userFilters
      if (state.accountRemember) {
        localStorage.setItem("userFilters", JSON.stringify(userFilters))
      } else {
        sessionStorage.setItem("userFilters", JSON.stringify(userFilters))
      }
    },
    userColumns(state, userColumns) {
      state.userColumns = userColumns
      if (state.accountRemember) {
        localStorage.setItem("userColumns", JSON.stringify(userColumns))
      } else {
        sessionStorage.setItem("userColumns", JSON.stringify(userColumns))
      }
    },
    userSearchs(state, userSearchs) {
      state.userSearchs = userSearchs
      localStorage.setItem("userSearchs", JSON.stringify(userSearchs))
    }
  },
  actions: {
    login({ getters, commit }, loginData) {
      return new Promise((resolve, reject) => {
        //console.log("login data ", loginData)
        //const login = { email: loginData.username, passw: md5(loginData.password) }
        let pass = md5(loginData.password)
        if (loginData.reload) {
          pass = getters.accountPass
        }
        axios
          .post("login_by_emailpass?email=" + loginData.username + "&passw=" + pass)
          .then(response => {
            //this.$log("log ", response)
            if (response.data.settings.user_type != "web" && response.data.settings.user_type != "api") {
              commit("accountId", response.data.oid)
              commit("companyId", response.data.company)
              commit("accountName", response.data.fullname)
              commit("accountUser", response.data.username)
              commit("accountPass", md5(loginData.password))
              commit("accountEmail", response.data.email)
              commit("libraryData", response.data.library)
              if (response.data.settings) {
                //this.$log("apptype login ", response.data.settings.app_type)
                commit("appType", response.data.settings.app_type)
                commit("appTypes", response.data.settings.app_types)
                commit("accountLevel", response.data.settings.user_type)
                commit("accountMenu", response.data.settings.menu_type)
                commit("debug", response.data.settings.debug)
                if (response.data.settings.filters) {
                  commit("userFilters", response.data.settings.filters)
                }
                if (response.data.settings.columns) {
                  commit("userColumns", response.data.settings.columns)
                }
              }
              axios.defaults.headers.common.Authorization = response.data.session_id
              commit("accountMenuList", response.data.menu)
              commit("sessionId", response.data.session_id)
              commit("accountToken", response.data.token)
              //dispatch("getLibraryData")
              commit("libraryData", response.data.library)
              //regimImprumut
              const reg = []
              response.data.library.config.library.regimes.map(itm => {
                reg.push({ name: itm, value: itm })
              })
              const sit = []
              response.data.library.config.library.sites.map(itm => {
                sit.push({ name: itm, value: itm })
              })
              const cln = []
              response.data.library.config.library.clients_types.map(itm => {
                cln.push({ name: itm, value: itm })
              })
              commit("dictionary", { name: "all_regimImprumut", values: reg })
              commit("dictionary", { name: "all_gestiune", values: sit })
              commit("dictionary", { name: "all_clnClasaCititor", values: cln })
              resolve()
            } else {
              reject(new Error("Wrong user or password"))
            }
          })
          .catch(err => {
            if (err.response) {
              reject(new Error("Wrong user or password"))
            } else {
              reject(new Error("Problemă de comunicare cu serverul"))
            }
          })
      })
    },
    // getLibraryData({ commit }) {
    //   axios.get("app_settings").then(response => {
    //     commit("libraryData", response.data._items[0])
    //   })
    // },
    getLibraryData({ getters, commit }) {
      //console.log("getLibraryData1 ", getters.companyId)
      if (getters.companyId) {
        axios.get("app_company/" + getters.companyId).then(response => {
          //console.log("getLibraryData2 ", response)
          commit("libraryData", response.data)
          //regimImprumut
          const reg = []
          response.data.config.library.regimes.map(itm => {
            reg.push({ name: itm, value: itm })
          })
          const sit = []
          response.data.config.library.sites.map(itm => {
            sit.push({ name: itm, value: itm })
          })
          const cln = []
          response.data.config.library.clients_types.map(itm => {
            cln.push({ name: itm, value: itm })
          })
          commit("dictionary", { name: "all_regimImprumut", values: reg })
          commit("dictionary", { name: "all_gestiune", values: sit })
          commit("dictionary", { name: "all_clnClasaCititor", values: cln })
        })
      }
    },
    setUserFilters({ getters }) {
      axios.get("app_user/" + getters.accountId).then(res => {
        axios.patch(
          "app_user/" + getters.accountId,
          { settings: { filters: getters.userFilters } },
          {
            headers: {
              "If-Match": res.data._etag
            }
          }
        )
      })
    },
    setUserColumns({ getters }) {
      axios.get("app_user/" + getters.accountId).then(res => {
        axios.patch(
          "app_user/" + getters.accountId,
          { settings: { columns: getters.userColumns } },
          {
            headers: {
              "If-Match": res.data._etag
            }
          }
        )
      })
    },
    setUserFavoritesMon({ getters }) {
      axios.get("app_user/" + getters.userWebId).then(res => {
        axios.patch(
          "app_user/" + getters.userWebId,
          { favorites: { mon: getters.userFavoritesMon } },
          {
            headers: {
              "If-Match": res.data._etag
            }
          }
        )
      })
    },
    setUserFavoritesAth({ getters }) {
      axios.get("app_user/" + getters.userWebId).then(res => {
        axios.patch(
          "app_user/" + getters.userWebId,
          { favorites: { ath: getters.userFavoritesAth } },
          {
            headers: {
              "If-Match": res.data._etag
            }
          }
        )
      })
    },
    setUserFavoritesArt({ getters }) {
      axios.get("app_user/" + getters.userWebId).then(res => {
        axios.patch(
          "app_user/" + getters.userWebId,
          { favorites: { art: getters.userFavoritesArt } },
          {
            headers: {
              "If-Match": res.data._etag
            }
          }
        )
      })
    },
    logOutUser({ commit }) {
      localStorage.removeItem("userName")
      sessionStorage.removeItem("userName")
      commit("userName", "")
      localStorage.removeItem("userWebId")
      sessionStorage.removeItem("userWebId")
      commit("userWebId", "")
      localStorage.removeItem("userId")
      sessionStorage.removeItem("userId")
      commit("userId", "")
      localStorage.removeItem("userBarcode")
      sessionStorage.removeItem("userBarcode")
      commit("userBarcode", "")
      localStorage.removeItem("userFavoritesMon")
      sessionStorage.removeItem("userFavoritesMon")
      commit("userFavoritesMon", [])
      localStorage.removeItem("userFavoritesAth")
      sessionStorage.removeItem("userFavoritesAth")
      commit("userFavoritesAth", [])
      localStorage.removeItem("userFavoritesArt")
      sessionStorage.removeItem("userFavoritesArt")
      commit("userFavoritesArt", [])
      localStorage.removeItem("userEmail")
      sessionStorage.removeItem("userEmail")
      commit("userEmail", "")
    },
    logOut({ commit }) {
      console.log("logOutlogOutlogOutlogOutlogOut")
      localStorage.removeItem("accountName")
      sessionStorage.removeItem("accountName")
      commit("accountName", "")
      localStorage.removeItem("accountUser")
      sessionStorage.removeItem("accountUser")
      commit("accountUser", "")
      localStorage.removeItem("accountPass")
      sessionStorage.removeItem("accountPass")
      commit("accountPass", "")
      localStorage.removeItem("accountLevel")
      sessionStorage.removeItem("accountLevel")
      commit("accountLevel", "")
      localStorage.removeItem("accountMenu")
      sessionStorage.removeItem("accountMenu")
      commit("accountMenu", "")
      localStorage.removeItem("accountMenuList")
      sessionStorage.removeItem("accountMenuList")
      commit("accountMenuList", "")
      localStorage.removeItem("accountMenuDefault")
      sessionStorage.removeItem("accountMenuDefault")
      commit("accountMenuDefault", "")
      localStorage.removeItem("debug")
      sessionStorage.removeItem("debug")
      commit("debug", "")
      localStorage.removeItem("accountEmail")
      sessionStorage.removeItem("accountEmail")
      commit("accountEmail", "")
      localStorage.removeItem("accountId")
      sessionStorage.removeItem("accountId")
      commit("accountId", "")
      localStorage.removeItem("companyId")
      sessionStorage.removeItem("companyId")
      commit("companyId", "")
      localStorage.removeItem("appType")
      sessionStorage.removeItem("appType")
      commit("appType", "")
      localStorage.removeItem("libraryData")
      sessionStorage.removeItem("libraryData")
      commit("libraryData", "")
      localStorage.removeItem("userFilters")
      sessionStorage.removeItem("userFilters")
      commit("userFilters", "")
      localStorage.removeItem("userColumns")
      sessionStorage.removeItem("userColumns")
      commit("userColumns", "")
      localStorage.removeItem("accountToken")
      sessionStorage.removeItem("accountToken")
      commit("accountToken", "")
      //this.$log("clearAccountData")
    }
  }
}
export default account

<template>
  <!-- :fullscreen="fullscreen" -->
  <v-dialog v-model="show" persistent scrollable max-width="1200px">
    <add-data
      ref="addData"
      :show="show"
      @close="show = false"
      @reload="reload"
      @fullscreenChanged="fullscreen = $event"
      @sheet="$emit('sheet', $event)"
      @inventories="$emit('inventories', $event)"
    />
  </v-dialog>
</template>
<script>
import AddData from "../settings/AddData"
export default {
  components: {
    AddData
  },
  data: () => ({
    show: false,
    fullscreen: true
  }),
  mounted() {
    this.$emit("mounted")
  },
  methods: {
    reload(e) {
      this.$log("reload adddatapopup")
      //this.show = false
      this.$emit("reload", e)
    },
    addData(response, view, recordDefault, ctg, imp) {
      this.show = true
      this.$nextTick(() => {
        this.$refs.addData.addData(response, view, recordDefault, ctg, imp)
      })
    },
    editData(response, rec, view) {
      this.$log("editData editData", view)
      this.show = true
      this.$nextTick(() => {
        this.$refs.addData.editData(response, rec, view)
      })
    }
  }
}
</script>
<style scoped></style>

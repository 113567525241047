<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field :value="value" :label="label" clearable readonly v-on="on">
        <template v-slot:append>
          <v-icon style="vertical-align: middle">mdi-calendar</v-icon>
        </template>
      </v-text-field>
    </template>
    <v-date-picker :value="value" scrollable :locale="$t('locale')" @input="dateSelected($event)" />
  </v-menu>
</template>

<script>
export default {
  props: {
    label: { type: String, default: "" },
    value: { type: String, default: "" }
  },
  data() {
    return {
      menu: false
    }
  },
  methods: {
    dateSelected(e) {
      this.$log("date ", e)
      this.$emit("input", e)
      this.menu = false
    }
  }
}
</script>
<style scopped></style>
<!--

-->

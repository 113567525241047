<template>
  <v-row :dense="children.dense">
    <draggable
      class="list-group py-0"
      tag="v-col"
      :list="recordData"
      :group="{ name: 'fields' + uid }"
      handle=".handleField"
      @start="isDrag = true"
      @end="isDrag = false"
    >
      <template v-for="(flds, ind) in recordData">
        <v-sheet
          v-if="fieldsList.fields.indexOf(flds.field) > -1"
          :key="flds.iiid"
          :class="flds.show ? 'mb-0' : 'mb-0'"
          :elevation="flds.show ? 0 : 0"
          @mouseenter="flds.show = false"
          @mouseleave="flds.show = false"
        >
          <!-- <div v-show="flds.show || isDrag"> -->
          <div
            v-show="
              children.showHeader ||
                flds.show ||
                isDrag ||
                fieldsCount[flds.field] > schemaContent[flds.field].meta.repeatable
            "
          >
            <!-- flds.focus || -->
            <!-- <v-icon style="vertical-align: middle;cursor: move" class="handleField">
              mdi-drag
            </v-icon> -->

            <span
              v-if="fieldsCount[flds.field] > schemaContent[flds.field].meta.repeatable"
              style="cursor: move;color:red"
              class="handleField"
              color="primary"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" class="d-inline-flex" v-on="on">
                    {{ schemaContent[flds.field].label }}
                    <v-text-field style="width:0px;height:0px" :rules="inputValidate({ meta: { mandatory: 1 } })" />
                  </div>
                </template>
                <span>{{ $t("Ați depășit numărul de repetări") }}</span>
              </v-tooltip>
            </span>
            <!-- <v-text-field v-show="false" :rules="inputValidate({ meta: { mandatory: 1 } })" /> -->

            <span v-else style="cursor: move" class="handleField" color="primary">
              {{ schemaContent[flds.field].label }}
            </span>
            <v-icon
              v-show="schemaContent[flds.field].meta.repeatable > 1"
              style="vertical-align: middle"
              tabindex="-1"
              @click="addField(flds.field, ind)"
            >
              mdi-plus
            </v-icon>
            <v-icon
              v-show="schemaContent[flds.field].meta.repeatable > 1 || fieldsCount[flds.field] > 1"
              style="vertical-align: middle"
              tabindex="-1"
              @click="delField(flds.field, ind)"
            >
              mdi-minus
            </v-icon>
          </div>
          <!-- <v-subheader class="display-1">{{ schemaContent[flds.field].label }}</v-subheader> -->
          <!-- <v-row v-show="!isDrag" :dense="children.dense"> -->
          <!-- {{ fieldsList.ind1[flds.field] }} -->
          <v-row v-if="fieldsList.ind1[flds.field] || fieldsList.ind2[flds.field]" :dense="children.dense">
            <v-col v-if="fieldsList.ind1[flds.field]" :md="12 / children.columns" cols="12">
              <!-- ind1{{ flds.ind1 }}-{{ schemaContent[flds.field + "ind1"] }} -->
              <v-row :dense="children.dense" class="text-no-wrap" align="center">
                <v-col v-show="children.showLabel" class="text-right grey--text" cols="4">Indicator1</v-col>
                <v-col>
                  <v-autocomplete
                    v-model="flds.ind1"
                    :label="children.showLabel ? '' : $t('Indicator 1')"
                    :dense="children.dense"
                    :hide-details="children.hideDetails"
                    item-text="name"
                    item-value="value"
                    :items="dictionary[schemaContent[flds.field + 'ind1'].meta.dictionary]"
                    @focus="flds.focus = true"
                    @blur="flds.focus = false"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col v-if="fieldsList.ind2[flds.field]" :md="12 / children.columns" cols="12">
              <!-- ind2{{ flds.ind2 }}-{{ schemaContent[flds.field + "ind2"] }} -->
              <v-row :dense="children.dense" class="text-no-wrap" align="center">
                <v-col v-show="children.showLabel" class="text-right grey--text" cols="4">Indicator2</v-col>
                <v-col>
                  <v-autocomplete
                    v-model="flds.ind2"
                    :label="children.showLabel ? '' : $t('Indicator 2')"
                    :dense="children.dense"
                    :hide-details="children.hideDetails"
                    item-text="name"
                    item-value="value"
                    :items="dictionary[schemaContent[flds.field + 'ind2'].meta.dictionary]"
                    @focus="flds.focus = true"
                    @blur="flds.focus = false"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <draggable
            v-show="!isDrag"
            class="list-group"
            tag="v-row"
            handle=".handleSubfield"
            :component-data="{ attrs: { dense: children.dense } }"
            :list="flds.subfields"
            :group="{ name: flds.field }"
          >
            <v-col
              v-for="(subfields, inds) in flds.subfields"
              :key="subfields.iiid"
              class="py-0"
              :md="12 / children.columns"
              cols="12"
            >
              <v-row
                v-if="fieldsList.subs[flds.field].indexOf(subfields.subfield) > -1"
                :dense="children.dense"
                class="text-no-wrap"
                @mouseenter="subfields.show = true"
                @mouseleave="subfields.show = false"
              >
                <v-col v-show="children.showLabel" class="text-right grey--text text-truncate handleSubfield" cols="4">
                  <v-tooltip
                    v-if="
                      subfieldsCount[flds.iiid + subfields.subfield] >
                        schemaContent[flds.field + subfields.subfield].meta.repeatable
                    "
                    v-model="subfields.show"
                    top
                  >
                    <template v-slot:activator="{ on }">
                      <span style="color:red" class="d-inline-flex" v-on="on">
                        {{ schemaContent[flds.field + subfields.subfield].label }}
                        <v-text-field style="width:0px;height:0px" :rules="inputValidate({ meta: { mandatory: 1 } })" />
                      </span>
                    </template>
                    {{ $t("Ați depășit numărul de repetări") }}
                  </v-tooltip>
                  <!-- <v-tooltip v-else v-model="subfields.show" top> -->
                  <v-tooltip v-else top>
                    <template v-slot:activator="{ on }">
                      <span v-on="on">
                        {{ schemaContent[flds.field + subfields.subfield].label }}
                      </span>
                    </template>
                    {{ schemaContent[flds.field + subfields.subfield].tooltip }}
                  </v-tooltip>
                  <v-icon
                    v-show="subfields.show && schemaContent[flds.field + subfields.subfield].meta.repeatable > 1"
                    style="vertical-align: middle"
                    class="d-float-left"
                    tabindex="-1"
                    @click="addSubfield(flds, subfields.subfield, inds)"
                  >
                    mdi-plus
                  </v-icon>
                  <v-icon
                    v-show="
                      (subfields.show && schemaContent[flds.field + subfields.subfield].meta.repeatable > 1) ||
                        (subfieldsCount[flds.iiid + subfields.subfield] > 1 &&
                          schemaContent[flds.field + subfields.subfield].meta.repeatable == 1)
                    "
                    style="vertical-align: middle"
                    class="d-float-left"
                    tabindex="-1"
                    @click="delSubfield(flds, subfields.subfield, inds)"
                  >
                    mdi-minus
                  </v-icon>
                </v-col>
                <v-col>
                  <v-text-field
                    v-if="schemaContent[flds.field + subfields.subfield].meta.component == 'TextInput'"
                    v-model="subfields.value"
                    :label="children.showLabel ? '' : schemaContent[flds.field + subfields.subfield].label"
                    :rules="inputValidate(schemaContent[flds.field + subfields.subfield])"
                    :dense="children.dense"
                    :hide-details="children.hideDetails"
                    :autofocus="children.autofocus && autoFocus && ind == 0 && inds == 0"
                    @focus="
                      flds.focus = true
                      autoFocus = false
                    "
                    @blur="flds.focus = false"
                  />
                  <v-textarea
                    v-else-if="schemaContent[flds.field + subfields.subfield].meta.component == 'TextArea'"
                    v-model="subfields.value"
                    :label="children.showLabel ? '' : schemaContent[flds.field + subfields.subfield].label"
                    :rules="inputValidate(schemaContent[flds.field + subfields.subfield])"
                    rows="3"
                    :dense="children.dense"
                    :hide-details="children.hideDetails"
                    :autofocus="children.autofocus && autoFocus && ind == 0 && inds == 0"
                    @focus="
                      flds.focus = true
                      autoFocus = false
                    "
                    @blur="flds.focus = false"
                  />
                  <v-text-field
                    v-else-if="schemaContent[flds.field + subfields.subfield].meta.component == 'NumericStepper'"
                    v-model="subfields.value"
                    :label="children.showLabel ? '' : schemaContent[flds.field + subfields.subfield].label"
                    :rules="inputValidate(schemaContent[flds.field + subfields.subfield])"
                    :dense="children.dense"
                    :hide-details="children.hideDetails"
                    type="number"
                    :autofocus="children.autofocus && autoFocus && ind == 0 && inds == 0"
                    @focus="
                      flds.focus = true
                      autoFocus = false
                    "
                    @blur="flds.focus = false"
                  />
                  <s-date-time
                    v-else-if="schemaContent[flds.field + subfields.subfield].meta.component == 'DateTime'"
                    v-model="subfields.value"
                    :label="children.showLabel ? '' : schemaContent[flds.field + subfields.subfield].label"
                    :rules="inputValidate(schemaContent[flds.field + subfields.subfield])"
                    :dense="children.dense"
                    :hide-details="children.hideDetails"
                    type="datetime"
                    :autofocus="children.autofocus && autoFocus && ind == 0 && inds == 0"
                    @focus="
                      flds.focus = true
                      autoFocus = false
                    "
                    @blur="flds.focus = false"
                  />
                  <s-date-time
                    v-else-if="schemaContent[flds.field + subfields.subfield].meta.component == 'DateField'"
                    v-model="subfields.value"
                    :label="children.showLabel ? '' : schemaContent[flds.field + subfields.subfield].label"
                    :rules="inputValidate(schemaContent[flds.field + subfields.subfield])"
                    :dense="children.dense"
                    :hide-details="children.hideDetails"
                    type="date"
                    :autofocus="children.autofocus && autoFocus && ind == 0 && inds == 0"
                    @focus="
                      flds.focus = true
                      autoFocus = false
                    "
                    @blur="flds.focus = false"
                  />
                  <v-autocomplete
                    v-else-if="schemaContent[flds.field + subfields.subfield].meta.component == 'ComboBox'"
                    v-model="subfields.value"
                    :label="children.showLabel ? '' : schemaContent[flds.field + subfields.subfield].label"
                    :rules="inputValidate(schemaContent[flds.field + subfields.subfield])"
                    :dense="children.dense"
                    :hide-details="children.hideDetails"
                    item-text="name"
                    item-value="value"
                    :items="dictionary[schemaContent[flds.field + subfields.subfield].meta.dictionary]"
                    :autofocus="children.autofocus && autoFocus && ind == 0 && inds == 0"
                    @focus="
                      flds.focus = true
                      autoFocus = false
                    "
                    @blur="flds.focus = false"
                  />
                  <v-combobox
                    v-else-if="
                      schemaContent[flds.field + subfields.subfield].meta.component == 'AutoComplete' &&
                        schemaContent[flds.field + subfields.subfield].meta.dictionary
                    "
                    v-model="subfields.value"
                    :label="children.showLabel ? '' : schemaContent[flds.field + subfields.subfield].label"
                    :rules="inputValidate(schemaContent[flds.field + subfields.subfield])"
                    :dense="children.dense"
                    :hide-details="children.hideDetails"
                    item-text="name"
                    item-value="value"
                    :return-object="false"
                    :items="dictionary[schemaContent[flds.field + subfields.subfield].meta.dictionary]"
                    :autofocus="children.autofocus && autoFocus && ind == 0 && inds == 0"
                    @focus="
                      flds.focus = true
                      autoFocus = false
                    "
                    @blur="flds.focus = false"
                  />
                  <s-autocomplete
                    v-else-if="schemaContent[flds.field + subfields.subfield].meta.component == 'AutoComplete'"
                    v-model="subfields.value"
                    :label="children.showLabel ? '' : schemaContent[flds.field + subfields.subfield].label"
                    :rules="inputValidate(schemaContent[flds.field + subfields.subfield])"
                    :dense="children.dense"
                    :hide-details="children.hideDetails"
                    :clearable="false"
                    view-type="marc"
                    :item-text="'fields.' + flds.field + '.' + subfields.subfield + '.val'"
                    :item-value="'fields.' + flds.field + '.' + subfields.subfield + '.val'"
                    :collection="'ebib_' + children.prefix"
                    :autofocus="children.autofocus && autoFocus && ind == 0 && inds == 0"
                  />
                  <s-autocomplete
                    v-else-if="schemaContent[flds.field + subfields.subfield].meta.component == 'Link'"
                    :value="subfields.value"
                    :label="children.showLabel ? '' : schemaContent[flds.field + subfields.subfield].label"
                    :rules="inputValidate(schemaContent[flds.field + subfields.subfield])"
                    :dense="children.dense"
                    :hide-details="children.hideDetails"
                    :clearable="false"
                    return-object
                    view-type="marc"
                    :where="
                      schemaContent[flds.field + subfields.subfield].meta.to_category
                        ? `&quot;ctg&quot;:&quot;` +
                          schemaContent[flds.field + subfields.subfield].meta.to_category +
                          `&quot;,`
                        : ''
                    "
                    :item-text="
                      'fields.' +
                        schemaContent[flds.field + subfields.subfield].meta.to_field +
                        '.' +
                        schemaContent[flds.field + subfields.subfield].meta.to_subfield +
                        '.val'
                    "
                    :item-value="
                      'fields.' +
                        schemaContent[flds.field + subfields.subfield].meta.to_field +
                        '.' +
                        schemaContent[flds.field + subfields.subfield].meta.to_subfield +
                        '.val'
                    "
                    :collection="'ebib_marc_' + schemaContent[flds.field + subfields.subfield].meta.to_prefix"
                    :autofocus="children.autofocus && autoFocus && ind == 0 && inds == 0"
                    type="v-autocomplete"
                    @search="searchRelation(flds, subfields.subfield)"
                    @input="setLink(flds, subfields.subfield, $event)"
                  >
                    <template v-slot:append>
                      <v-icon class="pt-1" tabindex="-1" @click.stop.prevent="searchRelation(flds, subfields.subfield)">
                        mdi-magnify
                      </v-icon>
                    </template>
                  </s-autocomplete>
                  <v-text-field
                    v-else-if="schemaContent[flds.field + subfields.subfield].meta.component == 'Linkold'"
                    v-model="subfields.value"
                    :label="children.showLabel ? '' : schemaContent[flds.field + subfields.subfield].label"
                    :rules="inputValidate(schemaContent[flds.field + subfields.subfield])"
                    :dense="children.dense"
                    :hide-details="children.hideDetails"
                    :autofocus="children.autofocus && autoFocus && ind == 0 && inds == 0"
                    @focus="
                      flds.focus = true
                      autoFocus = false
                    "
                    @blur="flds.focus = false"
                    @keydown.ctrl.f.stop.prevent="searchRelation(flds, subfields.subfield)"
                  >
                    <template v-slot:append>
                      <v-icon class="pt-1" tabindex="-1" @click="searchRelation(flds, subfields.subfield)">
                        mdi-magnify
                      </v-icon>
                    </template>
                  </v-text-field>
                  <v-checkbox
                    v-else-if="schemaContent[flds.field + subfields.subfield].meta.component == 'CheckBox'"
                    v-model="subfields.value"
                    :label="children.showLabel ? '' : schemaContent[flds.field + subfields.subfield].label"
                    :false-value="
                      schemaContent[flds.field + subfields.subfield].meta.dictionary
                        ? dictionary[schemaContent[flds.field + subfields.subfield].meta.dictionary][1].value
                        : false
                    "
                    :true-value="
                      schemaContent[flds.field + subfields.subfield].meta.dictionary
                        ? dictionary[schemaContent[flds.field + subfields.subfield].meta.dictionary][0].value
                        : true
                    "
                    class="mt-0"
                    :dense="children.dense"
                    :hide-details="children.hideDetails"
                    :autofocus="children.autofocus && autoFocus && ind == 0 && inds == 0"
                    @focus="
                      flds.focus = true
                      autoFocus = false
                    "
                    @blur="flds.focus = false"
                  />
                  <!-- <marc-image
                    v-else-if="schemaContent[flds.field + subfields.subfield].meta.component == 'Image'"
                    :id="flds.link"
                    :height="100"
                  /> -->
                  <v-img
                    v-else-if="schemaContent[flds.field + subfields.subfield].meta.component == 'Image' && flds.link"
                    :src="baseURL + 'file_stream/?f=' + flds.link"
                    contain
                    :height="100"
                    :width="250"
                  />
                  <span
                    v-else-if="schemaContent[flds.field + subfields.subfield].meta.component == 'Label'"
                    class="body-1"
                  >
                    {{ children.showLabel ? "" : schemaContent[flds.field + subfields.subfield].label + ": " }}
                    {{ subfields.value }}
                  </span>
                  <span v-else>
                    {{ children.showLabel ? "" : schemaContent[flds.field + subfields.subfield].label + ": "
                    }}{{ subfields.value }}
                  </span>
                </v-col>
              </v-row>
            </v-col>
          </draggable>
          <!-- </v-row> -->
        </v-sheet>
      </template>
    </draggable>
  </v-row>
</template>
<script>
import SDateTime from "../../components/inputs/DateTime"
import SAutocomplete from "../inputs/Autocomplete"
// import MarcImage from "./MarcImage"
import draggable from "vuedraggable"
import { EventBus } from "../../EventBus"
// import { debounce } from "../../util/helpers"
import axios from "../../plugins/axios"
export default {
  components: { SDateTime, SAutocomplete, draggable },
  filters: {
    limitLength(value, len) {
      if (!value) return ""
      value = value.toString()
      if (value.length > len) {
        return value.substring(0, len) + "..."
      }
      return value
    }
  },
  props: {
    schema: {
      required: true,
      type: Object
    },
    children: {
      required: true,
      type: Object
    },
    record: {
      required: true,
      type: Object
    },
    fields: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    render: 1,
    searchId: "",
    linkFields: null,
    linkSubfield: null,
    dictionary: {},
    idGlobal: 150,
    isDrag: false,
    autoFocus: true,
    etag: "",
    isScreamMarcView: true,
    recordData: [],
    schemaContent: {},
    fieldsCount: {},
    subfieldsCount: {},
    fieldsList: { fields: [], subs: {}, ind1: {}, ind2: {} }
  }),

  computed: {
    baseURL() {
      return axios.defaults.baseURL
    },
    companyId() {
      return this.$store.getters.companyId
    },
    appSchema() {
      return this.$store.getters.appSchema
    },
    uid() {
      return Date.now()
    }
  },
  watch: {
    record() {
      //this.$log("change recordddddddddddddddd")
      this.changeMacheta()
    }
  },
  beforeCreate() {
    //this.$log("beforecreate ", this)
  },
  mounted() {
    //this.$log("mounted ", this.fields)
  },
  created() {
    //this.$log("created recordddddddddddddddd")
    this.changeMacheta()
    EventBus.$on("search-link-selected", search => {
      if (this.searchId == search.id) {
        this.setRelation(search)
      }
    })
  },
  methods: {
    searchRelation(fields, subfield) {
      this.linkFields = fields
      this.linkSubfield = subfield
      //this.$log("srcrel ", this.schemaContent[fields.field + subfield])
      this.searchId = Date.now()
      let where = ""
      let vTable = ""
      if (this.schemaContent[fields.field + subfield].meta.to_category) {
        where = '"ctg":' + '"' + this.schemaContent[fields.field + subfield].meta.to_category + '"'
        const ctgview = this.$store.getters.appViewTableCollectionCategory(
          "marc_" + this.schemaContent[fields.field + subfield].meta.to_prefix,
          this.schemaContent[fields.field + subfield].meta.to_category
        )
        this.$log("ctgview ", ctgview)
        if (ctgview.length > 0) {
          vTable = ctgview[0]._id
        }
      }
      EventBus.$emit("search-link", {
        resource: "ebib_marc_" + this.schemaContent[fields.field + subfield].meta.to_prefix,
        field: null,
        id: this.searchId,
        config: { where: where, viewTable: vTable, ctg: this.schemaContent[fields.field + subfield].meta.to_category }
      })
      //this.$refs.searchLink.open(res, fld, this.searchId)
    },
    setRelation(search) {
      //this.$log("search selected ", search)
      //this.$log("search selected ", this.linkFields)
      if (search.value) {
        //this.linkFields.link = search.value._id
        this.$set(this.linkFields, "link", search.value._id)
        //this.render = Date.now()
        this.linkFields.subfields.map(sub => {
          if (this.schemaContent[this.linkFields.field + sub.subfield].meta.to_field) {
            const tofld = this.schemaContent[this.linkFields.field + sub.subfield].meta.to_field
            const tosub = this.schemaContent[this.linkFields.field + sub.subfield].meta.to_subfield
            //this.$log(sub.subfield + " - " + tofld + " - " + tosub)
            //this.$log("val1 ", search.value.fields)
            if (tosub) {
              if (search.value.fields[tofld]) {
                if (search.value.fields[tofld][0][tosub]) {
                  sub.value = search.value.fields[tofld][0][tosub][0].val
                }
              }
            } else {
              sub.value = this.resolvePath(tofld, search.value)
            }
          }
        })
      }
    },
    setLink(fields, subfield, e) {
      //this.$log("setlink1 ", e)
      //this.$log("setlink2 ", fields)
      if (typeof e == "object") {
        this.linkFields = fields
        this.linkSubfield = subfield
        axios
          .get("ebib_marc_" + this.schemaContent[fields.field + subfield].meta.to_prefix + "/" + e._id)
          .then(response => {
            this.$log("ressetlink ", response)
            this.setRelation({ value: response.data })
          })
      } else {
        this.$set(fields, "link", "")
        fields.subfields.map(sub => {
          if (sub.subfield == subfield) {
            sub.value = ""
          }
        })
      }
    },
    inputValidate(schema) {
      const rule = []

      if (schema.regex) {
        const reg = new RegExp(schema.regex)
        if (schema.meta.regex_info) {
          rule.push(v => !v || reg.test(v) || schema.meta.regex_info)
        } else {
          rule.push(v => !v || reg.test(v) || "Invalid")
        }
      }
      if (schema.meta.mandatory == 1) rule.push(v => !!v || this.$t("required"))
      return rule
    },
    getValueCheck(schema, value) {
      this.$log("check dictionary ", value)
      if (schema.meta.dictionary) {
        this.$log("check dictionary ", this.dictionary[schema.meta.dictionary][0].value)
        if (this.dictionary[schema.meta.dictionary][0].value == value) {
          this.$log("===")
          return true
        }
        return false
      }
      return value
    },
    setValueCheck(schema, rec, value) {
      this.$log("setValueCheck ", rec)
      if (schema.meta.dictionary) {
        //this.$log("check dictionary ", this.dictionary[schema.meta.dictionary])
        if (value) {
          this.$set(rec, "value", this.dictionary[schema.meta.dictionary][0].value)
          this.$log("setValueCheck r0 ", this.dictionary[schema.meta.dictionary][0].value)
        } else {
          this.$log("setValueCheck r1 ", this.dictionary[schema.meta.dictionary])
          this.$log("setValueCheck r2 ", this.dictionary[schema.meta.dictionary][1].value)
          this.$set(rec, "value", this.dictionary[schema.meta.dictionary][1].value)
        }
      } else {
        this.$set(rec, "value", value)
      }
    },
    // changeMachetaDebounce: debounce(function() {
    //   this.$log("debounce ")
    //   this.changeMacheta()
    // }, 100),
    addField(field, ind) {
      const rec = { field: field, iiid: ++this.idGlobal, show: false, focus: false, subfields: [] }
      this.fieldsList.subs[field].map(sub => {
        rec.subfields.push({ iiid: ++this.idGlobal, subfield: sub, value: "", show: false, focus: false })
      })
      this.$set(this.fieldsCount, field, this.fieldsCount[field] + 1)
      this.recordData.splice(ind + 1, 0, rec)
    },
    delField(field, ind) {
      if (this.recordData.filter(itm => itm.field == field).length > 1) {
        this.$set(this.fieldsCount, field, this.fieldsCount[field] - 1)
        //this.fieldsCount[field]--
        this.recordData.splice(ind, 1)
      } else {
        this.addField(field, ind)
        this.recordData.splice(ind, 1)
      }
    },
    addSubfield(flds, sub, ind) {
      this.$set(this.subfieldsCount, flds.iiid + sub, this.subfieldsCount[flds.iiid + sub] + 1)
      flds.subfields.splice(ind + 1, 0, { iiid: ++this.idGlobal, subfield: sub, value: "", show: false, focus: false })
    },
    delSubfield(flds, sub, ind) {
      if (flds.subfields.filter(itm => itm.subfield == sub).length > 1) {
        this.$set(this.subfieldsCount, flds.iiid + sub, this.subfieldsCount[flds.iiid + sub] - 1)
        flds.subfields.splice(ind, 1)
      } else {
        this.addSubfield(flds, sub, ind)
        flds.subfields.splice(ind, 1)
      }
      //subfields.splice(ind + 1, 0, { iiid: ++this.idGlobal, subfield: sub, value: "", show: false, focus: false })
    },
    generateRecord() {
      this.$log("generateRecord() marc ", this.recordData)
      const rec = []
      this.recordData.map(itm => {
        if (this.fieldsList.fields.indexOf(itm.field) > -1) {
          const fld = {}
          fld[itm.field] = {}
          if (this.fieldsList.ind1[itm.field]) {
            fld[itm.field].ind1 = itm.ind1
          }
          if (this.fieldsList.ind2[itm.field]) {
            fld[itm.field].ind2 = itm.ind2
          }
          if (itm.link) {
            fld[itm.field].link = itm.link
          }
          let bool = false
          itm.subfields.map((subs, ind) => {
            if (subs.value != null && subs.value != "") {
              bool = true
              if (!fld[itm.field][subs.subfield]) {
                fld[itm.field][subs.subfield] = []
              }
              fld[itm.field][subs.subfield].push({ val: subs.value, ord: ind })
              // const sub = {}
              // sub[subs.subfield] = subs.value
              // fld[itm.field].subfields.push(sub)
            }
          })
          if (bool) {
            rec.push(fld)
          }
        }
      })
      this.$log("recm ", rec)
      return rec
    },
    changeMacheta() {
      this.autoFocus = true
      this.generateSchemaContent(this.schema.fields.schema)
      this.recordData = []
      this.fieldsList.fields = []
      this.fieldsCount = {}
      this.subfieldsCount = {}
      //this.$log("change children ", this.children)
      //this.$log("change schema ", this.schema)
      //this.$log("change rec ", this.record)
      //this.dense=childre
      this.fields.map(itm => {
        //this.$log("arr1 ", itm)

        const arr = itm.split(".")
        //this.$log("arr2 ", arr)
        let afld = arr[1]
        if (arr[1].substr(-2, 2) == "[]") {
          afld = arr[1].substr(0, 3)
        }

        if (this.fieldsList.fields.indexOf(afld) == -1) {
          this.fieldsList.fields.push(afld)
          this.fieldsList.subs[afld] = []
        }
        //this.$log("substring -2 ", afld)
        if (arr[1].substr(-2, 2) == "[]") {
          const subs = this.schema.fields.schema[afld].schema.schema
          for (const sub in subs) {
            if (sub.length == 1) {
              this.fieldsList.subs[afld].push(sub)
            }
          }
        } else if (arr[2] == "ind1" || arr[2] == "ind2") {
          this.fieldsList[arr[2]][arr[1]] = true
        } else {
          this.fieldsList.subs[arr[1]].push(arr[2])
        }
      })
      //this.$log("change fsub ", this.fieldsList.subs)
      //if (this.record.fields) {
      //this.record.fields.map(fields => {
      for (const field in this.record.fields) {
        if (this.fieldsList.fields.indexOf(field) > -1) {
          for (const ind in this.record.fields[field]) {
            const rec = {
              field: field,
              subfields: [],
              iiid: ++this.idGlobal,
              show: false,
              focus: false,
              link: this.record.fields[field][ind].link,
              ind1: this.record.fields[field][ind].ind1,
              ind2: this.record.fields[field][ind].ind2,
              ord:
                this.record.fields[field][ind].ord != undefined
                  ? this.record.fields[field][ind].ord
                  : 1000 + this.idGlobal++
            }
            //this.record.fields[field][ind].subfields.map(subfields => {
            for (const subfield in this.record.fields[field][ind]) {
              if (subfield.length == 1 && this.schema.fields.schema[field].schema.schema[subfield]) {
                //this.$log(field + subfield + " - " + this.schema.fields.schema[field].schema.schema[subfield])
                this.record.fields[field][ind][subfield].map(subfields => {
                  if (this.subfieldsCount[rec.iiid + subfield] == undefined) {
                    this.subfieldsCount[rec.iiid + subfield] = 0
                  }
                  this.subfieldsCount[rec.iiid + subfield]++
                  rec.subfields.push({
                    subfield: subfield,
                    value: subfields.val,
                    ord: subfields.ord,
                    iiid: ++this.idGlobal,
                    show: false,
                    focus: false
                  })
                })
              }
            }
            //})
            if (this.fieldsCount[rec.field] == undefined) {
              this.fieldsCount[rec.field] = 0
            }
            this.fieldsCount[rec.field]++
            this.recordData.push(rec)
          }
        }
        if (this.schema.fields.schema[field]) {
          const unk = {}
          unk[field] = []
          for (const ind in this.record.fields[field]) {
            const sunk = {}
            for (const subfield in this.record.fields[field][ind]) {
              if (!this.schema.fields.schema[field].schema.schema[subfield]) {
                sunk[subfield] = this.record.fields[field][ind][subfield]
                this.record.fields[field][ind][subfield] = undefined
              }
            }
            if (Object.keys(sunk).length > 0) {
              unk[field].push(sunk)
            }
          }
          if (unk[field].length > 0) {
            if (!this.record.unknown_fields) {
              this.record.unknown_fields = []
            }
            this.record.unknown_fields.push(unk)
          }
        } else {
          if (!this.record.unknown_fields) {
            this.record.unknown_fields = []
          }
          this.record.unknown_fields.push(this.record.fields[field])
          this.record.fields[field] = undefined
        }
      }
      let rfld = []
      this.fields.map(itm => {
        //this.$log("und ", itm)
        const arr = itm.split(".")
        let afld = arr[1]
        if (arr[1].substr(-2, 2) == "[]") {
          afld = arr[1].substr(0, 3)
        }
        rfld = this.recordData.filter(itmf => itmf.field == afld)
        if (rfld.length < 1) {
          rfld = [
            {
              iiid: ++this.idGlobal,
              field: afld,
              show: false,
              focus: false,
              subfields: [],
              ord: 2000 + this.idGlobal++
            }
          ]
          if (this.fieldsCount[rfld[0].field] == undefined) {
            this.fieldsCount[rfld[0].field] = 0
          }
          this.fieldsCount[rfld[0].field]++
          this.recordData.push(rfld[0])
        } else {
          //this.$log("und ", afld)
          if (this.record.fields) {
            if (this.record.fields[afld]) {
              this.$set(this.record.fields, afld, undefined)
            }
          }
        }
        if (arr[1].substr(-2, 2) == "[]") {
          const subs = this.schema.fields.schema[afld].schema.schema
          for (const sub in subs) {
            if (sub.length == 1) {
              for (let i = 0; i < rfld.length; i++) {
                if (rfld[i].subfields.findIndex(itm => itm.subfield == sub) == -1) {
                  if (this.subfieldsCount[rfld[i].iiid + sub] == undefined) {
                    this.subfieldsCount[rfld[i].iiid + sub] = 0
                  }
                  this.subfieldsCount[rfld[i].iiid + sub]++
                  rfld[i].subfields.push({
                    iiid: ++this.idGlobal,
                    subfield: sub,
                    value: "",
                    show: false,
                    focus: false,
                    ord: rfld[i].subfields.length
                  })
                }
              }
            }
          }
        } else if (arr[2] == "ind1" || arr[2] == "ind2") {
          for (let i = 0; i < rfld.length; i++) {
            if (rfld[i][arr[2]] == undefined) {
              rfld[i][arr[2]] = ""
            }
          }
        } else {
          for (let i = 0; i < rfld.length; i++) {
            if (rfld[i].subfields.findIndex(itm => itm.subfield == arr[2]) == -1) {
              if (this.subfieldsCount[rfld[i].iiid + arr[2]] == undefined) {
                this.subfieldsCount[rfld[i].iiid + arr[2]] = 0
              }
              this.subfieldsCount[rfld[i].iiid + arr[2]]++
              rfld[i].subfields.push({
                iiid: ++this.idGlobal,
                subfield: arr[2],
                value: "",
                show: false,
                focus: false,
                ord: rfld[i].subfields.length
              })
            }
          }
        }
        for (let i = 0; i < rfld.length; i++) {
          rfld[i].subfields.sort((a, b) => {
            return a.ord - b.ord
          })
        }
      })
      this.recordData.sort((a, b) => {
        return a.ord - b.ord
      })

      //this.record=this.record.filter
      //this.$log("fiedls record", this.record)
      //console.table(this.recordData)
      //this.$log("change rec ", this.record)
    },
    resolvePath(path, obj) {
      return path.split(".").reduce(function(prev, curr) {
        return prev ? prev[curr] : null
      }, obj || self)
    },

    cloneKey(keys, clone) {
      return (clone[keys] = {})
    },

    generateSchemaContent(schema) {
      //this.$log("schema content")
      for (const fld in schema) {
        //this.$log("fld ", fld)
        //this.$log("fld ", schema[fld])
        this.schemaContent[fld] = { label: fld }
        if (schema[fld].meta) {
          this.schemaContent[fld].meta = schema[fld].meta
          if (schema[fld].meta.label) {
            this.schemaContent[fld].label = this.children.showField
              ? fld + " " + schema[fld].meta.label
              : schema[fld].meta.label
            this.schemaContent[fld].tooltip = fld + " " + schema[fld].meta.label
          }
        }
        if (schema[fld].schema != undefined) {
          if (schema[fld].schema.schema) {
            if (schema[fld].schema.schema.ind1) {
              this.schemaContent[fld + "ind1"] = { label: "Indicator1" }
              this.schemaContent[fld + "ind1"].meta = schema[fld].schema.schema.ind1.meta
              if (schema[fld].schema.schema.ind1.meta.dictionary) {
                this.getDictionaryValues("all." + schema[fld].schema.schema.ind1.meta.dictionary)
              }
            }
            if (schema[fld].schema.schema.ind2) {
              this.schemaContent[fld + "ind2"] = { label: "Indicator2" }
              this.schemaContent[fld + "ind2"].meta = schema[fld].schema.schema.ind2.meta
              if (schema[fld].schema.schema.ind2.meta.dictionary) {
                this.getDictionaryValues("all." + schema[fld].schema.schema.ind2.meta.dictionary)
              }
            }
            for (const sub in schema[fld].schema.schema) {
              if (sub.length == 1) {
                //this.$log("sub ", sub)
                //this.$log("sub ", schema[fld].schema[sub])
                this.schemaContent[fld + sub] = { label: fld + sub }
                this.schemaContent[fld + sub].regex = schema[fld].schema.schema[sub].schema.schema.val.regex
                if (schema[fld].schema.schema[sub].schema.schema.val.meta) {
                  this.schemaContent[fld + sub].meta = schema[fld].schema.schema[sub].schema.schema.val.meta
                  if (schema[fld].schema.schema[sub].schema.schema.val.meta.label) {
                    this.schemaContent[fld + sub].label = this.children.showSubfield
                      ? sub + " " + schema[fld].schema.schema[sub].schema.schema.val.meta.label
                      : schema[fld].schema.schema[sub].schema.schema.val.meta.label
                    this.schemaContent[fld + sub].tooltip =
                      sub + " " + schema[fld].schema.schema[sub].schema.schema.val.meta.label
                  }
                  if (schema[fld].schema.schema[sub].schema.schema.val.meta.dictionary) {
                    this.getDictionaryValues("all." + schema[fld].schema.schema[sub].schema.schema.val.meta.dictionary)
                  }
                }
              }
            }
          }
        }
      }
    },
    getdictionary(dic) {
      //this.$log("getdictionary ", dic)
      return new Promise((resolve, reject) => {
        if (this.$store.getters.dictionary("all_" + dic)) {
          //this.$log("getdictionary1 ", this.$store.getters.dictionary("all_" + dic))
          resolve(this.$store.getters.dictionary("all_" + dic))
          //this.$log("getdictionary2 ", dic)
        } else {
          axios
            .get('app_dictionary?where={"value":"' + dic + '"}')
            .then(response => {
              if (response.data._items[0]) {
                this.$store.commit("dictionary", { name: "all_" + dic, values: response.data._items[0].content })
                resolve(response.data._items[0].content)
              }
            })
            .catch(err => {
              this.$log("errrrrrr ", err)
              reject(new Error("Dictionary not found"))
            })
        }
      })
    },
    getDictionaryValues(dic) {
      //this.$log("getDictionaryValues ", dic)
      const dictionary = dic.split(".")
      if (dictionary[0] == "all") {
        //  axios.get('app_dictionary?where={"value":"' + dictionary[1] + '"}').then(response => {
        //this.$log("getDictionaryValues ", dic)
        //this.$log("getDictionaryValues ", response.data)
        //this.$log("getDictionaryValues1 ", dictionary[1])
        //this.$set(this.dictionary, dictionary[1], this.$store.getters.dictionary("all_" + dictionary[1]))
        this.getdictionary(dictionary[1]).then(res => {
          this.$set(this.dictionary, dictionary[1], res)
        })

        //if (response.data._items[0]) {
        //            this.$set(this.dictionary, dictionary[1], response.data._items[0].content)
        //        }
        //    })
      } else if (dictionary[0] == "system") {
        axios.get('app_system_dictionary?where={"value":"' + dictionary[1] + '"}').then(response => {
          this.$set(this.dictionary, dictionary[1], response.data._items[0].content)
        })
      }
    },
    showData(recordData, schema, listContent) {
      this.list = schema

      // recordData.fields.map(fields => {
      //   for (const fld in fields) {
      //     fields[fld].subfields.map(subfields => {
      //       subfields.iiid = this.idGlobal++
      //     })
      //   }
      //   fields.iiid = this.idGlobal++
      // })

      this.record = recordData
      this.$log("fld1 ", this.fieldsList)
      this.$log("fld2 ", this.record)
    },
    getRecord() {
      const rec = JSON.parse(JSON.stringify(this.record))
      rec.fields.map(fields => {
        fields.iiid = undefined
        for (const fld in fields) {
          if (fld != "iiid") {
            fields[fld].subfields.map(subfields => {
              subfields.iiid = undefined
            })
          }
        }
      })
      return rec
    }
  }
}
</script>
<style scoped></style>

<template>
  <v-data-table
    v-model="selected"
    :headers="headers"
    :items="records"
    item-key="name"
    class="elevation-1"
    :hide-default-footer="!showPagination || showMove"
    :hide-default-header="!showHeader"
    :show-select="showSelect"
    :height="height ? height : ''"
  >
    <template v-slot:top>
      {{ " " + (typeof children.name == "string" ? $t(children.name) : "") }}
      <v-btn v-if="showAdd" icon @click="addItem">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </template>
    <template v-if="showMove" v-slot:body="">
      <draggable v-model="record[children.key]" tag="tbody">
        <tr v-for="item in record[children.key]" :key="item.value">
          <td v-if="showMove">
            <v-icon small class="page__grab-icon">
              mdi-arrow-all
            </v-icon>
          </td>
          <td
            v-for="header in headers.slice(showMove ? 1 : 0, children.showAction ? -1 : headers.length)"
            :key="header.value"
          >
            {{ item[header.value] }}
          </td>
          <td v-if="children.showAction">
            <v-icon v-if="actions.indexOf('select') >= 0" color="info" small class="mr-2" @click="selectItem(item)">
              mdi-check
            </v-icon>
            <v-icon v-if="actions.indexOf('edit') >= 0" color="success" small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon v-if="actions.indexOf('delete') >= 0" small @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </td>
        </tr>
      </draggable>
    </template>
    <template v-slot:item.action="{ item }">
      <v-icon v-if="actions.indexOf('select') >= 0" color="info" small class="mr-2" @click="selectItem(item)">
        mdi-check
      </v-icon>
      <v-icon v-if="actions.indexOf('edit') >= 0" color="success" small class="mr-2" @click="editItem(item)">
        mdi-pencil
      </v-icon>
      <v-icon v-if="actions.indexOf('delete') >= 0" small @click="deleteItem(item)">
        mdi-delete
      </v-icon>
    </template>
  </v-data-table>
</template>
<script>
import Draggable from "vuedraggable"
import { EventBus } from "../../EventBus"

export default {
  components: { Draggable },
  props: {
    children: {
      required: true,
      type: Object
    },
    record: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    showAdd: true,
    showPagination: true,
    showMove: false,
    showHeader: true,
    showSelect: false,
    height: "",
    actions: ["edit", "delete"],
    show: false,
    selectedTM: 0,
    list: [],
    editRecord: {},
    editIndex: 0,
    headers: [],
    selected: [],
    records: []
  }),
  watch: {
    record() {
      this.changeHeader()
    }
  },

  mounted() {
    this.$log("table mount", this.children)
    this.$log("table mount", this.record)

    this.changeHeader()
    // this.tableChanged()
  },
  methods: {
    addItem() {
      this.$emit("addItem")
      // this.show = true
      // this.editRecord = {}
      // this.editIndex = -1
      // this.list.map(itm => (this.editRecord[itm.key] = ""))

      // this.$refs.dataView.showData(this.record, this.children)
      // this.$refs.addData.addData(this.tables[0])
    },
    editItem(itm) {
      this.$log("edit itm ", itm)
      this.$emit("editItem", itm)

      // this.show = true
      // this.editIndex = this.records.indexOf(itm)
      // this.$log("edit index ", this.editIndex)
      // this.editRecord = { ...itm }
    },
    selectItem(itm) {
      this.$log("selectItem ", itm)
      EventBus.$emit("table-form-select", itm)
      //this.records.splice(this.records.indexOf(itm), 1)
    },
    deleteItem(itm) {
      this.records.splice(this.records.indexOf(itm), 1)
    },
    saveData() {
      this.show = false
      if (this.editIndex >= 0) {
        this.records.splice(this.editIndex, 1, this.editRecord)
      } else {
        this.records.push(this.editRecord)
      }
    },
    changeHeader() {
      this.list = this.children.children
      this.showAdd = this.children.showAction
      this.actions = this.children.actions
      this.showHeader = this.children.showHeader
      this.showSelect = this.children.showSelect
      this.showPagination = this.children.showPagination
      this.showMove = this.children.showMove
      this.height = this.children.height
      this.records = this.record[this.children.key]
      this.headers = []
      if (this.showMove) {
        this.headers.push({ text: "Mută", value: "move", sortable: false, width: "1%" })
      }
      this.children.children.map(col => {
        this.headers.push({
          text: col.name,
          value: col.key,
          align: col.align ? col.align : undefined,
          width: col.width ? col.width : undefined
        })
      })
      if (this.children.showAction) {
        this.headers.push({ text: "Actions", value: "action", align: "right", sortable: false, width: "1%" })
      }
    }
  }
}
</script>

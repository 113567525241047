import { store } from "../store/store"
export default {
  install(Vue, options) {
    Vue.prototype.$log = (...text) => {
      if (store.getters.debug) {
        console.log(...text)
      }
    }
  }
}

import Vue from "vue"
import App from "./App.vue"
//import router from "./router"
import { store } from "./store/store"
import "./plugins/base"
import "./plugins/chartist"
import "./plugins/vee-validate"
import "./plugins/vue-world-map"
import Logger from "./plugins/my-logger"
import vuetify from "./plugins/vuetify"
import i18n from "./i18n"
import vueKanban from "vue-kanban"
import VueMask from "v-mask"
import VueObserveVisibility from "vue-observe-visibility"
import VueSocialSharing from "vue-social-sharing"
import VirtualCollection from "vue-virtual-collection"
import VueMeta from "vue-meta"
import axios from "./plugins/axios"
import VuePapaParse from "vue-papa-parse"

Vue.use(VuePapaParse)
Vue.use(VirtualCollection)
Vue.use(VueObserveVisibility)
Vue.use(VueSocialSharing)
Vue.use(Logger)
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})

Vue.config.productionTip = false
Vue.use(VueMask)
Vue.use(vueKanban)

fetch(process.env.BASE_URL + "config.json").then(response => {
  response.json().then(config => {
    // const config = {
    //   apiURLold: "http://vue.scream.ro:7780/",
    //   apiURL: "https://devapi.ebibliophil.ro/ils/",
    //   type: "admin",
    //   cats: [
    //     {
    //       id: "606c4d6e2c2ee87984ad1289",
    //       link: "ebib5",
    //       apiKey: "4e43ffb0-96d0-11eb-9bfe-5fe82a3f51d1"
    //     },
    //     {
    //       id: "5fdb7c9842ecb05e60b4cbd2",
    //       link: "pdtst",
    //       apiKey: "078b3010-977b-11eb-9bfe-5fe82a3f51d1"
    //     },
    //     {
    //       id: "5fd1f6ee85a8ca85f0e05090",
    //       link: "hdlde",
    //       apiKey: "f41c7768-8e02-11eb-9bfe-5fe82a3f51d1"
    //     },
    //     {
    //       id: "5fd8ad18f7b159ef7ad4dcbf",
    //       link: "cjbmt",
    //       apiKey: "df2fb4b4-8e02-11eb-9bfe-5fe82a3f51d1"
    //     },
    //     {
    //       id: "5ff2e882a201cec82048dfc8",
    //       link: "mmtst",
    //       apiKey: "bf92ee32-8e02-11eb-9bfe-5fe82a3f51d1"
    //     },
    //     {
    //       id: "6023d2e38e70e4126af7e861",
    //       link: "bibcr",
    //       apiKey: "b931b944-8d56-11eb-9bfe-5fe82a3f51d1"
    //     }
    //   ]
    // }

    //console.log("config.json ", config)
    Vue.prototype.$config = config
    axios.defaults.baseURL = config.apiURL
    if (config.language && !localStorage.getItem("language")) {
      store.commit("language", config.language)
    }
    var router
    if (config.type == "catalog") {
      //console.log("main url ", window.location.pathname)
      let token = ""
      if (Vue.prototype.$config.cat == "nvd") {
        token = "0468bc9b91e82e3fc5d1d43ce9830c65"
        Vue.prototype.$config.baseURL = "/"
        Vue.prototype.$config.primaryColor = "#3F51B5"
        Vue.prototype.$config.primaryColorDark = "#3F51B5"
        Vue.prototype.$config.color = "indigo"
        Vue.prototype.$config.colorText = "indigo--text"
        store.commit("primaryColorDark", "#3F51B5")
        store.commit("primaryColor", "#3F51B5")
      } else if (Vue.prototype.$config.cat == "infuni") {
        token = "186e30d5c0c1fb22ef22bd154eec8147"
        Vue.prototype.$config.baseURL = "/"
        Vue.prototype.$config.primaryColor = "#3F51B5"
        Vue.prototype.$config.primaryColorDark = "#3F51B5"
        Vue.prototype.$config.color = "indigo"
        Vue.prototype.$config.colorText = "indigo--text"
        store.commit("primaryColorDark", "#3F51B5")
        store.commit("primaryColor", "#3F51B5")
      } else {
        const cat = config.cats.find(cat => cat.link == window.location.pathname.split("/")[1])
        //console.log("vuetify ", vuetify)
        if (cat) {
          //console.log("main find ")
          Vue.prototype.$config.baseURL = "/" + window.location.pathname.split("/")[1]
          Vue.prototype.$config.primaryColor = "#3F51B5"
          Vue.prototype.$config.primaryColorDark = "#3F51B5"
          Vue.prototype.$config.color = "indigo"
          Vue.prototype.$config.colorText = "indigo--text"
          token = cat.token
          //axios.defaults.headers.common.Authorization = cat.apiKey
          store.commit("primaryColorDark", "#3F51B5")
          store.commit("primaryColor", "#3F51B5")
        } else {
          Vue.prototype.$config.baseURL = "/"
          Vue.prototype.$config.primaryColor = "#E65100"
          Vue.prototype.$config.primaryColorDark = "#E65100"
          Vue.prototype.$config.color = "orange darken-4"
          Vue.prototype.$config.colorText = "orange--text text--darken-4"
          token = config.cats[0].token
          //axios.defaults.headers.common.Authorization = config.cats[0].apiKey
          store.commit("primaryColorDark", "#E65100")
          store.commit("primaryColor", "#E65100")
        }
      }
      axios.get("login_by_usertoken/" + token).then(auth => {
        console.log("auth ", auth)
        store.commit("libraryData", auth.data.library)
        axios.defaults.headers.common.Authorization = auth.data.session_id
        store.commit("sessionId", auth.data.session_id)
        store.commit("companyId", auth.data.company)
        const context = require("./router.js")
        router = context.default
        if (Vue.prototype.$config.cat == "nvd") {
          store.commit("appType", "nvd")
        } else if (Vue.prototype.$config.cat == "infuni") {
          store.commit("appType", "infuni")
        } else {
          store.commit("appType", "ebib")
        }
        new Vue({
          router,
          store,
          vuetify,
          i18n,
          render: h => h(App)
        }).$mount("#app")
      })
    } else {
      const context = require("./router.js")
      router = context.default
      new Vue({
        router,
        store,
        vuetify,
        i18n,
        render: h => h(App)
      }).$mount("#app")
    }
  })
})

<template>
  <div>
    <v-text-field
      :value="value"
      :dense="dense"
      :label="label"
      :hide-details="hideDetails"
      clearable
      readonly
      @click="$refs.file.click()"
      @click:clear="
        file = null
        $emit('input', { file: null, file_name: '' })
      "
    >
      <template #prepend>
        <v-icon @click="$refs.file.click()">
          mdi-paperclip
        </v-icon>
      </template>
    </v-text-field>
    <div v-show="false">
      <input ref="file" type="file" :accept="accept" @change="fileChanged" />
    </div>
  </div>
</template>
<script>
import axios from "../../plugins/axios"
export default {
  name: "AddFile",
  components: {},
  props: {
    value: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    accept: {
      type: String,
      default: ""
    },
    category: {
      type: String,
      default: ""
    },
    collection: {
      type: String,
      default: "ebib_media"
    },
    height: {
      type: Number,
      default: 50
    },
    width: {
      type: Number,
      default: 100
    },
    dense: { type: Boolean, default: true },
    hideDetails: { type: [Boolean, String], default: true }
  },
  data: () => ({ file: null }),
  computed: {
    companyId() {
      return this.$store.getters.companyId
    }
  },
  watch: {},
  created() {},
  methods: {
    fileChanged(e) {
      this.$log("fileChanged ", e)
      const formData = new FormData()
      formData.append("company", this.companyId)
      formData.append("content_type", e.target.files[0].type)
      if (this.category) {
        formData.append("ctg", this.category)
      }
      formData.append("name", e.target.files[0].name)
      formData.append("file_name", e.target.files[0].name)
      formData.append("file", e.target.files[0])
      axios
        .post(this.collection + "/", formData, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          }
        })
        .then(res => {
          this.$log("response media ", res)
          //if (res.status == 201) {
          this.$emit("input", res.data)
          //}
        })
        .catch(error => {
          this.$log("error ", error)
        })
    }
  }
}
</script>
<style scoped></style>

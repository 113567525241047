<template>
  <div style="position: relative;min-height:120px">
    <v-img
      v-if="value[0].link"
      :src="baseURL + 'file_stream/?f=' + value[0].link"
      :height="height"
      :width="width"
      contain
    />
    <v-icon v-else size="120">
      mdi-image
    </v-icon>
    <v-btn
      style="bottom:10px;right:0px;"
      color="primary"
      absolute
      right
      bottom
      class="mr-0 px-2 py-0"
      elevation="2"
      fab
      x-small
      @click="$refs.manageImages.show = true"
    >
      <v-icon>mdi-pencil</v-icon>
    </v-btn>
    <manage-images ref="manageImages" :value="value" :height="height" :width="width" @input="$emit('input', $event)" />
  </div>
</template>
<script>
import axios from "../../plugins/axios"
import ManageImages from "./ManageImages"
export default {
  name: "AddImage",
  components: { ManageImages },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    height: {
      type: Number,
      default: 50
    },
    width: {
      type: Number,
      default: 100
    }
  },
  data: () => ({ src: "" }),
  computed: {
    baseURL() {
      return axios.defaults.baseURL
    },
    companyId() {
      return this.$store.getters.companyId
    }
  },
  methods: {}
}
</script>
<style scoped></style>

<template>
  <v-list>
    <v-list-item-group v-model="selected" multiple @change="selectedChanged">
      <template v-for="(rec, index) in records">
        <v-hover :key="rec._id" v-slot:default="{ hover }">
          <v-list-item class="pl-0 pr-2">
            <div style="height:85px" />
            <v-list-item-content>
              <v-list-item-title>
                {{ rec["fields.100.a.val"] }}
                {{ rec["fields.200.a.val"] ? " - " + rec["fields.200.a.val"] : "" }}
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon color="primary" v-on="on">
                      mdi-book
                    </v-icon>
                  </template>
                  <span>{{ rec["fields.400.a.val"] }}</span>
                </v-tooltip>
                <!-- {{ rec["fields.400.a.val"] ? " - " + rec["fields.400.a.val"] : "" }} -->
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ rec["fields.500.a.val"] }}
                {{ rec["fields.505.a.val"] ? "; " + rec["fields.505.a.val"] : "" }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>{{ rec["fields.300.a.val"] }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-list-item-action-text>
                {{ rec["fields.210.a.val"] }}
              </v-list-item-action-text>
              <v-list-item-action-text>
                <span v-if="rec['fields.520.a.val']">
                  {{ rec["fields.520.a.val"] }}
                  {{ rec["fields.520.c.val"] ? " " + rec["fields.520.c.val"] : "" }}
                  <v-icon>mdi-currency-usd</v-icon>
                </span>
              </v-list-item-action-text>
              <v-list-item-action-text>
                <span v-if="rec['fields.420.a.val']">
                  {{ rec["fields.420.a.val"] }}
                  <v-icon>mdi-table-arrow-left</v-icon>
                </span>
                <span v-if="rec['fields.421.a.val']">
                  {{ rec["fields.421.a.val"] }}
                  <v-icon>mdi-table-arrow-right</v-icon>
                </span>
              </v-list-item-action-text>
            </v-list-item-action>
            <v-list-item-action class="ml-4">
              <v-list-item-action-text>
                <v-btn v-if="showSelectItem && hover" color="info" icon x-small @click.stop="$emit('select-item', rec)">
                  <v-icon>
                    mdi-check
                  </v-icon>
                </v-btn>
                <v-btn
                  v-if="actions.indexOf('edit') >= 0 && hover"
                  v-show="!showSelectItem"
                  color="success"
                  icon
                  x-small
                  @click.stop="$emit('edit', rec)"
                >
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </v-list-item-action-text>
              <v-list-item-action-text>
                <v-btn
                  v-if="actions.indexOf('edit') >= 0 && hover"
                  v-show="showSelectItem"
                  color="success"
                  icon
                  x-small
                  @click.stop="$emit('edit', rec)"
                >
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>
                <v-btn v-if="debug && hover" color="blue-grey" icon x-small @click.stop="$emit('sheet-json', rec)">
                  <v-icon>
                    mdi-code-json
                  </v-icon>
                </v-btn>
                <v-btn
                  v-if="hover"
                  v-show="!showSelectItem"
                  color="warning"
                  icon
                  x-small
                  @click.stop="$emit('sheet-item', rec)"
                >
                  <v-icon>mdi-format-list-bulleted</v-icon>
                </v-btn>
              </v-list-item-action-text>
              <v-list-item-action-text>
                <v-edit-dialog
                  large
                  :save-text="$t('Șterge')"
                  :cancel-text="$t('Anulează')"
                  @save="$emit('delete', rec)"
                >
                  <v-btn v-if="actions.indexOf('delete') >= 0 && hover" color="error" icon x-small>
                    <v-icon>
                      mdi-delete
                    </v-icon>
                  </v-btn>
                  <template v-slot:input>
                    <v-sheet>
                      <v-card-title>
                        {{ $t("Sigur doriți să ștergeți?") }}
                      </v-card-title>
                    </v-sheet>
                  </template>
                </v-edit-dialog>
              </v-list-item-action-text>
            </v-list-item-action>
          </v-list-item>
        </v-hover>
        <v-divider v-if="index < records.length - 1" :key="index"> </v-divider>
      </template>
    </v-list-item-group>
  </v-list>
</template>
<script>
export default {
  filters: {
    limitData(value) {
      if (!value) return ""
      value = value.toString()
      if (value.length > 10) {
        return value.substring(0, 10)
      }
      return value
    }
  },
  props: {
    value: {
      default: () => [],
      type: Array
    },
    baseurl: {
      default: "",
      type: String
    },
    showSelectItem: {
      default: false,
      type: Boolean
    },
    records: {
      default: () => [],
      type: Array
    },
    actions: {
      default: () => [],
      type: Array
    },
    debug: {
      default: false,
      type: Boolean
    }
  },
  data: () => ({
    clicks: 0,
    lastSelected: null,
    selected: [],
    fields: [
      "fields.200.a.val",
      "fields.100.a.val",
      "fields.400.a.val",
      "fields.500.a.val",
      "fields.505.a.val",
      "fields.520.a.val",
      "fields.520.c.val",
      "fields.420.a.val",
      "fields.421.a.val",
      "fields.210.a.val",
      "fields.300.a.val"
    ]
  }),
  watch: {
    selected(newv, oldv) {
      if (newv.length != oldv.length) {
        this.$emit("select", this.selected)
      }
    },
    value(newv, oldv) {
      if (oldv != newv) {
        this.selected = []
        this.value.map(itm => this.selected.push(this.records.indexOf(itm)))
      }
    }
  },
  mounted() {
    this.$emit("mounted")
  },
  methods: {
    selectedChanged(e) {
      //this.$log("selectedChanged ", e)
      if (this.clicks == 0) {
        this.clicks++
        setTimeout(
          e => {
            if (this.clicks > 1) {
              if (e.length > this.selected.length) {
                this.$emit("double-click", e[e.length - 1])
              } else {
                this.$emit("double-click", this.lastSelected[this.lastSelected.length - 1])
              }
            }
            this.clicks = 0
          },
          250,
          e
        )
      } else {
        this.clicks++
      }
      if (e.length > 0) {
        this.lastSelected = e
      }
    }
  }
}
</script>
<style scoped></style>

<template>
  <v-row :dense="dense" class="text-no-wrap" align="start">
    <v-col v-for="(fields, ind) in record.fields" :key="ind" cols="12">
      <template v-for="(field, fld) in fields">
        <v-sheet v-if="fld != 'iiid'" :key="fld" elevation="0">
          <!-- <v-subheader class="display-1">{{ schemaContent[fld].label }}</v-subheader> -->
          <v-row :dense="dense">
            <v-col v-for="(subfields, i) in field.subfields" :key="i" cols="12" md="12">
              <template v-for="(sub, s) in subfields">
                <v-row v-if="s != 'iiid'" :key="s" :dense="dense" class="text-no-wrap" align="center">
                  <v-col class="text-right grey--text" cols="4">
                    {{ $t(schemaContent[fld + s].label) }}
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-if="schemaContent[fld + s].meta.component == 'TextInput'"
                      v-model="subfields[s]"
                      :dense="dense"
                      :hide-details="hided"
                    />
                    <v-textarea
                      v-else-if="schemaContent[fld + s].meta.component == 'TextArea'"
                      v-model="subfields[s]"
                      rows="3"
                      :dense="dense"
                      :hide-details="hided"
                    />
                    <v-text-field
                      v-else-if="schemaContent[fld + s].meta.component == 'NumericStepper'"
                      v-model="subfields[s]"
                      :dense="dense"
                      :hide-details="hided"
                      type="number"
                    />
                    <s-date-time
                      v-else-if="schemaContent[fld + s].meta.component == 'DateTime'"
                      v-model="subfields[s]"
                      :dense="dense"
                      :hide-details="hided"
                      type="datetime"
                    />
                    <s-date-time
                      v-else-if="schemaContent[fld + s].meta.component == 'DateField'"
                      v-model="subfields[s]"
                      :dense="dense"
                      :hide-details="hided"
                      type="datefield"
                    />
                    <v-autocomplete
                      v-else-if="schemaContent[fld + s].meta.component == 'ComboBox'"
                      v-model="subfields[s]"
                      :dense="dense"
                      :hide-details="hided"
                      item-text="name"
                      item-value="value"
                      :items="dictionary[schemaContent[fld + s].meta.dictionary]"
                    />
                    <v-text-field
                      v-else-if="schemaContent[fld + s].meta.component == 'AutoComplete'"
                      v-model="subfields[s]"
                      :dense="dense"
                      :hide-details="hided"
                    />
                    <v-checkbox
                      v-else-if="schemaContent[fld + s].meta.component == 'CheckBox'"
                      v-model="subfields[s]"
                      :dense="dense"
                      :hide-details="hided"
                    />
                    <span v-else-if="schemaContent[fld + s].meta.component == 'Label'">{{ subfields[s] }}</span>
                    <span v-else>{{ subfields[s] }}</span>
                  </v-col>
                </v-row>
              </template>
            </v-col>
          </v-row>
        </v-sheet>
      </template>
    </v-col>
  </v-row>
</template>
<script>
import SDateTime from "../../components/inputs/DateTime"
import axios from "../../plugins/axios"
export default {
  components: { SDateTime },
  data: () => ({
    dense: true,
    hided: true,
    dictionary: {},
    idGlobal: 150,
    etag: "",
    record: {},
    schemaContent: {}
  }),
  computed: {
    companyId() {
      return this.$store.getters.companyId
    }
  },
  methods: {
    resolvePath(path, obj) {
      return path.split(".").reduce(function(prev, curr) {
        return prev ? prev[curr] : null
      }, obj || self)
    },

    cloneKey(keys, clone) {
      return (clone[keys] = {})
    },

    generateSchemaContent(schema) {
      //this.$log("schema content")
      for (const fld in schema) {
        //this.$log("fld ", fld)
        //this.$log("fld ", schema[fld])
        this.schemaContent[fld] = { label: fld }
        if (schema[fld].meta) {
          this.schemaContent[fld].meta = schema[fld].meta
          if (schema[fld].meta.label) {
            this.schemaContent[fld].label = fld + " " + schema[fld].meta.label
          }
        }
        for (const sub in schema[fld].schema.subfields.schema.schema) {
          //this.$log("sub ", sub)
          //this.$log("sub ", schema[fld].schema.subfields.schema.schema[sub])
          this.schemaContent[fld + sub] = { label: fld + sub }
          if (schema[fld].schema.subfields.schema.schema[sub].meta) {
            this.schemaContent[fld + sub].meta = schema[fld].schema.subfields.schema.schema[sub].meta
            if (schema[fld].schema.subfields.schema.schema[sub].meta.label) {
              this.schemaContent[fld + sub].label =
                schema[fld].schema.subfields.schema.schema[sub].meta.label + " (" + fld + sub + ")"
            }
            if (schema[fld].schema.subfields.schema.schema[sub].meta.dictionary) {
              this.getDictionaryValues("all." + schema[fld].schema.subfields.schema.schema[sub].meta.dictionary)
            }
          }
        }
      }
    },
    // getdictionary(dic) {
    //   return this.$store.getters.dictionary(dic)
    // },
    getdictionary(dic) {
      return new Promise((resolve, reject) => {
        if (this.$store.getters.dictionary("all_" + dic)) {
          resolve(this.$store.getters.dictionary("all_" + dic))
        } else {
          axios
            .get('app_dictionary?where={"value":"' + dic + '"}')
            .then(response => {
              if (response.data._items[0]) {
                this.$store.commit("dictionary", { name: "all_" + dic, values: response.data._items[0].content })
                resolve(response.data._items[0].content)
              }
            })
            .catch(err => {
              this.$log("errrrrrr ", err)
              reject(new Error("Dictionary not found"))
            })
        }
      })
    },

    getDictionaryValues(dic) {
      const dictionary = dic.split(".")
      if (dictionary[0] == "all") {
        //axios.get('app_dictionary?where={"value":"' + dictionary[1] + '"}').then(response => {
        this.getdictionary(dictionary[1]).then(res => {
          this.$set(this.dictionary, dictionary[1], res)
        })
        // const response = this.getdictionary("all_" + dictionary[1])
        // this.$log("getDictionaryValues ", dic)
        // this.$log("getDictionaryValues ", response)
        // if (response) {
        //   this.$set(this.dictionary, dictionary[1], response)
        // }
        //})
      } else if (dictionary[0] == "system") {
        axios.get('app_system_dictionary?where={"value":"' + dictionary[1] + '"}').then(response => {
          this.$set(this.dictionary, dictionary[1], response.data._items[0].content)
        })
      }
    },
    // copyData(fields) {
    //this.$set(this.record, "_id", undefined)
    //   fields.map(fld => {
    //     this.$set(this.record.fields, fld, JSON.parse(JSON.stringify(this.recordView.fields[fld])))
    //   })
    // },
    showData(recordData, schema, listContent) {
      this.list = schema
      this.generateSchemaContent(listContent.fields.schema.schema)
      if (recordData.fields) {
        recordData.fields.map(fields => {
          for (const fld in fields) {
            fields[fld].subfields.map(subfields => {
              subfields.iiid = this.idGlobal++
            })
          }
          fields.iiid = this.idGlobal++
        })
      }

      this.record = recordData
      this.$log("fld ", this.record)
    },
    getRecord() {
      const rec = JSON.parse(JSON.stringify(this.record))
      rec.fields.map(fields => {
        fields.iiid = undefined
        for (const fld in fields) {
          if (fld != "iiid") {
            fields[fld].subfields.map(subfields => {
              subfields.iiid = undefined
            })
          }
        }
      })
      return rec
    }
  }
}
</script>
<style scoped></style>

<template>
  <v-dialog v-model="show" persistent scrollable>
    <v-card outlined>
      <v-card-title class="pl-4 pr-2 pt-2">
        {{ title }}
        <v-spacer />
        <v-icon
          color="red"
          large
          @click="
            show = false
            compare = false
          "
        >
          mdi-close
        </v-icon>
      </v-card-title>
      <v-card-text class="px-4 py-0">
        <v-data-table
          v-if="type == 'marc'"
          ref="table"
          :height="height"
          dense
          fixed-header
          :server-items-length="2000"
          :headers="headers"
          :items="records"
          hide-default-footer
        >
          <template #item.sub="props">
            <v-tooltip v-if="props.item.sub" top>
              <template #activator="{ on }">
                <div v-on="on">
                  <v-icon color="info" class="mr-2">mdi-help-circle-outline</v-icon>
                  <span> {{ props.item.sub }}</span>
                </div>
              </template>
              <span v-if="fieldSchema['fields.' + props.item.fld + '.' + props.item.sub + '.val']">{{
                fieldSchema["fields." + props.item.fld + "." + props.item.sub + ".val"].lbl
              }}</span>
            </v-tooltip>
          </template>
          <template v-if="compare" #item.val="props">
            <span v-if="props.item.val && !props.item.val2" class="red--text"> {{ props.item.val }} </span>
            <!-- <span v-else-if="props.item.val && props.item.val != props.item.val2" class="blue--text">
              {{ props.item.val }}
            </span> -->
            <span v-else> {{ props.item.val }} </span>
          </template>
          <template v-if="compare" #item.val2="props">
            <span v-if="props.item.val2 && !props.item.val" class="green--text"> {{ props.item.val2 }} </span>
            <span v-else-if="props.item.val2 && props.item.val != props.item.val2" class="orange--text">
              {{ props.item.val2 }}
            </span>
            <span v-else> {{ props.item.val2 }} </span>
          </template>
          <!-- <template v-if="!compare" v-slot:item.val="props">
            <v-hover v-slot:default="{ hover }">
              <div style="min-height:20px" class="d-flex">
                <v-edit-dialog
                  :return-value.sync="props.item.val"
                  large
                  :save-text="$t('Salvează')"
                  :cancel-text="$t('Anulează')"
                >
                  <span>{{ props.item.val }}</span>
                  <template v-slot:input>
                    <v-text-field
                      v-model="props.item.val"
                      style="width:250px"
                      class="mt-3"
                      hide-details
                      autofocus
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
                <v-spacer />
                <v-btn v-if="hover" text icon x-small @click="deleteRow(props)">
                  <v-icon color="error">mdi-delete</v-icon>
                </v-btn>
              </div>
            </v-hover>
          </template> -->
        </v-data-table>
        <!-- <pre v-else ref="pre" class="text-body-1">{{ resource }}</pre> -->
        <vue-json-pretty
          v-else-if="jsonType == 'view'"
          v-model="selectedJson"
          :data="resource"
          show-line
          show-length
          selectable-type="single"
          highlight-selected-node
        >
        </vue-json-pretty>
        <v-textarea v-else ref="pre" v-model="resourcestr" style="font-family:'Courier New'" auto-grow />
      </v-card-text>
      <v-card-actions>
        <v-btn v-show="type == 'json'" outlined @click="copyText">
          {{ $t("Copiază") }}
        </v-btn>
        <v-icon color="info" class="ml-3">mdi-identifier</v-icon>
        <span>
          {{ resource._id }}
        </span>
        <v-btn icon @click="copyTextToClipboard(resource._id)">
          <v-icon color="primary">mdi-content-copy</v-icon>
        </v-btn>
        <v-icon color="info" class="ml-3">mdi-tag</v-icon>
        <span v-if="debug">
          {{ resource._etag }}
        </span>
        <v-btn v-if="debug" icon @click="copyTextToClipboard(resource._etag)">
          <v-icon color="primary">mdi-content-copy</v-icon>
        </v-btn>
        <v-btn-toggle v-if="type == 'json'" dense>
          <v-btn small text @click="jsonType = 'view'"><v-icon>mdi-code-json</v-icon></v-btn>
          <v-btn small text @click="jsonType = 'edit'"><v-icon>mdi-pencil</v-icon></v-btn>
        </v-btn-toggle>
        <v-spacer />
        <div style="width:55px" class="ml-3">
          <v-select
            v-if="resource._latest_version > 1"
            v-model="selectedVersion"
            :items="versions"
            @change="versionChanged"
          />
        </div>
        <v-checkbox
          v-show="resource._latest_version > 1 && type == 'marc'"
          v-model="compare"
          @change="compareChanged"
        />
        <div v-if="compare && type == 'marc'" style="width:55px" class="ml-3">
          <v-select
            v-if="resource._latest_version > 1"
            v-model="selectedVersionCompare"
            :items="versions"
            @change="versionChangedCompare"
          />
        </div>

        <v-spacer />
        <v-btn color="indigo" outlined @click="refresh">
          {{ $t("Reîncarcă") }}
        </v-btn>
        <v-btn color="primary" outlined @click="saveRecord">
          {{ $t("Salvează") }}
        </v-btn>
        <v-btn
          color="primary"
          @click="
            show = false
            compare = false
          "
        >
          {{ $t("Închide") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import VueJsonPretty from "vue-json-pretty"
import "vue-json-pretty/lib/styles.css"
import axios from "../../plugins/axios"
import { EventBus } from "../../EventBus"
export default {
  components: { VueJsonPretty },
  data: () => ({
    show: false,
    records: [],
    resource: {},
    fieldSchema: {},
    resourceCompare: {},
    selectedVersion: 1,
    selectedVersionCompare: 1,
    compare: false,
    collection: "",
    category: "",
    etag: "",
    title: "",
    selfLink: "",
    resourcestr: "",
    type: "marc",
    jsonType: "view",
    selectedJson: null
  }),
  computed: {
    debug() {
      return this.$store.getters.debug
    },
    headers() {
      if (this.compare) {
        return [
          { text: "Câmp", value: "fld", align: "left", type: "String", width: "95px" },
          { text: "Ordine", value: "ord", align: "left", type: "String", width: "10%" },
          { text: "Subcâmp", value: "sub", align: "left", type: "String", width: "10%" },
          { text: "Valoare", value: "val", align: "left", type: "String", width: "30%" },
          { text: "Ordine nouă", value: "ord2", align: "left", type: "String", width: "10%" },
          { text: "Valore nouă", value: "val2", align: "left", type: "String", width: "30%" }
        ]
      }
      return [
        { text: "Câmp", value: "fld", align: "left", type: "String", width: "95px" },
        { text: "Ordine", value: "ord", align: "left", type: "String", width: "10%" },
        { text: "Subcâmp", value: "sub", align: "left", type: "String", width: "10%" },
        { text: "Valoare", value: "val", align: "left", type: "String", width: "70%" }
      ]
    },
    versions() {
      const arr = []
      for (let i = this.resource._latest_version; i >= 1; i--) {
        arr.push({ text: "v" + i, value: i })
      }
      return arr
    },
    height() {
      //this.$log("ww ", window)
      return window.innerHeight - 290
    }
  },
  methods: {
    refresh() {
      axios.get(this.collection + "/" + this.resource._id).then(response => {
        this.resource = response.data
        this.open(this.resource, this.type, this.collection, this.category, this.fieldSchema)
      })
    },
    versionChanged(ver) {
      this.$log("version changed ", ver)
      axios.get(this.collection + "/" + this.resource._id + "?version=" + ver).then(response => {
        this.resource = response.data
        this.open(this.resource, this.type, this.collection, this.category, this.fieldSchema)
      })
    },
    versionChangedCompare(ver) {
      this.$log("version changed ", ver)
      axios.get(this.collection + "/" + this.resource._id + "?version=" + ver).then(response => {
        this.resourceCompare = response.data
        this.open(this.resource, this.type, this.collection, this.category, this.fieldSchema)
      })
    },
    compareChanged() {
      if (this.compare && !this.resourceCompare._id) {
        this.versionChangedCompare(1)
      }
      if (!this.compare) {
        this.versionChanged(this.selectedVersion)
      }
    },
    deleteRow(e) {
      //this.$log("delete e ", e)
      if (e.item.val != "") {
        this.records.splice(this.records.indexOf(e.item), 1)
      } else {
        const ind = this.records.indexOf(e.item)
        this.records.splice(this.records.indexOf(e.item), 1)
        while (this.records[ind].sub) {
          this.records.splice(ind, 1)
        }
      }
    },
    fallbackCopyTextToClipboard(text) {
      var textArea = document.createElement("textarea")
      textArea.value = text
      // Avoid scrolling to bottom
      textArea.style.top = "0"
      textArea.style.left = "0"
      textArea.style.position = "fixed"
      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()
      try {
        document.execCommand("copy")
        //var msg = successful ? "successful" : "unsuccessful"
        //this.$log("Fallback: Copying text command was " + msg)
      } catch (err) {
        console.error("Fallback: Oops, unable to copy", err)
      }
      document.body.removeChild(textArea)
    },
    copyTextToClipboard(text) {
      if (!navigator.clipboard) {
        this.fallbackCopyTextToClipboard(text)
        return
      }
      navigator.clipboard.writeText(text).then(
        function() {
          //this.$log("Async: Copying to clipboard was successful!")
        },
        function() {
          //console.error("Async: Could not copy text: ", err)
        }
      )
    },
    copyText() {
      // var range = document.createRange()
      // range.selectNodeContents(this.$refs.pre)
      // var sel = window.getSelection()
      // sel.removeAllRanges()
      // sel.addRange(range)
      // this.$refs.pre.$el.focus()
      // this.$refs.pre.$el.select()
      // document.execCommand("copy")
      this.copyTextToClipboard(this.resourcestr)
    },
    resolvePath(path, obj) {
      return path.split(".").reduce(function(prev, curr) {
        return prev ? prev[curr] : null
      }, obj || self)
    },
    saveRecord() {
      this.$log("saverecord")
      let rec
      if (this.type == "marc") {
        if (this.compare) {
          rec = JSON.parse(JSON.stringify(this.resourceCompare))
        } else {
          rec = JSON.parse(JSON.stringify(this.resource))
        }
        rec.fields = {}
        let r = {}
        let lastfld = ""
        if (this.compare) {
          this.records.map(itm => {
            if (itm.sub == "") {
              if (lastfld) {
                if (JSON.stringify(r).length > 2) {
                  rec.fields[lastfld].push(r)
                } else if (rec.fields[itm.fld].length == 0) {
                  rec.fields[itm.fld] = undefined
                }
              }
              if (!rec.fields[itm.fld]) {
                rec.fields[itm.fld] = []
              }
              r = {}
              lastfld = itm.fld
            } else if (itm.sub == "link") {
              r.link = itm.val2
            } else {
              if (itm.val2) {
                if (!r[itm.sub]) {
                  r[itm.sub] = []
                }
                r[itm.sub].push({ val: itm.val2, ord: itm.ord2 })
              }
            }
          })
        } else {
          this.records.map(itm => {
            if (itm.sub == "") {
              if (lastfld) {
                rec.fields[lastfld].push(r)
              }
              if (!rec.fields[itm.fld]) {
                rec.fields[itm.fld] = []
              }
              r = {}
              lastfld = itm.fld
            } else if (itm.sub == "link") {
              r.link = itm.val
            } else {
              if (!r[itm.sub]) {
                r[itm.sub] = []
              }
              r[itm.sub].push({ val: itm.val, ord: itm.ord })
            }
          })
        }
        if (JSON.stringify(r).length > 2) {
          rec.fields[lastfld].push(r)
        } else if (rec.fields[lastfld].length == 0) {
          rec.fields[lastfld] = undefined
        }
        //this.$log("rec ", rec)
      } else {
        try {
          rec = JSON.parse(this.resourcestr)
        } catch (err) {
          EventBus.$emit("show-alert", { message: err.name + ": " + err.message, timeout: 7000 })
        }
      }
      if (rec) {
        //const link = rec._links.self.href
        rec._created = undefined
        rec._etag = undefined
        rec._links = undefined
        rec._version = undefined
        rec._latest_version = undefined
        rec._updated = undefined
        //this.collection + "/" + this.resource._id
        axios({
          url: this.selfLink,
          method: "PUT",
          headers: {
            "If-Match": this.etag
          },
          data: rec
        })
          .then(response => {
            if (response.status == 200) {
              this.$emit("reload")
            }
            this.compare = false
            this.show = false
          })
          .catch(() => {
            //this.$log("error save data ", error)
            this.compare = false
            this.show = true
          })
      }
    },

    open(resource, type, col, cat, sch) {
      //this.$log("res ", resource)
      //this.$log("type ", type)
      //this.$log("col ", col)
      //this.$log("cat ", cat)
      //this.$log("sch ", sch)
      this.collection = col
      this.category = cat
      this.fieldSchema = sch
      this.selfLink = col + "/" + resource._id
      // if (resource._links) {
      //   this.selfLink = resource._links.self.href
      // }
      resource._links = undefined
      this.resource = resource
      if (this.resource._latest_version == this.resource._version) {
        this.etag = this.resource._etag
      }
      this.selectedVersion = this.resource._version
      this.resourcestr = JSON.stringify(resource, null, 2)
      this.type = type
      this.show = true
      this.records = []
      this.title = this.$t("Details")
      if (cat) {
        this.title += " - " + cat
      }
      if (col) {
        if (this.resolvePath("fields.299.0.a.0.val", resource)) {
          this.title += " - " + this.resolvePath("fields.299.0.a.0.val", resource)
        } else if (this.resolvePath("fields.200.0.a.0.val", resource)) {
          this.title += " - " + this.resolvePath("fields.200.0.a.0.val", resource)
        }
      }

      //this.$log("msr ", resource)
      if (this.compare) {
        for (const fld in this.resource.fields) {
          for (const flds in this.resource.fields[fld]) {
            let bool = false
            const fd = { fld: fld, ordf: flds, sub: "", val: "", ord: this.resource.fields[fld][flds].ord }
            const fdl = { fld: fld, ordf: flds }
            if (this.resource.fields[fld][flds].link) {
              fdl.sub = "link"
              fdl.val = this.resource.fields[fld][flds].link
            }
            if (this.resourceCompare.fields[fld]) {
              if (this.resourceCompare.fields[fld][flds]) {
                bool = true
                fd.sub2 = ""
                fd.val2 = ""
                fd.ord2 = this.resourceCompare.fields[fld][flds].ord
                if (this.resourceCompare.fields[fld][flds].link) {
                  fdl.val2 = this.resourceCompare.fields[fld][flds].link
                }
              }
            }
            this.records.push(fd)
            if (fdl.val || fdl.val2) {
              this.records.push(fdl)
            }
            for (const sub in this.resource.fields[fld][flds]) {
              if (sub.length == 1) {
                for (const subs in this.resource.fields[fld][flds][sub]) {
                  const sb = {
                    fld: fld,
                    ordf: flds,
                    sub: sub,
                    val: this.resource.fields[fld][flds][sub][subs].val,
                    ord: this.resource.fields[fld][flds][sub][subs].ord
                  }
                  if (bool) {
                    if (this.resourceCompare.fields[fld][flds][sub]) {
                      if (this.resourceCompare.fields[fld][flds][sub][subs]) {
                        sb.val2 = this.resourceCompare.fields[fld][flds][sub][subs].val
                        sb.ord2 = this.resourceCompare.fields[fld][flds][sub][subs].ord
                      }
                    }
                  }
                  this.records.push(sb)
                }
              }
            }
            if (bool) {
              for (const sub in this.resourceCompare.fields[fld][flds]) {
                if (sub.length == 1) {
                  for (const subs in this.resourceCompare.fields[fld][flds][sub]) {
                    let bool2 = false
                    if (!this.resource.fields[fld][flds][sub]) {
                      bool2 = true
                    } else {
                      if (!this.resource.fields[fld][flds][sub][subs]) {
                        bool2 = true
                      }
                    }
                    if (bool2) {
                      this.records.push({
                        fld: fld,
                        ordf: flds,
                        sub: sub,
                        val2: this.resourceCompare.fields[fld][flds][sub][subs].val,
                        ord2: this.resourceCompare.fields[fld][flds][sub][subs].ord
                      })
                    }
                  }
                }
              }
            }
          }
        }

        for (const fld in this.resourceCompare.fields) {
          for (const flds in this.resourceCompare.fields[fld]) {
            let bool = false
            if (!this.resource.fields[fld]) {
              bool = true
            } else {
              if (!this.resource.fields[fld][flds]) {
                bool = true
              }
            }
            if (bool) {
              this.records.push({
                fld: fld,
                ordf: flds,
                sub: "",
                val2: "",
                ord2: this.resourceCompare.fields[fld][flds].ord
              })
              if (this.resourceCompare.fields[fld][flds].link) {
                this.records.push({
                  fld: fld,
                  ordf: flds,
                  sub: "link",
                  val2: this.resourceCompare.fields[fld][flds].link
                })
              }
              for (const sub in this.resourceCompare.fields[fld][flds]) {
                if (sub.length == 1) {
                  for (const subs in this.resourceCompare.fields[fld][flds][sub]) {
                    const sb = {
                      fld: fld,
                      ordf: flds,
                      sub: sub,
                      val2: this.resourceCompare.fields[fld][flds][sub][subs].val,
                      ord2: this.resourceCompare.fields[fld][flds][sub][subs].ord
                    }
                    this.records.push(sb)
                  }
                }
              }
            }
          }
        }
        this.records.sort((a, b) => {
          return Number(a.fld) - Number(b.fld) || Number(a.ordf) - Number(b.ordf) || a.sub.localeCompare(b.sub)
        })
        //this.$log("this.records sort ", this.records)
        //console.table(this.records)
      } else {
        for (const fld in resource.fields) {
          for (const flds in resource.fields[fld]) {
            this.records.push({ fld: fld, sub: "", val: "", ord: resource.fields[fld][flds].ord })
            if (resource.fields[fld][flds].link) {
              this.records.push({ fld: fld, sub: "link", val: resource.fields[fld][flds].link, ord: "" })
            }
            for (const sub in resource.fields[fld][flds]) {
              if (sub.length == 1) {
                for (const subs in resource.fields[fld][flds][sub]) {
                  this.records.push({
                    fld: fld,
                    sub: sub,
                    val: resource.fields[fld][flds][sub][subs].val,
                    ord: resource.fields[fld][flds][sub][subs].ord
                  })
                }
              }
            }
          }
        }
        this.records.sort((a, b) => {
          return Number(a.fld) - Number(b.fld) || a.sub > b.sub
        })
      }
    }
  }
}
</script>
<style>
.v-textarea textarea {
  line-height: 16px !important;
}
</style>

<template>
  <v-dialog v-model="show" persistent scrollable>
    <v-card outlined>
      <v-card-title>
        {{ $t("Administrare inventare") }}
        <v-spacer />
        <v-icon color="red" class="mt-n2 mr-n2" large @click="show = false">mdi-close</v-icon>
      </v-card-title>
      <v-card-text class="pa-2">
        <table-management
          ref="table"
          name="tmsearch"
          :table="table"
          :where="where"
          :show-select-inv="showSelect"
          view-table="5fb37f15fbf23ba802de2341"
          :record-default="recordDefault"
          @selectRMFInventories="selectRMFInventories"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="success" @click="show = false">
          {{ $t("Închide") }}
        </v-btn>
        <!-- <v-btn color="primary">
          {{ $t("Add") }}
        </v-btn> -->
      </v-card-actions>
    </v-card>
    <search-link ref="searchLink" select-event select-multiple @select="searchSelected" />
  </v-dialog>
</template>
<script>
import axios from "../../plugins/axios"
import TableManagement from "../../components/containers/TableManagement"
import SearchLink from "../../components/containers/SearchLink"
import moment from "moment"

export default {
  components: {
    TableManagement,
    SearchLink
  },
  props: {
    selectEvent: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    show: false,
    showSelect: false,
    collection: "",
    recordDefault: {},
    table: "",
    where: "",
    embed: "",
    viewTable: "",
    openInv: null,
    searchId: null
  }),
  computed: {
    appSchema() {
      return this.$store.getters.appSchema
    }
  },
  methods: {
    selectRMFInventories() {
      this.$refs.searchLink.open("ebib_marc_inv", null, "rmfinv")
    },
    searchSelected(e) {
      this.$log("searchSelected rmf ", e)
      if (this.openInv.ctg == "intrare") {
        e.fields["420"] = [{ link: this.openInv._id, a: [{ val: this.openInv.title }] }]
      } else {
        e.fields["421"] = [{ link: this.openInv._id, a: [{ val: this.openInv.title }] }]
      }
      const et = e._etag
      e._etag = undefined
      axios
        .put("ebib_marc_inv/" + e._id, e, {
          headers: {
            "If-Match": et
          }
        })
        .then(res => {
          this.$log("reas add ", res)
          if (res.status == 200) {
            this.$refs.table.getRecords()
          }
        })
    },
    open(inv) {
      this.$log("open inv ", inv)
      this.openInv = inv
      if (inv) {
        if (inv.collection == "ebib_marc_rmf") {
          this.showSelect = true
          this.viewTable = undefined
          const d = new Date(this.$store.getters.serverDate)
          const d220a = moment(d).format("YYYY-MM-DD hh:mm:ss")
          if (inv.ctg == "intrare") {
            this.where = '"fields.420.link":"' + inv._id + '"'
            this.recordDefault = {
              ctg: "intrare",
              fields: {
                220: [
                  {
                    a: [{ val: d220a }]
                  }
                ],
                420: [
                  {
                    a: [{ val: inv.title }],
                    link: inv._id
                  }
                ]
              }
            }
          } else {
            this.where = '"fields.421.link":"' + inv._id + '"'
            this.recordDefault = {
              ctg: "ieșire",
              fields: {
                220: [
                  {
                    a: [{ val: d220a }]
                  }
                ],
                421: [
                  {
                    a: [{ val: inv.title }],
                    link: inv._id
                  }
                ]
              }
            }
          }
        } else {
          this.showSelect = false
          this.where = '"fields.400.link":"' + inv._id + '"'
          this.viewTable = inv.viewTable
          this.recordDefault = {
            ctg: "inventar",
            fields: {
              400: [
                {
                  a: [{ val: inv.title }],
                  link: inv._id
                }
              ],
              500: [
                {
                  a: [{ val: "1 - General" }]
                }
              ],
              501: [
                {
                  a: [{ val: "Bibliotecă" }]
                }
              ],
              505: [
                {
                  a: [{ val: "Împrumut" }]
                }
              ],
              700: [
                {
                  a: [{ val: "Da" }]
                }
              ]
            }
          }
        }
      }
      //this.$log("open this.recordDefault1 ", this.recordDefault)
      //this.$log("open this.recordDefault2 ", this.appSchema)
      this.show = true
      this.table = "marc_inv"
      this.collection = this.appSchema.filter(itm => itm.collection == "marc_inv")[0].item_url
      this.$nextTick(() => {
        this.$log("this.$refs.tmsearch ", this.$refs.table)
        if (this.$refs.table) {
          this.$refs.table.getRecords()
        }
      })
    }
  }
}
</script>
<style scoped></style>

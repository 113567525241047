<template>
  <v-list-item
    :href="href"
    :rel="href && href !== '#' ? 'noopener' : undefined"
    :target="href && href !== '#' ? '_blank' : undefined"
    :to="item.to"
    class="mb-0"
    exact
    :active-class="catalog ? $config.colorText : `primary ${!isDark ? 'black' : 'white'}--text`"
  >
    <!-- :active-class="catalog ? 'indigo--text' : `primary ${!isDark ? 'black' : 'white'}--text`" -->
    <v-list-item-icon v-if="text" class="v-list-item__icon--text pl-2" v-text="computedText" />
    <v-list-item-icon v-else-if="item.icon">
      <v-icon :color="catalog ? $config.color : ''" :class="{ 'pl-2': mini }" v-text="item.icon" />
    </v-list-item-icon>
    <v-list-item-content v-if="item.title || item.subtitle">
      <v-list-item-title class="font-weight-bold" v-text="item.title" />
      <v-list-item-subtitle v-text="item.subtitle" />
    </v-list-item-content>
    <v-list-item-avatar v-if="item.count" class="ml-1 mr-0 justify-end mt-1 mb-1" width="55px">
      {{ item.count }}
    </v-list-item-avatar>
  </v-list-item>
</template>

<script>
import Themeable from "vuetify/lib/mixins/themeable"

export default {
  name: "Item",

  mixins: [Themeable],

  props: {
    item: {
      type: Object,
      default: () => ({
        href: undefined,
        icon: undefined,
        subtitle: undefined,
        title: undefined,
        to: undefined
      })
    },
    text: {
      type: Boolean,
      default: false
    },
    catalog: {
      type: Boolean,
      default: false
    },
    mini: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    computedText() {
      if (!this.item || !this.item.title) return ""

      // let text = ""
      // this.item.title.split(" ").forEach(val => {
      //   text += val.substring(0, 1)
      // })
      // return text
      if (this.mini) {
        return this.item.title.substr(0, 3)
      }
      return ""
    },
    href() {
      return this.item.href || (!this.item.to ? "#" : undefined)
    }
  }
}
</script>

import { render, staticRenderFns } from "./AddDataPopUp.vue?vue&type=template&id=058f4106&scoped=true&"
import script from "./AddDataPopUp.vue?vue&type=script&lang=js&"
export * from "./AddDataPopUp.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "058f4106",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDialog } from 'vuetify/lib/components/VDialog';
installComponents(component, {VDialog})

<template>
  <v-card outlined>
    <v-card-title ref="cardTitle" class="pa-2 d-flex flex-nowrap">
      <v-sheet
        v-if="isMarc"
        color="primary"
        max-height="90"
        width="auto"
        elevation="6"
        class="mt-0 mr-3 mb-0 pa-1"
        dark
      >
        <v-icon size="24">
          {{ $route.meta.icon }}
        </v-icon>
      </v-sheet>
      <v-sheet v-else color="primary" max-height="90" width="auto" elevation="6" class="mt-0 mr-3 mb-0 pa-1" dark>
        <v-icon v-if="recordId" size="24">
          mdi-pencil
        </v-icon>
        <v-icon v-else size="24">
          mdi-plus
        </v-icon>
      </v-sheet>
      <!-- :style="'width:' + titleWidth + 'px'" -->
      <span class="text-truncate">
        {{ title }}
      </span>

      <v-spacer />
      <v-menu bottom left offset-y :max-height="menuMaxHeight" :close-on-content-click="false">
        <template #activator="{ on: onMenu }">
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-btn min-width="0" text fab small v-on="{ ...onMenu, ...on }">
                <v-icon>mdi-help-circle-outline</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("Ajutor!") }}</span>
          </v-tooltip>
        </template>
        <v-card class="mt-0 mb-0" width="360">
          <v-card-title>
            {{ $t("Ajutor!") }}
          </v-card-title>
          <v-card-text class="text-h6">
            <v-divider />
            <v-subheader>{{ $t("Butoane") }}</v-subheader>
            <div>
              <v-btn color="error" fab text small>
                <v-icon>mdi-close</v-icon>
              </v-btn>
              {{ $t("Închide ferestra fără salvarea înregistrări.") }}
            </div>
            <v-subheader>{{ $t("Scurtături") }}</v-subheader>
            <div>{{ $t("F8: Resetează modificările făcute.") }}</div>
            <div>{{ $t("CTRL+S: Salvează/Adaugă înregistrarea.") }}</div>
          </v-card-text>
        </v-card>
      </v-menu>
      <!-- v-if="record._latest_version > 1" -->
      <div style="width:55px">
        <v-select v-model="selectedVersion" dense hide-details :items="versions" @change="versionChanged" />
      </div>
      <v-menu bottom left offset-y>
        <template #activator="{ on }">
          <v-btn text fab small class="mr-2 ml-2" v-on="on">
            <v-icon size="24">
              mdi-dots-vertical
            </v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item v-for="(tab, ind) in collectionForms" :key="tab._id" @click="changeForm(ind)">
            {{ tab.name }}
          </v-list-item>
        </v-list>
      </v-menu>
      <!-- <v-btn aria-label="Close" icon fab large @click="$emit('close')"> -->
      <v-icon color="red" large @click="$emit('close')">
        mdi-close
      </v-icon>
      <!-- </v-btn> -->
    </v-card-title>
    <v-divider />
    <v-card-text>
      <v-form ref="dataForm">
        <data-view v-if="viewType == 'data'" ref="dataView" name="datavieform" @sheet="$emit('sheet', $event)" />
        <html-view v-if="viewType == 'html'" ref="htmlView" name="htmlvieform" @sheet="$emit('sheet', $event)" />
        <marc-view v-if="viewType == 'marc'" ref="marcView" name="marcvieform" @sheet="$emit('sheet', $event)" />
      </v-form>
    </v-card-text>
    <v-divider />
    <v-card-actions>
      <!-- <v-btn
        v-if="record._id && collection == 'graduate_syllabi'"
        color="#316394"
        outlined
        @click="graduateReport('docx')"
      >
        {{ $t("Export") }}
      </v-btn> -->
      <v-menu v-if="record._id && collection == 'graduate_syllabi'" offset-y>
        <template #activator="{ on: onMenu }">
          <v-btn color="#316394" outlined v-on="onMenu">
            {{ $t("Export") }}
          </v-btn>
        </template>
        <v-sheet>
          <div class="pt-2 px-4">
            <v-btn color="#316394" block @click="graduateReport('docx')">DOCX</v-btn>
          </div>
          <div class="mt-2 pb-2 px-4">
            <v-btn color="#316394" block @click="graduateReport('pdf')">PDF</v-btn>
          </div>
        </v-sheet>
      </v-menu>
      <v-btn v-if="collection == 'marc_cln'" outlined @click="openCard">
        {{ $t("Permis") }}
      </v-btn>
      <v-btn v-else-if="collection == 'marc_mon'" outlined @click="importMon">
        {{ $t("Import") }}
      </v-btn>
      <v-btn v-if="isMarc && recordId" color="warning" outlined @click="$emit('sheet', { _id: recordId })">
        {{ $t("Fișă") }}
      </v-btn>
      <v-btn
        v-if="recordId && (collection == 'marc_mon' || collection == 'marc_rmf')"
        color="info"
        outlined
        @click="
          $emit('inventories', {
            _id: recordId,
            'fields.200.a.val': resolvePath('fields.200.0.a.0.val', record),
            ctg: record.ctg
          })
        "
      >
        {{ $t("Inventare") }}
      </v-btn>
      <v-spacer />
      <div v-if="collection == 'marc_inv'" style="width:65px">
        <v-text-field
          v-model="noInventories"
          class="mt-2"
          dense
          hide-details
          :label="$t('Nr. inventare')"
          min="1"
          max="10"
          step="1"
          type="number"
        />
      </div>
      <v-btn v-if="collection == 'marc_inv'" class="ml-2" outlined color="info" @click="generateInventories">
        {{ $t("Generează") }}
      </v-btn>
      <v-spacer />
      <v-btn color="error" outlined @click="$emit('close')">
        {{ $t("Anulează") }}
      </v-btn>
      <v-btn v-if="recordId && isMarc" outlined color="indigo" @click="copyData">
        {{ $t("Copiază") }}
      </v-btn>
      <v-btn v-if="isMarc" outlined color="info" @click="saveData(false)">
        {{ $t("Aplică") }}
      </v-btn>
      <v-btn v-if="recordId" color="success" @click="saveData(true)">
        {{ $t("Salvează") }}
      </v-btn>
      <v-btn v-else color="success" @click="saveData(true)">
        {{ importing ? $t("Importă") : $t("Adaugă") }}
      </v-btn>
    </v-card-actions>
    <v-overlay v-model="overlay" absolute>
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
    <!-- <v-dialog v-model="show" persistent scrollable max-width="1200px">
      <import-ebibliophil ref="import" is-pop-up @import="importRecord" @close="show = false" />
    </v-dialog> -->
  </v-card>
</template>
<script>
// import axioss from "axios"
import axios from "../../plugins/axios"
import DataView from "../settings/DataView"
import HtmlView from "../settings/HtmlView"
import MarcView from "../settings/MarcView"
// import ImportEbibliophil from "../../views/dashboard/template/ImportEbibliophil4"

import { EventBus } from "../../EventBus"
export default {
  components: {
    DataView,
    HtmlView,
    MarcView
  },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    // show: false,
    overlay: false,
    importing: false,
    viewType: "data",
    htmlView: false,
    noInventories: 1,
    addedInventories: [],
    addInventories: {},
    selectedVersion: 1,
    collectionName: "",
    title: "",
    collectionAppType: "",
    collection: "",
    table: "",
    collectionForms: [],
    recordId: "",
    etag: "",
    prefix: "",
    viewCollection: "",
    collectionSchema: {},
    listContent: {},
    list: [],
    recordView: {},
    record: {},
    recordDef: {},
    lastInd: 0,
    importId: 0,
    loan: {
      loan: {
        "1 - General": {
          General: {
            Împrumut: {
              days: 21,
              items: 3
            },
            "Împrumut la sala de lectură": {
              days: 1,
              items: 3
            },
            Rezervare: {
              days: 1,
              items: 3
            }
          },
          Elevi: {
            Împrumut: {
              days: 21,
              items: 3
            },
            "Împrumut la sala de lectură": {
              days: 1,
              items: 3
            },
            Rezervare: {
              days: 1,
              items: 3
            }
          },
          Profesori: {
            Împrumut: {
              days: 21,
              items: 3
            },
            "Împrumut la sala de lectură": {
              days: 1,
              items: 3
            },
            Rezervare: {
              days: 1,
              items: 3
            },
            "Circuit intern": {
              days: 100,
              items: 3
            }
          },
          Pensionar: {
            Împrumut: {
              days: 21,
              items: 3
            },
            "Împrumut la sala de lectură": {
              days: 1,
              items: 3
            },
            Rezervare: {
              days: 1,
              items: 3
            }
          },
          "Cititor Sala Lectura": {
            Împrumut: {
              days: 21,
              items: 3
            },
            "Împrumut la sala de lectură": {
              days: 1,
              items: 3
            },
            Rezervare: {
              days: 1,
              items: 3
            }
          },
          Bibliotecar: {
            Împrumut: {
              days: 100,
              items: 3
            },
            "Împrumut la sala de lectură": {
              days: 3,
              items: 3
            },
            Rezervare: {
              days: 1,
              items: 3
            },
            "Circuit intern": {
              days: 100,
              items: 3
            }
          },
          "Colaborator Biblioteca": {
            Împrumut: {
              days: 21,
              items: 3
            },
            "Împrumut la sala de lectură": {
              days: 21,
              items: 3
            },
            Rezervare: {
              days: 1,
              items: 3
            },
            "Circuit intern": {
              days: 1,
              items: 3
            }
          },
          Special: {
            Împrumut: {
              days: 21,
              items: 3
            },
            "Împrumut la sala de lectură": {
              days: 1,
              items: 3
            },
            Rezervare: {
              days: 1,
              items: 3
            }
          },
          "Uz Intern": {
            Împrumut: {
              days: 21,
              items: 3
            },
            "Împrumut la sala de lectură": {
              days: 21,
              items: 3
            },
            Rezervare: {
              days: 1,
              items: 3
            },
            "Circuit intern": {
              days: 1,
              items: 3
            }
          }
        },
        "2 - Depozit legal": {
          General: {
            Împrumut: {
              days: 21,
              items: 3
            },
            "Împrumut la sala de lectură": {
              days: 21,
              items: 3
            },
            Rezervare: {
              days: 1,
              items: 3
            }
          },
          Elevi: {
            Împrumut: {
              days: 21,
              items: 3
            },
            "Împrumut la sala de lectură": {
              days: 1,
              items: 3
            },
            Rezervare: {
              days: 1,
              items: 3
            }
          },
          Profesori: {
            Împrumut: {
              days: 21,
              items: 3
            },
            "Împrumut la sala de lectură": {
              days: 1,
              items: 3
            },
            Rezervare: {
              days: 1,
              items: 3
            },
            "Circuit intern": {
              days: 100,
              items: 3
            }
          },
          "Cititor Sala Lectura": {
            Împrumut: {
              days: 21,
              items: 3
            },
            "Împrumut la sala de lectură": {
              days: 21,
              items: 3
            },
            Rezervare: {
              days: 1,
              items: 3
            }
          },
          Bibliotecar: {
            Împrumut: {
              days: 21,
              items: 3
            },
            "Împrumut la sala de lectură": {
              days: 21,
              items: 3
            },
            Rezervare: {
              days: 1,
              items: 3
            },
            "Circuit intern": {
              days: 1,
              items: 3
            }
          },
          "Colaborator Biblioteca": {
            Împrumut: {
              days: 21,
              items: 3
            },
            "Împrumut la sala de lectură": {
              days: 21,
              items: 3
            },
            Rezervare: {
              days: 1,
              items: 3
            },
            "Circuit intern": {
              days: 1,
              items: 3
            }
          },
          Pensionar: {
            Împrumut: {
              days: 21,
              items: 3
            },
            "Împrumut la sala de lectură": {
              days: 21,
              items: 3
            },
            Rezervare: {
              days: 1,
              items: 3
            }
          },
          Special: {
            Împrumut: {
              days: 21,
              items: 3
            },
            "Împrumut la sala de lectură": {
              days: 21,
              items: 3
            },
            Rezervare: {
              days: 1,
              items: 3
            }
          },
          "Uz Intern": {
            Împrumut: {
              days: 21,
              items: 3
            },
            "Împrumut la sala de lectură": {
              days: 21,
              items: 3
            },
            Rezervare: {
              days: 1,
              items: 3
            },
            "Circuit intern": {
              days: 1,
              items: 3
            }
          }
        }
      },
      regimes: ["Împrumut", "Împrumut la sala de lectură", "Rezervare"],
      sites: ["1 - General", "2 - Depozit legal", "Scoasă din inventar"],
      clients_types: ["General", "Cititor Sala Lectura", "Uz intern", "Elevi", "Profesori", "Altele"],
      loan_exceptions: {
        restricted_sites_for_loan: ["2 - Depozit legal", "Scoasă din inventar"],
        restricted_regimes_for_loan: ["Rezervare"],
        restricted_inv_categories: [
          "prelucrare",
          "depozit-legal",
          "legatorie",
          "casat",
          "pentru-casare",
          "transfer",
          "custodie",
          "arhiva"
        ],
        restricted_cln_categories: ["endorser", "inactive"],
        restricted_cln_passport_status: ["Expirat", "Suspendat"]
      },
      reservation_exceptions: {
        restricted_sites: ["2 - Depozit legal", "Scoasă din inventar"],
        restricted_regimes: ["Scoasa din inventar"],
        restricted_inv_categories: [
          "prelucrare",
          "depozit-legal",
          "legatorie",
          "casat",
          "pentru-casare",
          "transfer",
          "custodie",
          "arhiva"
        ],
        restricted_cln_categories: ["endorser", "inactive"],
        restricted_cln_passport_status: ["Expirat", "Suspendat"]
      },
      client_subscription: {
        signup_duration_months: 60,
        visa_duration_months: 12
      }
    }
  }),
  computed: {
    titleWidth() {
      // this.$log("cartitle--------------- ", this.$refs.cardTitle)
      // this.$log("cartitle ", this.overlay)
      if (this.$refs.cardTitle && !this.overlay) {
        // this.$log("cartitle--------------- ", this.$refs.cardTitle.clientWidth)
        return this.$refs.cardTitle.clientWidth - 250
      }
      return 300
    },
    menuMaxHeight() {
      return window.innerHeight - 120
    },
    versions() {
      const arr = []
      for (let i = this.record._latest_version; i >= 1; i--) {
        arr.push({ text: "v" + i, value: i })
      }
      return arr
    },
    companyId() {
      return this.$store.getters.companyId
    },
    isMarc() {
      if (this.collection.substr(0, 4) == "marc") {
        return true
      }
      return false
    },
    biblioteca() {
      return this.$store.getters.libraryData.library.public_name
    },
    localitatea() {
      return this.$store.getters.libraryData.library.county
    },
    baseURL() {
      return axios.defaults.baseURL
    }
  },
  created() {
    window.addEventListener("keydown", e => {
      if (this.show) {
        //this.$log("key dialog ", this.show)
        if (e.key == "F8") {
          //this.$log("reset")
          this.record = JSON.parse(JSON.stringify(this.recordDef))
          this.changeForm(this.lastInd)
        }
        if (e.ctrlKey) {
          if (e.key == "s" || e.key == "S") {
            e.preventDefault()
            this.saveData(true)
          }
        }
      }
    })
    EventBus.$on("import-mon4-selected", search => {
      if (this.collection == "marc_mon") {
        this.$log("import-mon4-selected ", this.collection)
        this.$log("import-mon4-selected ", search)
        this.importRecord(search)
      }
    })
    EventBus.$on("import-monography-selected", search => {
      if (this.importId == search.id) {
        this.$log("import-monography-selected1 ", search)
        this.$log("import-monography-selected2 ", this.importId)
        for (const fld in search.value.fields) {
          if (fld.substr(0, 1) == "7" || fld.substr(0, 1) == "4") {
            search.value.fields[fld] = undefined
          }
        }
        search.value.fields["916"] = undefined
        search.value.fields["917"] = undefined
        search.value.fields["001"] = undefined
        search.value.fields["005"] = undefined
        search.value.fields["856"] = undefined
        this.record = search.value
        this.changeForm(this.lastInd)
        //this.$log("setlink ", this.$refs.htmView)
        //this.$refs.htmView.setLinkValue(search.value)
      }
    })
  },
  destroyed() {
    this.$log("destroyed")
  },
  methods: {
    graduateReport(type) {
      this.$log("graduateReport type ", type)
      axios
        .post("exec_report/r500_infuni_fisa_disciplina/" + type, { query: { $and: [{ _id: this.record._id }] } })
        .then(response => {
          this.$log("resp ", response)
          this.downloadReport(response.data)
        })
    },
    downloadReport(item) {
      this.$log("downloadReport ", item)
      this.$log("downloadReport ", axios.defaults.headers.common.Authorization)
      //axios.defaults.headers.common.Authorization
      // axioss
      //   .get("http://api.academics.cunbm.utcluj.ro:8080/ils/download_doc/" + item._id, {
      //     responseType: "blob",
      //     headers: {
      //       Authorization: axios.defaults.headers.common.Authorization
      //     }
      //   })
      axios
        .get("download_doc/" + item._id, {
          responseType: "blob"
        })
        .then(rap => {
          this.$log("resdes ")
          if (rap.status == 200) {
            var a = document.createElement("a")
            document.body.appendChild(a)
            a.style = "display: none"
            //var binaryData = []
            //binaryData.push(rap.data)
            var url = window.URL.createObjectURL(rap.data)
            //var url = window.URL.createObjectURL(new Blob([rap.data], { type: rap.headers["content-type"] }))
            //this.$log("resurl ", url)
            a.href = url
            a.download = item.report_name
            a.click()
            document.body.removeChild(a)
          }
        })
    },
    generateInventories() {
      this.$log("generateInventories ", this.resolvePath("fields.100.0.a.0.val", this.record))
      if (this.$refs.dataForm.validate()) {
        let rec = {}
        if (this.viewType == "html") {
          rec = this.$refs.htmlView.getRecord()
        } else if (this.viewType == "data") {
          rec = this.$refs.dataView.getRecord()
        } else if (this.viewType == "marc") {
          rec = this.$refs.marcView.getRecord()
        }
        this.addInventories.brc = String(Number(this.resolvePath("fields.200.0.a.0.val", rec)))
        this.addInventories.inv = this.resolvePath("fields.100.0.a.0.val", rec)
        this.addInventories.pre = ""
        this.addInventories.suf = ""
        const arr = this.addInventories.inv.split("/")
        if (arr.length == 1) {
          this.addInventories.inv = arr[0]
        } else if (arr.length == 2) {
          if (String(arr[0]).length > String(arr[1]).length) {
            this.addInventories.suf = "/" + arr[1]
            this.addInventories.inv = arr[0]
          } else {
            this.addInventories.pre = arr[0] + "/"
            this.addInventories.inv = arr[1]
          }
        } else if (arr.length == 3) {
          this.addInventories.pre = arr[0] + "/"
          this.addInventories.suf = "/" + arr[2]
          this.addInventories.inv = arr[1]
        }
        this.addInventories.invl = String(this.addInventories.inv).length
        this.addInventories.brcl = String(this.resolvePath("fields.200.0.a.0.val", rec)).length

        if (this.collectionAppType != "system") {
          rec.company = this.companyId
        }
        rec._created = undefined
        rec._links = undefined
        rec._version = undefined
        rec._latest_version = undefined
        rec._updated = undefined
        rec._status = undefined
        rec._etag = undefined
        rec._id = undefined
        this.addInventories.added = []
        this.addInventories.no = 0
        this.addInventories.rec = rec
        this.checkDuplicate()
        //this.addNextInventories()

        // for (let i = 0; i < this.noInventories; i++) {

        // }
      }
    },
    checkDuplicate() {
      axios
        .post(
          "marc/is_duplicated_fs_value/inv/200/a/" + this.addInventories.brc.padStart(this.addInventories.brcl, "0")
        )
        .then(response => {
          if (response.data == 0) {
            this.addNextInventories()
          } else {
            this.addInventories.inv = String(Number(this.addInventories.inv) + 1)
            this.addInventories.brc = String(Number(this.addInventories.brc) + 1)
            this.addInventories.no++
            this.checkDuplicate()
          }
        })
    },
    addNextInventories() {
      if (this.addInventories.no < this.noInventories) {
        const rec = JSON.parse(JSON.stringify(this.addInventories.rec))
        rec.fields["200"][0].a[0].val = this.addInventories.brc.padStart(this.addInventories.brcl, "0")
        rec.fields["100"][0].a[0].val =
          this.addInventories.pre +
          this.addInventories.inv.padStart(this.addInventories.invl, "0") +
          this.addInventories.suf
        axios
          .post(this.table, rec)
          .then(response => {
            this.$log("add res ", response)
            if (response.status == 201) {
              this.addInventories.added.push(this.resolvePath("fields.200.0.a.0.val", response.data))
              //this.addedInventories.push(this.resolvePath("fields.200.0.a.0.val", response.data))
            }
            this.checkDuplicate()
          })
          .catch(() => {
            this.$log("error")
            this.checkDuplicate()
          })
        this.addInventories.inv = String(Number(this.addInventories.inv) + 1)
        this.addInventories.brc = String(Number(this.addInventories.brc) + 1)
        this.addInventories.no++
      } else {
        this.$log("finish add ", this.addInventories.added)
        EventBus.$emit("show-alert", {
          message:
            "Au fost adăugate " + this.addInventories.added.length + " inventare: " + this.addInventories.added + ".",
          timeout: 10000
        })
        this.$emit("reload")
      }
    },
    versionChanged(ver) {
      this.$log("versionChanged ", ver)
      axios.get(this.table + "/" + this.recordId + "?version=" + ver).then(response => {
        this.record = response.data
        this.changeForm(this.lastInd)
      })
    },
    importMon() {
      this.$log("importMon ")
      //this.show = true
      EventBus.$emit("import-mon4")
      //this.importId = Date.now()
      //EventBus.$emit("import-monography", { id: this.importId })
    },
    openCard() {
      this.$log("Open car ")
      const name = this.resolvePath("fields.200.0.a.0.val", this.record)
      const barcode = this.resolvePath("fields.500.0.a.0.val", this.record)
      const lnkImg = this.resolvePath("fields.856.0.link", this.record)
      let data = this.resolvePath("fields.505.0.b.0.val", this.record)
      if (data) {
        data = data.substr(8, 2) + "." + data.substr(5, 2) + "." + data.substr(0, 4)
      }
      window.open(
        "/html/permis.html?biblioteca=" +
          encodeURI(this.biblioteca) +
          "&localitate=" +
          this.localitatea +
          "&nume=" +
          encodeURI(name) +
          "&barcode=" +
          barcode +
          "&data=" +
          data +
          "&imagine=" +
          this.baseURL +
          "file_stream/?f=" +
          lnkImg
      )
      //EventBus.$emit("user-card", { id: this.recordId })
    },
    changeForm(ind) {
      this.lastInd = ind
      //this.$log("changeeeeeeeeeeeee ", JSON.stringify(this.collectionForms[ind]))
      //this.$log("changeeeeeeeeeeeee fullscreen ", this.collectionForms[ind].fullscreen)
      if (this.collectionForms[ind].fullscreen != undefined) {
        this.$emit("fullscreenChanged", this.collectionForms[ind].fullscreen)
      }
      this.list = this.collectionForms[ind].content
      this.collectionName = this.collectionForms[ind].name
      this.viewType = this.collectionForms[ind].view_type
      const arr = this.collectionForms[ind].collection.split("_")
      this.prefix = arr[1]
      this.$nextTick(() => {
        if (this.collectionForms[ind].view_type == "html") {
          //this.$refs.htmlView.showData(this.record, this.list[0], this.listContent)
          this.$refs.htmlView.showData(this.record, this.collectionForms[ind], this.listContent)
        } else if (this.collectionForms[ind].view_type == "data") {
          this.$refs.dataView.showData(this.record, this.list, this.listContent)
        } else if (this.collectionForms[ind].view_type == "marc") {
          this.$refs.marcView.showData(this.record, this.list, this.listContent)
        }
        this.overlay = false
      })
    },
    appViewCollection(col) {
      return this.$store.getters.appViewCollection(col)
    },
    appViewId(id) {
      return this.$store.getters.appViewId(id)
    },
    addData(response, view, recordDefault, ctg, importing) {
      this.importing = importing
      this.$log("adddata recordDefault ", recordDefault)
      this.$log("adddata response ", response)
      this.$log("adddata ctg ", ctg)
      this.collectionSchema = response
      this.viewCollection = view
      this.noInventories = 1
      this.overlay = true
      this.recordId = 0
      this.record = {}
      if (recordDefault) {
        this.record = JSON.parse(JSON.stringify(recordDefault))
        this.recordDef = JSON.parse(JSON.stringify(recordDefault))
      }
      if (ctg) {
        this.record.ctg = ctg
      }
      this.collectionAppType = response.owner.app_type
      this.collection = response.collection
      this.table = response.item_url
      this.listContent = response.content
      this.list = []
      if (this.table == "app_company") {
        this.record.config = { library: {} }
        for (const v in this.loan) {
          this.record.config.library[v] = this.loan[v]
        }
      }
      //this.$log("adddata view ", view)
      if (view != undefined && view != null && view != "") {
        //axios.get("app_view/" + view).then(schema => {
        const schema = this.appViewId(view)
        //this.$log("adddata view schema", schema)
        if (schema) {
          this.collectionForms = []
          this.collectionForms.push(schema[0])
          this.changeForm(0)
          this.$nextTick(() => this.$refs.dataForm.resetValidation())
          // this.changeHeader(this.tableManagement.findIndex(itm => itm.default))
          this.title = (importing ? this.$t("Importă") : this.$t("Adaugă")) + " " + this.$t(this.collectionName)
        }
        //})
      } else {
        //axios.get('app_view?where={"collection": "' + this.collection + '", "type": "form"}').then(schema => {
        const schema = this.appViewCollection(this.collection)
        //this.$log("schema reload", schema)
        if (schema.length > 0) {
          this.collectionForms = schema
          //this.$log("adddata recctg ", this.record)
          this.changeForm(this.collectionForms.findIndex(itm => itm.default))
          this.$nextTick(() => this.$refs.dataForm.resetValidation())
          // this.changeHeader(this.tableManagement.findIndex(itm => itm.default))
        }
        //this.title = this.$t("Adaugă") + " " + this.$t(this.collectionName)
        this.title = (importing ? this.$t("Importă") : this.$t("Adaugă")) + " " + this.$t(this.collectionName)
        //})
      }
    },
    editData(response, rec, view) {
      this.importing = false
      this.overlay = true
      this.collectionSchema = response
      this.viewCollection = view
      this.$log("editData ", rec)
      this.$log("editData response ", response)
      this.noInventories = 1
      this.record = rec
      this.recordDef = JSON.parse(JSON.stringify(rec))
      this.selectedVersion = this.record._version
      this.recordId = rec._id
      this.etag = rec._etag
      this.collectionAppType = response.owner.app_type
      this.collection = response.collection
      this.table = response.item_url
      this.listContent = response.content
      //this.$log("schemalist", this.collection)
      this.list = []
      //this.$log("edit view ", view)
      if (view != undefined && view != null && view != "") {
        const schema = this.appViewId(view)
        if (schema) {
          this.collectionForms = []
          this.collectionForms.push(schema[0])
          this.changeForm(0)
        }
      } else {
        const schema = this.appViewCollection(this.collection)
        //this.$log("schema reload", schema)
        if (schema.length > 0) {
          this.collectionForms = schema
          if (rec.ctg) {
            if (this.collectionForms.findIndex(itm => itm.ctg == rec.ctg) > -1) {
              if (this.collectionForms.findIndex(itm => itm.ctg == rec.ctg && itm.default) > -1) {
                this.changeForm(this.collectionForms.findIndex(itm => itm.ctg == rec.ctg && itm.default))
              } else {
                this.changeForm(this.collectionForms.findIndex(itm => itm.ctg == rec.ctg))
              }
            } else {
              this.changeForm(this.collectionForms.findIndex(itm => itm.default))
            }
          } else {
            this.changeForm(this.collectionForms.findIndex(itm => itm.default))
          }
        }
      }
      this.$log("edit title ", this.resolvePath("fields.200.0.a.0.val", rec))
      if (this.resolvePath("fields.299.0.a.0.val", rec)) {
        this.title = this.$t("Editează") + " - " + this.resolvePath("fields.299.0.a.0.val", rec)
      } else if (this.resolvePath("fields.200.0.a.0.val", rec)) {
        this.title = this.$t("Editează") + " - " + this.resolvePath("fields.200.0.a.0.val", rec)
      } else {
        this.title = this.$t("Editează") + " - " + this.$t(this.collectionName)
      }
    },
    importRecord(rec) {
      //this.show = false
      this.$log("importRecord ", rec)
      const dic = JSON.parse(JSON.stringify(this.record))
      Object.assign(dic, JSON.parse(JSON.stringify(rec)))
      this.record = { ...dic }
      if (this.recordId) {
        this.editData(this.collectionSchema, { ...dic }, this.viewCollection)
      } else {
        this.addData(this.collectionSchema, this.viewCollection, { ...dic })
      }
    },
    resolvePath(path, obj) {
      return path.split(".").reduce(function(prev, curr) {
        return prev ? prev[curr] : null
      }, obj || self)
    },
    copyData() {
      this.$log("copy data ", this.record)
      this.title = this.$t("Adaugă") + " " + this.$t(this.collectionName)
      this.recordId = 0
      this.record._latest_version = undefined
      this.record._version = undefined
      this.$set(this.record, "_id", undefined)
      this.$set(this.record, "_etag", undefined)
      const flds = ["001", "003", "005", "006", "916", "917"]
      this.$log("copy prefix ", this.prefix)

      if (this.prefix == "mon" || this.prefix == "cln" || this.prefix == "evn") {
        for (const fld in this.record.fields) {
          if (fld.substr(0, 1) == "4") {
            flds.push(fld)
          }
        }
      }
      //this.$log("copy flds ", flds)
      if (this.collectionForms[this.lastInd].view_type == "html") {
        //this.$refs.htmlView.showData(this.record, this.list[0], this.listContent)
        this.$refs.htmlView.copyData(flds)
      } else if (this.collectionForms[this.lastInd].view_type == "data") {
        this.$refs.dataView.copyData(flds)
      } else if (this.collectionForms[this.lastInd].view_type == "marc") {
        this.$refs.marcView.copyData(flds)
      }
    },
    saveData(close) {
      //this.$log("form validate1 ", this.$refs.dataForm.validate())
      //this.$log("form validate2 ", this.$refs.dataForm.$el.querySelector(".v-messages.error--text:first-of-type"))
      this.$log("saveData ", this.table)
      if (this.$refs.dataForm.validate()) {
        if (this.viewType == "html") {
          this.record = this.$refs.htmlView.getRecord()
        } else if (this.viewType == "data") {
          this.record = this.$refs.dataView.getRecord()
        } else if (this.viewType == "marc") {
          this.record = this.$refs.marcView.getRecord()
        }
        if (this.collectionAppType != "system") {
          this.record.company = this.companyId
        }
        this.record._created = undefined
        this.record._links = undefined
        this.record._version = undefined
        this.record._latest_version = undefined
        this.record._updated = undefined
        this.record._status = undefined
        this.record._etag = undefined
        this.$log("this.record ", this.record)
        if (this.record) {
          const formData = new FormData()
          let hasFile = false
          for (const rec in this.record) {
            if (this.record[rec] != undefined) {
              if (this.record[rec] instanceof File) {
                formData.append(rec, this.record[rec])
                this.record[rec] = undefined
                hasFile = true
              }
            }
          }
          this.$log("this.record hasFile", hasFile)
          this.overlay = true
          this.cleanRecord()
          if (this.recordId == 0) {
            this.record._id = undefined
            axios
              .post(this.table, this.record)
              .then(response => {
                this.overlay = false
                if (response.status == 201) {
                  this.$emit("reload", response.data)
                  if (hasFile) {
                    this.postFile(response, formData)
                  }
                  if (this.table == "app_company_old") {
                    axios.post("app_dictionary", {
                      name: "Bibliotecă - Clasă cititor",
                      value: "all_clnClasaCititor",
                      active: true,
                      content: this.$store.getters.dictionary("all_clnClasaCititor"),
                      owner: { user: [], company: response.data._id, app_type: ["ebib"] }
                    })
                    axios.post("app_dictionary", {
                      name: "Gestiune",
                      value: "all_gestiune",
                      active: true,
                      content: this.$store.getters.dictionary("all_gestiune"),
                      owner: { user: [], company: response.data._id, app_type: ["ebib"] }
                    })
                    axios.post("app_dictionary", {
                      name: "Regim împrumut",
                      value: "all_regimImprumut",
                      active: true,
                      content: this.$store.getters.dictionary("all_regimImprumut"),
                      owner: { user: [], company: response.data._id, app_type: ["ebib"] }
                    })
                  }
                  if (close) {
                    this.$emit("close")
                  } else {
                    this.record = response.data
                    this.recordId = this.record._id
                    this.etag = this.record._etag
                    this.changeForm(this.lastInd)
                  }
                }
              })
              .catch(() => {
                this.overlay = false
              })
          } else {
            let met = "PUT"
            if (this.table == "ebib_media" || this.table == "nvd_media") {
              met = "PATCH"
            }
            // axios
            //   .patch(this.table + "/" + this.recordId, this.record, {
            //     headers: {
            //       "If-Match": this.etag
            //     }
            //   })
            axios({
              url: this.table + "/" + this.recordId,
              method: met,
              headers: {
                "If-Match": this.etag
              },
              data: this.record
            })
              .then(response => {
                if (response.status == 200) {
                  this.$emit("reload", response.data)
                  if (hasFile) {
                    this.postFile(response, formData)
                  }
                  if (close) {
                    this.$emit("close")
                  } else {
                    this.record = response.data
                    this.etag = this.record._etag
                    this.changeForm(this.lastInd)
                  }
                }
                this.overlay = false
              })
              .catch(() => {
                //this.$log("error save data ", error)
                this.overlay = false
              })
          }
        }
      } else {
        this.$nextTick(() => {
          const el = this.$refs.dataForm.$el.querySelector(".v-messages.error--text:first-of-type")
          let msg = "Următoarele câmpuri nu sunt completate corect: ###"
          this.$log("scrollIntoView ", el)
          const nlist = this.$refs.dataForm.$el.querySelectorAll(".v-messages.error--text")
          for (let i = 0; i < nlist.length; i++) {
            msg +=
              nlist[i].parentElement.parentElement.parentElement.querySelector(".v-label").innerText +
              " - " +
              nlist[i].querySelector(".v-messages__message").innerText +
              "###"
            this.$log("error ", nlist[i].querySelector(".v-messages__message").innerText)
            //this.$log("list ", nlist[i].parentElement.parentElement.parentElement.querySelector(".v-label").innerText)
          }
          el.scrollIntoView(false)
          EventBus.$emit("show-alert", { message: msg, timeOut: 8000, color: "error" })
        })
      }
    },
    postFile(response, formData) {
      axios
        .patch(this.table + "/" + response.data._id, formData, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "If-Match": response.data._etag
          }
        })
        .then(res => {
          this.$log("response media ", res)
          this.overlay = false
        })
        .catch(error => {
          this.$log("error ", error)
          this.overlay = false
        })
    },
    cleanRecord() {
      if (this.table == "ebib_marc_inv") {
        if (!this.resolvePath("fields.520.0.a.0.val", this.record)) {
          this.record.fields["520"] = undefined
        }
      }
    }
  }
}
</script>
<style scoped></style>

<template>
  <v-list>
    <v-list-item-group v-model="selected" multiple @change="selectedChanged">
      <template v-for="(rec, index) in records">
        <v-list-item :key="rec._id" class="pl-0 pr-2">
          <!-- <v-list-item-avatar tile size="70">
              <v-img
                v-if="rec['fields.856.link']"
                alt="user"
                class="ml-2"
                :src="baseurl + 'coperta/?pre=mon&rid=' + rec._id"
                contain
                width="45px"
                height="70px"
                max-height="70px"
              ></v-img>
            </v-list-item-avatar> -->
          <v-list-item-content>
            <v-list-item-title>
              {{ rec.cln_barcode + " - " + rec.mon_titlu }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ rec.site }}
              {{ " - " + rec.regime }}
            </v-list-item-subtitle>
            <v-list-item-subtitle>{{ rec.user_in + " - " + rec.date_in }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-list-item-action-text>
              <span>
                {{ rec.inv_barcode + " - " + rec.inv_nr }}
              </span>
            </v-list-item-action-text>
            <v-list-item-action-text>
              <span>
                {{ rec.inv_cota }}
              </span>
            </v-list-item-action-text>
            <v-list-item-action-text>
              {{ rec.date_to_return }}
            </v-list-item-action-text>
          </v-list-item-action>
        </v-list-item>

        <v-divider v-if="index < records.length - 1" :key="index"> </v-divider>
      </template>
    </v-list-item-group>
  </v-list>
</template>
<script>
export default {
  filters: {
    limitData(value) {
      if (!value) return ""
      value = value.toString()
      if (value.length > 10) {
        return value.substring(0, 10)
      }
      return value
    }
  },
  props: {
    value: {
      default: () => [],
      type: Array
    },
    baseurl: {
      default: "",
      type: String
    },
    showSelectItem: {
      default: false,
      type: Boolean
    },
    records: {
      default: () => [],
      type: Array
    },
    actions: {
      default: () => [],
      type: Array
    },
    debug: {
      default: false,
      type: Boolean
    }
  },
  data: () => ({
    clicks: 0,
    lastSelected: null,
    selected: [],
    fields: []
  }),
  watch: {
    selected(newv, oldv) {
      if (newv.length != oldv.length) {
        this.$emit("select", this.selected)
      }
    },
    value(newv, oldv) {
      if (oldv != newv) {
        this.selected = []
        this.value.map(itm => this.selected.push(this.records.indexOf(itm)))
      }
    }
  },
  mounted() {
    this.$emit("mounted")
  },
  methods: {
    selectedChanged(e) {
      //this.$log("selectedChanged ", e)
      if (this.clicks == 0) {
        this.clicks++
        setTimeout(
          e => {
            if (this.clicks > 1) {
              if (e.length > this.selected.length) {
                this.$emit("double-click", e[e.length - 1])
              } else {
                this.$emit("double-click", this.lastSelected[this.lastSelected.length - 1])
              }
            }
            this.clicks = 0
          },
          250,
          e
        )
      } else {
        this.clicks++
      }
      if (e.length > 0) {
        this.lastSelected = e
      }
    }
  }
}
</script>
<style scoped></style>

<template>
  <router-view />
</template>

<script>
export default {
  name: "App"
}
</script>
<style>
body {
  overflow: hidden;
  height: 100vh;
}
html {
  overflow: hidden !important;
}
</style>
<style lang="sass">

::-webkit-scrollbar
  width: 10px
  height: 10px

::-webkit-scrollbar-track
  background: #f5f5f5

::-webkit-scrollbar-thumb
  background: #b0b0b0

.theme--light ::-webkit-scrollbar
  width: 10px
  height: 10px

.theme--light ::-webkit-scrollbar-track
  background: #f5f5f5

.theme--light ::-webkit-scrollbar-thumb
  background: #b0b0b0

.theme--light ::-webkit-scrollbar-corner
  background: #f5f5f5

.theme--dark ::-webkit-scrollbar
  width: 10px
  height: 10px

.theme--dark ::-webkit-scrollbar-track
  background: #202020

.theme--dark ::-webkit-scrollbar-thumb
  background: #3e3e3e

.theme--dark ::-webkit-scrollbar-corner
  background: #202020
</style>

<template>
  <v-dialog v-model="show" persistent scrollable max-width="900px">
    <v-card>
      <v-card-title>
        {{ $t("Administrare coperți") }}
        <v-spacer />
        <v-icon color="red" class="mt-n2 mr-n2" large @click="show = false">
          mdi-close
        </v-icon>
      </v-card-title>
      <v-card-text>
        <!-- <v-row> -->
        <draggable class="list-group " tag="v-row" :list="records" @change="dragChange">
          <template v-for="(item, i) in records">
            <v-hover :key="i" v-slot:default="{ hover }">
              <v-col cols="12" sm="6" md="4" lg="3">
                <v-sheet
                  :style="'position: relative;' + (i != records.length - 1 ? 'cursor:move' : '')"
                  class="d-flex justify-center align-center pa-2"
                  elevation="3"
                  width="100%"
                  height="100%"
                  min-height="150px"
                >
                  <v-img
                    v-if="item.link"
                    :src="baseURL + 'file_stream/?f=' + item.link"
                    :height="height"
                    :width="width"
                    contain
                  />
                  <v-menu v-else absolute offset-y style="max-width: 600px">
                    <template v-slot:activator="{ on }">
                      <v-btn v-if="isMobile" color="cyan" fab x-small @click="$refs.file.click()">
                        <v-icon>
                          mdi-plus
                        </v-icon>
                      </v-btn>
                      <v-btn v-else color="cyan" fab x-small v-on="on">
                        <v-icon>
                          mdi-plus
                        </v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="$refs.file.click()">
                        <v-list-item-icon>
                          <v-icon>mdi-upload</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{ $t("Încarcă") }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="$refs.camCapture.open()">
                        <v-list-item-icon>
                          <v-icon>mdi-camera</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{ $t("Capturează") }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-sheet
                    v-if="i == 0 && records.length > 1"
                    style="position: absolute;bottom:8px;left:8px;float:left"
                    color="primary"
                    class="mr-0 mt-0 d-flex px-2 white--text"
                  >
                    {{ $t("Principală") }}
                  </v-sheet>
                  <v-sheet style="position: absolute;top:5px;right:5px;float:right" class="mr-0 mt-0 d-flex">
                    <v-btn v-show="hover && i != records.length - 1" color="error" icon small @click="deleteImage(i)">
                      <v-icon>
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </v-sheet>
                </v-sheet>
              </v-col>
            </v-hover>
          </template>
        </draggable>
        <!-- </v-row> -->
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="success" @click="show = false">
          {{ $t("Închide") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <div v-show="false">
      <input ref="file" type="file" accept="image/*" @change="imageChanged" />
    </div>
    <cam-capture ref="camCapture" @captured="imgCaptured" />
  </v-dialog>
</template>
<script>
import axios from "../../plugins/axios"
import CamCapture from "./CamCapture"
import draggable from "vuedraggable"
import isMobile from "ismobilejs"
export default {
  name: "AddImage",
  components: { CamCapture, draggable },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    height: {
      type: Number,
      default: 50
    },
    width: {
      type: Number,
      default: 100
    }
  },
  data: () => ({ src: "", show: false }),
  computed: {
    records() {
      return [...this.value]
    },
    baseURL() {
      return axios.defaults.baseURL
    },
    companyId() {
      return this.$store.getters.companyId
    },
    isMobile() {
      return isMobile(navigator.userAgent).any
    }
  },
  watch: {
    records() {
      this.$log("records changed")
    }
  },
  methods: {
    deleteImage(i) {
      const arr = [...this.value]
      arr.splice(i, 1)
      this.$emit("input", arr)
    },
    dragChange(e) {
      const arr = [...this.value]
      arr.splice(e.moved.newIndex, 0, arr.splice(e.moved.oldIndex, 1)[0])
      this.$emit("input", arr)
    },
    imageChanged(e) {
      this.$log("imageChanged ", e)
      const formData = new FormData()
      formData.append("company", this.companyId)
      formData.append("content_type", "image/jpeg")
      formData.append("ctg", "imagine")
      formData.append("name", e.target.files[0].name)
      formData.append("file_name", e.target.files[0].name)
      formData.append("file", e.target.files[0])
      axios
        .post("ebib_media/", formData, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          }
        })
        .then(res => {
          this.$log("response media ", res)
          if (res.status == 201) {
            const arr = [...this.value]
            arr[arr.length - 1].link = res.data._id
            arr.push({ link: "" })
            this.$emit("input", arr)
          }
        })
        .catch(error => {
          this.$log("error ", error)
        })
    },
    imgCaptured(e) {
      const arr = [...this.value]
      arr[arr.length - 1].link = e._id
      arr.push({ link: "" })
      this.$emit("input", arr)
    }
  }
}
</script>
